import { ScaleTypes } from '@carbon/charts/interfaces/enums';
import { Alignments } from "@carbon/charts/interfaces";
const loading = false; // setting loading true shows skeleton only


export const donutOne = {
    data: [
        {
          "group": "% Female Members <21 or >40",
          "value": 23
        },
        {
          "group": "% Members 21-40",
          "value": 22
        },
        {
          "group": "extra",
          "value": 55
        }
      ],

      options: {
        "resizable": true,
        "donut": {
          "center": {
            "number": 45,
            "numberFormatter": (x: number) => x.toLocaleString("en-US") + "%"
          },
          "alignment": Alignments.CENTER
        },
        "height": "300px",
        "data": {
          "loading": loading
        },
        "color": {
          "scale": {
            "% Female Members <21 or >40": "#ffbc90",
            "% Members 21-40": "#FF6400",
            "extra": "#C6C9CA"
          }
        }
    }
}

export const donutTwo = {
  data: [
      {
        "group": "% Chronic Members",
        "value": 33
      },
      {
        "group": "Extra",
        "value": 67
      }
    ],

    options: {
      "resizable": true,
      "donut": {
        "center": {
          "number": 33,
          "numberFormatter": (x: number) => x.toLocaleString("en-US") + "%"
        },
        "alignment": Alignments.CENTER
      },
      "height": "300px",
      "data": {
        "loading": loading
      },
	  "color": {
		"scale": {
		  "% Chronic Members": "#9F1853",
		  "Extra": "#C6C9CA"
		}
	  }
  }
}

export const donutThree = {
  data: [
      {
        "group": "% of Members with a Pharmacy Claim",
        "value": 75
      },
      {
        "group": "Extra",
        "value": 25
      }
    ],

    options: {
      "resizable": true,
      "donut": {
        "center": {
          "number": 75,
          "numberFormatter": (x: number) => x.toLocaleString("en-US") + "%"
        },
        "alignment": Alignments.CENTER
      },
      "height": "300px",
      "data": {
        "loading": loading
      },
	  "color": {
		"scale": {
		  "% of Members with a Pharmacy Claim": "#0080E2",
		  "Extra": "#C6C9CA"
		}
	  }
  }
}

export const donutFour = {
  data: [
      {
        "group": " % of Members with $0 Allowed  ",
        "value": 15
      },
      {
        "group": "Extra",
        "value": 85
      }
    ],

    options: {
      "resizable": true,
      "donut": {
        "center": {
          "number": 15,
          "numberFormatter": (x: number) => x.toLocaleString("en-US") + "%"
        },
        "alignment": Alignments.CENTER
      },
      "height": "300px",
      "data": {
        "loading": loading
      },
	  "color": {
		"scale": {
		  "% of Members with $0 Allowed": "#00A663",
		  "Extra": "#C6C9CA"
		}
	  }
  }
}

export const line = {
    data: [
        {
          "group": "Prior",
          "date": "2018-12-31T18:30:00.000Z",
          "temp": 24400
        },
        {
          "group": "Prior",
          "date": "2019-01-31T18:30:00.000Z",
          "temp": 25500
        },
        {
          "group": "Prior",
          "date": "2019-02-28T18:30:00.000Z",
          "temp": 28800
        },
        {
          "group": "Prior",
          "date": "2019-03-31T18:30:00.000Z",
          "temp": 28880
        },
        {
          "group": "Prior",
          "date": "2019-04-30T18:30:00.000Z",
          "temp": 25480
        },
        {
          "group": "Prior",
          "date": "2019-05-31T18:30:00.000Z",
          "temp": 28800
        },
        {
          "group": "Prior",
          "date": "2019-06-30T18:30:00.000Z",
          "temp": 29900
        },
        {
          "group": "Current",
          "date": "2018-12-31T18:30:00.000Z",
          "rainfall": 30000
        },
        {
          "group": "Current",
          "date": "2019-01-31T18:30:00.000Z",
          "rainfall": 30900
        },
        {
          "group": "Current",
          "date": "2019-02-28T18:30:00.000Z",
          "rainfall": 39900
        },
        {
          "group": "Current",
          "date": "2019-03-31T18:30:00.000Z",
          "rainfall": 37700
        },
        {
          "group": "Current",
          "date": "2019-04-30T18:30:00.000Z",
          "rainfall": 38000
        },
        {
          "group": "Current",
          "date": "2019-05-31T18:30:00.000Z",
          "rainfall": 39000
        },
        {
          "group": "Current",
          "date": "2019-06-30T18:30:00.000Z",
          "rainfall": 40900
        }
      ],
      options: {
        "axes": {
          "left": {
            "title": "",
            "mapsTo": "temp"
          },
          "bottom": {
            "scaleType": ScaleTypes.TIME,
            "mapsTo": "date",
            "title": ""
          },
          "right": {
            "title": "",
            "mapsTo": "rainfall",
            "correspondingDatasets": [
              "Current"
            ]
          }
        },
        "curve": "curveMonotoneX",
        "height": "300px",
        "data": {
          "loading": loading
        },
        "color": {
          "scale": {
              "Current": "#0067B5",
              "Prior": "#50BEE1"
          }
        }
      }
}
export const lineFinancial = {
  data: [
    {
      "group": "Prior",
      "date": "Dec",
      "temp": 23
    },
    {
      "group": "Prior",
      "date": "Jan",
      "temp": 15
    },
    {
      "group": "Prior",
      "date": "Feb",
      "temp": 24
    },
    {
      "group": "Prior",
      "date": "Mar",
      "temp": 33
    },
    {
      "group": "Prior",
      "date": "Apr",
      "temp": 23
    },
    {
      "group": "Prior",
      "date": "May",
      "temp": 32
    },
    {
      "group": "Prior",
      "date": "Jun",
      "temp": 23
    },
    {
      "group": "Current",
      "date": "Dec",
      "rainfall": 50
    },
    {
      "group": "Current",
      "date": "Jan",
      "rainfall": 65
    },
    {
      "group": "Current",
      "date": "Feb",
      "rainfall": 35
    },
    {
      "group": "Current",
      "date": "Mar",
      "rainfall": 43
    },
    {
      "group": "Current",
      "date": "Apr",
      "rainfall": 53
    },
    {
      "group": "Current",
      "date": "May",
      "rainfall": 19
    },
    {
      "group": "Current",
      "date": "Jun",
      "rainfall": 13
    }
  ],
  options: {
    "title": "Net Paid PMPM by Month",
    "axes": {
      "left": {
        "title": "",
        "mapsTo": "temp"
      },
      "bottom": {
        "scaleType": ScaleTypes.LABELS,
        "mapsTo": "date",
        "title": ""
      },
      "right": {
        "title": "",
        "mapsTo": "rainfall",
        "correspondingDatasets": [
          "Current"
        ]
      }
    },
    "curve": "curveMonotoneX",
    "height": "400px",
    "data": {
      "loading": loading
    },
  },
  optionsPdf: {
    "title": "Net Paid PMPM by Month",
    "axes": {
      "left": {
        "title": "",
        "mapsTo": "temp"
      },
      "bottom": {
        "scaleType": ScaleTypes.LABELS,
        "mapsTo": "date",
        "title": ""
      },
      "right": {
        "title": "",
        "mapsTo": "rainfall",
        "correspondingDatasets": [
          "Current"
        ]
      }
    },
    "curve": "curveMonotoneX",
    "height": "300px",
    "data": {
      "loading": loading
    },
  }
}

export const stackedBarChart = {
  data: [
    {
      "group": "Non-HCC",
      "key": "Plan A",
      "value": 40
    },
    {
      "group": "Non-HCC",
      "key": "Plan B",
      "value": 40
    },
    {
      "group": "Non-HCC",
      "key": "Plan C",
      "value": 40
    },
    {
      "group": "HCC",
      "key": "Plan A",
      "value": 20
    },
    {
      "group": "HCC",
      "key": "Plan B",
      "value": 22
    },
    {
      "group": "HCC",
      "key": "Plan C",
      "value": 25
    },
    {
      "group": "Non-Specialty Rx",
      "key": "Plan A",
      "value": 10
    },
    {
      "group": "Non-Specialty Rx",
      "key": "Plan B",
      "value": 10
    },
    {
      "group": "Non-Specialty Rx",
      "key": "Plan C",
      "value": 10
    },
    {
      "group": "Specialty Rx",
      "key": "Plan A",
      "value": 10
    },
    {
      "group": "Specialty Rx",
      "key": "Plan B",
      "value": 12
    },
    {
      "group": "Specialty Rx",
      "key": "Plan C",
      "value": 15
    }
  ],
  options: {
    "title": "Combined Cost - Net Paid PMPM",
    "axes": {
      "left": {
        "mapsTo": "value",
        "stacked": true
      },
      "bottom": {
        "mapsTo": "key",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "height": "400px",
    "data": {
      "loading": loading
    },
    "color": {
      "scale": {
          "Non-HCC": "#004877",
          "HCC": "#50BEE1",
          "Non-Specialty Rx": "#FFA200",
          "Specialty Rx": "#FFC800",
      }
    }
  },
  optionsPdf: {
    "title": "Combined Cost - Net Paid PMPM",
    "axes": {
      "left": {
        "mapsTo": "value",
        "stacked": true
      },
      "bottom": {
        "mapsTo": "key",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "height": "300px",
    "data": {
      "loading": loading
    },
    "color": {
      "scale": {
          "Non-HCC": "#004877",
          "HCC": "#50BEE1",
          "Non-Specialty Rx": "#FFA200",
          "Specialty Rx": "#FFC800",
      }
    }
  }
}


export const ExploymentPlan = {
	"data": [
	  {
		"group": "New Enrollment",
		"relationship": "Plan A",
		"value": 10
	  },
	  {
		"group": "New Enrollment",
		"relationship": "Plan B",
		"value": 10
	  },
	  {
		"group": "New Enrollment",
		"relationship": "Plan C",
		"value": 10
	  },
	  {
		"group": "Previous Enrollment",
		"relationship": "Plan A",
		"value": 45
	  },
	  {
		"group": "Previous Enrollment",
		"relationship": "Plan B",
		"value": 30
	  },
	  {
		"group": "Previous Enrollment",
		"relationship": "Plan C",
		"value": 30
	  },
	],
	"options": {
	  "axes": {
		"bottom": {
		  "mapsTo": "relationship",
		  "scaleType": ScaleTypes.LABELS
		},
		"left": {
		  "mapsTo": "value",
		  "scaleType": ScaleTypes.LINEAR,
		  "percentage":  true,
		  "ticks": {
			"values": [ 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100 ],
			"formatter": ((value: any)=>{ return value+'%' })
		  },
		}
	  },
	  "height": "300px",	
	  "color": {
		"scale": {
		  "New Enrollment": "#0080E2",
		  "Previous Enrollment": "#FFA200",
		}
	  },
	  "legend": {
		"truncation": {
		  "numCharacter": 50
		}
	  },
	  "bars": {
		"width": 20
	  }
	}
 }

export const simpleBarChart = {
  data: [
    {
      "group": "Percent of Population",
      "relationship" : "EE",
      "value": 40
    },
    {
      "group": "Percent of Population",
      "relationship" : "SP",
      "value": 30
    },
	{
		"group": "Percent of Population",
    "relationship" : "DEP",
		"value": 30
	}
  ],
  options: {
    "axes": {
      "left": {
        "mapsTo": "value"
      },
      "bottom": {
        "mapsTo": "relationship",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "height": "300px",
    "data": {
      "loading": loading
    },
	"color": {
		"scale": {
			"Percent of Population": "#FFA200"
		}
	  }
  }
}

export const groupedBarChart = {
  data: [
    {
      "group": "Prior",
      "key": "$0",
      "value": 33
    },
	{
		"group": "Current",
		"key": "$0",
		"value": 30
	  },
    {
      "group": "Prior",
      "key": "$1-$250",
      "value": 24
    },
	{
		"group": "Current",
		"key": "$1-$250",
		"value": 25
	},
    {
      "group": "Prior",
      "key": "$251-$500",
      "value": 28
    },
	{
		"group": "Current",
		"key": "$251-$500",
		"value": 27
	},
	{
		"group": "Prior",
		"key": "$501-$1,000",
		"value": 8
	},
	{
		"group": "Current",
		"key": "$501-$1,000",
		"value": 6
	},
	{
		"group": "Prior",
		"key": "$1,501-2,000",
		"value": 5
	},
	{
		"group": "Current",
		"key": "$1,501-2,000",
		"value": 4
	},
	{
		"group": "Prior",
		"key": "$1,001-$1,500",
		"value": 3
	},
	{
		"group": "Current",
		"key": "$1,001-$1,500",
		"value": 2
	},
	{
		"group": "Prior",
		"key": "$2,001-$2,500",
		"value": 3
	},
	{
		"group": "Current",
		"key": "$2,001-$2,500",
		"value": 2
	},
	{
		"group": "Prior",
		"key": "$2,501-$3,000",
		"value": 3
	},
	{
		"group": "Current",
		"key": "$2,501-$3,000",
		"value": 2
	},
	{
		"group": "Prior",
		"key": "$3,001-$4,000",
		"value": 3
	},
	{
		"group": "Current",
		"key": "$3,001-$4,000",
		"value": 2
	},
	{
		"group": "Prior",
		"key": "$4,001-$5,000",
		"value": 3
	},
	{
		"group": "Current",
		"key": "$4,001-$5,000",
		"value": 2
	},
	{
		"group": "Prior",
		"key": "$5,001-$7,500",
		"value": 3
	},
	{
		"group": "Current",
		"key": "$5,001-$7,500",
		"value": 2
	},
	{
		"group": "Prior",
		"key": "$&,501-$10,000",
		"value": 3
	},
	{
		"group": "Current",
		"key": "$&,501-$10,000",
		"value": 2
	},
	{
		"group": "Prior",
		"key": "$10,001-$15,000",
		"value": 4
	},
	{
		"group": "Current",
		"key": "$10,001-$15,000",
		"value": 3
	},
	{
		"group": "Prior",
		"key": "$15,001-$20,000",
		"value": 3
	},
	{
		"group": "Current",
		"key": "$15,001-$20,000",
		"value": 2
	},
	{
		"group": "Prior",
		"key": "$20,001-$25,000",
		"value": 5
	},
	{
		"group": "Current",
		"key": "$20,001-$25,000",
		"value": 3
	},
	{
		"group": "Prior",
		"key": "$25,001-$50,000",
		"value": 2
	},
	{
		"group": "Current",
		"key": "$25,001-$50,000",
		"value": 4
	},
	{
		"group": "Prior",
		"key": "$50,001-$75,000",
		"value": 2
	},
	{
		"group": "Current",
		"key": "$50,001-$75,000",
		"value": 4
	},
	{
		"group": "Prior",
		"key": "$75,001-$100,000",
		"value": 1
	},
	{
		"group": "Current",
		"key": "$75,001-$100,000",
		"value": 2
	},
	{
		"group": "Prior",
		"key": "$100,001-$150,000",
		"value": 1
	},
	{
		"group": "Current",
		"key": "$100,001-$150,000",
		"value": 2
	},
	{
		"group": "Prior",
		"key": "$150,001-$500,000",
		"value": 1
	},
	{
		"group": "Current",
		"key": "$150,001-$500,000",
		"value": 2
	},
	{
		"group": "Prior",
		"key": " >$500,001",
		"value": 1
	},
	{
		"group": "Current",
		"key": " >$500,001",
		"value": 2
	},
  ],
  options: {
    "axes": {
      "left": {
        "mapsTo": "value"
      },
      "bottom": {
        "scaleType": ScaleTypes.LABELS,
        "mapsTo": "key"
      }
    },
    "height": "400px",
    "data": {
      "loading": loading
    },
	"color": {
		"scale": {
			"Prior": "#50BEE1",
			"Current": "#0067B5"
		}
	}
  }
}

export const customDomainLine = {
  data: [
    {
      "group": "Employee",
      "key": "Prior",
      "value": 450
    },
    {
      "group": "Employee",
      "key": "Current",
      "value": 410
    },
    {
      "group": "Spouse",
      "key": "Prior",
      "value": 300
    },
    {
      "group": "Spouse",
      "key": "Current",
      "value": 350
    },
    {
      "group": "Dependent",
      "key": "Prior",
      "value": 150
    },
    {
      "group": "Dependent",
      "key": "Current",
      "value": 200
    }
  ],
  options: {
    "title": "Net Paid PMPM by Relationship",
    "axes": {
      "bottom": {
        "mapsTo": "key",
        "scaleType": ScaleTypes.LABELS,
        "domain": [
          "Prior",
          "Current"
        ]
      },
      "left": {
        "domain": [
          100,
          500
        ],
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR
      }
    },
    "color": {
      "scale": {
          "Employee": "#004877",
          "Spouse": "#50BEE1",
          "Dependent": "#0080E2"
      }
    },
    "height": "400px"
  },
  optionsPdf: {
    "title": "Net Paid PMPM by Relationship",
    "axes": {
      "bottom": {
        "mapsTo": "key",
        "scaleType": ScaleTypes.LABELS,
        "domain": [
          "Prior",
          "Current"
        ]
      },
      "left": {
        "domain": [
          100,
          500
        ],
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR
      }
    },
    "color": {
      "scale": {
          "Employee": "#004877",
          "Spouse": "#50BEE1",
          "Dependent": "#0080E2"
      }
    },
    "height": "300px"
  }
}


export const customDomainLine2 = {
  data: [
    {
      "group": "EE + Family",
      "key": "Prior",
      "value": 450
    },
    {
      "group": "EE + Family",
      "key": "Current",
      "value": 410
    },
    {
      "group": "EE + SP",
      "key": "Prior",
      "value": 300
    },
    {
      "group": "EE + SP",
      "key": "Current",
      "value": 350
    },
    {
      "group": "EE",
      "key": "Prior",
      "value": 150
    },
    {
      "group": "EE",
      "key": "Current",
      "value": 200
    }
  ],
  options: {
    "title": "Net Paid PMPM by Tier",
    "axes": {
      "bottom": {
        "mapsTo": "key",
        "scaleType": ScaleTypes.LABELS,
        "domain": [
          "Prior",
          "Current"
        ]
      },
      "left": {
        "domain": [
          100,
          500
        ],
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR
      }
    },
    "color": {
      "scale": {
          "EE + Family": "#004877",
          "EE + SP": "#50BEE1",
          "EE": "#0080E2"
      }
    },
    "height": "400px"
  },
    optionsPdf: {
    "title": "Net Paid PMPM by Tier",
    "axes": {
      "bottom": {
        "mapsTo": "key",
        "scaleType": ScaleTypes.LABELS,
        "domain": [
          "Prior",
          "Current"
        ]
      },
      "left": {
        "domain": [
          100,
          500
        ],
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR
      }
    },
    "color": {
      "scale": {
          "EE + Family": "#004877",
          "EE + SP": "#50BEE1",
          "EE": "#0080E2"
      }
    },
    "height": "300px"
  }
}

export const UtilizationBarChart = {
  data: [
    {
      "group": "Employee",
      "key": "ED",
      "value": 35
    },
    {
      "group": "Employee",
      "key": "UC",
      "value": 22
    },
    {
      "group": "Employee",
      "key": "PCP",
      "value": 20
    },
    {
      "group": "Employee",
      "key": "Walk-in",
      "value": 42
    },
    {
      "group": "Employee",
      "key": "Telemed",
      "value": 42
    },
    {
      "group": "Spouse",
      "key": "ED",
      "value": 45
    },
    {
      "group": "Spouse",
      "key": "UC",
      "value": 55
    },
    {
      "group": "Spouse",
      "key": "PCP",
      "value": 35
    },
    {
      "group": "Spouse",
      "key": "Walk-in",
      "value": 40
    },
    {
      "group": "Spouse",
      "key": "Telemed",
      "value": 40
    },
    {
      "group": "Dependent",
      "key": "ED",
      "value": 20
    },
    {
      "group": "Dependent",
      "key": "UC",
      "value": 23
    },
    {
      "group": "Dependent",
      "key": "PCP",
      "value": 45
    },
    {
      "group": "Dependent",
      "key": "Walk-in",
      "value": 18
    },
    {
      "group": "Dependent",
      "key": "Telemed",
      "value": 18
    }
  ],
  options: {
    // "title": "Emergency Department & Urgent Care",
    "axes": {
      "left": {
        "mapsTo": "value",
        "stacked": true,
        "ticks": {
          values: [0, 20, 40, 60, 80, 100],
          formatter: ((value: any)=>{return value+'%'})
        },
      },
      "bottom": {
        "mapsTo": "key",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "color": {
      "scale": {
        "Employee": "#004877",
        "Spouse": "#50BEE1",
        "Dependent": "#C6C9CA"
      }
    },
    "height": "300px",
    "data": {
      "loading": loading
    },
  }
}

export const EmergencyDepartmentAndUrgentCare = {
  data: [
    {
      "group": "Emergency Department",
      "key": "Plan A",
      "value": 780
    },
    {
      "group": "Emergency Department",
      "key": "Plan B",
      "value": 450
    },
    {
      "group": "Emergency Department",
      "key": "Plan C",
      "value": 580
    },
    {
      "group": "Urgent Care",
      "key": "Plan A",
      "value": 450
    },
    {
      "group": "Urgent Care",
      "key": "Plan B",
      "value": 250
    },
    {
      "group": "Urgent Care",
      "key": "Plan C",
      "value": 250
    }
  ],
  options: {
    // "title": "Emergency Department & Urgent Care",
    "axes": {
      "left": {
        "mapsTo": "value",
        "ticks": {
          "values": [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]
        }
      },
      "bottom": {
        "mapsTo": "key",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "color": {
      "scale": {
        "Emergency Department": "#0080E2",
        "Urgent Care": "#FF6400"
      }
    },
    "height": "300px",
    "data": {
      "loading": loading
    },
    "bars": {
      "maxWidth": 30
    }
  }
}

export const EmergenctAndUrgentCare = {
  data: [
    {
      "group": "Prior",
      "key": "Symptons, signs, ill-defined conditions",
      "value": 105
    },
    {
      "group": "Prior",
      "key": "Diseases of the Heart",
      "value": 65
    },
    {
      "group": "Prior",
      "key": "Diseases of the Urinary System",
      "value": 80
    },
    {
      "group": "Prior",
      "key": "Lower GI Disorders",
      "value": 65
    },
    {
      "group": "Prior",
      "key": "Fractures",
      "value": 80
    },
    {
      "group": "Prior",
      "key": "Respiratory infections",
      "value": 65
    },
    {
      "group": "Prior",
      "key": "Complications related to pregnancy",
      "value": 75
    },
    {
      "group": "Prior",
      "key": "Open wounds",
      "value": 78
    },
    {
      "group": "Prior",
      "key": "Diseases of female genital organs",
      "value": 84
    },
    {
      "group": "Current",
      "key": "Symptons, signs, ill-defined conditions",
      "value": 108
    },
    {
      "group": "Current",
      "key": "Diseases of the Heart",
      "value": 73
    },
    {
      "group": "Current",
      "key": "Diseases of the Urinary System",
      "value": 89
    },
    {
      "group": "Current",
      "key": "Lower GI Disorders",
      "value": 89
    },
    {
      "group": "Current",
      "key": "Fractures",
      "value": 81
    },
    {
      "group": "Current",
      "key": "Respiratory infections",
      "value": 79
    },
    {
      "group": "Current",
      "key": "Complications related to pregnancy",
      "value": 77
    },
    {
      "group": "Current",
      "key": "Open wounds",
      "value": 82
    },
    {
      "group": "Current",
      "key": "Diseases of female genital organs",
      "value": 89
    },
    {
      "group": "Norm",
      "key": "Symptons, signs, ill-defined conditions",
      "value": 61
    },
    {
      "group": "Norm",
      "key": "Diseases of the Heart",
      "value": 43
    },
    {
      "group": "Norm",
      "key": "Diseases of the Urinary System",
      "value": 49
    },
    {
      "group": "Norm",
      "key": "Lower GI Disorders",
      "value": 55
    },
    {
      "group": "Norm",
      "key": "Fractures",
      "value": 42
    },
    {
      "group": "Norm",
      "key": "Respiratory infections",
      "value": 41
    },
    {
      "group": "Norm",
      "key": "Complications related to pregnancy",
      "value": 41
    },
    {
      "group": "Norm",
      "key": "Open wounds",
      "value": 49
    },
    {
      "group": "Norm",
      "key": "Diseases of female genital organs",
      "value": 80
    }
  ],
  options: {
    // "title": "Emergenct & Urgent Care",
    "axes": {
      "left": {
        "mapsTo": "value",
        "ticks": {
          "values": [0, 20, 40, 60, 80, 100, 120, 140],
          formatter: ((value:any)=>{return '$'+value})
        }
      },
      "bottom": {
        "mapsTo": "key",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "color": {
      "scale": {
        "Prior": "#004877",
        "Current": "#50BEE1",
        "Norm": "#FFA200"
      }
    },
    "height": "300px",
    "data": {
      "loading": loading
    },
  }
}

export const FrequentFlyersTreeChart = {
  data: [{
    "name": "Cancer",
    "children": [
      {
        "name": "Cancer",
        "value": 50000000,
        "showLabel": true
      }
    ]
  },
  {
    "name": "Diabetes without",
    "children": [
      {
        "name": "Diabetes without...",
        "value": 20000000,
        "showLabel": true
      }
    ]
  },
  {
    "name": "Depression, Sub...",
    "children": [
      {
        "name": "Depression, Sub...",
        "value": 20000000,
        "showLabel": true
      }
    ]
  },
  {
    "name": "Hypertension",
    "children": [
      {
        "name": "Hypertension",
        "value": 30000000,
        "showLabel": true
      }
    ]
  },
  {
    "name": "Healthy Female",
    "children": [
      {
        "name": "Healthy Female $40,000,000",
        "value": 30000000,
        "showLabel": true
      }
    ]
  },
  {
    "name": "Neurologi...",
    "children": [
      {
        "name": "Neurologi...",
        "value": 2500000,
        "showLabel": true
      }
    ]
  },
  {
    "name": "Renal Fail...",
    "children": [
      {
        "name": "Renal Fail...",
        "value": 2300000,
        "showLabel": true
      }
    ]
  },
  {
    "name": "Healthy F...",
    "children": [
      {
        "name": "Healthy F...",
        "value": 2300000,
        "showLabel": true
      }
    ]
  },
  {
    "name": "Healthy F..",
    "children": [
      {
        "name": "Healthy F..",
        "value": 2300000,
        "showLabel": true
      }
    ]
  },
  {
    "name": "name",
    "children": [
      {
        "name": "name",
        "value": 900000,
        "showLabel": false
      }
    ]
  }
  ],
  options: {
    // "title": "Frequent Fliers",
    "height": "400px",
    "color": {
      "scale": {
        "Cancer": "#0080E2",
        "Diabetes without...": "#004D88",
        "Depression, Sub...": "#004071",
        "Hypertension": "#0073CB",
        "Healthy Female": "#005A9E",
        "Neurologi...": "#00335A",
        "Renal Fail...": "#002644",
        "Healthy F...": "#001A2D",
        "Healthy F..": "#000D17",
        "name": "#00150D"
      }
    },
    "legend": {
      "enabled": false
    }
  }
}

export const AllowedPMPMVBenchmark = {
  "data": [
    {
      "group": "Client Position",
      "relationship": "Emergency Department",
      "actual": 65
    },
    {
      "group": "Loosely Managed",
      "relationship": "Emergency Department",
      "value": 10
    },
    {
      "group": "10% Managed",
      "relationship": "Emergency Department",
      "value": 10
    },
    {
      "group": "20% Managed",
      "relationship": "Emergency Department",
      "value": 10
    },
    {
      "group": "30% Managed",
      "relationship": "Emergency Department",
      "value": 10
    },
    {
      "group": "40% Managed",
      "relationship": "Emergency Department",
      "value": 10
    },
    {
      "group": "50% Managed",
      "relationship": "Emergency Department",
      "value": 10
    },
    {
      "group": "60% Managed",
      "relationship": "Emergency Department",
      "value": 10
    },
    {
      "group": "70% Managed",
      "relationship": "Emergency Department",
      "value": 10
    },
    {
      "group": "80% Managed",
      "relationship": "Emergency Department",
      "value": 10
    },
    {
      "group": "90% Managed",
      "relationship": "Emergency Department",
      "value": 10
    },
    {
      "group": "Highly Managed",
      "relationship": "Emergency Department",
      "value": 10
    },
    {
      "group": "Client Position",
      "relationship": "Urgent Care",
      "actual": 75
    },
    {
      "group": "Loosely Managed",
      "relationship": "Urgent Care",
      "value": 10
    },
    {
      "group": "10% Managed",
      "relationship": "Urgent Care",
      "value": 10
    },
    {
      "group": "20% Managed",
      "relationship": "Urgent Care",
      "value": 10
    },
    {
      "group": "30% Managed",
      "relationship": "Urgent Care",
      "value": 10
    },
    {
      "group": "40% Managed",
      "relationship": "Urgent Care",
      "value": 10
    },
    {
      "group": "50% Managed",
      "relationship": "Urgent Care",
      "value": 10
    },
    {
      "group": "60% Managed",
      "relationship": "Urgent Care",
      "value": 10
    },
    {
      "group": "70% Managed",
      "relationship": "Urgent Care",
      "value": 10
    },
    {
      "group": "80% Managed",
      "relationship": "Urgent Care",
      "value": 10
    },
    {
      "group": "90% Managed",
      "relationship": "Urgent Care",
      "value": 10
    },
    {
      "group": "Highly Managed",
      "relationship": "Urgent Care",
      "value": 10
    },
    {
      "group": "Client Position",
      "relationship": "Physician Office",
      "actual": 98
    },
    {
      "group": "Loosely Managed",
      "relationship": "Physician Office",
      "value": 10
    },
    {
      "group": "10% Managed",
      "relationship": "Physician Office",
      "value": 10
    },
    {
      "group": "20% Managed",
      "relationship": "Physician Office",
      "value": 10
    },
    {
      "group": "30% Managed",
      "relationship": "Physician Office",
      "value": 10
    },
    {
      "group": "40% Managed",
      "relationship": "Physician Office",
      "value": 10
    },
    {
      "group": "50% Managed",
      "relationship": "Physician Office",
      "value": 10
    },
    {
      "group": "60% Managed",
      "relationship": "Physician Office",
      "value": 10
    },
    {
      "group": "70% Managed",
      "relationship": "Physician Office",
      "value": 10
    },
    {
      "group": "80% Managed",
      "relationship": "Physician Office",
      "value": 10
    },
    {
      "group": "90% Managed",
      "relationship": "Physician Office",
      "value": 10
    },
    {
      "group": "Highly Managed",
      "relationship": "Physician Office",
      "value": 10
    }
  ],
  "options": {
    "axes": {
      "top": {
        "mapsTo": "value",
        "correspondingDatasets": [
          "Loosely Managed",
          "10% Managed",
          "20% Managed",
          "30% Managed",
          "40% Managed",
          "50% Managed",
          "60% Managed",
          "70% Managed",
          "80% Managed",
          "90% Managed",
          "Highly Managed",
        ],
        "stacked": true,
        "domain": [0, 100],
        "visible": false
      },
      "bottom": {
        "mapsTo": "actual",
        "correspondingDatasets": [
          "Client Position"
        ],
        "domain": [0, 100],
        "visible": false
      },
      "left": {
        "mapsTo": "relationship",
        "scaleType": ScaleTypes.LABELS,
        "domain": ['Emergency Department', 'Urgent Care', 'Physician Office']
      }
    },
    "comboChartTypes": [
      {
        "type": "stacked-bar",
        "correspondingDatasets": [
          "Loosely Managed",
          "10% Managed",
          "20% Managed",
          "30% Managed",
          "40% Managed",
          "50% Managed",
          "60% Managed",
          "70% Managed",
          "80% Managed",
          "90% Managed",
          "Highly Managed",
        ]
      },
      {
        "type": "line",
        "correspondingDatasets": [
          "Client Position"
        ],
        "options": {
          "points": {
            "radius": 7
          }
        },
      }
    ],
    "height": "400px",
    "bars": {
      "width": 60
    },
    "color": {
      "scale": {
        "Client Position": "#0080E2",
        "Loosely Managed": "#A52040",
        "10% Managed": "#C45758",
        "20% Managed": "#DB816B",
        "30% Managed": "#ECA178",
        "40% Managed": "#FFC387",
        "50% Managed": "#FFC800",
        "60% Managed": "#B1AC14",
        "70% Managed": "#739523",
        "80% Managed": "#43842F",
        "90% Managed": "#006B3F",
        "Highly Managed": "#125203"

      }
    },
    "grid": {
      "x": {
        "enabled": false
      },
      "y": {
        "enabled": false
      },
      "getFillColor": {
        "defaultFillColor": "#0080E2"
      }
    }
  }
}

export const OutpatientAllowedPMPMVBenchmark = {
  "data": [
    {
      "group": "Client Position",
      "relationship": "Imaging",
      "actual": 65
    },
    {
      "group": "Loosely Managed",
      "relationship": "Imaging",
      "value": 10
    },
    {
      "group": "10% Managed",
      "relationship": "Imaging",
      "value": 10
    },
    {
      "group": "20% Managed",
      "relationship": "Imaging",
      "value": 10
    },
    {
      "group": "30% Managed",
      "relationship": "Imaging",
      "value": 10
    },
    {
      "group": "40% Managed",
      "relationship": "Imaging",
      "value": 10
    },
    {
      "group": "50% Managed",
      "relationship": "Imaging",
      "value": 10
    },
    {
      "group": "60% Managed",
      "relationship": "Imaging",
      "value": 10
    },
    {
      "group": "70% Managed",
      "relationship": "Imaging",
      "value": 10
    },
    {
      "group": "80% Managed",
      "relationship": "Imaging",
      "value": 10
    },
    {
      "group": "90% Managed",
      "relationship": "Imaging",
      "value": 10
    },
    {
      "group": "Highly Managed",
      "relationship": "Imaging",
      "value": 10
    },
    {
      "group": "Client Position",
      "relationship": "Lab",
      "actual": 75
    },
    {
      "group": "Loosely Managed",
      "relationship": "Lab",
      "value": 10
    },
    {
      "group": "10% Managed",
      "relationship": "Lab",
      "value": 10
    },
    {
      "group": "20% Managed",
      "relationship": "Lab",
      "value": 10
    },
    {
      "group": "30% Managed",
      "relationship": "Lab",
      "value": 10
    },
    {
      "group": "40% Managed",
      "relationship": "Lab",
      "value": 10
    },
    {
      "group": "50% Managed",
      "relationship": "Lab",
      "value": 10
    },
    {
      "group": "60% Managed",
      "relationship": "Lab",
      "value": 10
    },
    {
      "group": "70% Managed",
      "relationship": "Lab",
      "value": 10
    },
    {
      "group": "80% Managed",
      "relationship": "Lab",
      "value": 10
    },
    {
      "group": "90% Managed",
      "relationship": "Lab",
      "value": 10
    },
    {
      "group": "Highly Managed",
      "relationship": "Lab",
      "value": 10
    },
    {
      "group": "Client Position",
      "relationship": "Outpatient Surgery",
      "actual": 98
    },
    {
      "group": "Loosely Managed",
      "relationship": "Outpatient Surgery",
      "value": 10
    },
    {
      "group": "10% Managed",
      "relationship": "Outpatient Surgery",
      "value": 10
    },
    {
      "group": "20% Managed",
      "relationship": "Outpatient Surgery",
      "value": 10
    },
    {
      "group": "30% Managed",
      "relationship": "Outpatient Surgery",
      "value": 10
    },
    {
      "group": "40% Managed",
      "relationship": "Outpatient Surgery",
      "value": 10
    },
    {
      "group": "50% Managed",
      "relationship": "Outpatient Surgery",
      "value": 10
    },
    {
      "group": "60% Managed",
      "relationship": "Outpatient Surgery",
      "value": 10
    },
    {
      "group": "70% Managed",
      "relationship": "Outpatient Surgery",
      "value": 10
    },
    {
      "group": "80% Managed",
      "relationship": "Outpatient Surgery",
      "value": 10
    },
    {
      "group": "90% Managed",
      "relationship": "Outpatient Surgery",
      "value": 10
    },
    {
      "group": "Highly Managed",
      "relationship": "Outpatient Surgery",
      "value": 10
    }
  ],
  "options": {
    "axes": {
      "top": {
        "mapsTo": "value",
        "correspondingDatasets": [
          "Loosely Managed",
          "10% Managed",
          "20% Managed",
          "30% Managed",
          "40% Managed",
          "50% Managed",
          "60% Managed",
          "70% Managed",
          "80% Managed",
          "90% Managed",
          "Highly Managed",
        ],
        "stacked": true,
        "domain": [0, 100],
        "visible": false
      },
      "bottom": {
        "mapsTo": "actual",
        "correspondingDatasets": [
          "Client Position"
        ],
        "domain": [0, 100],
        "visible": false
      },
      "left": {
        "mapsTo": "relationship",
        "scaleType": ScaleTypes.LABELS,
        "domain": ['Imaging', 'Lab', 'Outpatient Surgery']
      }
    },
    "comboChartTypes": [
      {
        "type": "stacked-bar",
        "correspondingDatasets": [
          "Loosely Managed",
          "10% Managed",
          "20% Managed",
          "30% Managed",
          "40% Managed",
          "50% Managed",
          "60% Managed",
          "70% Managed",
          "80% Managed",
          "90% Managed",
          "Highly Managed",
        ]
      },
      {
        "type": "line",
        "correspondingDatasets": [
          "Client Position"
        ],
        "options": {
          "points": {
            "radius": 7
          }
        },
      }
    ],
    "height": "400px",
    "bars": {
      "width": 60
    },
    "color": {
      "scale": {
        "Client Position": "#0080E2",
        "Loosely Managed": "#A52040",
        "10% Managed": "#C45758",
        "20% Managed": "#DB816B",
        "30% Managed": "#ECA178",
        "40% Managed": "#FFC387",
        "50% Managed": "#FFC800",
        "60% Managed": "#B1AC14",
        "70% Managed": "#739523",
        "80% Managed": "#43842F",
        "90% Managed": "#006B3F",
        "Highly Managed": "#125203"

      }
    },
    "grid": {
      "x": {
        "enabled": false
      },
      "y": {
        "enabled": false
      },
      "getFillColor": {
        "defaultFillColor": "#0080E2"
      }
    }
  }
}

export const OutpatientUtilizationBenchmark = {
  "data": [
    {
      "group": "Client Position",
      "relationship": "Imaging",
      "actual": 65
    },
    {
      "group": "Loosely Managed",
      "relationship": "Imaging",
      "value": 10
    },
    {
      "group": "10% Managed",
      "relationship": "Imaging",
      "value": 10
    },
    {
      "group": "20% Managed",
      "relationship": "Imaging",
      "value": 10
    },
    {
      "group": "30% Managed",
      "relationship": "Imaging",
      "value": 10
    },
    {
      "group": "40% Managed",
      "relationship": "Imaging",
      "value": 10
    },
    {
      "group": "50% Managed",
      "relationship": "Imaging",
      "value": 10
    },
    {
      "group": "60% Managed",
      "relationship": "Imaging",
      "value": 10
    },
    {
      "group": "70% Managed",
      "relationship": "Imaging",
      "value": 10
    },
    {
      "group": "80% Managed",
      "relationship": "Imaging",
      "value": 10
    },
    {
      "group": "90% Managed",
      "relationship": "Imaging",
      "value": 10
    },
    {
      "group": "Highly Managed",
      "relationship": "Imaging",
      "value": 10
    },
    {
      "group": "Client Position",
      "relationship": "Lab",
      "actual": 75
    },
    {
      "group": "Loosely Managed",
      "relationship": "Lab",
      "value": 10
    },
    {
      "group": "10% Managed",
      "relationship": "Lab",
      "value": 10
    },
    {
      "group": "20% Managed",
      "relationship": "Lab",
      "value": 10
    },
    {
      "group": "30% Managed",
      "relationship": "Lab",
      "value": 10
    },
    {
      "group": "40% Managed",
      "relationship": "Lab",
      "value": 10
    },
    {
      "group": "50% Managed",
      "relationship": "Lab",
      "value": 10
    },
    {
      "group": "60% Managed",
      "relationship": "Lab",
      "value": 10
    },
    {
      "group": "70% Managed",
      "relationship": "Lab",
      "value": 10
    },
    {
      "group": "80% Managed",
      "relationship": "Lab",
      "value": 10
    },
    {
      "group": "90% Managed",
      "relationship": "Lab",
      "value": 10
    },
    {
      "group": "Highly Managed",
      "relationship": "Lab",
      "value": 10
    },
    {
      "group": "Client Position",
      "relationship": "Outpatient Surgery",
      "actual": 98
    },
    {
      "group": "Loosely Managed",
      "relationship": "Outpatient Surgery",
      "value": 10
    },
    {
      "group": "10% Managed",
      "relationship": "Outpatient Surgery",
      "value": 10
    },
    {
      "group": "20% Managed",
      "relationship": "Outpatient Surgery",
      "value": 10
    },
    {
      "group": "30% Managed",
      "relationship": "Outpatient Surgery",
      "value": 10
    },
    {
      "group": "40% Managed",
      "relationship": "Outpatient Surgery",
      "value": 10
    },
    {
      "group": "50% Managed",
      "relationship": "Outpatient Surgery",
      "value": 10
    },
    {
      "group": "60% Managed",
      "relationship": "Outpatient Surgery",
      "value": 10
    },
    {
      "group": "70% Managed",
      "relationship": "Outpatient Surgery",
      "value": 10
    },
    {
      "group": "80% Managed",
      "relationship": "Outpatient Surgery",
      "value": 10
    },
    {
      "group": "90% Managed",
      "relationship": "Outpatient Surgery",
      "value": 10
    },
    {
      "group": "Highly Managed",
      "relationship": "Outpatient Surgery",
      "value": 10
    }
  ],
  "options": {
    "axes": {
      "top": {
        "mapsTo": "value",
        "correspondingDatasets": [
          "Loosely Managed",
          "10% Managed",
          "20% Managed",
          "30% Managed",
          "40% Managed",
          "50% Managed",
          "60% Managed",
          "70% Managed",
          "80% Managed",
          "90% Managed",
          "Highly Managed",
        ],
        "stacked": true,
        "domain": [0, 100],
        "visible": false
      },
      "bottom": {
        "mapsTo": "actual",
        "correspondingDatasets": [
          "Client Position"
        ],
        "domain": [0, 100],
        "visible": false
      },
      "left": {
        "mapsTo": "relationship",
        "scaleType": ScaleTypes.LABELS,
        "domain": ['Imaging', 'Lab', 'Outpatient Surgery']
      }
    },
    "comboChartTypes": [
      {
        "type": "stacked-bar",
        "correspondingDatasets": [
          "Loosely Managed",
          "10% Managed",
          "20% Managed",
          "30% Managed",
          "40% Managed",
          "50% Managed",
          "60% Managed",
          "70% Managed",
          "80% Managed",
          "90% Managed",
          "Highly Managed",
        ]
      },
      {
        "type": "line",
        "correspondingDatasets": [
          "Client Position"
        ],
        "options": {
          "points": {
            "radius": 7
          }
        },
      }
    ],
    "height": "400px",
    "bars": {
      "width": 60
    },
    "color": {
      "scale": {
        "Client Position": "#0080E2",
        "Loosely Managed": "#A52040",
        "10% Managed": "#C45758",
        "20% Managed": "#DB816B",
        "30% Managed": "#ECA178",
        "40% Managed": "#FFC387",
        "50% Managed": "#FFC800",
        "60% Managed": "#B1AC14",
        "70% Managed": "#739523",
        "80% Managed": "#43842F",
        "90% Managed": "#006B3F",
        "Highly Managed": "#125203"

      }
    },
    "grid": {
      "x": {
        "enabled": false
      },
      "y": {
        "enabled": false
      },
      "getFillColor": {
        "defaultFillColor": "#0080E2"
      }
    }
  }
}

export const EmergencyDepartmentAndUrgentCareBarChart = {
  data: [
    {
      "group": "Emergency Department",
      "key": "Tampa",
      "value": 770
    },
    {
      "group": "Emergency Department",
      "key": "Chicago",
      "value": 480
    },
    {
      "group": "Emergency Department",
      "key": "Dallas",
      "value": 480
    },
    {
      "group": "Emergency Department",
      "key": "Los Angeles",
      "value": 480
    },
    {
      "group": "Emergency Department",
      "key": "Boston",
      "value": 480
    },
    {
      "group": "Emergency Department Norm",
      "key": "Tampa",
      "value": 790
    },
    {
      "group": "Emergency Department Norm",
      "key": "Chicago",
      "value": 500
    },
    {
      "group": "Emergency Department Norm",
      "key": "Dallas",
      "value": 500
    },
    {
      "group": "Emergency Department Norm",
      "key": "Los Angeles",
      "value": 500
    },
    {
      "group": "Emergency Department Norm",
      "key": "Boston",
      "value": 500
    },
    {
      "group": "Urgent Care",
      "key": "Tampa",
      "value": 410
    },
    {
      "group": "Urgent Care",
      "key": "Chicago",
      "value": 220
    },
    {
      "group": "Urgent Care",
      "key": "Dallas",
      "value": 220
    },
    {
      "group": "Urgent Care",
      "key": "Los Angeles",
      "value": 220
    },
    {
      "group": "Urgent Care",
      "key": "Boston",
      "value": 220
    },
    {
      "group": "Urgent Care Norm",
      "key": "Tampa",
      "value": 430
    },
    {
      "group": "Urgent Care Norm",
      "key": "Chicago",
      "value": 240
    },
    {
      "group": "Urgent Care Norm",
      "key": "Dallas",
      "value": 240
    },
    {
      "group": "Urgent Care Norm",
      "key": "Los Angeles",
      "value": 240
    },
    {
      "group": "Urgent Care Norm",
      "key": "Boston",
      "value": 240
    },
  ],
  options: {
    // "title": "Emergency Department & Urgent Care",
    "axes": {
      "left": {
        "mapsTo": "value",
        "stacked": true
      },
      "bottom": {
        "mapsTo": "key",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "color": {
      "scale": {
        "Emergency Department": "#50BEE1",
        "Emergency Department Norm": "#004877",
        "Urgent Care": "#FFC800",
        "Urgent Care Norm": "#FF6400"
      }
    },
    "height": "300px",
    "data": {
      "loading": loading
    },
  }
}

export const PotentialSiteOfServiceSavings_BarChart = {
  data: [
    {
      "group": "Maximum",
      "key": "Lab",
      "value": 5
    },
    {
      "group": "Maximum",
      "key": "Imaging",
      "value": 10
    },
    {
      "group": "Maximum",
      "key": "Surgery",
      "value": 58
    },
    {
      "group": "Maximum",
      "key": "Total",
      "value": 68
    },
    {
      "group": "50% Achievement",
      "key": "Lab",
      "value": 2.5
    },
    {
      "group": "50% Achievement",
      "key": "Imaging",
      "value": 5
    },
    {
      "group": "50% Achievement",
      "key": "Surgery",
      "value": 24
    },
    {
      "group": "50% Achievement",
      "key": "Total",
      "value": 24
    }
  ],
  options: {
    "title": "Potential Site of Service Savings",
    "axes": {
      "left": {
        "mapsTo": "value",
        "stacked": true,
        "ticks": {
          values: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
          formatter: ((value: any)=>{return '$'+value+'M'})
        },
      },
      "bottom": {
        "mapsTo": "key",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "color": {
      "scale": {
        "Maximum": "#FFC800",
        "50% Achievement": "#deb72c"
      }
    },
    "height": "400px",
    "data": {
      "loading": loading
    },
    "bars": {
      "width": 110
    }
  }
}

export const UtilizationByRelationship_StackedBarChart = {
  data: [
    {
      "group": "Outpatient Surgery",
      "key": "Employee",
      "value": 35
    },
    {
      "group": "Outpatient Surgery",
      "key": "Spouse",
      "value": 22
    },
    {
      "group": "Outpatient Surgery",
      "key": "Dependent",
      "value": 20
    },
    {
      "group": "Lab",
      "key": "Employee",
      "value": 45
    },
    {
      "group": "Lab",
      "key": "Spouse",
      "value": 58
    },
    {
      "group": "Lab",
      "key": "Dependent",
      "value": 38
    },
    {
      "group": "Imaging",
      "key": "Employee",
      "value": 20
    },
    {
      "group": "Imaging",
      "key": "Spouse",
      "value": 20
    },
    {
      "group": "Imaging",
      "key": "Dependent",
      "value": 42
    }
  ],
  options: {
    "title": "Utilization by Relationship",
    "axes": {
      "left": {
        "mapsTo": "value",
        "stacked": true
      },
      "bottom": {
        "mapsTo": "key",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "height": "400px",
    "data": {
      "loading": loading
    },
    "color": {
      "scale": {
        "Outpatient Surgery": "#004877",
        "Lab": "#0080E2",
        "Imaging": "#50BEE1"
      }
    },
    "bars": {
      "maxWidth": 80
    }
  }
}

export const UtilzationByPlan_GroupedBarChart = {
  data: [
    {
      "group": "Outpatient Surgery",
      "key": "Outpatient Surgery",
      "value": 250
    },
    {
      "group": "Outpatient Surgery",
      "key": "Lab",
      "value": 90
    },
    {
      "group": "Outpatient Surgery",
      "key": "Imaging",
      "value": 70
    },
    {
      "group": "Lab",
      "key": "Outpatient Surgery",
      "value": 300
    },
    {
      "group": "Lab",
      "key": "Lab",
      "value": 110
    },
    {
      "group": "Lab",
      "key": "Imaging",
      "value": 90
    },
    {
      "group": "Imaging",
      "key": "Outpatient Surgery",
      "value": 180
    },
    {
      "group": "Imaging",
      "key": "Lab",
      "value": 80
    },
    {
      "group": "Imaging",
      "key": "Imaging",
      "value": 70
    }
  ],
  options: {
    "title": "Utilization by Plan",
    "axes": {
      "left": {
        "mapsTo": "value"
      },
      "bottom": {
        "mapsTo": "key",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "height": "400px",
    "data": {
      "loading": loading
    },
    "color": {
      "scale": {
        "Outpatient Surgery": "#004877",
        "Lab": "#0080E2",
        "Imaging": "#50BEE1"
      }
    }
  }
}

export const OutOfNetworkUtilization_GroupedChart = {
  data: [
    {
      "group": "Outpatient Surgery",
      "key": "Prior",
      "value": 6
    },
    {
      "group": "Outpatient Surgery",
      "key": "Current",
      "value": 33
    },
    {
      "group": "Outpatient Surgery",
      "key": "Norm",
      "value": 35
    },
    {
      "group": "Lab",
      "key": "Prior",
      "value": 7
    },
    {
      "group": "Lab",
      "key": "Current",
      "value": 25
    },
    {
      "group": "Lab",
      "key": "Norm",
      "value": 30
    },
    {
      "group": "Imaging",
      "key": "Prior",
      "value": 7
    },
    {
      "group": "Imaging",
      "key": "Current",
      "value": 27
    },
    {
      "group": "Imaging",
      "key": "Norm",
      "value": 36
    }
  ],
  options: {
    "title": "Out of Network Utilization",
    "axes": {
      "left": {
        "mapsTo": "value",
        "ticks": {
          "values": [0, 5, 10, 15, 20, 25, 30, 35, 40]
        }
      },
      "bottom": {
        "mapsTo": "key",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "height": "400px",
    "data": {
      "loading": loading
    },
    "color": {
      "scale": {
        "Outpatient Surgery": "#004877",
        "Lab": "#0080E2",
        "Imaging": "#50BEE1"
      }
    }
  }
}

export const OutOfNetworkUtilizationByMarket_GroupedChart = {
  data: [
    {
      "group": "Outpatient Surgery",
      "key": "Tampa",
      "value": 62
    },
    {
      "group": "Outpatient Surgery",
      "key": "Chicago",
      "value": 61
    },
    {
      "group": "Outpatient Surgery",
      "key": "Dallas",
      "value": 78
    },
    {
      "group": "Outpatient Surgery",
      "key": "Los Angeles",
      "value": 65
    },
    {
      "group": "Outpatient Surgery",
      "key": "Boston",
      "value": 63
    },
    {
      "group": "Lab",
      "key": "Tampa",
      "value": 33
    },
    {
      "group": "Lab",
      "key": "Chicago",
      "value": 25
    },
    {
      "group": "Lab",
      "key": "Dallas",
      "value": 30
    },
    {
      "group": "Lab",
      "key": "Los Angeles",
      "value": 33
    },
    {
      "group": "Lab",
      "key": "Boston",
      "value": 32
    },
    {
      "group": "Imaging",
      "key": "Tampa",
      "value": 20
    },
    {
      "group": "Imaging",
      "key": "Chicago",
      "value": 12
    },
    {
      "group": "Imaging",
      "key": "Dallas",
      "value": 15
    },
    {
      "group": "Imaging",
      "key": "Los Angeles",
      "value": 16
    },
    {
      "group": "Imaging",
      "key": "Boston",
      "value": 12
    }
  ],
  options: {
    "title": "Out of Network Utilization by Market",
    "axes": {
      "left": {
        "mapsTo": "value",
        "stacked": true
      },
      "bottom": {
        "mapsTo": "key",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "height": "400px",
    "data": {
      "loading": loading
    },
    "color": {
      "scale": {
        "Outpatient Surgery": "#004877",
        "Lab": "#0080E2",
        "Imaging": "#50BEE1"
      }
    }
  }
}

export const OutpatientSurgeries_BarChart = {
  data: [
    {
      "group": "Cornerstone Arch North: $453,630",
      "key": "one",
      "value": 453630
    },
    {
      "group": "Access Surgery Partners: $340,674",
      "key": "two",
      "value": 340674
    },
    {
      "group": "Allied Surgery Associates: $208,675",
      "key": "three",
      "value": 208675
    }
  ],
  options: {
    // "title": "Outpatient Surgeries",
    "axes": {
      "left": {
        "mapsTo": "value",
        "ticks": {
          "values": [100000, 200000, 300000, 400000, 500000, 600000],
          "formatter": ((value: any)=>{ return "$"+value })
        }
      },
      "bottom": {
        "mapsTo": "key",
        "scaleType": ScaleTypes.LABELS,
        "visible": false
      }
    },
    "color": {
      "scale": {
        "Cornerstone Arch North: $453,630": "#0080E2",
        "Access Surgery Partners: $340,674": "#004877",
        "Allied Surgery Associates: $208,675": "#50BEE1"
      }
    },
    "grid": {
      'x': {
        'enabled': false
      }
    },
    "height": "350px",
    "data": {
      "loading": loading
    },
    "bars": {
      "maxWidth": 100
    }
  }
}

export const LabServices_BarChart = {
  data: [
    {
      "group": "Labcorp: $380,630",
      "key": "one",
      "value": 380630
    },
    {
      "group": "Sonic Heathcare: $270,674",
      "key": "two",
      "value": 270674
    },
    {
      "group": "Abbott  $105,675",
      "key": "three",
      "value": 105675
    }
  ],
  options: {
    // "title": "Lab Services",
    "axes": {
      "left": {
        "mapsTo": "value",
        "ticks": {
          "values": [100000, 200000, 300000, 400000, 500000, 600000],
          "formatter": ((value: any)=>{ return "$"+value })
        }
      },
      "bottom": {
        "mapsTo": "key",
        "scaleType": ScaleTypes.LABELS,
        "visible": false
      }
    },
    "color": {
      "scale": {
        "Labcorp: $380,630": "#0080E2",
        "Sonic Heathcare: $270,674": "#004877",
        "Abbott  $105,675": "#50BEE1"
      }
    },
    "height": "350px",
    "data": {
      "loading": loading
    },
    "grid": {
      'x': {
        'enabled': false
      }
    },
    "bars": {
      "maxWidth": 100
    }
  }
}
export const ImagingServices_BarChart = {
  data: [
    {
      "group": "RadNet: $505,630",
      "key": "one",
      "value": 505630
    },
    {
      "group": "CDI: $405,674",
      "key": "two",
      "value": 405674
    },
    {
      "group": "MedQuest Associates: $300,674",
      "key": "three",
      "value": 300674
    }
  ],
  options: {
    // "title": "Imaging Services",
    "axes": {
      "left": {
        "mapsTo": "value",
        "ticks": {
          "values": [100000, 200000, 300000, 400000, 500000, 600000],
          "formatter": ((value: any)=>{ return "$"+value })
        }
      },
      "bottom": {
        "mapsTo": "key",
        "scaleType": ScaleTypes.LABELS,
        "visible": false
      }
    },
    "color": {
      "scale": {
        "RadNet: $505,630": "#0080E2",
        "CDI: $405,674": "#004877",
        "MedQuest Associates: $300,674": "#50BEE1"
      }
    },
    "height": "350px",
    "data": {
      "loading": loading
    },
    "grid": {
      'x': {
        'enabled': false
      }
    },
    "bars": {
      "maxWidth": 100
    }
  }
}

export const OutpatientSurgeriesComboChart = {
  data: [
    {
      "group": "Upper GI, Endoscopy, Biopsy",
      "date": "one",
      "value": 4000
    },
    {
      "group": "Colonoscopy, Biopsy",
      "date": "two",
      "value": 2900
    },
    {
      "group": "Therapeutic proceedures on muscles, tendons",
      "date": "three",
      "value": 1700
    },
    {
      "group": "Excision of the skin lesion",
      "date": "four",
      "value": 1600
    },
    {
      "group": "Insertion of Catheter, Spinal Stimulator, Inspinal Canal",
      "date": "five",
      "value": 1600
    },
    {
      "group": "Allowed per RVU",
      "date": "one",
      "amount": 800
    },
    {
      "group": "Allowed per RVU",
      "date": "two",
      "amount": 900
    },
    {
      "group": "Allowed per RVU",
      "date": "three",
      "amount": 1900
    },
    {
      "group": "Allowed per RVU",
      "date": "four",
      "amount": 400
    },
    {
      "group": "Allowed per RVU",
      "date": "five",
      "amount": 700
    }
  ],

  options: {

    // "title": "Outpatient Surgeries",
    "axes": {
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "title": "Utilization per 1000",
        "correspondingDatasets": [
          "Upper GI, Endoscopy, Biopsy",
          "Colonoscopy, Biopsy",
          "Therapeutic proceedures on muscles, tendons",
          "Excision of the skin lesion",
          "Insertion of Catheter, Spinal Stimulator, Inspinal Canal"
        ],
      },
      "right": {
        "mapsTo": "amount",
        "scaleType": ScaleTypes.LINEAR,
        "title": "Allowed per RVU",
        "correspondingDatasets": ["Allowed per RVU"],
        "ticks": {
          "values": [ 0, 500, 1000, 1500, 2000, 2500 ],
          "formatter": ((value: any)=>{ return "$"+value })
        }

      },
      "bottom": {
        // "title": "Day of the Week",
        "mapsTo": "date",
        "scaleType": ScaleTypes.LABELS,
        "visible": false

      }
    },
    "comboChartTypes": [
      {
        "type": "simple-bar",
        "correspondingDatasets": [
          "Upper GI, Endoscopy, Biopsy",
          "Colonoscopy, Biopsy",
          "Therapeutic proceedures on muscles, tendons",
          "Excision of the skin lesion",
          "Insertion of Catheter, Spinal Stimulator, Inspinal Canal"
        ]
      },
      {
        "type": "line",
        "options": {
          "points": {
            "radius": 3
          }
        },
        "correspondingDatasets": [
          "Allowed per RVU"
        ]
      }
    ],
    "height": "300px",
    "color": {
      "scale": {
        "Upper GI, Endoscopy, Biopsy": "#0080E2",
        "Colonoscopy, Biopsy": "#004877",
        "Therapeutic proceedures on muscles, tendons": "#50BEE1",
        "Excision of the skin lesion": "#727A7D",
        "Insertion of Catheter, Spinal Stimulator, Inspinal Canal": "#C6C9CA",
        "Allowed per RVU": "#FF6400"
      }
    }
  }

}

export const LabServicesComboChart = {
  data: [
    {
      "group": "Comphrensive Metabolic Panel",
      "date": "one",
      "value": 4000
    },
    {
      "group": "Lipid Panel",
      "date": "two",
      "value": 2900
    },
    {
      "group": "Tissue Exam by Pathologist",
      "date": "three",
      "value": 1700
    },
    {
      "group": "Assay Thyroid Stim Hormone",
      "date": "four",
      "value": 1600
    },
    {
      "group": "Complete CBC with Auto Diff WBC",
      "date": "five",
      "value": 1600
    },
    {
      "group": "Allowed per RVU",
      "date": "one",
      "amount": 100
    },
    {
      "group": "Allowed per RVU",
      "date": "two",
      "amount": 90
    },
    {
      "group": "Allowed per RVU",
      "date": "three",
      "amount": 110
    },
    {
      "group": "Allowed per RVU",
      "date": "four",
      "amount": 80
    },
    {
      "group": "Allowed per RVU",
      "date": "five",
      "amount": 90
    }
  ],

  options: {

    // "title": "Lab Services",
    "axes": {
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "title": "Utilization per 1000",
        "correspondingDatasets": [
          "Comphrensive Metabolic Panel",
          "Lipid Panel",
          "Tissue Exam by Pathologist",
          "Assay Thyroid Stim Hormone",
          "Complete CBC with Auto Diff WBC"
        ]
      },
      "right": {
        "mapsTo": "amount",
        "scaleType": ScaleTypes.LINEAR,
        "title": "Allowed per RVU",
        "correspondingDatasets": ["Allowed per RVU"],
        "ticks": {
          // "values": [ 0, 500, 1000, 1500, 2000, 2500 ],
          "formatter": ((value: any)=>{ return "$"+value })
        }

      },
      "bottom": {
        // "title": "Day of the Week",
        "mapsTo": "date",
        "scaleType": ScaleTypes.LABELS,
        "visible": false

      }
    },
    "comboChartTypes": [
      {
        "type": "simple-bar",
        "correspondingDatasets": [
          "Comphrensive Metabolic Panel",
          "Lipid Panel",
          "Tissue Exam by Pathologist",
          "Assay Thyroid Stim Hormone",
          "Complete CBC with Auto Diff WBC"
        ]
      },
      {
        "type": "line",
        "options": {
          "points": {
            "radius": 3
          }
        },
        "correspondingDatasets": [
          "Allowed per RVU"
        ]
      }
    ],
    "height": "300px",
    "color": {
      "scale": {
        "Comphrensive Metabolic Panel": "#0080E2",
        "Lipid Panel": "#004877",
        "Tissue Exam by Pathologist": "#50BEE1",
        "Assay Thyroid Stim Hormone": "#727A7D",
        "Complete CBC with Auto Diff WBC": "#C6C9CA",

        "Allowed per RVU": "#FF6400"
      }
    }
  }

}

export const ImagingServicesComboChart = {
  data: [
    {
      "group": "CT ",
      "date": "one",
      "value": 4000
    },
    {
      "group": "MRI",
      "date": "two",
      "value": 2900
    },
    {
      "group": "PET",
      "date": "three",
      "value": 1700
    },
    {
      "group": "XRay",
      "date": "four",
      "value": 1600
    },
    {
      "group": "Ultrasound",
      "date": "five",
      "value": 1600
    },
    {
      "group": "Allowed per RVU",
      "date": "one",
      "amount": 400
    },
    {
      "group": "Allowed per RVU",
      "date": "two",
      "amount": 900
    },
    {
      "group": "Allowed per RVU",
      "date": "three",
      "amount": 2400
    },
    {
      "group": "Allowed per RVU",
      "date": "four",
      "amount": 300
    },
    {
      "group": "Allowed per RVU",
      "date": "five",
      "amount": 400
    }
  ],

  options: {

    // "title": "Imaging Services",
    "axes": {
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "title": "Utilization per 1000",
        "correspondingDatasets": [
          "CT ",
          "MRI",
          "PET",
          "XRay",
          "Ultrasound"
        ]
      },
      "right": {
        "mapsTo": "amount",
        "scaleType": ScaleTypes.LINEAR,
        "title": "Allowed per RVU",
        "correspondingDatasets": ["Allowed per RVU"],
        "ticks": {
          "formatter": ((value: any)=>{ return "$"+value })
        }

      },
      "bottom": {
        // "title": "Day of the Week",
        "mapsTo": "date",
        "scaleType": ScaleTypes.LABELS,
        "visible": false
      }
    },
    "comboChartTypes": [
      {
        "type": "simple-bar",
        "correspondingDatasets": [
          "CT ",
          "MRI",
          "PET",
          "XRay",
          "Ultrasound"
        ]
      },
      {
        "type": "line",
        "options": {
          "points": {
            "radius": 3
          }
        },
        "correspondingDatasets": [
          "Allowed per RVU"
        ]
      }
    ],
    "height": "300px",
    "color": {
      "scale": {
        "CT ": "#0080E2",
        "MRI": "#004877",
        "PET": "#50BEE1",
        "XRay": "#727A7D",
        "Ultrasound": "#C6C9CA",

        "Allowed per RVU": "#FF6400"
      }
    }
  }

}

export const HighCostClaimantAvgCost_GroupedChart = {
  data: [
    {
      "group": "RX",
      "key": "Prior",
      "value": 1000
    },
    {
      "group": "Ancillary",
      "key": "Prior",
      "value": 700
    },
    {
      "group": "Physician",
      "key": "Prior",
      "value": 1500
    },
    {
      "group": "Outpatient",
      "key": "Prior",
      "value": 7500
    },
    {
      "group": "Inpatient",
      "key": "Prior",
      "value": 7800
    },
    {
      "group": "RX",
      "key": "Current",
      "value": 1100
    },
    {
      "group": "Ancillary",
      "key": "Current",
      "value": 700
    },
    {
      "group": "Physician",
      "key": "Current",
      "value": 680
    },
    {
      "group": "Outpatient",
      "key": "Current",
      "value": 8000
    },
    {
      "group": "Inpatient",
      "key": "Current",
      "value": 8100
    }
  ],
  options: {
    "title": "Average Cost by Setting of Service",
    "axes": {
      "left": {
        "mapsTo": "value",
        "ticks": {
          "values": [0, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000]
        }
      },
      "bottom": {
        "mapsTo": "key",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "height": "415px",
    "data": {
      "loading": loading
    },
    "color": {
      "scale": {
        "RX": "#50BEE1",
        "Ancillary": "#FFC800",
        "Physician": "#727A7D",
        "Outpatient": "#FF6400",
        "Inpatient": "#0080E2"
      }
    }
  }
};

export const inpatientAdmitOpportunity = {
    data: [
      {
        "group": "Ambulatory Care Sensitive Conditions",
        "value": 407
      },
      {
        "group": "7/15/30 Day All Cause Readmit",
        "value": 498
      },
      {
        "group": "Preference Sensitive Care Admissions",
        "value": 896
      }
    ],
    options: {
      "axes": {
        "bottom": {
          "mapsTo": "value"
        },
        "left": {
          "mapsTo": "group",
          "scaleType": ScaleTypes.LABELS
        }
      },
      "height": "400px",
      "color": {
        "scale": {
          "7/15/30 Day All Cause Readmit": "#74C061",
          "Ambulatory Care Sensitive Conditions": "#0080E2",
          "Preference Sensitive Care Admissions": "#FFA200"
        }
      },
      "bars": {
        "maxWidth": 300
      }
    }
}

export const inpatientAdmissionsTotalPaid = {
    data: [
        {
            "group": "Healthy",
            "value": 80
        },
        {
            "group": "Chronic",
            "value": 20
        }
    ],
    options: {
        "resizable": true,
        "legend": {
            "truncation": {
                "numCharacter": 50
            }
        },
        "donut": {
            "center": {
              "number": 20,
              "numberFormatter":((value: any)=>{return value+'%'}),
              "label": "Chronic Condition"
            },
        },
        "height": "350px",
        "color": {
            "scale": {
                "Healthy": "#50BEE1",
                "Chronic": "#FFA200"
            }
        }
    }
}

export const inpatientMemberMonths = {
  data: [
    {
      "group": "Chronic",
      "value": 30
    },
    {
      "group": "Healthy",
      "value": 70
    }
  ],
  options: {
    "resizable": true,
    "legend": {
      "truncation": {
        "numCharacter": 50
      }
    },
    "donut": {
      "center": {
        "number": 21,
         "numberFormatter":((value: any)=>{return value+'%'}),
        "label": "Chronic Condition"
      },
    },
    "height": "350px",
    "color": {
      "scale": {
        "Healthy": "#50BEE1",
        "Chronic": "#FFA200"
      }
    }
  }
}

export const inpatientAdmits = {
    data: [
      {
        "group": "Chronic",
        "value": 70
      },
      {
        "group": "Healthy",
        "value": 30
      }
    ],
    options: {
      "resizable": true,
      "legend": {
        "truncation": {
          "numCharacter": 50
        }
      },
      "donut": {
        "center": {
          "number": 70,
          "numberFormatter":((value: any)=>{return value+'%'}),
          "label": "Chronic Condition"
        },
      },
      "height": "350px",
      "color": {
        "scale": {
          "Healthy": "#50BEE1",
          "Chronic": "#FFA200"
        }
      }
    }
}

export const telehealthVisitsPercentOfOfficeVisits = {
    data: [
	    {
		    "group": "Percentage of Office Prior",
		    "month": "Jan",
		    "value": 1
	    },
      {
        "group": "Percentage of Office Prior",
        "month": "Feb",
        "value": 0.5
      },
      {
        "group": "Percentage of Office Prior",
        "month": "Mar",
        "value": 1
      },
      {
        "group": "Percentage of Office Prior",
        "month": "Apr",
        "value": 2
      },
      {
        "group": "Percentage of Office Prior",
        "month": "May",
        "value": 0.5
      },
      {
        "group": "Percentage of Office Prior",
        "month": "Jun",
        "value": 1
      },
      {
        "group": "Percentage of Office Prior",
        "month": "Jul",
        "value": 2.5
      },
      {
        "group": "Percentage of Office Prior",
        "month": "Aug",
        "value": 3
      },
      {
        "group": "Percentage of Office Prior",
        "month": "Sep",
        "value": 4
      },
      {
        "group": "Percentage of Office Prior",
        "month": "Oct",
        "value": 2
      },
      {
        "group": "Percentage of Office Prior",
        "month": "Nov",
        "value": 1
      },
      {
        "group": "Percentage of Office Prior",
        "month": "Dec",
        "value": 2
      },
      {
        "group": "Percentage of Office Current",
        "month": "Jan",
        "value": 2.5
      },
      {
        "group": "Percentage of Office Current",
        "month": "Feb",
        "value": 0.5
      },
      {
        "group": "Percentage of Office Current",
        "month": "Mar",
        "value": 17
      },
      {
        "group": "Percentage of Office Current",
        "month": "Apr",
        "value": 17
      },
      {
        "group": "Percentage of Office Current",
        "month": "May",
        "value": 27
      },
      {
        "group": "Percentage of Office Current",
        "month": "Jun",
        "value": 28
      },
      {
        "group": "Percentage of Office Current",
        "month": "Jul",
        "value": 26
      },
      {
        "group": "Percentage of Office Current",
        "month": "Aug",
        "value": 29
      },
      {
        "group": "Percentage of Office Current",
        "month": "Sep",
        "value": 23
      },
      {
        "group": "Percentage of Office Current",
        "month": "Oct",
        "value": 13
      },
      {
        "group": "Percentage of Office Current",
        "month": "Nov",
        "value": 12
      },
      {
        "group": "Percentage of Office Current",
        "month": "Dec",
        "value": 8
      }
],
options: {
	"title": "Telehealth Visits Percent of Office Visits",
	"axes": {
		"bottom": {
			"mapsTo": "month",
			"scaleType": ScaleTypes.LABELS
		},
    "left": {
      "mapsTo": "value",
      "ticks": {
        "values": [ 0, 5, 10, 15, 20, 25, 30 ],
        "formatter": ((value: any)=>{ return value+'%' })
      }
    }
	},
	"height": "400px",
	"color": {
    "scale": {
      "Percentage of Office Prior": "#FF6400",
      "Percentage of Office Current": "#0080E2"
    }
	},
	"legend": {
		"truncation": {
			"numCharacter": 50
		}
	}
}
}

export const inpatientAdmissionsCostOfCare = {
  "data": [
    {
      "group": "Allowed per RVU",
      "service": "Hospital 1",
      "value": 200
    },
    {
      "group": "Allowed per RVU",
      "service": "Hospital 2",
      "value": 190
    },
    {
      "group": "Allowed per RVU",
      "service": "Hospital 3",
      "value": 180
    },
    {
      "group": "Allowed per RVU",
      "service": "Hospital 4",
      "value": 175
    },
    {
      "group": "Allowed per RVU",
      "service": "Hospital 5",
      "value": 105
    },
    {
      "group": "Allowed per RVU",
      "service": "Hospital 6",
      "value": 105
    },
    {
      "group": "Allowed per RVU",
      "service": "Hospital 7",
      "value": 100
    },
    {
      "group": "Allowed per RVU",
      "service": "Hospital 8",
      "value": 95
    },
    {
      "group": "Allowed per RVU",
      "service": "Hospital 9",
      "value": 85
    },
    {
      "group": "Allowed per RVU",
      "service": "Hospital 10",
      "value": 80
    },
    {
      "group": "Admits per 1000",
      "service": "Hospital 1",
      "admits": 410
    },
    {
      "group": "Admits per 1000",
      "service": "Hospital 2",
      "admits": 590
    },
    {
      "group": "Admits per 1000",
      "service": "Hospital 3",
      "admits": 840
    },
    {
      "group": "Admits per 1000",
      "service": "Hospital 4",
      "admits": 500
    },
    {
      "group": "Admits per 1000",
      "service": "Hospital 5",
      "admits": 280
    },
    {
      "group": "Admits per 1000",
      "service": "Hospital 6",
      "admits": 350
    },
    {
      "group": "Admits per 1000",
      "service": "Hospital 7",
      "admits": 390
    },
    {
      "group": "Admits per 1000",
      "service": "Hospital 8",
      "admits": 400
    },
    {
      "group": "Admits per 1000",
      "service": "Hospital 9",
      "admits": 780
    },
    {
      "group": "Admits per 1000",
      "service": "Hospital 10",
      "admits": 605
    }
  ],
  options: {
    "title": "Unit Cost and Total Cost of Care Comparison by Top Hospitals",
    "axes": {
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "correspondingDatasets": [
          "Allowed per RVU"
        ],
        "ticks": {
          "values": [0, 50, 100, 150, 200, 250],
          formatter: ((value:any)=>{return '$'+value})
        }
      },
      "right": {
        "mapsTo": "admits",
        "scaleType": ScaleTypes.LINEAR,
        "correspondingDatasets": [
          "Admits per 1000"
        ],
        "ticks": {
          "values": [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000],
          formatter: ((value:any)=>{return '$'+value})
        }
      },
      "bottom": {
        "mapsTo": "service",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "comboChartTypes": [
      {
        "type": "simple-bar",
        "correspondingDatasets": [
          "Allowed per RVU"
        ]
      },
      {
        "type": "line",
        "options": {
          "points": {
            "radius": 5
          }
        },
        "correspondingDatasets": [
          "Admits per 1000"
        ]
      }
    ],
    "height": "400px",
    "color": {
      "scale": {
        "Allowed per RVU": "#0080E2",
        "Admits per 1000": "#FF6400"
      }
    },
    "bars": {
      "width": 60
    }
  }
}

export const percentAdmitsByCCHGGrouping = {
  data: [
    {
      "group": "Non-CCHG Grouping",
      "type": "Current",
      "value": 8
    },
    {
      "group": "Non-CCHG Grouping",
      "type": "Prior",
      "value": 15
    },
    {
      "group": "Non-CCHG Grouping",
      "type": "Norm",
      "value": 22
    },
    {
      "group": "Healthy",
      "type": "Current",
      "value": 32
    },
    {
      "group": "Healthy",
      "type": "Prior",
      "value": 40
    },
    {
      "group": "Healthy",
      "type": "Norm",
      "value": 30
    },
    {
      "group": "Chronic Condition",
      "type": "Current",
      "value": 60
    },
    {
      "group": "Chronic Condition",
      "type": "Prior",
      "value": 45
    },
    {
      "group": "Chronic Condition",
      "type": "Norm",
      "value": 48
    }
  ],
  options: {
    "title": "Percentage of Admits by CCHG Grouping",
    "axes": {
      "bottom": {
        "mapsTo": "type",
        "scaleType": ScaleTypes.LABELS
      },
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "ticks": {
          "values": [ 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100 ],
          "formatter": ((value: any)=>{ return value+'%' })
        },
        "percentage": true
      }
    },
    "height": "400px",
    "color": {
      "scale": {
        "Non-CCHG Grouping": "#004877",
        "Healthy": "#50BEE1",
        "Chronic Condition": "#FFA200"
      }
    },
    "legend": {
      "truncation": {
        "numCharacter": 50
      }
    },
    "bars": {
      "width": 75
    }
  }
}

export const admitsByRelationship = {
  data: [
    {
      "group": "Employee",
      "relationship": "All Inpatient",
      "value": 33
    },
    {
      "group": "Spouse",
      "relationship": "All Inpatient",
      "value": 47
    },
    {
      "group": "Dependent",
      "relationship": "All Inpatient",
      "value": 20
    },
    {
      "group": "Employee",
      "relationship": "Inpatient Medicine",
      "value": 25
    },
    {
      "group": "Spouse",
      "relationship": "Inpatient Medicine",
      "value": 40
    },
    {
      "group": "Dependent",
      "relationship": "Inpatient Medicine",
      "value": 35
    },
    {
      "group": "Employee",
      "relationship": "Inpatient Surgery",
      "value": 20
    },
    {
      "group": "Spouse",
      "relationship": "Inpatient Surgery",
      "value": 35
    },
    {
      "group": "Dependent",
      "relationship": "Inpatient Surgery",
      "value": 45
    },
    {
      "group": "Employee",
      "relationship": "Inpatient Maternity",
      "value": 50
    },
    {
      "group": "Spouse",
      "relationship": "Inpatient Maternity",
      "value": 45
    },
    {
      "group": "Dependent",
      "relationship": "Inpatient Maternity",
      "value": 5
    },
    {
      "group": "Employee",
      "relationship": "Inpatient Behavioral",
      "value": 65
    },
    {
      "group": "Spouse",
      "relationship": "Inpatient Behavioral",
      "value": 33
    },
    {
      "group": "Dependent",
      "relationship": "Inpatient Behavioral",
      "value": 2
    }
  ],
  options: {
    "title": "Admits by Relationship",
    "axes": {
      "bottom": {
        "mapsTo": "relationship",
        "scaleType": ScaleTypes.LABELS
      },
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "ticks": {
          "values": [ 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100 ],
          "formatter": ((value: any)=>{ return value+'%' })
        },
        "percentage": true
      }
    },
    "height": "400px",
    "color": {
      "scale": {
        "Employee": "#004877",
        "Spouse": "#0080E2",
        "Dependent": "#50BEE1"
      }
    },
    "legend": {
      "truncation": {
        "numCharacter": 50
      }
    },
    "bars": {
      "width": 40
    }
  }
}

export const admitsPer1000 = {
  "data": [
    {
      "group": "Allowed per RVU",
      "service": "All Inpatient",
      "value": 26000
    },
    {
      "group": "Allowed per RVU",
      "service": "11-Medical",
      "value": 23000
    },
    {
      "group": "Allowed per RVU",
      "service": "12-Surgical",
      "value": 44000
    },
    {
      "group": "Allowed per RVU",
      "service": "13-Psychiatric",
      "value": 18000
    },
    {
      "group": "Allowed per RVU",
      "service": "21-Mat Norm Delivery",
      "value": 6000
    },
    {
      "group": "Allowed per RVU Norm",
      "service": "All Inpatient",
      "value": 21000
    },
    {
      "group": "Allowed per RVU Norm",
      "service": "11-Medical",
      "value": 26000
    },
    {
      "group": "Allowed per RVU Norm",
      "service": "12-Surgical",
      "value": 43000
    },
    {
      "group": "Allowed per RVU Norm",
      "service": "13-Psychiatric",
      "value": 21000
    },
    {
      "group": "Allowed per RVU Norm",
      "service": "21-Mat Norm Delivery",
      "value": 5000
    },
    {
      "group": "Admits per 1000",
      "service": "All Inpatient",
      "admits": 43
    },
    {
      "group": "Admits per 1000",
      "service": "11-Medical",
      "admits": 17
    },
    {
      "group": "Admits per 1000",
      "service": "12-Surgical",
      "admits": 16
    },
    {
      "group": "Admits per 1000",
      "service": "13-Psychiatric",
      "admits": 3
    },
    {
      "group": "Admits per 1000",
      "service": "21-Mat Norm Delivery",
      "admits": 11
    },
    {
      "group": "Admits per 1000 Norm",
      "service": "All Inpatient",
      "admits": 41
    },
    {
      "group": "Admits per 1000 Norm",
      "service": "11-Medical",
      "admits": 14
    },
    {
      "group": "Admits per 1000 Norm",
      "service": "12-Surgical",
      "admits": 17
    },
    {
      "group": "Admits per 1000 Norm",
      "service": "13-Psychiatric",
      "admits": 5
    },
    {
      "group": "Admits per 1000 Norm",
      "service": "21-Mat Norm Delivery",
      "admits": 10
    }
  ],
  "options": {
    "axes": {
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "correspondingDatasets": [
          "Allowed per RVU",
          "Allowed per RVU Norm"
        ],
        "ticks": {
          "values": [0, 5000, 10000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000],
          formatter: ((value:any)=>{return '$'+value})
        }
      },
      "right": {
        "mapsTo": "admits",
        "scaleType": ScaleTypes.LINEAR,
        "correspondingDatasets": [
          "Admits per 1000",
          "Admits per 1000 Norm"
        ]
      },
      "bottom": {
        "mapsTo": "service",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "comboChartTypes": [
      {
        "type": "grouped-bar",
        "correspondingDatasets": [
          "Allowed per RVU",
          "Allowed per RVU Norm"
        ]
      },
      {
        "type": "line",
        "options": {
          "points": {
            "radius": 5
          }
        },
        "correspondingDatasets": [
          "Admits per 1000",
          "Admits per 1000 Norm"
        ]
      }
    ],
    "height": "400px",
    "color": {
      "scale": {
        "Allowed per RVU": "#0080E2",
        "Allowed per RVU Norm": "#FFA200",
        "Admits per 1000": "#FF6400",
        "Admits per 1000 Norm": "#727A7D"
      }
    },
    "bars": {
      "width": 30
    }
  }
}

export const relationshipType = {
  "data": [
    {
      "group": "Employee",
      "relationship": "Percentage of Cost",
      "value": 33
    },
    {
      "group": "Spouse",
      "relationship": "Percentage of Cost",
      "value": 47
    },
    {
      "group": "Dependent",
      "relationship": "Percentage of Cost",
      "value": 20
    },
    {
      "group": "Employee",
      "relationship": "Percentage of Members",
      "value": 25
    },
    {
      "group": "Spouse",
      "relationship": "Percentage of Members",
      "value": 40
    },
    {
      "group": "Dependent",
      "relationship": "Percentage of Members",
      "value": 35
    }
  ],
  "options": {
    "title": "Relationship Type",
    "axes": {
      "bottom": {
        "mapsTo": "relationship",
        "scaleType": ScaleTypes.LABELS
      },
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "percentage":  true,
        "ticks": {
          "values": [ 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100 ],
          "formatter": ((value: any)=>{ return value+'%' })
        },
      }
    },
    "height": "400px",
    "width": "300px",
    "color": {
      "scale": {
        "Employee": "#004877",
        "Spouse": "#0080E2",
        "Dependent": "#50BEE1"
      }
    },
    "legend": {
      "truncation": {
        "numCharacter": 50
      }
    },
    "bars": {
      "width": 40
    }
  }
}

export const paidPMPMbyAgeBand = {
  "data": [
    {
      "group": "Male",
      "age": "00-01",
      "value": 2
    },
    {
      "group": "Female",
      "age": "00-01",
      "value": 2
    },
    {
      "group": "Male",
      "age": "02-04",
      "value": 18
    },
    {
      "group": "Female",
      "age": "02-04",
      "value": 10
    },
    {
      "group": "Male",
      "age": "05-07",
      "value": 22
    },
    {
      "group": "Female",
      "age": "05-07",
      "value": 10
    },
    {
      "group": "Male",
      "age": "08-14",
      "value": 22
    },
    {
      "group": "Female",
      "age": "08-14",
      "value": 22
    },
    {
      "group": "Male",
      "age": "15-19",
      "value": 62
    },
    {
      "group": "Female",
      "age": "15-19",
      "value": 80
    },
    {
      "group": "Male",
      "age": "20-24",
      "value": 75
    },
    {
      "group": "Female",
      "age": "20-24",
      "value": 60
    },
    {
      "group": "Male",
      "age": "25-29",
      "value": 40
    },
    {
      "group": "Female",
      "age": "25-29",
      "value": 30
    },
    {
      "group": "Male",
      "age": "30-34",
      "value": 25
    },
    {
      "group": "Female",
      "age": "30-34",
      "value": 25
    },
    {
      "group": "Male",
      "age": "35-39",
      "value": 25
    },
    {
      "group": "Female",
      "age": "35-39",
      "value": 22
    },
    {
      "group": "Male",
      "age": "40-44",
      "value": 18
    },
    {
      "group": "Female",
      "age": "40-44",
      "value": 25
    },
    {
      "group": "Male",
      "age": "45-49",
      "value": 22
    },
    {
      "group": "Female",
      "age": "45-49",
      "value": 20
    },
    {
      "group": "Male",
      "age": "50-54",
      "value": 23
    },
    {
      "group": "Female",
      "age": "50-54",
      "value": 20
    },
    {
      "group": "Male",
      "age": "55-59",
      "value": 22
    },
    {
      "group": "Female",
      "age": "55-59",
      "value": 18
    },
    {
      "group": "Male",
      "age": "60-64",
      "value": 18
    },
    {
      "group": "Female",
      "age": "60-64",
      "value": 15
    }
  ],
  "options": {
    "title": "Paid PMPM by Age Band and Gender",
    "axes": {
      "bottom": {
        "mapsTo": "age",
        "scaleType": ScaleTypes.LABELS
      },
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "ticks": {
          "values": [ 0, 40, 80, 120, 160, 200],
          "formatter": ((value: any)=>{ return '$'+value })
        },
        "stacked": true
      }
    },
    "height": "400px",
    "width": "900px",
    "color": {
      "scale": {
        "Male": "#0080E2",
        "Female": "#FF6400"
      }
    },
    "legend": {
      "truncation": {
        "numCharacter": 50
      }
    }
  }
}

export const utilizationByDiagnosis = {
  "data": [
    {
      "group": "Norm",
      "diagnosis": "Mood disorders",
      "value": 460
    },
    {
      "group": "Norm",
      "diagnosis": "Anxiety disorders",
      "value": 355
    },
    {
      "group": "Norm",
      "diagnosis": "Adjustment disorders",
      "value": 250
    },
    {
      "group": "Norm",
      "diagnosis": "Disorders usually diagnosed in infancy, childhood, or adolescence",
      "value": 140
    },
    {
      "group": "Norm",
      "diagnosis": "Substance-related disorders",
      "value": 180
    },
    {
      "group": "Current",
      "diagnosis": "Substance-related disorders",
      "value": 150
    },
    {
      "group": "Current",
      "diagnosis": "Disorders usually diagnosed in infancy, childhood, or adolescence",
      "value": 190
    },
    {
      "group": "Current",
      "diagnosis": "Adjustment disorders",
      "value": 225
    },
    {
      "group": "Current",
      "diagnosis": "Anxiety disorders",
      "value": 375
    },
    {
      "group": "Current",
      "diagnosis": "Mood disorders",
      "value": 445
    },
    {
      "group": "Prior",
      "diagnosis": "Substance-related disorders",
      "value": 170
    },
    {
      "group": "Prior",
      "diagnosis": "Disorders usually diagnosed in infancy, childhood, or adolescence",
      "value": 155
    },
    {
      "group": "Prior",
      "diagnosis": "Adjustment disorders",
      "value": 230
    },
    {
      "group": "Prior",
      "diagnosis": "Anxiety disorders",
      "value": 370
    },
    {
      "group": "Prior",
      "diagnosis": "Mood disorders",
      "value": 447
    }
    ],
    "options": {
    "axes": {
      "left": {
        "mapsTo": "diagnosis",
        "scaleType": ScaleTypes.LABELS
      },
      "bottom": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR
      }
    },
    "height": "400px",
    "color": {
      "scale": {
        "Norm": "#004877",
        "Current": "#0080E2",
        "Prior":"#50BEE1"
      }
    }
  }
}

export const paidPMPMByDiagnosis = {
  "data": [
    {
      "group": "Norm",
      "diagnosis": "Mood disorders",
      "value": 7.90
    },
    {
      "group": "Norm",
      "diagnosis": "Anxiety disorders",
      "value": 5.30
    },
    {
      "group": "Norm",
      "diagnosis": "Adjustment disorders",
      "value": 4.10
    },
    {
      "group": "Norm",
      "diagnosis": "Disorders usually diagnosed in infancy, childhood, or adolescence",
      "value": 3.90
    },
    {
      "group": "Norm",
      "diagnosis": "Substance-related disorders",
      "value": 3.00
    },
    {
      "group": "Current",
      "diagnosis": "Substance-related disorders",
      "value": 2.80
    },
    {
      "group": "Current",
      "diagnosis": "Disorders usually diagnosed in infancy, childhood, or adolescence",
      "value": 3.80
    },
    {
      "group": "Current",
      "diagnosis": "Adjustment disorders",
      "value": 4.40
    },
    {
      "group": "Current",
      "diagnosis": "Anxiety disorders",
      "value": 5.60
    },
    {
      "group": "Current",
      "diagnosis": "Mood disorders",
      "value": 7.50
    },
    {
      "group": "Prior",
      "diagnosis": "Substance-related disorders",
      "value": 3.00
    },
    {
      "group": "Prior",
      "diagnosis": "Disorders usually diagnosed in infancy, childhood, or adolescence",
      "value": 4.00
    },
    {
      "group": "Prior",
      "diagnosis": "Adjustment disorders",
      "value": 4.00
    },
    {
      "group": "Prior",
      "diagnosis": "Anxiety disorders",
      "value": 5.00
    },
    {
      "group": "Prior",
      "diagnosis": "Mood disorders",
      "value": 8.20
    }
    ],
    "options": {
    "axes": {
      "left": {
        "mapsTo": "diagnosis",
        "scaleType": ScaleTypes.LABELS
      },
      "bottom": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "ticks": {
          "values": [ 0, 1.00, 2.00, 3.00, 4.00, 5.00, 6.00, 7.00, 8.00, 9.00],
          "formatter": ((value: any)=>{ return '$'+value })
        },
      }
    },
    "height": "400px",
    "color": {
      "scale": {
        "Norm": "#004877",
        "Current": "#0080E2",
        "Prior":"#50BEE1"
      }
    }
  }
}

export const pharmacyUtilizationByTherapeuticClass = {
  "data": [
    {
      "group": "Norm",
      "diagnosis": "ADHD, Anti-narcolepsy, Anti-obesity, Anorexiants",
      "value": 250
    },
    {
      "group": "Norm",
      "diagnosis": "Anti-Anxiety Agents",
      "value": 50
    },
    {
      "group": "Norm",
      "diagnosis": "Anti-Depressants",
      "value": 100
    },
    {
      "group": "Norm",
      "diagnosis": "Antipsychotics/Antimanic Agents",
      "value": 200
    },
    {
      "group": "Norm",
      "diagnosis": "Psychological and Neurological Agents",
      "value": 2600
    },
    {
      "group": "Current",
      "diagnosis": "ADHD, Anti-narcolepsy, Anti-obesity, Anorexiants",
      "value": 240
    },
    {
      "group": "Current",
      "diagnosis": "Anti-Anxiety Agents",
      "value": 50
    },
    {
      "group": "Current",
      "diagnosis": "Anti-Depressants",
      "value": 100
    },
    {
      "group": "Current",
      "diagnosis": "Antipsychotics/Antimanic Agents",
      "value": 195
    },
    {
      "group": "Current",
      "diagnosis": "Psychological and Neurological Agents",
      "value": 2675
    },
    {
      "group": "Prior",
      "diagnosis": "ADHD, Anti-narcolepsy, Anti-obesity, Anorexiants",
      "value": 245
    },
    {
      "group": "Prior",
      "diagnosis": "Anti-Anxiety Agents",
      "value": 50
    },
    {
      "group": "Prior",
      "diagnosis": "Anti-Depressants",
      "value": 100
    },
    {
      "group": "Prior",
      "diagnosis": "Antipsychotics/Antimanic Agents",
      "value": 225
    },
    {
      "group": "Prior",
      "diagnosis": "Psychological and Neurological Agents",
      "value": 2650
    }
    ],
    "options": {
    "axes": {
      "left": {
        "mapsTo": "diagnosis",
        "scaleType": ScaleTypes.LABELS
      },
      "bottom": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "ticks": {
          "values": [ 0, 500, 1000, 1500, 2000, 2500, 3000],
          "formatter": ((value: any)=>{ return '$'+value })
        },
      }
    },
    "height": "400px",
    "color": {
      "scale": {
        "Norm": "#004877",
        "Current": "#0080E2",
        "Prior":"#50BEE1"
      }
    }
  }
}

export const pharmacyUtilizationPer1000ByTherapeuticClass = {
  "data": [
    {
      "group": "Norm",
      "diagnosis": "ADHD, Anti-narcolepsy, Anti-obesity, Anorexiants",
      "value": 280
    },
    {
      "group": "Norm",
      "diagnosis": "Anti-Anxiety Agents",
      "value": 220
    },
    {
      "group": "Norm",
      "diagnosis": "Anti-Depressants",
      "value": 980
    },
    {
      "group": "Norm",
      "diagnosis": "Antipsychotics/Antimanic Agents",
      "value": 80
    },
    {
      "group": "Norm",
      "diagnosis": "Psychological and Neurological Agents",
      "value": 50
    },
    {
      "group": "Current",
      "diagnosis": "ADHD, Anti-narcolepsy, Anti-obesity, Anorexiants",
      "value": 285
    },
    {
      "group": "Current",
      "diagnosis": "Anti-Anxiety Agents",
      "value": 220
    },
    {
      "group": "Current",
      "diagnosis": "Anti-Depressants",
      "value": 990
    },
    {
      "group": "Current",
      "diagnosis": "Antipsychotics/Antimanic Agents",
      "value": 85
    },
    {
      "group": "Current",
      "diagnosis": "Psychological and Neurological Agents",
      "value": 45
    },
    {
      "group": "Prior",
      "diagnosis": "ADHD, Anti-narcolepsy, Anti-obesity, Anorexiants",
      "value": 275
    },
    {
      "group": "Prior",
      "diagnosis": "Anti-Anxiety Agents",
      "value": 220
    },
    {
      "group": "Prior",
      "diagnosis": "Anti-Depressants",
      "value": 970
    },
    {
      "group": "Prior",
      "diagnosis": "Antipsychotics/Antimanic Agents",
      "value": 75
    },
    {
      "group": "Prior",
      "diagnosis": "Psychological and Neurological Agents",
      "value": 40
    }
    ],
    "options": {
    "axes": {
      "left": {
        "mapsTo": "diagnosis",
        "scaleType": ScaleTypes.LABELS
      },
      "bottom": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "ticks": {
          "values": [ 0, 200, 400, 600, 800, 1000, 1200]
        },
      }
    },
    "height": "400px",
    "color": {
      "scale": {
        "Norm": "#004877",
        "Current": "#0080E2",
        "Prior":"#50BEE1"
      }
    }
  }
}

export const EBMCompliance = {
    "data": [
      {
        "group": "With BH Diagnosis",
        "groupName": "",
        "value": 83
      },
      {
        "group": "Without BH Diagnosis",
        "groupName": "",
        "value": 89
      }
    ],
    "options": {
      "axes": {
        "bottom": {
          "mapsTo": "groupName",
          "scaleType": ScaleTypes.LABELS
        },
        "left": {
          "mapsTo": "value",
          "scaleType": ScaleTypes.LINEAR,
          "percentage": true,
          "ticks": {
            "values": [ 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            "formatter": ((value: any)=>{ return value+'%' })
          },
        }
      },
      "height": "400px",
      "color": {
        "scale": {
          "With BH Diagnosis": "#FFA200",
          "Without BH Diagnosis": "#FF6400"
        }
      },
      "legend": {
        "truncation": {
          "numCharacter": 50
        }
        },
    }
}

export const TelemedVisits = {
  "data": [
    {
      "group": "With BH Diagnosis",
      "groupName": "",
      "value": 100
    },
    {
      "group": "Without BH Diagnosis",
      "groupName": "",
      "value": 57
    }
  ],
  "options": {
    "axes": {
      "bottom": {
        "mapsTo": "groupName",
        "scaleType": ScaleTypes.LABELS
      },
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
      }
    },
    "height": "400px",
    "color": {
      "scale": {
        "With BH Diagnosis": "#FFA200",
        "Without BH Diagnosis": "#FF6400"
      }
    },
    "legend": {
      "truncation": {
        "numCharacter": 50
      }
      },
  }
}

export const InpatientAdmitsVisits = {
  "data": [
    {
      "group": "With BH Diagnosis",
      "groupName": "",
      "value": 135
    },
    {
      "group": "Without BH Diagnosis",
      "groupName": "",
      "value": 105
    }
  ],
  "options": {
    "axes": {
      "bottom": {
        "mapsTo": "groupName",
        "scaleType": ScaleTypes.LABELS
      },
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
      }
    },
    "height": "400px",
    "color": {
      "scale": {
        "With BH Diagnosis": "#FFA200",
        "Without BH Diagnosis": "#FF6400"
      }
    },
    "legend": {
      "truncation": {
        "numCharacter": 50
      }
      },
  }
}

export const EmergencyDepartment = {
  "data": [
    {
      "group": "With BH Diagnosis",
      "groupName": "",
      "value": 360
    },
    {
      "group": "Without BH Diagnosis",
      "groupName": "",
      "value": 220
    }
  ],
  "options": {
    "axes": {
      "bottom": {
        "mapsTo": "groupName",
        "scaleType": ScaleTypes.LABELS
      },
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
      }
    },
    "height": "400px",
    "color": {
      "scale": {
        "With BH Diagnosis": "#FFA200",
        "Without BH Diagnosis": "#FF6400"
      }
    },
    "legend": {
      "truncation": {
        "numCharacter": 50
      }
      },
  }
}

export const membersWithHighOpiodUse = {
  "data": [
    {
      "group": "With BH Diagnosis",
      "groupName": "",
      "value": 3.5
    },
    {
      "group": "Without BH Diagnosis",
      "groupName": "",
      "value": 1.9
    }
  ],
  "options": {
    "axes": {
      "bottom": {
        "mapsTo": "groupName",
        "scaleType": ScaleTypes.LABELS
      },
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "ticks": {
          "formatter": ((value: any)=>{ return value+'%' })
        },
      }
    },
    "height": "400px",
    "color": {
      "scale": {
        "With BH Diagnosis": "#FFA200",
        "Without BH Diagnosis": "#FF6400"
      }
    },
    "legend": {
      "truncation": {
        "numCharacter": 50
      }
      },
  }
}

export const rxGenericUtilizationRate = {
  "data": [
    {
      "group": "With BH Diagnosis",
      "groupName": "",
      "value": 62.5
    },
    {
      "group": "Without BH Diagnosis",
      "groupName": "",
      "value": 59.5
    }
  ],
  "options": {
    "axes": {
      "bottom": {
        "mapsTo": "groupName",
        "scaleType": ScaleTypes.LABELS
      },
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "ticks": {
          "formatter": ((value: any)=>{ return value+'%' })
        },
      }
    },
    "height": "400px",
    "color": {
      "scale": {
        "With BH Diagnosis": "#FFA200",
        "Without BH Diagnosis": "#FF6400"
      }
    },
    "legend": {
      "truncation": {
        "numCharacter": 50
      }
      },
  }
}

export const rxMailOrderRate = {
  "data": [
    {
      "group": "With BH Diagnosis",
      "groupName": "",
      "value": 16
    },
    {
      "group": "Without BH Diagnosis",
      "groupName": "",
      "value": 22
    }
  ],
  "options": {
    "axes": {
      "bottom": {
        "mapsTo": "groupName",
        "scaleType": ScaleTypes.LABELS
      },
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "ticks": {
          "formatter": ((value: any)=>{ return value+'%' })
        },
      }
    },
    "height": "400px",
    "color": {
      "scale": {
        "With BH Diagnosis": "#FFA200",
        "Without BH Diagnosis": "#FF6400"
      }
    },
    "legend": {
      "truncation": {
        "numCharacter": 50
      }
      },
  }
}

export const populationImpact = {
  "data": [
    {
      "group": "Non-High cost with Behavioral Health",
      "relationship": "Percentage of Total Cost",
      "value": 20
    },
    {
      "group": "Non-High cost without Behavioral Health",
      "relationship": "Percentage of Total Cost",
      "value": 8
    },
    {
      "group": "High cost with Behavioral Health",
      "relationship": "Percentage of Total Cost",
      "value": 68
    },
    {
      "group": "High cost without Behavioral Health",
      "relationship": "Percentage of Total Cost",
      "value": 4
    },
    {
      "group": "Non-High cost with Behavioral Health",
      "relationship": "Percentage of Members",
      "value": 8
    },
    {
      "group": "Non-High cost without Behavioral Health",
      "relationship": "Percentage of Members",
      "value": 22
    },
    {
      "group": "High cost with Behavioral Health",
      "relationship": "Percentage of Members",
      "value": 45
    },
    {
      "group": "High cost without Behavioral Health",
      "relationship": "Percentage of Members",
      "value": 25
    }
  ],
  "options": {
    "title": "Population Impact",
    "axes": {
      "bottom": {
        "mapsTo": "relationship",
        "scaleType": ScaleTypes.LABELS,
          "truncation": {
            "numCharacter": 50
          }
      },
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "percentage":  true,
        "ticks": {
          "values": [ 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100 ],
          "formatter": ((value: any)=>{ return value+'%' })
        },
      }
    },
    "height": "400px",
    "color": {
      "scale": {
        "Non-High cost with Behavioral Health": "#0080E2",
        "Non-High cost without Behavioral Health": "#50BEE1",
        "High cost with Behavioral Health": "#FFA200",
        "High cost without Behavioral Health": "#FF6400"
      }
    },
    "bars": {
      "width": 40
    },
    "legend": {
      "truncation": {
        "numCharacter": 50
      }
      },
  }
}

export const adequacyOfBenefits = {
  data: [
    {
      "group": "Prior",
      "value": 79.5
    },
    {
      "group": "Current",
      "value": 81.3
    }
  ],
  options: {
    "title": "Adequacy of Benefits",
    "axes": {
      "left": {
        "mapsTo": "value",
        "scaleType":  ScaleTypes.LINEAR,
        "ticks": {
          "values": [ 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100 ],
          "formatter": ((value: any)=>{ return value+'%' })
        },
      },
      "bottom": {
        "mapsTo": "group",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "height": "400px",
    "bars": {
      "width":  48
    },
    "color": {
      "scale": {
        "Prior": "#0067B5",
        "Current": "#0067B5"
      }
    }
  }
}

export const INNNetworkUtilization = {
  data: [
    {
      "group": "Prior",
      "value": 94.5
    },
    {
      "group": "Current",
      "value": 92.5
    }
  ],
  options: {
    "title": "In Network Utilization",
    "axes": {
      "left": {
        "mapsTo": "value",
        "scaleType":  ScaleTypes.LINEAR,
        "ticks": {
          "values": [ 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100 ],
          "formatter": ((value: any)=>{ return value+'%' })
        },
      },
      "bottom": {
        "mapsTo": "group",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "height": "400px",
    "bars": {
      "width":  48
    },
    "color": {
      "scale": {
        "Prior": "#0067B5",
        "Current": "#0067B5"
      }
    }
  }
}

export const allServices = {
  "data": [
    {
      "service": "Prior",
      "group": "Paid PMPM",
      "value": 491
    },
    {
      "service": "Prior",
      "group": "Utilizations per 1000",
      "per1000": 28000
    },
    {
      "service": "Current",
      "group": "Paid PMPM",
      "value": 517
    },
    {
      "service": "Current",
      "group": "Utilizations per 1000",
      "per1000": 27500
    }
  ],
  "options": {
    "title": "All Services",
    "axes": {
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "correspondingDatasets": [
          "Paid PMPM"
        ],
        "ticks": {
          formatter: ((value:any)=>{return '$'+value})
        }
      },
      "right": {
        "mapsTo": "per1000",
        "scaleType": ScaleTypes.LINEAR,
        "correspondingDatasets": [
          "Utilizations per 1000"
        ],
        "ticks": {
          formatter: ((value:any)=>{return '$'+value})
        }
      },
      "bottom": {
        "mapsTo": "service",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "comboChartTypes": [
      {
        "type": "simple-bar",
        "correspondingDatasets": [
          "Paid PMPM"
        ]
      },
      {
        "type": "line",
        "options": {
          "points": {
            "radius": 5
          }
        },
        "correspondingDatasets": [
          "Utilizations per 1000"
        ]
      }
    ],
    "height": "400px",
    "color": {
      "scale": {
        "Paid PMPM": "#0067B5",
        "Utilizations per 1000": "#FF6400"
      }
    },
    "bars": {
      "width": 48
    },
    "legend": {
      "truncation": {
        "numCharacter": 50
      }
      },
  }
}

export const Medical = {
  "data": [
    {
      "service": "Prior",
      "group": "Paid PMPM",
      "value": 415
    },
    {
      "service": "Prior",
      "group": "Utilizations per 1000",
      "per1000": 19500
    },
    {
      "service": "Current",
      "group": "Paid PMPM",
      "value": 436
    },
    {
      "service": "Current",
      "group": "Utilizations per 1000",
      "per1000": 19000
    }
  ],
  "options": {
    "title": "Medical",
    "axes": {
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "correspondingDatasets": [
          "Paid PMPM"
        ],
        "ticks": {
          formatter: ((value:any)=>{return '$'+value})
        }
      },
      "right": {
        "mapsTo": "per1000",
        "scaleType": ScaleTypes.LINEAR,
        "correspondingDatasets": [
          "Utilizations per 1000"
        ],
        "ticks": {
          formatter: ((value:any)=>{return '$'+value})
        }
      },
      "bottom": {
        "mapsTo": "service",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "comboChartTypes": [
      {
        "type": "simple-bar",
        "correspondingDatasets": [
          "Paid PMPM"
        ]
      },
      {
        "type": "line",
        "options": {
          "points": {
            "radius": 5
          }
        },
        "correspondingDatasets": [
          "Utilizations per 1000"
        ]
      }
    ],
    "height": "400px",
    "color": {
      "scale": {
        "Paid PMPM": "#0067B5",
        "Utilizations per 1000": "#FF6400"
      }
    },
    "bars": {
      "width": 48
    },
    "legend": {
      "truncation": {
        "numCharacter": 50
      }
      },
  }
}

export const pharmacy = {
  "data": [
    {
      "service": "Prior",
      "group": "Paid PMPM",
      "value": 76
    },
    {
      "service": "Prior",
      "group": "Utilizations per 1000",
      "per1000": 9000
    },
    {
      "service": "Current",
      "group": "Paid PMPM",
      "value": 81
    },
    {
      "service": "Current",
      "group": "Utilizations per 1000",
      "per1000": 9400
    }
  ],
  "options": {
    "title": "Pharmacy",
    "axes": {
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "correspondingDatasets": [
          "Paid PMPM"
        ],
        "ticks": {
          formatter: ((value:any)=>{return '$'+value})
        }
      },
      "right": {
        "mapsTo": "per1000",
        "scaleType": ScaleTypes.LINEAR,
        "correspondingDatasets": [
          "Utilizations per 1000"
        ],
        "ticks": {
          formatter: ((value:any)=>{return '$'+value})
        }
      },
      "bottom": {
        "mapsTo": "service",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "comboChartTypes": [
      {
        "type": "simple-bar",
        "correspondingDatasets": [
          "Paid PMPM"
        ]
      },
      {
        "type": "line",
        "options": {
          "points": {
            "radius": 5
          }
        },
        "correspondingDatasets": [
          "Utilizations per 1000"
        ]
      }
    ],
    "height": "400px",
    "color": {
      "scale": {
        "Paid PMPM": "#0067B5",
        "Utilizations per 1000": "#FF6400"
      }
    },
    "bars": {
      "width": 48
    },
    "legend": {
      "truncation": {
        "numCharacter": 50
      }
      },
  }
}

export const facilityInpatient = {
  "data": [
    {
      "service": "Prior",
      "group": "Paid PMPM",
      "value": 491
    },
    {
      "service": "Prior",
      "group": "Utilizations per 1000",
      "per1000": 280
    },
    {
      "service": "Current",
      "group": "Paid PMPM",
      "value": 517
    },
    {
      "service": "Current",
      "group": "Utilizations per 1000",
      "per1000": 280
    }
  ],
  "options": {
    "title": "Facility Inpatient",
    "axes": {
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "correspondingDatasets": [
          "Paid PMPM"
        ],
        "ticks": {
          formatter: ((value:any)=>{return '$'+value})
        }
      },
      "right": {
        "mapsTo": "per1000",
        "scaleType": ScaleTypes.LINEAR,
        "correspondingDatasets": [
          "Utilizations per 1000"
        ],
        "ticks": {
          formatter: ((value:any)=>{return '$'+value})
        }
      },
      "bottom": {
        "mapsTo": "service",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "comboChartTypes": [
      {
        "type": "simple-bar",
        "correspondingDatasets": [
          "Paid PMPM"
        ]
      },
      {
        "type": "line",
        "options": {
          "points": {
            "radius": 5
          }
        },
        "correspondingDatasets": [
          "Utilizations per 1000"
        ]
      }
    ],
    "height": "400px",
    "color": {
      "scale": {
        "Paid PMPM": "#0067B5",
        "Utilizations per 1000": "#FF6400"
      }
    },
    "bars": {
      "width": 48
    },
    "legend": {
      "truncation": {
        "numCharacter": 50
      }
      },
  }
}

export const facilityOutpatient = {
  "data": [
    {
      "service": "Prior",
      "group": "Paid PMPM",
      "value": 491
    },
    {
      "service": "Prior",
      "group": "Utilizations per 1000",
      "per1000": 2400
    },
    {
      "service": "Current",
      "group": "Paid PMPM",
      "value": 517
    },
    {
      "service": "Current",
      "group": "Utilizations per 1000",
      "per1000": 2400
    }
  ],
  "options": {
    "title": "Facility Outpatient",
    "axes": {
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "correspondingDatasets": [
          "Paid PMPM"
        ],
        "ticks": {
          formatter: ((value:any)=>{return '$'+value})
        }
      },
      "right": {
        "mapsTo": "per1000",
        "scaleType": ScaleTypes.LINEAR,
        "correspondingDatasets": [
          "Utilizations per 1000"
        ],
        "ticks": {
          formatter: ((value:any)=>{return '$'+value})
        }
      },
      "bottom": {
        "mapsTo": "service",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "comboChartTypes": [
      {
        "type": "simple-bar",
        "correspondingDatasets": [
          "Paid PMPM"
        ]
      },
      {
        "type": "line",
        "options": {
          "points": {
            "radius": 5
          }
        },
        "correspondingDatasets": [
          "Utilizations per 1000"
        ]
      }
    ],
    "height": "400px",
    "color": {
      "scale": {
        "Paid PMPM": "#0067B5",
        "Utilizations per 1000": "#FF6400"
      }
    },
    "bars": {
      "width": 48
    },
    "legend": {
      "truncation": {
        "numCharacter": 50
      }
      },
  }
}

export const ancillary = {
  "data": [
    {
      "service": "Prior",
      "group": "Paid PMPM",
      "value": 19
    },
    {
      "service": "Prior",
      "group": "Utilizations per 1000",
      "per1000": 1400
    },
    {
      "service": "Current",
      "group": "Paid PMPM",
      "value": 14
    },
    {
      "service": "Current",
      "group": "Utilizations per 1000",
      "per1000": 1100
    }
  ],
  "options": {
    "title": "Ancillary",
    "axes": {
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "correspondingDatasets": [
          "Paid PMPM"
        ],
        "ticks": {
          formatter: ((value:any)=>{return '$'+value})
        }
      },
      "right": {
        "mapsTo": "per1000",
        "scaleType": ScaleTypes.LINEAR,
        "correspondingDatasets": [
          "Utilizations per 1000"
        ],
        "ticks": {
          formatter: ((value:any)=>{return '$'+value})
        }
      },
      "bottom": {
        "mapsTo": "service",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "comboChartTypes": [
      {
        "type": "simple-bar",
        "correspondingDatasets": [
          "Paid PMPM"
        ]
      },
      {
        "type": "line",
        "options": {
          "points": {
            "radius": 5
          }
        },
        "correspondingDatasets": [
          "Utilizations per 1000"
        ]
      }
    ],
    "height": "400px",
    "color": {
      "scale": {
        "Paid PMPM": "#0067B5",
        "Utilizations per 1000": "#FF6400"
      }
    },
    "bars": {
      "width": 48
    },
    "legend": {
      "truncation": {
        "numCharacter": 50
      }
      },
  }
}

export const chronicConditions = {
  "data": [
    {
      "service": "Prior",
      "group": "Paid PMPM",
      "value": 253.56
    },
    {
      "service": "Prior",
      "group": "Percent of Total Spend",
      "per1000": 57
    },
    {
      "service": "Current",
      "group": "Paid PMPM",
      "value": 286.40
    },
    {
      "service": "Current",
      "group": "Percent of Total Spend",
      "per1000": 57
    }
  ],
  "options": {
    "title": "Chronic Conditions",
    "axes": {
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "correspondingDatasets": [
          "Paid PMPM"
        ],
        "ticks": {
          formatter: ((value:any)=>{return '$'+value})
        }
      },
      "right": {
        "mapsTo": "per1000",
        "scaleType": ScaleTypes.LINEAR,
        "correspondingDatasets": [
          "Percent of Total Spend"
        ],
        "ticks": {
          formatter: ((value: any)=>{return value+'%'})
        }
      },
      "bottom": {
        "mapsTo": "service",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "comboChartTypes": [
      {
        "type": "simple-bar",
        "correspondingDatasets": [
          "Paid PMPM"
        ]
      },
      {
        "type": "line",
        "options": {
          "points": {
            "radius": 5
          }
        },
        "correspondingDatasets": [
          "Percent of Total Spend"
        ]
      }
    ],
    "height": "400px",
    "color": {
      "scale": {
        "Paid PMPM": "#0067B5",
        "Percent of Total Spend": "#FF6400"
      }
    },
    "bars": {
      "width": 48
    },
    "legend": {
      "truncation": {
        "numCharacter": 50
      }
    }
  }
}

export const highCostClaimants = {
  "data": [
    {
      "service": "Prior",
      "group": "Paid PMPM",
      "value": 163.99
    },
    {
      "service": "Prior",
      "group": "Percent of Total Spend",
      "per1000": 33
    },
    {
      "service": "Current",
      "group": "Paid PMPM",
      "value": 184.07
    },
    {
      "service": "Current",
      "group": "Percent of Total Spend",
      "per1000": 36
    }
  ],
  "options": {
    "title": "High Cost Claimants",
    "axes": {
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "correspondingDatasets": [
          "Paid PMPM"
        ],
        "ticks": {
          formatter: ((value:any)=>{return '$'+value})
        }
      },
      "right": {
        "mapsTo": "per1000",
        "scaleType": ScaleTypes.LINEAR,
        "correspondingDatasets": [
          "Percent of Total Spend"
        ],
        "ticks": {
          formatter: ((value: any)=>{return value+'%'})
        }
      },
      "bottom": {
        "mapsTo": "service",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "comboChartTypes": [
      {
        "type": "simple-bar",
        "correspondingDatasets": [
          "Paid PMPM"
        ]
      },
      {
        "type": "line",
        "options": {
          "points": {
            "radius": 5
          }
        },
        "correspondingDatasets": [
          "Percent of Total Spend"
        ]
      }
    ],
    "height": "400px",
    "color": {
      "scale": {
        "Paid PMPM": "#0067B5",
        "Percent of Total Spend": "#FF6400"
      }
    },
    "bars": {
      "width": 48
    },
    "legend": {
      "truncation": {
        "numCharacter": 50
      }
      },
  }
}

export const yearToYearPaidPMPMChanges = {
  data: [
    {
      "group": "Prior",
      "value": [
        475,
        491
      ]
    },
    {
      "group": "Facility Inpatient",
      "value": [
        492,
        502
      ]
    },
    {
      "group": "Facility Outpatient",
      "value": [
        502,
        513
      ]
    },
    {
      "group": "Professional",
      "value": [
        514,
        517
      ]
    },
    {
      "group": "Pharmacy",
      "value": [
        517,
        522
      ]
    },
    {
      "group": "Ancillary",
      "value": [
        517,
        522
      ]
    },
    {
      "group": "Current",
      "value": [
        475,
        517
      ]
    }
  ],
  options: {
    "title": "Year to Year Paid PMPM Changes",
    "axes": {
      "left": {
        "mapsTo": "value",
        "includeZero": false,
        "ticks": {
          "values": [475, 480, 485, 490, 495, 500, 505, 510, 515, 520, 525],
          "formatter": ((value:any)=>{return '$'+value})
        }
      },
      "bottom": {
        "mapsTo": "group",
        "scaleType":  ScaleTypes.LABELS,
        "domain": ["Prior", "Facility Inpatient", "Facility Outpatient", "Professional", "Pharmacy", "Ancillary", "Current"]
      }
    },
    "height": "400px",
    "bars": {
      "width": 48
    },
    "color": {
      "scale": {
        "Prior": "#50BEE1",
        "Current": "#0067B5",
        "Facility Inpatient": "#FF6400",
        "Facility Outpatient": "#FF6400",
        "Professional": "#FF6400",
        "Pharmacy": "#FF6400",
        "Ancillary": "#00A663"
      }
    },
    "legend": {
      "truncation": {
        "numCharacter": 50
      }
      },
  }
}

export const totalNetPaidByHCG = {
  data: [
    {
      "group": "Facility Inpatient",
      "value": 22
    },
    {
      "group": "Facility Outpatient",
      "value": 32
    },
    {
      "group": "Professional",
      "value": 27
    },
    {
      "group": "Pharmacy",
      "value": 16
    },
    {
      "group": "Ancillary",
      "value": 3
    }
  ],
    options: {
      "title": "Total Net Paid by HCG",
        "resizable": true,
        "donut": {
          "center": {
            "number": 410446137,
            "label": "Total Paid Dollars",
            "numberFormatter": (x: number) => "$" + x.toLocaleString("en-US")
          },
            "alignment": Alignments.CENTER
        },
        "height": "350px",
        "color": {
          "scale": {
            "Facility Inpatient": "#0080E2",
            "Facility Outpatient": "#004877",
            "Professional": "#74C061",
            "Pharmacy": "#FFA200",
            "Ancillary": "#50BEE1"
          }
        },
        "legend": {
          "truncation": {
            "numCharacter": 50
          }
        }
    }
}

export const MHSAClaimantsSpendByCCHG ={
  data: [
    {
      "name": "Depression, substance abuse, and other mental health disorder",
      "children": [
        {
          "name": "Depression, substance abuse, and other mental health disorder",
          "value": 40,
          "showLabel": true
        }
      ]
    },
    {
      "name": "Hypertension includes stroke & peripheral vascular disease",
      "children": [
        {
          "name": "Hypertension includes stroke & peripheral vascular disease",
          "value": 20,
          "showLabel": true
        }
      ]
    },
    {
      "name": "Intellectual disability",
      "children": [
        {
          "name": "Intellectual disability",
          "value": 10,
          "showLabel": true
        }
      ]
    },
    {
      "name": "Healthy female (16-40)",
      "children": [
        {
          "name": "Healthy female (16-40)",
          "value": 10,
          "showLabel": true
        }
      ]
    },
    {
      "name": "Asthma",
      "children": [
        {
          "name": "Asthma",
          "value": 8,
          "showLabel": true
        }
      ]
    },
    {
      "name": "Major...",
      "children": [
        {
          "name": "Major...",
          "value": 5,
          "showLabel": true
        }
      ]
    },
    {
      "name": "Anxiety",
      "children": [
        {
          "name": "Anxiety",
          "value": 4,
          "showLabel": true
        }
      ]
    },
    {
      "name": "Neuro...",
      "children": [
        {
          "name": "Neuro...",
          "value": 3,
          "showLabel": true
        }
      ]
    }
  ],
  options: {
    "title": "BH Claimants Net Paid PMPM by CCHG",
    "height": "350px",
    "color": {
      "scale": {
        "Depression, substance abuse, and other mental health disorder": "#100306",
        "Hypertension includes stroke & peripheral vascular disease": "#310A13",
        "Intellectual disability": "#531020",
        "Healthy female (16-40)": "#73162C",
        "Asthma": "#951D39",
        "Major...": "#AE3652",
        "Anxiety": "#B74D65",
        "Neuro...": "#C06379"
      },
      "legend": {
        "enabled": false
      }
    }
  }
}

export const totalCostImpactOfBehavioralComorbidity = {
  "data": [
    {
      "group": "Without BH Diagnosis",
      "value": 1488
    },
    {
      "group": "With BH Diagnosis",
      "value": 3475
    }
  ],
  "options": {
    "axes": {
      "bottom": {
        "mapsTo": "group",
        "scaleType": ScaleTypes.LABELS,
        "truncation": {
            "numCharacter": 50
        }
      },
      "left": {
        "mapsTo": "value",
        "scaleType": ScaleTypes.LINEAR,
        "ticks": {
          "formatter": ((value: any)=>{ return '$'+value })
        },
      }
    },
    "height": "400px",
    "color": {
      "scale": {
        "Without BH Diagnosis": "#0080E2",
        "With BH Diagnosis": "#0080E2"
      }
    },
    "bars": {
      "width": 64
    },
    "legend": {
      "enabled": false
    }
  }
}

export const inpatientAdmissionsPer1000VBenchmark = {
  "data": [
    {
      "group": "Client Position",
      "relationship": "Inpatient Medical",
      "actual": 65
    },
    {
      "group": "Loosely Managed",
      "relationship": "Inpatient Medical",
      "value": 10
    },
    {
      "group": "10% Managed",
      "relationship": "Inpatient Medical",
      "value": 10
    },
    {
      "group": "20% Managed",
      "relationship": "Inpatient Medical",
      "value": 10
    },
    {
      "group": "30% Managed",
      "relationship": "Inpatient Medical",
      "value": 10
    },
    {
      "group": "40% Managed",
      "relationship": "Inpatient Medical",
      "value": 10
    },
    {
      "group": "50% Managed",
      "relationship": "Inpatient Medical",
      "value": 10
    },
    {
      "group": "60% Managed",
      "relationship": "Inpatient Medical",
      "value": 10
    },
    {
      "group": "70% Managed",
      "relationship": "Inpatient Medical",
      "value": 10
    },
    {
      "group": "80% Managed",
      "relationship": "Inpatient Medical",
      "value": 10
    },
    {
      "group": "90% Managed",
      "relationship": "Inpatient Medical",
      "value": 10
    },
    {
      "group": "Highly Managed",
      "relationship": "Inpatient Medical",
      "value": 10
    },
    {
      "group": "Client Position",
      "relationship": "Inpatient Surgery",
      "actual": 75
    },
    {
      "group": "Loosely Managed",
      "relationship": "Inpatient Surgery",
      "value": 10
    },
    {
      "group": "10% Managed",
      "relationship": "Inpatient Surgery",
      "value": 10
    },
    {
      "group": "20% Managed",
      "relationship": "Inpatient Surgery",
      "value": 10
    },
    {
      "group": "30% Managed",
      "relationship": "Inpatient Surgery",
      "value": 10
    },
    {
      "group": "40% Managed",
      "relationship": "Inpatient Surgery",
      "value": 10
    },
    {
      "group": "50% Managed",
      "relationship": "Inpatient Surgery",
      "value": 10
    },
    {
      "group": "60% Managed",
      "relationship": "Inpatient Surgery",
      "value": 10
    },
    {
      "group": "70% Managed",
      "relationship": "Inpatient Surgery",
      "value": 10
    },
    {
      "group": "80% Managed",
      "relationship": "Inpatient Surgery",
      "value": 10
    },
    {
      "group": "90% Managed",
      "relationship": "Inpatient Surgery",
      "value": 10
    },
    {
      "group": "Highly Managed",
      "relationship": "Inpatient Surgery",
      "value": 10
    },
    {
      "group": "Client Position",
      "relationship": "Inpatient Maternity",
      "actual": 98
    },
    {
      "group": "Loosely Managed",
      "relationship": "Inpatient Maternity",
      "value": 10
    },
    {
      "group": "10% Managed",
      "relationship": "Inpatient Maternity",
      "value": 10
    },
    {
      "group": "20% Managed",
      "relationship": "Inpatient Maternity",
      "value": 10
    },
    {
      "group": "30% Managed",
      "relationship": "Inpatient Maternity",
      "value": 10
    },
    {
      "group": "40% Managed",
      "relationship": "Inpatient Maternity",
      "value": 10
    },
    {
      "group": "50% Managed",
      "relationship": "Inpatient Maternity",
      "value": 10
    },
    {
      "group": "60% Managed",
      "relationship": "Inpatient Maternity",
      "value": 10
    },
    {
      "group": "70% Managed",
      "relationship": "Inpatient Maternity",
      "value": 10
    },
    {
      "group": "80% Managed",
      "relationship": "Inpatient Maternity",
      "value": 10
    },
    {
      "group": "90% Managed",
      "relationship": "Inpatient Maternity",
      "value": 10
    },
    {
      "group": "Highly Managed",
      "relationship": "Inpatient Maternity",
      "value": 10
    },
    {
      "group": "Client Position",
      "relationship": "Behavioral",
      "actual": 95
    },
    {
      "group": "Loosely Managed",
      "relationship": "Behavioral",
      "value": 10
    },
    {
      "group": "10% Managed",
      "relationship": "Behavioral",
      "value": 10
    },
    {
      "group": "20% Managed",
      "relationship": "Behavioral",
      "value": 10
    },
    {
      "group": "30% Managed",
      "relationship": "Behavioral",
      "value": 10
    },
    {
      "group": "40% Managed",
      "relationship": "Behavioral",
      "value": 10
    },
    {
      "group": "50% Managed",
      "relationship": "Behavioral",
      "value": 10
    },
    {
      "group": "60% Managed",
      "relationship": "Behavioral",
      "value": 10
    },
    {
      "group": "70% Managed",
      "relationship": "Behavioral",
      "value": 10
    },
    {
      "group": "80% Managed",
      "relationship": "Behavioral",
      "value": 10
    },
    {
      "group": "90% Managed",
      "relationship": "Behavioral",
      "value": 10
    },
    {
      "group": "Highly Managed",
      "relationship": "Behavioral",
      "value": 10
    }
  ],
  "options": {
    "axes": {
      "top": {
        "mapsTo": "value",
        "correspondingDatasets": [
          "Loosely Managed",
          "10% Managed",
          "20% Managed",
          "30% Managed",
          "40% Managed",
          "50% Managed",
          "60% Managed",
          "70% Managed",
          "80% Managed",
          "90% Managed",
          "Highly Managed",
        ],
        "stacked": true,
        "domain": [0, 100],
        "visible": false
      },
      "bottom": {
        "mapsTo": "actual",
        "correspondingDatasets": [
          "Client Position"
        ],
        "domain": [0, 100],
        "visible": false
      },
      "left": {
        "mapsTo": "relationship",
        "scaleType": ScaleTypes.LABELS,
        "domain": ['Inpatient Medical', 'Inpatient Surgery', 'Inpatient Maternity', 'Behavioral']
      }
    },
    "comboChartTypes": [
      {
        "type": "stacked-bar",
        "correspondingDatasets": [
          "Loosely Managed",
          "10% Managed",
          "20% Managed",
          "30% Managed",
          "40% Managed",
          "50% Managed",
          "60% Managed",
          "70% Managed",
          "80% Managed",
          "90% Managed",
          "Highly Managed",
        ]
      },
      {
        "type": "line",
        "correspondingDatasets": [
          "Client Position"
        ],
        "options": {
          "points": {
            "radius": 7
          }
        },
      }
    ],
    "height": "400px",
    "bars": {
      "width": 60
    },
    "color": {
      "scale": {
        "Client Position": "#0080E2",
        "Loosely Managed": "#A52040",
        "10% Managed": "#C45758",
        "20% Managed": "#DB816B",
        "30% Managed": "#ECA178",
        "40% Managed": "#FFC387",
        "50% Managed": "#FFC800",
        "60% Managed": "#B1AC14",
        "70% Managed": "#739523",
        "80% Managed": "#43842F",
        "90% Managed": "#006B3F",
        "Highly Managed": "#125203"

      }
    },
    "grid": {
      "x": {
        "enabled": false
      },
      "y": {
        "enabled": false
      },
      "getFillColor": {
        "defaultFillColor": "#0080E2"
      }
    }
  }
}

export const inpatientAllowedCostPerAdmitVBenchmark = {
  "data": [
    {
      "group": "Client Position",
      "relationship": "Inpatient Medical",
      "actual": 65
    },
    {
      "group": "Loosely Managed",
      "relationship": "Inpatient Medical",
      "value": 10
    },
    {
      "group": "10% Managed",
      "relationship": "Inpatient Medical",
      "value": 10
    },
    {
      "group": "20% Managed",
      "relationship": "Inpatient Medical",
      "value": 10
    },
    {
      "group": "30% Managed",
      "relationship": "Inpatient Medical",
      "value": 10
    },
    {
      "group": "40% Managed",
      "relationship": "Inpatient Medical",
      "value": 10
    },
    {
      "group": "50% Managed",
      "relationship": "Inpatient Medical",
      "value": 10
    },
    {
      "group": "60% Managed",
      "relationship": "Inpatient Medical",
      "value": 10
    },
    {
      "group": "70% Managed",
      "relationship": "Inpatient Medical",
      "value": 10
    },
    {
      "group": "80% Managed",
      "relationship": "Inpatient Medical",
      "value": 10
    },
    {
      "group": "90% Managed",
      "relationship": "Inpatient Medical",
      "value": 10
    },
    {
      "group": "Highly Managed",
      "relationship": "Inpatient Medical",
      "value": 10
    },
    {
      "group": "Client Position",
      "relationship": "Inpatient Surgery",
      "actual": 75
    },
    {
      "group": "Loosely Managed",
      "relationship": "Inpatient Surgery",
      "value": 10
    },
    {
      "group": "10% Managed",
      "relationship": "Inpatient Surgery",
      "value": 10
    },
    {
      "group": "20% Managed",
      "relationship": "Inpatient Surgery",
      "value": 10
    },
    {
      "group": "30% Managed",
      "relationship": "Inpatient Surgery",
      "value": 10
    },
    {
      "group": "40% Managed",
      "relationship": "Inpatient Surgery",
      "value": 10
    },
    {
      "group": "50% Managed",
      "relationship": "Inpatient Surgery",
      "value": 10
    },
    {
      "group": "60% Managed",
      "relationship": "Inpatient Surgery",
      "value": 10
    },
    {
      "group": "70% Managed",
      "relationship": "Inpatient Surgery",
      "value": 10
    },
    {
      "group": "80% Managed",
      "relationship": "Inpatient Surgery",
      "value": 10
    },
    {
      "group": "90% Managed",
      "relationship": "Inpatient Surgery",
      "value": 10
    },
    {
      "group": "Highly Managed",
      "relationship": "Inpatient Surgery",
      "value": 10
    },
    {
      "group": "Client Position",
      "relationship": "Inpatient Maternity",
      "actual": 98
    },
    {
      "group": "Loosely Managed",
      "relationship": "Inpatient Maternity",
      "value": 10
    },
    {
      "group": "10% Managed",
      "relationship": "Inpatient Maternity",
      "value": 10
    },
    {
      "group": "20% Managed",
      "relationship": "Inpatient Maternity",
      "value": 10
    },
    {
      "group": "30% Managed",
      "relationship": "Inpatient Maternity",
      "value": 10
    },
    {
      "group": "40% Managed",
      "relationship": "Inpatient Maternity",
      "value": 10
    },
    {
      "group": "50% Managed",
      "relationship": "Inpatient Maternity",
      "value": 10
    },
    {
      "group": "60% Managed",
      "relationship": "Inpatient Maternity",
      "value": 10
    },
    {
      "group": "70% Managed",
      "relationship": "Inpatient Maternity",
      "value": 10
    },
    {
      "group": "80% Managed",
      "relationship": "Inpatient Maternity",
      "value": 10
    },
    {
      "group": "90% Managed",
      "relationship": "Inpatient Maternity",
      "value": 10
    },
    {
      "group": "Highly Managed",
      "relationship": "Inpatient Maternity",
      "value": 10
    },
    {
      "group": "Client Position",
      "relationship": "Behavioral",
      "actual": 95
    },
    {
      "group": "Loosely Managed",
      "relationship": "Behavioral",
      "value": 10
    },
    {
      "group": "10% Managed",
      "relationship": "Behavioral",
      "value": 10
    },
    {
      "group": "20% Managed",
      "relationship": "Behavioral",
      "value": 10
    },
    {
      "group": "30% Managed",
      "relationship": "Behavioral",
      "value": 10
    },
    {
      "group": "40% Managed",
      "relationship": "Behavioral",
      "value": 10
    },
    {
      "group": "50% Managed",
      "relationship": "Behavioral",
      "value": 10
    },
    {
      "group": "60% Managed",
      "relationship": "Behavioral",
      "value": 10
    },
    {
      "group": "70% Managed",
      "relationship": "Behavioral",
      "value": 10
    },
    {
      "group": "80% Managed",
      "relationship": "Behavioral",
      "value": 10
    },
    {
      "group": "90% Managed",
      "relationship": "Behavioral",
      "value": 10
    },
    {
      "group": "Highly Managed",
      "relationship": "Behavioral",
      "value": 10
    }
  ],
  "options": {
    "axes": {
      "top": {
        "mapsTo": "value",
        "correspondingDatasets": [
          "Loosely Managed",
          "10% Managed",
          "20% Managed",
          "30% Managed",
          "40% Managed",
          "50% Managed",
          "60% Managed",
          "70% Managed",
          "80% Managed",
          "90% Managed",
          "Highly Managed",
        ],
        "stacked": true,
        "domain": [0, 100],
        "visible": false
      },
      "bottom": {
        "mapsTo": "actual",
        "correspondingDatasets": [
          "Client Position"
        ],
        "domain": [0, 100],
        "visible": false
      },
      "left": {
        "mapsTo": "relationship",
        "scaleType": ScaleTypes.LABELS,
        "domain": ['Inpatient Medical', 'Inpatient Surgery', 'Inpatient Maternity', 'Behavioral']
      }
    },
    "comboChartTypes": [
      {
        "type": "stacked-bar",
        "correspondingDatasets": [
          "Loosely Managed",
          "10% Managed",
          "20% Managed",
          "30% Managed",
          "40% Managed",
          "50% Managed",
          "60% Managed",
          "70% Managed",
          "80% Managed",
          "90% Managed",
          "Highly Managed",
        ]
      },
      {
        "type": "scatter",
        "correspondingDatasets": [
          "Client Position"
        ],
        "options": {
          "points": {
            "radius": 7
          }
        },
      }
    ],
    "height": "400px",
    "bars": {
      "width": 60
    },
    "color": {
      "scale": {
        "Client Position": "#FFFFFF",
        "Loosely Managed": "#A52040",
        "10% Managed": "#C45758",
        "20% Managed": "#DB816B",
        "30% Managed": "#ECA178",
        "40% Managed": "#FFC387",
        "50% Managed": "#FFC800",
        "60% Managed": "#B1AC14",
        "70% Managed": "#739523",
        "80% Managed": "#43842F",
        "90% Managed": "#006B3F",
        "Highly Managed": "#125203"

      }
    },
    "grid": {
      "x": {
        "enabled": false
      },
      "y": {
        "enabled": false
      },
      "getFillColor": {
        "defaultFillColor": "#0080E2"
      }
    }
  }
}
