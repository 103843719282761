import React from 'react';
import { DataTable,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    Column,
    Row,
	OverflowMenu
  } from 'carbon-components-react';
import { DonutChart, GroupedBarChart, LineChart, SimpleBarChart, StackedBarChart } from '@carbon/charts-react';
import { donutOne, donutTwo, donutThree, donutFour, groupedBarChart, line, simpleBarChart, ExploymentPlan } from '../../../data/Chart1/chartData';
import { ChartTypes } from '../../Widget/DataDisplays/ChartTypes';
import { useSelector } from 'react-redux';
import StyledLink from '../../SideNavigation/StyledLink';
import OverviewBox from '../../Other/OverviewBox';

const demographicsRows = require('../../../data/DemographicsTable/rows.json');
const demographicsHeaders = require('../../../data/DemographicsTable/headers.json');

const DemographicsDashboard = () => {
	const reportCreatedDate = useSelector((state: any) => state.root.reportCreatedDate);
return (

  <Column>
  	<Row>
        <Column>
            <OverviewBox
                maxPerSection={3}
                description="Member demographics provide a snapshot look at critical metrics associated with your member population. Explore member counts, critical population key metrics, and the financial impact on varying demographic differences. Utilize this storyboard to understand demographic changes and the associated contribution to trend."
                sections={["Key Indicators", "Critical Populations", "Enrollment Profile"]}
            />
        </Column>
    </Row>
    <Row style={{marginLeft: "0px", paddingTop: "40px"}}>
		<Column style={{padding: "0"}}>
			<Row style={{marginLeft: "0", marginBottom: "20px"}}><h2>Key Indicators</h2></Row>
			<DataTable rows={demographicsRows} headers={demographicsHeaders} isSortable>
		{({
			rows = demographicsRows,
			headers = demographicsHeaders,
			getHeaderProps,
			getRowProps,
			getTableProps,
			getTableContainerProps
		}: { rows: ChartTypes.ITableRow[], headers:ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any}) => (
			<TableContainer
			title="Member Demographics"
			description=""
			{...getTableContainerProps()}
			>
			<Table {...getTableProps()} isSortable>
				<TableHead>
				<TableRow>
					{headers.map((header) => (
					<TableHeader
						key={header.key}
						{...getHeaderProps({ header })}
						isSortable
					>
						{header.header}
					</TableHeader>
					))}
				</TableRow>
				</TableHead>
				<TableBody>
				{rows.map((row) => (
					<TableRow {...getRowProps( { row })}>
					{row.cells.map((cell) => (
						<TableCell style={{backgroundColor: "#FFF"}} key={cell.id}>{cell.value}</TableCell>
					))}
					</TableRow>
				))}
				</TableBody>
			</Table>
			</TableContainer>
		)}
		</DataTable>
		</Column>
	</Row>

	<Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Critical Populations</h3></Row>
	<Row style={{ marginTop: "22px", marginLeft: '0px' }}>
            <Column style={{ backgroundColor: "#FFF", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px' }}>
						<h5>Female Members</h5>
						<p style={{ color: "#616771", paddingBottom: '38px' }}></p>
						<DonutChart
							data={donutOne.data}
							options={donutOne.options}
						/>
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px'  }}>
						<h5>Chronic Members</h5>
						<p style={{ color: "#616771", paddingBottom: '38px' }}></p>
						<DonutChart
							data={donutTwo.data}
							options={donutTwo.options}
						/>
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px'  }}>
						<h5>Pharmacy Claimants</h5>
						<p style={{ color: "#616771", paddingBottom: '38px' }}></p>
						<DonutChart
							data={donutThree.data}
							options={donutThree.options}
						/>
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
			<Column style={{ backgroundColor: "#FFF", marginLeft: "20px", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px'  }}>
						<h5>Non-utilizing Members</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>$0 Dollars Allowed</p>
						<DonutChart
							data={donutFour.data}
							options={donutFour.options}
						/>
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>

		<Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Enrollment Profile</h3></Row>
        <Row style={{ marginTop: "22px", marginLeft: '0px' }}>
			<Column lg={6} style={{ paddingLeft: "0"}}>
				<div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "20px", paddingTop: "20px", height: "100%", display: "flex"}}>
						<div style={{ backgroundColor: "white", paddingBottom: '10px', width: "100%" }}>
							<h5>Average Members by Month</h5>
							<p style={{ color: "#616771", marginTop: "22px", paddingBottom: '15px' }}></p>
							<LineChart
								data={line.data}
								options={line.options}
							/>
						</div>
						<OverflowMenu/>
					</div>
			</Column>
			<Column lg={3} style={{ padding: "0"}}>
				<div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "20px", paddingTop: "20px", height: "100%", display: "flex"}}>
						<div style={{ backgroundColor: "white", paddingBottom: '10px'  }}>
							<h5>Enrollment by Plan</h5>
							<p style={{ color: "#616771", marginTop: "22px", paddingBottom: '15px' }}></p>
							<StackedBarChart options={ExploymentPlan.options} data={ExploymentPlan.data} />
						</div>
						<OverflowMenu/>
					</div>
			</Column>
			<Column lg={3} style={{ paddingRight: "0"}}>
				<div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "20px", paddingTop: "20px", height: "100%", display: "flex"}}>
						<div style={{ backgroundColor: "white", paddingBottom: '10px'  }}>
							<h5>Distribution by EE, SP, DEP</h5>
							<p style={{ color: "#616771", paddingBottom: '15px' }}>with MARA Concurrent Risk</p>
							<SimpleBarChart
								data={simpleBarChart.data}
								options={simpleBarChart.options}
							/>
						</div>
						<OverflowMenu/>
					</div>
			</Column>
        </Row>

		<Row style={{ marginTop: "22px", marginLeft: '0px' }}>
			<Column sm={2} md={4} lg={12} style={{paddingLeft: "0px", paddingRight: "0px" }}>
				<div id="" style={{backgroundColor: "white", padding: "20px", paddingTop: "20px", height: "100%", display: "flex", width: '100%'}}>
						<div style={{ backgroundColor: "white", paddingBottom: '10px', width: '100%' }}>
							<h5>Members by Payment Band - Allowed</h5>
							<p style={{ color: "#616771", marginTop: "22px", paddingBottom: '0' }}></p>
							<GroupedBarChart
								data={groupedBarChart.data}
								options={groupedBarChart.options}
							/>
						</div>
						<OverflowMenu/>
					</div>
			</Column>
		</Row>

		<Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Explore Related Topics</h3></Row>
        <Row style={{ marginLeft: '0px', paddingBottom: '80px' }}>
            <Column style={{ padding: '0px', marginTop: '30px' }}>
                <ul>
					<li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
						<StyledLink to="/Covid19" onClick={() => window.scrollTo(0, 0)}>Members with Chronic Conditions<span style={{ color: 'gray' }}> (Special Topic)</span> </StyledLink>
					</li>
					<li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
						<StyledLink to="#">Contribution to Trend <span style={{ color: 'gray' }}> (Storyboard)</span>  </StyledLink>
					</li>
					<li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
						<StyledLink to="#">High Cost Claimants <span style={{ color: 'gray' }}> (Storyboard)</span>  </StyledLink>
					</li>
                </ul>
            </Column>
            <Column style={{ padding: '0px', marginTop: '30px' }}>
                <ul>
					<li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
						<StyledLink to="/Covid19" onClick={() => window.scrollTo(0, 0)}>Members with $0 Allowed<span style={{ color: 'gray' }}> (Special Topic)</span> </StyledLink>
					</li>
					<li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
						<StyledLink to="#">Member Cost Share <span style={{ color: 'gray' }}> (Special Topic)</span>  </StyledLink>
					</li>
					<li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
						<StyledLink to="#">Rising Risk <span style={{ color: 'gray' }}> (Special Topic)</span>  </StyledLink>
					</li>
                </ul>
            </Column>
        </Row>

  </Column>
);
}

export default DemographicsDashboard;