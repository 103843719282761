import { ScaleTypes } from '@carbon/charts/interfaces/enums';
import { GaugeTypes } from '@carbon/charts/interfaces/enums';

const loading = false; // setting loading true shows skeleton only




export const NewMemberPrevalence_StackedBarChart = {
    data: [
        {
            "group": "New Enrollee High Cost Members: 10%",
            "key": "",
            "value": 10
        },
        {
            "group": "Existing Enrollee High Cost Members",
            "key": "",
            "value": 80
        },
    ],
    options: {
        "title": "New Member Prevalence",
        "axes": {
            "left": {
                "mapsTo": "value",
                "stacked": true,
                "visible": false

            },
            "bottom": {
                // "mapsTo": "key",
                "scaleType": ScaleTypes.LABELS,
                "visible": false
            }
        },
        "height": "300px",
        "grid": {
            'y': {
                'enabled': false
            },
            'x': {
                'enabled': false
            }
        },
        "data": {
            "loading": loading
        },
        "color": {
            "scale": {
                "New Enrollee High Cost Members: 10%": "#004877",
                "Existing Enrollee High Cost Members": "#FFC800",
            }
        },
        "bars": {
            "maxWidth": 50
        },
        "legend": {
            "truncation": {
              "numCharacter": 50
            }
        }
    }
}
export const PopulationImpact_StackedBarChart = {
    data: [
        {
            "group": "High Cost Members: 7%",
            "key": "",
            "value": 7
        },
        {
            "group": "High Cost Member Spent: 31.2%",
            "key": "",
            "value": 31.25
        },
        {
            "group": "Non-High Cost Members",
            "key": "",
            "value": 77
        }
    ],
    options: {
        "title": "Population Impact",
        "axes": {
            "left": {
                "mapsTo": "value",
                "stacked": true,
                "visible": false

            },
            "bottom": {
                // "mapsTo": "key",
                "scaleType": ScaleTypes.LABELS,
                "visible": false
            }
        },
        "height": "300px",
        "grid": {
            'y': {
                'enabled': false
            },
            'x': {
                'enabled': false
            }
        },
        "data": {
            "loading": loading
        },
        "color": {
            "scale": {
                "High Cost Members: 7%": "#004877",
                "High Cost Member Spent: 31.2%": "#FF6400",
                "Non-High Cost Members": "#C6C9CA",
            }
        },
        "bars": {
            "maxWidth": 35
        },
        "legend": {
            "truncation": {
              "numCharacter": 50
            }
        },
    }
}


export const PriorHighCostStausGauge = {
    "data": [
        {
            "group": "value",
            "value": 30
        }
    ],
    "options": {
        title: "Prior High Cost Staus",
        resizable: true,
        height: "65%",
        width: "100%",
        "gauge": {
            "type": GaugeTypes.FULL
        },
        "color": {
            "scale": {
                "value": "#004877"
            }
        },
        "legend": {
            "enabled": false
        }
    }
}

export const ComparativeCost_BarChart = {
    data: [
        {
            "group": "Average PMPM",
            "value": 5
        },
        {
            "group": "High Cost Members",
            "value": 10
        }
    ],
    options: {
        "title": "Comparative Cost",
        "axes": {
            "left": {
                "mapsTo": "value",
                "visible": false
            },
            "bottom": {
                "mapsTo": "group",
                "scaleType": ScaleTypes.LABELS,
                "visible": false
            }
        },
        "color": {
            "scale": {
                "Average PMPM": "#C6C9CA",
                "High Cost Members": "#004877"
            }
        },
        "height": "180px",
        "grid": {
            'y': {
                'enabled': false
            },
            'x': {
                'enabled': false
            }
        },
        "data": {
            "loading": loading
        },
        "bars": {
            "width": 60
        },
        "legend": {
            "enabled": false
        }
    }
}