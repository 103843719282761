import React from 'react';
import { Breadcrumb, BreadcrumbItem, Column, Row, Modal,DataTable,Dropdown,Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow } from 'carbon-components-react';
import DashboardFilters from '../Dashboard/DashboardFilters';
import { ChevronDown20, DocumentExport20, Time20, ArrowDownRight20} from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ModalStateManager } from '../Other/ModalStateManager';
import { ChartTypes } from '../Widget/DataDisplays/ChartTypes';
import StyledLink from '../SideNavigation/StyledLink';
import { SpecialTopicsExportModal } from './SpecialTopicsExportModal';
const dynamicDrillRows = require('../../data/DynamicDrill/rows.json');
const dynamicDrillHeaders = require('../../data/DynamicDrill/headers.json');

const HeaderBreadcrumb = (isSpecialTopic: boolean, pageTitle: string, pageLink: string) => {
    if (isSpecialTopic) {
		return (
			<Breadcrumb>
				<BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
				<BreadcrumbItem><Link to="/SpecialTopics">Special Topics</Link></BreadcrumbItem>
				<BreadcrumbItem><Link to={pageLink}>{pageTitle}</Link></BreadcrumbItem>
			</Breadcrumb>
		);
	} else {
		return (
			<Breadcrumb>
				<BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
				<BreadcrumbItem><Link to="/SmallGroupAnnual">Recurring Storyboards</Link></BreadcrumbItem>
				<BreadcrumbItem><Link to="/SmallGroupAnnual">Small Group Semi-Annual</Link></BreadcrumbItem>
			</Breadcrumb>
		);
	}
}

const Dashboard = ({ dashboardContent, isSpecialTopic, pageTitle, pageLink }: { dashboardContent: JSX.Element, isSpecialTopic: boolean, pageTitle: string, pageLink: string }) => {
	const activeDashboardTab = useSelector((state: any) => state.root.activeDashboardTab);
	

	return (
		<Column>
			<Row style={{marginLeft: "30px"}}>{HeaderBreadcrumb(isSpecialTopic, pageTitle, pageLink)}</Row>
			<Row style={{marginLeft: "45px"}}>
				<Column lg={2} style={{padding: "0"}}>
					<Row><h3 style={{ marginTop: "20px" }}>{pageTitle === "Small Group Semi-Annual" ? (activeDashboardTab.name || pageTitle) : (pageTitle || "Demographics")}</h3></Row>
					<Row style = {{paddingRight: "20px", paddingTop: "32px"}}><DashboardFilters /></Row>
				</Column>

				<Column lg={8} style={{ padding: "17px 0px 0px 0px" }}>
					{dashboardContent}
				</Column>

				<Column lg={2} style={{padding: "0"}}>
					<Row style={{marginTop: "57px", marginLeft: "20px"}}>
						<div style={{cursor: "pointer", paddingLeft: "15px", padding: "5px", display: "flex", width: "200px", height: "40px", backgroundColor: "white"}}>
						<Column style={{padding: "0", display: "flex", alignItems: "center"}} lg={2}><DocumentExport20 /></Column>
						<Column style={{padding: "0", display: "flex", alignItems: "center"}} lg={6}>
							<div style={{cursor: "pointer", paddingLeft: "20px", padding: "5px", display: "flex", width: "200px", height: "40px", lineHeight: '30px'}}>
								<SpecialTopicsExportModal pageLink={pageLink} />
                            </div>
						</Column>
						<Column style={{padding: "0px 10px 0px 0px", display: "flex", alignItems: "center", justifyContent: "flex-end"}} lg={4}><ChevronDown20 /></Column>
						</div>
					</Row>
					<Row style={{marginTop: "20px", marginLeft: "20px"}}>
						<div style={{cursor: "pointer", paddingLeft: "15px", padding: "5px", display: "flex", width: "200px", height: "40px", backgroundColor: "white"}}>
						<Column style={{padding: "0", display: "flex", alignItems: "center"}} lg={2}><Time20 /></Column>
						<Column style={{padding: "0", display: "flex", alignItems: "center"}} lg={10}>Schedule Report</Column>
						</div>
					</Row>
					{isSpecialTopic ? null : <Row style={{ marginTop: "20px", marginLeft: "20px" }}>
						<div style={{cursor: "pointer", paddingLeft: "15px", padding: "5px", display: "flex", width: "200px", height: "40px", backgroundColor: "white"}}>
						<Column style={{padding: "0", display: "flex", alignItems: "center"}} lg={2}><ArrowDownRight20 /></Column>
						<Column style={{padding: "0", display: "flex", alignItems: "center"}} lg={10}>{activeDashboardTab.name==='Inpatient Admissions'?<DrillModal/>:'Dynamic Drill'}</Column>
						</div>
					</Row>}
				</Column>
			</Row>
		</Column>
  );
  }
  const dimensionList = ["Relationship","CCHG","DRG","Provider","HCC Detail","Plan","Age Band"];
const DrillModal = () => {
	return (
		<ModalStateManager
		  renderLauncher={({ setOpen }: any) => (
			<div onClick={() => setOpen(true)}>
			  Dynamic Drill
			</div>
		  )}>
		  {({ open, setOpen }: any) => (
				<Modal
					modalHeading="Dynamic Drill - Inpatient"
					// modalLabel="Account resources"
					primaryButtonText="Close"
					size="lg"
					onRequestSubmit={() => setOpen(false)}
					open={open}
					onRequestClose={() => setOpen(false)}
					id='inpatientDrillModal'
				>
					<Row style={{ flexDirection: 'column', alignContent: 'center' }}>
						<p style={{ marginBottom: '1rem' }}>
							Select a dimension to split data
						</p>
						<Dropdown
							initialSelectedItem={dimensionList[0]}
							id="inpatientDrillModalDropdown"
							titleText=""
							label=""
							items={dimensionList}
							onChange={() => { }}
							ariaLabel="THisss"
						/>

					</Row>
					<Row style={{ marginLeft: "0px", padding: "0px 28px 69px 10px" }}>
						<Column style={{ padding: "0" }}>
							<Row style={{ marginBottom: '10px', padding: '0px 17px' }}>
								<Column lg={6} style={{ padding: '0px' }}><p style={{ margin: '8px 0px', fontStyle: 'italic' }}>Showing data for 3 values</p></Column>
								<Column lg={6}>
									<Row style={{ flexDirection: 'row-reverse' }} >
										<div style={{ height: '40px', width: '147px', marginLeft: '10px', padding: '5px', backgroundColor: '#39414D', color: 'white', display: 'flex', alignItems: 'center' }}>
											<span style={{ margin: '0px 10px' }}><DocumentExport20 /> </span>
											<span >Export Data</span>
										</div>
									</Row>
								</Column>
							</Row>
							<DataTable rows={dynamicDrillRows} headers={dynamicDrillHeaders} isSortable>
								{({
									rows = dynamicDrillRows,
									headers = dynamicDrillHeaders,
									getHeaderProps,
									getRowProps,
									getTableProps,
									getTableContainerProps
								}: { rows: ChartTypes.ITableRow[], headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
									<TableContainer
										title=""
										description=""
										{...getTableContainerProps()}
									>
										<Table {...getTableProps()} isSortable>
											<TableHead>
												<TableRow>
													{headers.map((header) => (
														<TableHeader
															key={header.key}
															{...getHeaderProps({ header })}
															isSortable
														>
															{header.header}
														</TableHeader>
													))}
												</TableRow>
											</TableHead>
											<TableBody>
												{rows.map((row) => (
													<TableRow {...getRowProps({ row })}>
														{row.cells.map((cell) => (
															<TableCell style={{ backgroundColor: "#FFF" }} key={cell.id}>{cell.value}</TableCell>
														))}
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								)}
							</DataTable>
						</Column>
					</Row>
				</Modal>
		  )}
		</ModalStateManager>
	  );
}

export default Dashboard;
