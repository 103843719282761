import { AreaChartOptions, ScaleTypes } from "@carbon/charts/interfaces";

const covidByMonthData = [
	{
		"group": "COVID-19 Diagnosis per 1000",
		"month": "Jan",
		"value": 0
	},
	{
		"group": "COVID-19 Diagnosis per 1000",
		"month": "Feb",
		"value": 17
	},
	{
		"group": "COVID-19 Diagnosis per 1000",
		"month": "Mar",
		"value": 86
	},
	{
		"group": "COVID-19 Diagnosis per 1000",
		"month": "Apr",
		"value": 150
	},
	{
		"group": "COVID-19 Diagnosis per 1000",
		"month": "May",
		"value": 220
	},
	{
		"group": "COVID-19 Diagnosis per 1000",
		"month": "Jun",
		"value": 220
	},
	{
		"group": "COVID-19 Diagnosis per 1000",
		"month": "Jul",
		"value": 73
	},
	{
		"group": "COVID-19 Diagnosis per 1000",
		"month": "Aug",
		"value": 25
	},
	{
		"group": "COVID-19 Diagnosis per 1000",
		"month": "Sep",
		"value": 50
	},
	{
		"group": "COVID-19 Diagnosis per 1000",
		"month": "Oct",
		"value": 34
	},
	{
		"group": "COVID-19 Diagnosis per 1000",
		"month": "Nov",
		"value": 20
	},
	{
		"group": "COVID-19 Diagnosis per 1000",
		"month": "Dec",
		"value": 17
	},
	{
		"group": "Paid PMPM per 1000",
		"month": "Jan",
		"value": 11
	},
	{
		"group": "Paid PMPM per 1000",
		"month": "Feb",
		"value": 26
	},
	{
		"group": "Paid PMPM per 1000",
		"month": "Mar",
		"value": 107
	},
	{
		"group": "Paid PMPM per 1000",
		"month": "Apr",
		"value": 98
	},
	{
		"group": "Paid PMPM per 1000",
		"month": "May",
		"value": 219
	},
	{
		"group": "Paid PMPM per 1000",
		"month": "Jun",
		"value": 201
	},
	{
		"group": "Paid PMPM per 1000",
		"month": "Jul",
		"value": 100
	},
	{
		"group": "Paid PMPM per 1000",
		"month": "Aug",
		"value": 58
	},
	{
		"group": "Paid PMPM per 1000",
		"month": "Sep",
		"value": 68
	},
	{
		"group": "Paid PMPM per 1000",
		"month": "Oct",
		"value": 45
	},
	{
		"group": "Paid PMPM per 1000",
		"month": "Nov",
		"value": 25
	},
	{
		"group": "Paid PMPM per 1000",
		"month": "Dec",
		"value": 19
	},
	{
		"group": "COVID-19 Inpatient Admits per 1000",
		"month": "Jan",
		"value": 7
	},
	{
		"group": "COVID-19 Inpatient Admits per 1000",
		"month": "Feb",
		"value": 17
	},
	{
		"group": "COVID-19 Inpatient Admits per 1000",
		"month": "Mar",
		"value": 26
	},
	{
		"group": "COVID-19 Inpatient Admits per 1000",
		"month": "Apr",
		"value": 29
	},
	{
		"group": "COVID-19 Inpatient Admits per 1000",
		"month": "May",
		"value": 27
	},
	{
		"group": "COVID-19 Inpatient Admits per 1000",
		"month": "Jun",
		"value": 24
	},
	{
		"group": "COVID-19 Inpatient Admits per 1000",
		"month": "Jul",
		"value": 18
	},
	{
		"group": "COVID-19 Inpatient Admits per 1000",
		"month": "Aug",
		"value": 20
	},
	{
		"group": "COVID-19 Inpatient Admits per 1000",
		"month": "Sep",
		"value": 23
	},
	{
		"group": "COVID-19 Inpatient Admits per 1000",
		"month": "Oct",
		"value": 9
	},
	{
		"group": "COVID-19 Inpatient Admits per 1000",
		"month": "Nov",
		"value": 6
	},
	{
		"group": "COVID-19 Inpatient Admits per 1000",
		"month": "Dec",
		"value": 3
	}
];
const covidByMonthOptions: AreaChartOptions = {
	"title": "COVID-19 by Month",
	"axes": {
		"bottom": {
            "title": "Month",
			"mapsTo": "month",
			"scaleType": ScaleTypes.LABELS
		},
		"left": {
            "title": "Cost",
			"mapsTo": "value",
			"scaleType": ScaleTypes.LINEAR,
			domain: [0, 273],
			ticks: {
				formatter: (x: any) => "$" + x
			}
		}
	},
	"height": "400px",
	"color": {
		"scale": {
			"COVID-19 Diagnosis per 1000": "#FFA200",
			"Paid PMPM per 1000": "#50BEE1",
			"COVID-19 Inpatient Admits per 1000": "#0080E2"
		}
	},
	"legend": {
		"truncation": {
			"numCharacter": 50
		}
	}
};
const covidByMonthPDFOptions: AreaChartOptions = {
	"title": "COVID-19 by Month",
	"axes": {
		"bottom": {
            "title": "Month",
			"mapsTo": "month",
			"scaleType": ScaleTypes.LABELS
		},
		"left": {
            "title": "Cost",
			"mapsTo": "value",
			"scaleType": ScaleTypes.LINEAR,
			ticks: {
				formatter: (x: any) => "$" + x
			}
		}
	},
	"height": "250px",
	"color": {
		"scale": {
			"COVID-19 Diagnosis per 1000": "#FFA200",
			"Paid PMPM per 1000": "#50BEE1",
			"COVID-19 Inpatient Admits per 1000": "#0080E2"
		}
	},
	"legend": {
		"truncation": {
			"numCharacter": 50
		}
	}
};

export {covidByMonthData, covidByMonthOptions, covidByMonthPDFOptions}