import { Column, DataTable, OverflowMenu, Row, Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow } from "carbon-components-react"
import { EmergentCareReports, HighCostUtilizationDetailsHeader, 
	HighCostUtilizationDetailsRow } from '../../../data/HighCostClaimants/HighCostClaimantsDashboardData'
import { ChartTypes } from "../../Widget/DataDisplays/ChartTypes"
import { downTrendRedSvg, upTrendGreenSvg } from "../../Other/SVG"
import { DonutChart, GaugeChart, GroupedBarChart, SimpleBarChart, StackedBarChart, TreemapChart } from "@carbon/charts-react"
import { HighCostClaimantAvgCost_GroupedChart } from "../../../data/Chart1/chartData"
import StyledLink from "../../SideNavigation/StyledLink"
import { CostUtilizationSummaryHeader, CostUtilizationSummaryRow, CurrentEnrollmentStatus, DistributionByAgeBand, PriorCostStatus, ProspectiveRiskStrataforNextPlanYear1Header, ProspectiveRiskStrataforNextPlanYear1Rows, ProspectiveRiskStrataforNextPlanYear2Header, ProspectiveRiskStrataforNextPlanYear2Rows, RelationshipStatus } from "../../../data/HighCostClaimants/EnrollmentProfile"
import { PopulationImpact_StackedBarChart, NewMemberPrevalence_StackedBarChart, PriorHighCostStausGauge, ComparativeCost_BarChart } from "../../../data/HighCostClaimants/ExecutiveSummaryData"
import { AllowedPMPM_BarChart, MemberDistribution_BarChart, PrimaryCCHGAssignmentTreeMap, highCostClaimantDistributionMARARisk, topProspectiveCondition } from "../../../data/HighCostClaimants/ClinicalProfile"
import { useSelector } from "react-redux"
const commonChronic = require('../../../data/HighCostClaimants/CommonChronic.json');


const HighCostClaimantsReports = [
    'Executive Summary',
    'Cost and Utilization Summary',
    'Service Type Detail',
    'Enrollment Profile',
    'Clinical Profile',
    'Risk Profile'
]

export const HighCostClaimants = () => {
    const reportCreatedDate = useSelector((state: any) => state.root.reportCreatedDate);
	return (
        <Column>
			<Row style={{marginLeft: "0px"}}>
            <Column>
                <Row><h1 style={{color: "#39414D", marginTop: '18px'}}>Overview</h1></Row>
                <Row style={{ marginTop: "10px", width: "832px" }}><p>Managing high cost claimants is a key healthplan priority.  Stay on top of key cost and utilization behaviors of high cost claimants, identify clinical drivers, and anticipate future needs by evaluating  current experience.</p></Row>
                <Row style={{ marginTop: "20px", width: "900px" }}><p>{`Report Created: ${reportCreatedDate}`}</p></Row>
                <Row style={{ marginTop: "20px", width: "900px" }}>
                    <Column style={{ padding: '0px' }}>
                            <ul>
								{HighCostClaimantsReports.slice(0, 3).map((rep) => {
									return <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href={`#${rep.replace(/ /g, '')}`} style={{ textDecoration: 'none' }}> {rep}</a></li>
								})}
                            </ul>
                    </Column>
                    <Column style={{ padding: '0px' }}>
                            <ul>
								{HighCostClaimantsReports.slice(3, 6).map((rep) => {
									return <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}> <a href={`#${rep.replace(/ /g, '')}`} style={{ textDecoration: 'none' }}>{rep} </a></li>
								})}
                            </ul>
                    </Column>
                </Row>
            </Column>
        </Row>

			<Row style={{ marginLeft: "0px", marginTop: "70px" }}>
				<h2 id={EmergentCareReports[0].replace(/ /g, '')}>Executive Summary</h2>
			</Row>
			<Row style={{ paddingTop: '20px', paddingBottom: '1rem' }}>
				<Column>
                    <div style={{ backgroundColor: "white", padding: "20px" }}>

						<StackedBarChart data={PopulationImpact_StackedBarChart.data} options={PopulationImpact_StackedBarChart.options} />
					</div>
			    </Column>
				<Column>
                    <div style={{ backgroundColor: "white", padding: "20px" }}>
						<StackedBarChart data={NewMemberPrevalence_StackedBarChart.data} options={NewMemberPrevalence_StackedBarChart.options} />
					</div>
				</Column>
                <Column>
					<div style={{ backgroundColor: "white", padding: "20px", height: "100%" }}>
						<GaugeChart data={PriorHighCostStausGauge.data} options={PriorHighCostStausGauge.options} />
						<Row style={{ padding: '8% 11%' }}>
							<p style={{ fontSize: '14px', lineHeight: '18px' }}> <span style={{ fontWeight: 'bold' }}>30% </span>of high cost members were non-high cost members in the previous year.</p>
						</Row>
						<Row>
							<div style={{display: 'flex', padding: '2px 16px' }}><div style={{ height: "12px", width: '12px', backgroundColor: '#004877', marginRight: "5px" }}></div><div style={{fontSize: '12px', fill: '#565656', fontFamily: 'IBM Plex Sans Condensed, Helvetica Neue, Arial, sans-serif'}}>Prior year Non-High Cost members</div></div>
						</Row>
					</div>
				</Column>
                <Column>
					<div style={{ backgroundColor: "white", padding: "20px", height: "100%" }}>
						<SimpleBarChart data={ComparativeCost_BarChart.data} options={ComparativeCost_BarChart.options} />
						<Row style={{ padding: '8% 11%' }}>
							<p style={{ fontSize: '14px', lineHeight: '18px' }}>High cost members have a PMPM <span style={{ fontWeight: 'bold' }}>48.8% </span> higher than the group average.</p>
						</Row>
						<Row>
							<div style={{ display: 'flex', padding: '2px 16px' }}><div style={{ height: "12px", width: '12px', backgroundColor: '#C6C9CA', marginRight: "5px" }}></div><div style={{fontSize: '12px', fill: '#565656', fontFamily: 'IBM Plex Sans Condensed, Helvetica Neue, Arial, sans-serif'}}>Average PMPM</div></div>
							<div style={{ display: 'flex', padding: '2px 16px' }}><div style={{ height: "12px", width: '12px', backgroundColor: '#004877', marginRight: "5px" }}></div><div style={{fontSize: '12px', fill: '#565656', fontFamily: 'IBM Plex Sans Condensed, Helvetica Neue, Arial, sans-serif'}}>High Cost Members</div></div>
						</Row>
					</div>
				</Column>
			</Row>
			<Row style={{  marginLeft: "0px", marginTop: "70px" }}>
				<h2 id={EmergentCareReports[1].replace(/ /g, '')}>Cost & Utilization Summary</h2>
			</Row>
	
			<Row style={{marginTop: "30px"}}>
				<Column>
					<DataTable rows={CostUtilizationSummaryRow} headers={CostUtilizationSummaryHeader} isSortable>
						{({
							rows = CostUtilizationSummaryRow,
							headers = CostUtilizationSummaryHeader,
							getHeaderProps,
							getRowProps,
							getTableProps,
							getTableContainerProps
						}: { rows: any, headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
							<TableContainer
								title="Key Indicators Detail"
								{...getTableContainerProps()}
							>
								<Table {...getTableProps()} isSortable>
									<TableHead>
										<TableRow>
											{headers.map((header) => (
												<TableHeader
													key={header.key}
													{...getHeaderProps({ header })}
													isSortable
												>
													{header.header}
												</TableHeader>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.map((row: any) => (
											<TableRow {...getRowProps({ row })}>
												{row.cells.map((cell: any) => {
													return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>{cell.value}</TableCell>)
												})}
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</DataTable>
				</Column>
			</Row>

			<Row style={{marginLeft: "0px", marginTop: "70px"}}>
				<Column style={{padding: "0"}}>
					<h2>Service Type Detail</h2>
					<p style={{marginTop: "20px"}}>Breakdown of cost and utilization by service type.</p>
				</Column>
			</Row>
            <Row style={{marginLeft: "0px", marginTop: "40px"}}>
                <Column style={{padding: "0px 10px 0px 0px"}}>
                    <div style={{backgroundColor: "white", padding: "20px"}}>
						<GroupedBarChart
							data={HighCostClaimantAvgCost_GroupedChart.data}
							options={HighCostClaimantAvgCost_GroupedChart.options}
                        />
                    </div>
                </Column>
                <Column style={{padding: "0px 10px 0px 0px"}}>
				<DataTable rows={HighCostUtilizationDetailsRow} headers={HighCostUtilizationDetailsHeader} isSortable>
						{({
							rows = HighCostUtilizationDetailsRow,
							headers = HighCostUtilizationDetailsHeader,
							getHeaderProps,
							getRowProps,
							getTableProps,
							getTableContainerProps
						}: { rows: any, headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
							<TableContainer
								title="Utilization Details"
								description=""
								{...getTableContainerProps()}
							>
								<Table {...getTableProps()} isSortable>
									<TableHead>
										<TableRow>
											{headers.map((header) => (
												<TableHeader
													key={header.key}
													{...getHeaderProps({ header })}
													isSortable
												>
													{header.header}
												</TableHeader>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.slice(0,7).map((row: any) => (
											<TableRow {...getRowProps({ row })}>
												{row.cells.map((cell: any) => {
													if (cell.value[0] === "+") {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
															{upTrendGreenSvg()}{cell.value.substring(1)}</TableCell>)
													} else if (cell.value[0] === "-") {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
															{downTrendRedSvg()}{cell.value.substring(1)}</TableCell>)
													} else {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>{cell.value}</TableCell>)
													}
												})}
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</DataTable>
                </Column>
            </Row>

			<Row style={{marginLeft: "0px", marginTop: "70px"}}>
				<Column style={{padding: "0"}}>
					<h2>Enrollment Profile</h2>
					<p style={{marginTop: "20px"}}>This member profile summarizes the enrollment characteristics and history of high cost claimants.</p>
				</Column>
			</Row>

			<Row style={{marginLeft: "0px", marginTop: "30px"}}>
				<Column style={{ backgroundColor: "#FFF", display: "flex", alignItems: "center" }}>
					<div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
						<DonutChart options={CurrentEnrollmentStatus.options} data={CurrentEnrollmentStatus.data} />
						<OverflowMenu/>
					</div>
				</Column>
				<Column style={{ backgroundColor: "#FFF", marginLeft: "20px", display: "flex", alignItems: "center" }}>
					<div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
						<DonutChart options={PriorCostStatus.options} data={PriorCostStatus.data} />
						<OverflowMenu/>
					</div>
				</Column>
				<Column style={{ backgroundColor: "#FFF", marginLeft: "20px", display: "flex", alignItems: "center" }}>
					<div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
						<DonutChart options={RelationshipStatus.options} data={RelationshipStatus.data} />
						<OverflowMenu/>
					</div>
				</Column>
            </Row>
			<Row style={{marginLeft: "0px", marginTop: "30px"}}>
				<Column style={{ backgroundColor: "#FFF" }}>
					<div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "100%", display: "flex"}}>
						<GroupedBarChart options={DistributionByAgeBand.options} data={DistributionByAgeBand.data} />
						<OverflowMenu/>
					</div>
				</Column>
			</Row>

			<Row style={{marginLeft: "0px", marginTop: "70px"}}>
				<Column style={{padding: "0"}}>
					<h2>Clinical Profile</h2>
					<p style={{marginTop: "20px"}}>CCHGs provide the clinically important drivers of high costs, both by the primary condition for each members and the number of comorbidities. All members are assigned to at least one CCHG, and can have up to an additional five CCHG comorbidities.</p>
				</Column>
			</Row>

			<Row style={{ marginLeft: "0px", marginTop: "40px"}}>
				<Column style={{ backgroundColor: "#FFF", justifyContent: "center" }}>
					<div id="Export-InpatientImpact" style={{ backgroundColor: "white", padding: "20px", height: "100%", display: "flex" }}>
						<TreemapChart options={PrimaryCCHGAssignmentTreeMap.options} data={PrimaryCCHGAssignmentTreeMap.data} />
						<OverflowMenu />
					</div>
				</Column>
			</Row>
			<Row style={{ paddingTop: '30px', marginLeft: '0px'}}>
            	<Column lg={6} style={{padding: "0px 10px 0px 0px"}}>
					<div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "100%", display:"flex"}}>
						<SimpleBarChart options={commonChronic.options} data={commonChronic.data}/>
						<OverflowMenu/>
					</div>
				</Column>
				<Column lg={3} style={{padding: "0px 10px 0px 0px"}}>
					<div style={{ backgroundColor: "white", padding: "20px", height: "100%", display: "flex" }}>
						<div>
							<h5>Member Distribution</h5>
							<p style={{ color: "#616771", paddingBottom: '15px' }}>By Count of CCHG</p>
							<SimpleBarChart
								data={MemberDistribution_BarChart.data}
								options={MemberDistribution_BarChart.options}
							/>
						</div>
						<OverflowMenu />
					</div>
                </Column>
				<Column lg={3} style={{padding: "0"}}>
					<div style={{ backgroundColor: "white", padding: "20px", height: "100%", display: "flex" }}>
						<div>
							<h5>Allowed PMPM</h5>
							<p style={{ color: "#616771", paddingBottom: '15px' }}>By Count of CCHG</p>
							<SimpleBarChart
								data={AllowedPMPM_BarChart.data}
								options={AllowedPMPM_BarChart.options}
							/>
						</div>
						<OverflowMenu />
					</div>
                </Column>
			</Row>

			<Row style={{marginLeft: "0px", marginTop: "70px"}}>
				<Column style={{padding: "0"}}>
					<h2>Risk Profile</h2>
					<p style={{marginTop: "20px"}}>MARA prospective models represent the expected risk in the next plan year.</p>
					<ul>
						<li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
							<span style={{ color: 'gray' }}>Prospective conditions are expressed as the expected contribution to overall cost. </span>
						</li>
						<li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
							<span style={{ color: 'gray' }}>Component risk scores provide the expected allocation of cost by setting of service.</span>
						</li>
					</ul>
				</Column>
			</Row>

			<Row style={{ paddingTop: '30px', marginLeft: '0px'}}>
            	<Column style={{padding: "0px 10px 0px 0px"}}>
					<div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "100%", display:"flex"}}>
						<div style={{ backgroundColor: "white", paddingBottom: '10px', width: '100%' }}>
							<h5>Distribution of MARA Risk</h5>
							<p style={{ color: "#616771", paddingBottom: '15px' }}>Prospective Risk by Service Setting</p>
							<SimpleBarChart options={highCostClaimantDistributionMARARisk.options} data={highCostClaimantDistributionMARARisk.data}/>
						</div>
						<OverflowMenu/>
					</div>
				</Column>
				<Column style={{padding: "0"}}>
					<div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "100%", display:"flex"}}>
						<div style={{ backgroundColor: "white", paddingBottom: '10px', width: '100%' }}>
							<h5>Top Prospective Conditions</h5>
							<p style={{ color: "#616771", paddingBottom: '15px' }}>By Percent Contribution to Risk</p>
							<SimpleBarChart options={topProspectiveCondition.options} data={topProspectiveCondition.data}/>
						</div>
						<OverflowMenu/>
					</div>
				</Column>
			</Row>

			<Row style={{marginLeft: "0", paddingBottom: "10px", marginTop: "30px", backgroundColor: "white"}}>
				<Column style={{padding: "0"}}>
					<div style={{ padding: '18px 20px' }}><h5>Prospective Risk Strata for Next Plan Year</h5><p style={{ fontSize: "15px" }}>By Current High-cost Status</p></div>
				<DataTable rows={ProspectiveRiskStrataforNextPlanYear1Rows} headers={ProspectiveRiskStrataforNextPlanYear1Header} isSortable>
						{({
							rows = ProspectiveRiskStrataforNextPlanYear1Rows,
							headers = ProspectiveRiskStrataforNextPlanYear1Header,
							getHeaderProps,
							getRowProps,
							getTableProps,
							getTableContainerProps
						}: { rows: any, headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
							<TableContainer
								title={""}
								{...getTableContainerProps()}
							>
								<Table {...getTableProps()} isSortable>
									<TableHead>
										<TableRow>
											{headers.map((header) => (
												<TableHeader
													key={header.key}
													{...getHeaderProps({ header })}
													isSortable
												>
													{header.header}
												</TableHeader>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.map((row: any) => (
											<TableRow {...getRowProps({ row })}>
												{row.cells.map((cell: any) => {
													return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>{cell.value}</TableCell>)
												})}
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</DataTable>
					<DataTable rows={ProspectiveRiskStrataforNextPlanYear2Rows} headers={ProspectiveRiskStrataforNextPlanYear2Header} isSortable>
						{({
							rows = ProspectiveRiskStrataforNextPlanYear2Rows,
							headers = ProspectiveRiskStrataforNextPlanYear2Header,
							getHeaderProps,
							getRowProps,
							getTableProps,
							getTableContainerProps
						}: { rows: any, headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
							<TableContainer
								title=""
								{...getTableContainerProps()}
							>
								<Table {...getTableProps()} isSortable>
									<TableHead>
										<TableRow>
											{headers.map((header) => (
												<TableHeader
													key={header.key}
													{...getHeaderProps({ header })}
													isSortable
													style={{paddingRight: '40px'}}
												>
													{header.header}
												</TableHeader>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.map((row: any) => (
											<TableRow {...getRowProps({ row })}>
												{row.cells.map((cell: any) => {
													return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>{cell.value}</TableCell>)
												})}
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</DataTable>
					<Row style={{marginLeft: "0", padding: "15px"}}>Prospective risk strata is the expected risk level in the next plan year. The "Very High Risk" level represents the set of members who are most likely to meet the high-cost threshold. This chart shows the expected movement between current high-cost status and next year's group.</Row>
				</Column>
			</Row>
			<Row style={{ marginLeft: "0px", padding: '3rem 0rem 1rem' }}>
				<h1>Explore Related Topics</h1>
			</Row>
			<Row id="tableLink" style={{ marginLeft: '0', marginBottom: '60px' }}>
				<Column lg={5} style={{ padding: '0px' }}>
					<ul>
						<li  className='relatedList' style={{ padding: '6px 0px',  color: '#0080E2' }}>
							<StyledLink nowrap to="#" onClick={() => window.scrollTo(0, 0)} >High Cost Claimant Member Detail <span style={{ color: 'gray' }}>(Special Topic)</span> </StyledLink>
						</li>
						<li className='relatedList' style={{ padding: '6px 0px', color: '#0080E2' }}>
						<StyledLink to="#">Cardiac <span style={{ color: 'gray' }}>(Special Topic)</span> </StyledLink>
						
						</li>
						<li className='relatedList' style={{ padding: '6px 0px', color: '#0080E2' }}>
						
							<StyledLink to="#">Neoplasms <span style={{ color: 'gray' }}>(Special Topic)</span> </StyledLink>
						</li>
					</ul>
				</Column>
				<Column lg={3} style={{ padding: '0px' }}>
					<ul>
						<li className='relatedList' style={{ padding: '6px 0px',  color: '#0080E2' }}>
						<StyledLink to="#">Diabetes <span style={{padding: '6px 0px', color: 'gray' }}>(Special Topic)</span>  </StyledLink>
							
						</li>
						<li className='relatedList' style={{ padding: '6px 0px', color: '#0080E2' }}>
							<StyledLink to="#">Maternity &amp; Neonatal  <span style={{ color: 'gray' }}>(Special Topic)</span>  </StyledLink>
						</li>
						<li className='relatedList' style={{ padding: '6px 0px', color: '#0080E2' }}>
							<StyledLink to="#">Back Pain <span style={{ color: 'gray' }}>(Special Topic)</span></StyledLink>
						</li>
					</ul>
				</Column>
				<Column style={{ padding: '0px' }}>
					<ul>
						<li className='relatedList' style={{ padding: '6px 0px', color: '#0080E2' }}>
						
							<StyledLink to="#">Members with Chronic Conditions <span style={{ color: 'gray' }}>(Special Topic)</span> </StyledLink>
						</li>
						
					</ul>
				</Column>
				
			</Row>

		</Column>
    )
}