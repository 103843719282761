import { Column, Modal, TextInput } from "carbon-components-react";
import { connect } from "react-redux";
import { actions } from "../../redux/Dashboard/actions";

const LoginPage = (props: any) => {
    return (
      <Modal
        open
        modalHeading="Log In"
        primaryButtonText="Log in"
        onRequestSubmit={() => { props.login() }}
      >
        <div style={{height: "200px"}}>
        <Column md={7}>

      <TextInput
        data-modal-primary-focus
        id="text-input-1"
        labelText="username"
        placeholder="username@medinsight.com"
        
      />
      <TextInput.PasswordInput
        data-modal-primary-focus
        id="text-input-1"
        labelText="password"
        placeholder=""
      />
      </Column>
      </div>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch: any) => {
    return {
      login: () => {
        dispatch(actions.login());
      }
    };
  }
  
  const mapStateToProps = (state: any) => {
    return {
      loginSuccessful: state.root.loginSuccessful
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);