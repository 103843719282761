import { ScaleTypes, LegendPositions } from '@carbon/charts/interfaces/enums';

const loading = false; // setting loading true shows skeleton only

export const MemberDistribution_BarChart = {
    data: [
        {
            "group": "1",
            "value": 21
        },
        {
            "group": "2-3",
            "value": 28
        },
        {
            "group": "4-5",
            "value": 26
        },
        {
            "group": "6",
            "value": 22
        }
    ],
    options: {
        // "title": "Member Distribution",
        "axes": {
            "left": {
                "mapsTo": "value",
                "ticks": {
                    "values": [5, 10, 15, 20, 25, 30, 35, 40]
                }
            },
            "bottom": {
                "mapsTo": "group",
                "scaleType": ScaleTypes.LABELS,
            }
        },
        "color": {
            "scale": {
                "1": "#0080E2",
                "2-3": "#0080E2",
                "4-5": "#0080E2",
                "6": "#0080E2"
            }
        },

        "height": "250px",
        "data": {
            "loading": loading
        },
        "bars": {
            "maxWidth": 50
        },
        "legend": {
            "enabled": false
        }
    }
}
export const AllowedPMPM_BarChart = {
    data: [
        {
            "group": "1",
            "value": 9000
        },
        {
            "group": "2-3",
            "value": 17000
        },
        {
            "group": "4-5",
            "value": 22000
        },
        {
            "group": "6",
            "value": 27000
        }
    ],
    options: {
        // "title": "Allowed PMPM",
        "axes": {
            "left": {
                "mapsTo": "value",
                "ticks": {
                    "values": [5000, 10000, 15000, 20000, 25000, 30000]
                }
            },
            "bottom": {
                "mapsTo": "group",
                "scaleType": ScaleTypes.LABELS,
            }
        },
        "color": {
            "scale": {
                "1": "#0080E2",
                "2-3": "#0080E2",
                "4-5": "#0080E2",
                "6": "#0080E2"
            }
        },

        "height": "250px",
        "data": {
            "loading": loading
        },
        "bars": {
            "maxWidth": 50
        },
        "legend": {
            "enabled": false
        }
    }
}

export const PrimaryCCHGAssignmentTreeMap = {
    "data": [
        {
            "name": "Cancer: 38%",
            "children": [
                {
                    "name": "Cancer",
                    "value": 38,
                    "showLabel": true
                }
            ]
        },
        {
            "name": "All other conditions: 21%",
            "children": [
                {
                    "name": "All other conditions",
                    "value": 21,
                    "showLabel": true
                }
            ]
        },
        {
            "name": "Renal failure: 9%",
            "children": [
                {
                    "name": "Renal failure",
                    "value": 9,
                    "showLabel": true
                }
            ]
        },
        {
            "name": "Hypertension including stroke & peripheral vascular disease: 9%",
            "children": [
                {
                    "name": "Hypertension including stroke & peripheral vascular disease",
                    "value": 9,
                    "showLabel": true
                }
            ]
        },
        {
            "name": "Severe heart failure, transplant, rheumatic heart disease, non-rheumatic vascular heart disease: 7%",
            "children": [
                {
                    "name": "Severe heart failure, transplant, rheumatic heart disease, non-rheumatic vascular heart disease",
                    "value": 7,
                    "showLabel": true
                }
            ]
        },
        {
            "name": "Diabetes without CAD: 4%",
            "children": [
                {
                    "name": "Diabetes without CAD",
                    "value": 4,
                    "showLabel": true
                }
            ]
        },
        {
            "name": "Neurologic disorders: 3%",
            "children": [
                {
                    "name": "Neurologic disorders",
                    "value": 3,
                    "showLabel": true
                }
            ]
        },
        {
            "name": "Healthy female: 3%",
            "children": [
                {
                    "name": "Healthy female",
                    "value": 3,
                    "showLabel": true
                }
            ]
        },
        {
            "name": "Healthy male: 3%",
            "children": [
                {
                    "name": "Healthy male",
                    "value": 3,
                    "showLabel": true
                }
            ]
        },
        {
            "name": "Severe rheumatic & other connected tissue disease: 3%",
            "children": [
                {
                    "name": "Severe rheumatic & other connected tissue disease",
                    "value": 3,
                    "showLabel": true
                }
            ]
        }
    ],
    "options": {
        "title": "Primary CCHG Assignment",
        "height": "293px",
        "color": {
            "scale": {
                "Cancer: 38%": "#0066B5",
                "All other conditions: 21%": "#B37100",
                "Renal failure: 9%": "#FF6400",
                "Hypertension including stroke & peripheral vascular disease: 9%": "#B2B5B6",
                "Severe heart failure, transplant, rheumatic heart disease, non-rheumatic vascular heart disease: 7%": "#FFC800",
                "Diabetes without CAD: 4%": "#50BEE1",
                "Neurologic disorders: 3%": "#68AC56",
                "Healthy female: 3%": "#004877",
                "Healthy male: 3%": "#993C00",
                "Severe rheumatic & other connected tissue disease: 3%": "#6C645F"
            }
        },
        "legend": {
            "position": LegendPositions.RIGHT,
            "truncation": {
                "type": "mid_line",
                "threshold": 1,
                "numCharacter": 105
            }
        }
    }
}

export const highCostClaimantDistributionMARARisk = {
    "data": [
      {
          "group": "Physician & Other",
          "value": 22
      },
      {
          "group": "Emergency Room",
          "value": 3
      },
      {
          "group": "Pharmacy",
          "value": 12
      },
      {
          "group": "Outpatient",
          "value": 33
      },
      {
          "group": "Inpatient",
          "value": 27
      }
  ],
  "options": {
    "axes": {
      "bottom": {
        "mapsTo": "value",
        "values": [ 0, 10, 20, 30, 40, 50, 60 ],
        "formatter": ((value: any)=>{ return value+'%' })
      },
      "left": {
        "mapsTo": "group",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "height": "300px",
    "color": {
      "scale": {
        "Physician & Other": "#0080E2",
        "Emergency Room": "#0080E2",
        "Pharmacy": "#0080E2",
        "Outpatient": "#0080E2",
        "Inpatient": "#0080E2"
      }
    },
    "legend": {
      "enabled": false
    },
    "bars": {
        "width": 24
    }
  }
}

export const topProspectiveCondition = {
    "data": [
        {
            "group": "10. All Other",
            "value": 28
        },
        {
            "group": "9. Digestive System/Gastrointestinal",
            "value": 4
        },
        {
            "group": "8. Nervous System & Sense Organs/Nervous",
            "value": 5
        },
        {
            "group": "7. Respiratory System",
            "value": 6
        },
        {
            "group": "6. Blood & Blood Forming Organs",
            "value": 7
        },
        {
            "group": "5. Genitourinary System",
            "value": 8
        },
        {
            "group": "4. Symptoms, Signs, & ill defined Conditions",
            "value": 9
        },
        {
            "group": "3. Circulatory System",
            "value": 10
        },
        {
            "group": "2. Endocrine System",
            "value": 12
        },
        {
            "group": "1. Neoplasms / Maglignan",
            "value": 18
        }
    ],
    "options": {
      "axes": {
        "bottom": {
          "mapsTo": "value",
          "values": [ 0, 5, 10, 15, 20, 25, 30 ],
          "formatter": ((value: any)=>{ return value+'%' })
        },
        "left": {
          "mapsTo": "group",
          "scaleType": ScaleTypes.LABELS
        }
      },
      "height": "300px",
      "color": {
        "scale": {
          "10. All Other": "#0080E2",
          "9. Digestive System/Gastrointestinal": "#0080E2",
          "8. Nervous System & Sense Organs/Nervous": "#0080E2",
          "7. Respiratory System": "#0080E2",
          "6. Blood & Blood Forming Organs": "#0080E2",
          "5. Genitourinary System": "#0080E2",
          "4. Symptoms, Signs, & ill defined Conditions": "#0080E2",
          "3. Circulatory System": "#0080E2",
          "2. Endocrine System": "#0080E2",
          "1. Neoplasms / Maglignan": "#0080E2"
        }
      },
      "legend": {
        "enabled": false
      }
    }
}
  