import imgText from '../../../images/covidMap.png';
import { AjaxAction } from '../../../ajax/AjaxActions';
import { PDFContentPage, PDFTitlePage, createTOC } from '../../Exporting/PDFComponents';
import React, { FunctionComponent, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Row, Grid, UnorderedList, ListItem, Column, DataTable, TableHead, TableRow, TableBody, TableCell, TableContainer, Table, TableHeader, DataTableHeader, DataTableCell, DenormalizedRow, OverflowMenuItem, OverflowMenu, AspectRatio } from 'carbon-components-react';
import { AreaChart, DonutChart, GaugeChart, ComboChart, GroupedBarChart } from '@carbon/charts-react';
import { ChartHolder } from '../../Other/CommonUtil';
import { comorbidCostData, comorbidCostOptions, comorbidCostPDFOptions } from '../../../data/Covid19/ComorbidCost';
import { covidByMonthData, covidByMonthOptions, covidByMonthPDFOptions } from '../../../data/Covid19/Covid19ByMonth';
import OverviewBox from '../../Other/OverviewBox';
const admitsPer1000 = require("../../../data/Covid19/AdmitsPer1000.json");
const clinicalProfile = require("../../../data/Covid19/ClinicalProfile.json");
const covidAdmits = require("../../../data/Covid19/Covid19Admits.json");
const covidAdmissions = require("../../../data/Covid19/Covid19Admissions.json")
const covidDataTable = require("../../../data/Covid19/Covid19DataTable.json");
const covidProfCompTable = require("../../../data/Covid19/COV19ProfileCompTable.json");
const covidMemOverTimeTable = require("../../../data/Covid19/COV19MembersOverTimeTable.json");
const covidUtilRates = require("../../../data/Covid19/UtilizationRates.json");
const behaveNetPaid = require("../../../data/Covid19/BehavioralNetPaid.json");
const teleHealth = require("../../../data/Covid19/TelehealthVOffice.json");

type SumProps = {
    id: string,
    title: string,
    topVal: string,
    topDescript: string,
    midVal: string,
    midDescript: string,
    botVal: string,
    botDescript: string,
    isInPdf: boolean
}

const SummaryBox = ({ id, title, topVal, topDescript, midVal, midDescript, botVal, botDescript, isInPdf }: SumProps) => {
    if (isInPdf) {
        return <Column className="HeadColumnContainer1" id={id}>
            <Row><h5>{title}</h5></Row>
            <Row className="Row1" ><p>{topVal}</p></Row>
            <Row className="Row2" ><p>{topDescript}</p></Row>
            <Row className="Row3" ><div></div></Row>
            <Row className="Row4" ><p>{midVal}</p></Row>
            <Row className="Row5" ><p>{midDescript}</p></Row>
            <Row className="Row6" ><div></div></Row>
            <Row className="Row4" ><p>{botVal}</p></Row>
            <Row className="Row5" ><p>{botDescript}</p></Row>
        </Column>
    }
    else {
        return <Column className="HeadColumnContainer2" id={id} >
            <Row ><h5>{title}</h5></Row>
            <Row className="Row21" ><p>{topVal}</p></Row>
            <Row className="Row22" ><p>{topDescript}</p></Row>
            <Row className="Row23" ><div></div></Row>
            <Row className="Row24" ><p>{midVal}</p></Row>
            <Row className="Row25" ><p>{midDescript}</p></Row>
            <Row className="Row23" ><div></div></Row>
            <Row className="Row24" ><p>{botVal}</p></Row>
            <Row className="Row25" ><p>{botDescript}</p></Row>
        </Column>
    }
}

const summaryBoxes = (isInPdf: boolean) => {
    const summary = (<><SummaryBox
        id="Export-CovidAdmitAge"
        title="COVID Admit Age"
        topVal="49.7"
        topDescript="Average age of Member with COVID and Inpatient Admit"
        midVal="51.6%"
        midDescript="Norm"
        botVal="-11%"
        botDescript="Variance"
        isInPdf
    />
        <SummaryBox
            id="Export-VentilatorUse"
            title="Ventilator Use"
            topVal="1.5%"
            topDescript="of COVID Admissions with a Ventilator"
            midVal="2.3%"
            midDescript="Norm"
            botVal="-35%"
            botDescript="Variance"
            isInPdf
        />
        <SummaryBox
            id="Export-ICUAdmissions"
            title="ICU Admissions"
            topVal="8.5%"
            topDescript="of COVID Admissions with ICU Admissions"
            midVal="9.5%"
            midDescript="Norm"
            botVal="-11%"
            botDescript="Variance"
            isInPdf
        /></>);
    return (
        isInPdf ? <Row id="TOC:Patient Impact" style={{ display: "flex", justifyContent: "space-evenly" }}>{summary}</Row> : <Row style={{ marginTop: "30px", display: "flex", justifyContent: "flex-start" }}>{summary}</Row>
    );
}

const Covid19Dashboard = () => {
    return <Column className="Covid19DashboardColumnContainer">
        <Row className="OverviewBoxContainer">
            <OverviewBox
                maxPerSection={3}
                sections={["Executive Summary", "Key Indicators", "Inpatient Impact", "Clinical Profile", "Connected Utilization"]}
                specialTopic={true}
            />
        </Row>
        <Row id="Export-CovidMap" className="CovidMapContainer">
            <Column className="CovidMapColumnContainer" >
                <Row className="Covid19MapHeadingContainer">
                    <p>COVID-19 Map</p>
                </Row>
                <Row className="Covid19MapContainer">
                    {/* Need to decide the map module */}
                    <div>
                        <img src={imgText} alt={"Carlie Anglemire"} />
                    </div>
                </Row>
            </Column>
        </Row>
        <Row className="ConfirmedCovidCasesContainer">
            <Column className="Covid19CasesContainer" id="Export-ConfirmedCovidCases">
                {/* need to make this summary box type of thing a component */}
                <Row className="Covid19CaseRow1"><p>Confirmed COVID-19 Cases</p></Row>
                <Row className="Covid19CaseRow2"><p>34</p></Row>
                <Row className="Covid19CaseRow3"><p>Confirmed Cases per 1000</p></Row>
                <Row className="Covid19CaseRow4"><div></div></Row>
                <Row className="Covid19CaseRow5"><p>22.4</p></Row>
                <Row className="Covid19CaseRow6"><p>Norm</p></Row>
            </Column>
            <Column className="DiagnosedInpatientContainer" >
                <div id="Export-InpatientImpact" style={{ backgroundColor: "white", padding: "20px 10px 20px 10px", height: "100%" }}>
                    <GaugeChart options={admitsPer1000.options} data={admitsPer1000.data} />
                </div>
            </Column>
            <Column className="PopulationCovidRiskContainer">
                <div id="Export-ClinicalProfile" style={{ backgroundColor: "white", padding: "20px 10px 20px 10px", height: "100%" }}>
                    <GaugeChart options={clinicalProfile.options} data={clinicalProfile.data} />
                </div>
            </Column>
            <Column className="ConnectedUtillContainer" id="Export-ConnectedUtil">
                <Row className="ConnectedUtillRow1" ><h5>Connected Utilization</h5></Row>
                <Row className="ConnectedUtillRow2" ><p>245</p></Row>
                <Row className="ConnectedUtillRow3" ><p>Telehealth Visits per 1000</p></Row>
                <Row className="ConnectedUtillRow4" ><div></div></Row>
                <Row className="ConnectedUtillRow5" ><p>232</p></Row>
                <Row className="ConnectedUtillRow6" ><p>Norm</p></Row>
            </Column>
        </Row>
        <Row className="Covid19ExecutiveContainer">
            <Column id="Export-DataDescription1">
                <Row className="Covid19ExecutiveHeading"><p>COVID-19 Executive Summary</p></Row>
                <Row><p>The images above summarize the detail provided in this Hot Topic.  The key metrics that are pivotal to understand the impact of COVID-19 are geography, diagnostic case rates, inpatient impact, existing and potential clinical risk, and impact on connected service types.</p></Row>
            </Column>
        </Row>
        <Row className="KeyIndicatorsHeading"><h3>Key Indicators</h3></Row>
        <Row className="KeyIndicatorContainer">
            <Column className="KeyIndicatorColumn1"  lg={9}>
                <div id="Export-CovidByMonth" style={{ backgroundColor: "white", padding: "20px", display: "flex" }}>
                    <AreaChart data={covidByMonthData} options={covidByMonthOptions} />
                    <OverflowMenu>
                        <OverflowMenuItem itemText="Export PDF" onClick={() => new AjaxAction().downloadPDF("/Covid19")} />
                    </OverflowMenu>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", display: "flex", alignItems: "center", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.10)" }}>
                <div id="Export-ComorbidCost" style={{ backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex" }}>
                    <DonutChart options={comorbidCostOptions} data={comorbidCostData} />
                </div>
            </Column>
        </Row>
        <Row style={{ marginTop: "30px", marginLeft: "0px" }}>
            <Column>
                <Row><h2>Inpatient Impact</h2></Row>
                <Row style={{ marginTop: "30px" }}>
                    <Column style={{ padding: "0px 0px 0px 0px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.10)" }} lg={10}>
                        <div id="Export-CovidAdmits" style={{ backgroundColor: "white", padding: "20px", height: "100%" }}>
                            <ComboChart data={covidAdmits.data} options={covidAdmits.options} />
                        </div>
                    </Column>
                </Row>
                {summaryBoxes(false)}
            </Column>
        </Row>
        <Row style={{ marginTop: "30px", marginLeft: "0px" }}>
            <Column style={{ padding: "0px" }}>
                <Row><h2 style={{ marginLeft: "1rem" }}>Clinical Profile</h2></Row>
                <Row>
                    <Column>
                        <div style={{ marginTop: "20px", display: "flex", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.10)" }}>
                            <DataTable rows={covidDataTable.rows} headers={covidDataTable.headers}>
                                {({
                                    rows = covidDataTable.rows,
                                    headers = covidDataTable.headers,
                                    getHeaderProps,
                                    getRowProps,
                                    getTableProps,
                                    getTableContainerProps,
                                }: { rows: DenormalizedRow[], headers: DataTableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
                                    <TableContainer
                                        title={
                                            <div style={{}}>
                                                <div style={{ display: "flex", justifyContent: "space-between", height: "1.5rem" }}>
                                                    <p style={{ fontSize: "16px", fontWeight: 600 }}>2020 COVID Diagnosis without and with Inpatient Admit</p>
                                                    <OverflowMenu>
                                                    </OverflowMenu>
                                                </div>
                                                <div style={{ display: "flex", transform: "translateY(.75rem)" }}>
                                                    <p style={{ marginLeft: "460px", fontSize: "14px", fontWeight: 600, color: "#616771" }}>2020 COVID Diagnosis without Inpatient Admit</p>
                                                    <p style={{ marginLeft: "120px", fontSize: "14px", fontWeight: 600, color: "#616771" }}>2020 COVID Diagnosis with Inpatient Admit</p>
                                                </div>
                                            </div>
                                        }
                                        {...getTableContainerProps()}
                                        style={{ backgroundColor: "#FFF", flexGrow: 1 }}>
                                        <Table {...getTableProps()} isSortable style={{ backgroundColor: "#FFF" }}>
                                            <TableHead>
                                                <TableRow>
                                                    {// This is a bad way to do it as it is hardcoded. Need to figure out a better way
                                                        headers.slice(0, 7).map((header: DataTableHeader) => (
                                                            <TableHeader
                                                                key={header.key}
                                                                {...getHeaderProps({ header })}>
                                                                {header.header}
                                                            </TableHeader>
                                                        ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.slice(0, 7).map((row: DenormalizedRow) => (
                                                    <TableRow key={row.id} {...getRowProps({ row })}>
                                                        {row.cells.map((cell: DataTableCell) => (
                                                            <TableCell key={cell.id} style={{ backgroundColor: "#FFF" }}>{cell.value}</TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <TableHead>
                                                <TableRow>
                                                    <TableHeader key={"top3Conditions"} colSpan={headers.length}>
                                                        {"Top 3 Conditons"}
                                                    </TableHeader>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.slice(7).map((row: DenormalizedRow) => (
                                                    <TableRow key={row.id} {...getRowProps({ row })}>
                                                        {row.cells.map((cell: DataTableCell) => (
                                                            <TableCell key={cell.id} style={{ backgroundColor: "#FFF" }}>{cell.value}</TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </DataTable>
                        </div>
                    </Column>
                </Row>
            </Column>
        </Row>
        <Row style={{ marginTop: "30px", marginLeft: "0px" }}>
            <Column id="Export-DataDescription2">
                <Row><p style={{ fontSize: "20px", fontWeight: 400 }}>Preparing for long haul COVID-19 implications</p></Row>
                <Row><p style={{}}>The table above identifies individuals who had a COVID-19 diagnosis between January 1, 2020 and December 31, 2020.  The diagnosed population is then segmented into individuals who had an inpatient admission due to COVID-19 and those who did not.  The analysis pulls in key metrics for these cohorts for the year before, during, and after their diagnosis.  These results support early conversations about how long-term clinical solutions may need to be put in place for these vulnerable populations.</p></Row>
            </Column>
        </Row>
        <Row style={{ marginTop: "30px", marginLeft: "0px" }}>
            <Column>
                <Row><h2>Connected Utilization</h2></Row>
                <Row style={{ marginTop: "30px", display: "flex", justifyContent: "space-evenly" }}>
                    <Column style={{ padding: "0px 0px 0px 0px" }} lg={6}>
                        <div id="Export-UtilRates" style={{ backgroundColor: "white", padding: "20px", marginRight: "15px" }}>
                            <AreaChart data={covidUtilRates.data} options={covidUtilRates.options} />
                        </div>
                    </Column>
                    <Column style={{ padding: "0px 0px 0px 0px" }} lg={6}>
                        <div id="Export-BehavioralNetPaid" style={{ backgroundColor: "white", padding: "20px", marginLeft: "15px" }}>
                            <ComboChart data={behaveNetPaid.data} options={behaveNetPaid.options} />
                        </div>
                    </Column>
                </Row>
                <Row style={{ marginTop: "30px", display: "flex" }}>
                    <Column style={{ padding: "0px 0px 0px 0px" }} lg={6}>
                        <div id="Export-PCPTeleVsOffice" style={{ backgroundColor: "white", padding: "20px", marginRight: "15px" }}>
                            <ComboChart data={teleHealth.data} options={teleHealth.options} />
                        </div>
                    </Column>
                </Row>
            </Column>
        </Row>
        <Row style={{ marginTop: "40px", marginLeft: "0px", marginBottom: "20rem" }}>
            <Column id="Export-Analysis">
                <Row>
                    <p style={{ fontSize: "20px", fontWeight: 400 }}>Understanding the Bigger Picture</p>
                </Row>
                <Row>
                    <p style={{ fontSize: "16px", fontWeight: 400 }}>The images above capture some of the other key service areas that have been impacted by COVID-19.  Elective procedures, behavioral health, and telehealth services are top service types that have been impacted.</p>
                </Row>
            </Column>
        </Row>
    </Column>
}

const Covid19PDF: FunctionComponent = () => {
    // Render the Table of Contents after the document is finished render. TOC is built dynamically and needs the document done first
    useEffect(() => createTOC("TableOfContentsContainer", DOC_NAME, DOC_CATEGORY, DATE_GENERATED));
    const DOC_NAME = "Covid-19";
    const DOC_CATEGORY = "Hot Topic";
    const DATE_GENERATED = "Mar 30, 2021";
    const pdfProps = { docName: DOC_NAME, docCategory: DOC_CATEGORY, dateGenerated: DATE_GENERATED };
    let pageNum = 2;
    return <div id="PDFDocument">
        <PDFTitlePage {...pdfProps} />
        <div id="TableOfContentsContainer">
        </div>
        <PDFContentPage pageNum={pageNum++} {...pdfProps}>
            <Grid>
                <Row>
                    <p style={{ fontSize: "3rem", color: "#004877" }}>COVID-19</p>
                    <p style={{ marginTop: "1.5rem" }}>Explanation of what to expect from EGI. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tortor semper malesuada tempus, a, morbi iaculis. Sem neque aliquam tortor ac neque, dignissim ut. Ut adipiscing platea congue facilisis sed integer ipsum. Magna purus euismod a, id mauris vulputate amet ac.</p>
                    <p style={{ marginTop: "1rem", fontWeight: "bold" }}>Actionable language for the report with <a href="https://www.medinsight.milliman.com/en/">www.relevanturl.com</a> to relevant sections. Magna purus euismod a, id mauris vulputate amet ac.</p>
                </Row>
                <Row style={{ marginTop: "1rem" }}>
                    Filters
                </Row>
                <Row id="TOC:Overview" style={{ marginTop: "2rem" }}>
                    <h1>Overview</h1>
                    <p style={{ marginTop: ".5rem" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo odio interdum convallis nunc venenatis. Pulvinar et velit sit viverra cras at sed vel.</p>
                </Row>
                <Row style={{ marginTop: "1rem" }}>
                    <h1>COVID-19 Trend Map</h1>
                </Row>
                <Row id="TOC:COVID-19 Trend Map" style={{ marginTop: "1rem", backgroundColor: "#FFF", padding: "10px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.10)" }}>
                    <Row style={{ marginLeft: "0px" }}>
                        Covid-19 Map
                    </Row>
                    <Row style={{ padding: "10px" }}>
                        {/* Need to decide the map module */}
                        <img src={imgText} alt={"Carlie Anglemire"} width={"100%"} />
                    </Row>
                </Row>
            </Grid>
        </PDFContentPage>
        <PDFContentPage pageNum={pageNum++} {...pdfProps}>
            <Grid>
                <Row id="TOC:COVID-19 Trends">
                    <h2>COVID Trends</h2>
                </Row>
                <Row style={{ paddingTop: "1.5rem", height: "14rem" }}>
                    <Column id="Export-ConfirmedCovidCases" style={{ padding: "15px", backgroundColor: "#FFF" }}>
                        {/* need to make this summary box type of thing a component */}
                        <Row style={{ marginLeft: "0px" }}><h5 style={{ fontSize: "12px", fontWeight: "bold" }}>Confirmed Covid-19 Cases</h5></Row>
                        <Row style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><p style={{ marginTop: "2rem", fontSize: "48px" }}>34</p></Row>
                        <Row style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><p style={{ textAlign: "center", fontSize: "16px" }}>Confirmed cases per 1000</p></Row>
                    </Column>
                    <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", marginRight: "5px", justifyContent: "center" }}>
                        <div id="Export-InpatientImpact" style={{ backgroundColor: "white", padding: "5px", height: "100%" }}>
                            <GaugeChart options={admitsPer1000.pdfOptions} data={admitsPer1000.data} />
                        </div>
                    </Column>
                    <Column style={{ backgroundColor: "#FFF", marginLeft: "5px", marginRight: "20px" }}>
                        <div id="Export-ClinicalProfile" style={{ backgroundColor: "white", padding: "5px", height: "100%" }}>
                            <GaugeChart options={clinicalProfile.pdfOptions} data={clinicalProfile.data} />
                        </div>
                    </Column>
                    <Column id="Export-ConnectedUtil" style={{ padding: "15px", backgroundColor: "#FFF" }}>
                        <Row style={{ marginLeft: "0px" }}><p style={{ fontSize: "12px", fontWeight: "bold" }}>Connected Utilization</p></Row>
                        <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}><p style={{ marginTop: "2rem", fontSize: "48px" }}>245</p></Row>
                        <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}><p style={{ textAlign: "center", fontSize: "16px" }}>Telehealth Visits per 1000</p></Row>
                    </Column>
                </Row>
                <Row id="TOC:Key Indicators" style={{ marginTop: "3rem" }}>
                    <h2>Key Indicators</h2>
                </Row>
                <Row style={{ marginTop: "10px", display: "flex" }}>
                    <div style={{ width: "60%" }}>
                        <AreaChart data={covidByMonthData} options={covidByMonthPDFOptions} />
                    </div>
                    <div style={{ width: "35%", marginLeft: "1rem" }}>
                        <DonutChart options={comorbidCostPDFOptions} data={comorbidCostData} />
                    </div>
                </Row>
                <Row id="TOC:Patient Impact" style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <h2>Patient Impact</h2>
                </Row>
                <Row>
                    <div style={{ width: "60%" }}>
                        <ComboChart data={covidAdmits.data} options={covidAdmits.pdfOptions} />
                    </div>
                    <div style={{ width: "35%", marginLeft: "1rem" }}>
                        <GroupedBarChart data={covidAdmissions.data} options={covidAdmissions.pdfOptions} />
                    </div>
                </Row>
            </Grid>
        </PDFContentPage>
        <PDFContentPage pageNum={pageNum++} {...pdfProps}>
            <Grid>
                {summaryBoxes(true)}
                <Row id="TOC:Clinical Profile">
                    <h2>Clinical Profile</h2>
                </Row>
                <Row style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ marginTop: "20px", border: "2px solid #E8E9EA", width: "49%" }}>
                        <DataTable rows={covidProfCompTable.rows} headers={covidProfCompTable.headers}>
                            {({
                                rows = covidProfCompTable.rows,
                                headers = covidProfCompTable.headers,
                                getHeaderProps,
                                getRowProps,
                                getTableProps,
                                getTableContainerProps,
                            }: { rows: DenormalizedRow[], headers: DataTableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
                                <TableContainer
                                    title="COVID-19 Clinical Profile Comparison"
                                    {...getTableContainerProps()}
                                    style={{ backgroundColor: "#FFF", width: "100%" }}>
                                    <Table {...getTableProps()} style={{ backgroundColor: "#FFF", width: "100%" }}>
                                        <TableHead>
                                            <TableRow>
                                                {
                                                    headers.map((header: DataTableHeader) => (
                                                        <TableHeader
                                                            key={header.key}
                                                            {...getHeaderProps({ header })}
                                                            style={{ fontSize: "12px" }}>
                                                            {header.header}
                                                        </TableHeader>
                                                    ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row: DenormalizedRow) => (
                                                <TableRow key={row.id} {...getRowProps({ row })}>
                                                    {row.cells.map((cell: DataTableCell) => (
                                                        <TableCell key={cell.id} style={{ backgroundColor: "#FFF", fontSize: "12px" }}>{cell.value}</TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </DataTable>
                    </div>
                    <div style={{ marginTop: "20px", display: "flex", border: "2px solid #E8E9EA", width: "49%" }}>
                        <DataTable rows={covidMemOverTimeTable.rows} headers={covidMemOverTimeTable.headers}>
                            {({
                                rows = covidMemOverTimeTable.rows,
                                headers = covidMemOverTimeTable.headers,
                                getHeaderProps,
                                getRowProps,
                                getTableProps,
                                getTableContainerProps,
                            }: { rows: DenormalizedRow[], headers: DataTableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
                                <TableContainer
                                    title="Tracking COVID-19 Members Over Time"
                                    {...getTableContainerProps()}
                                    style={{ backgroundColor: "#FFF", flexGrow: 1 }}>
                                    <Table {...getTableProps()} style={{ backgroundColor: "#FFF" }}>
                                        <TableHead>
                                            <TableRow>
                                                {
                                                    headers.map((header: DataTableHeader) => (
                                                        <TableHeader
                                                            key={header.key}
                                                            {...getHeaderProps({ header })}
                                                            style={{ fontSize: "12px" }}>
                                                            {header.header}
                                                        </TableHeader>
                                                    ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row: DenormalizedRow) => (
                                                <TableRow key={row.id} {...getRowProps({ row })}>
                                                    {row.cells.map((cell: DataTableCell) => (
                                                        <TableCell key={cell.id} style={{ backgroundColor: "#FFF", fontSize: "12px" }}>{cell.value}</TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </DataTable>
                    </div>
                </Row>
            </Grid>
        </PDFContentPage>
        <PDFContentPage pageNum={pageNum++} {...pdfProps}>
            <Grid style={{ width: "100%" }}>
                <Row id="TOC:Connected Utilization">
                    <h2>Connected Utilization</h2>
                </Row>
                <Row style={{ marginTop: "1rem" }}>
                    <div style={{ width: "50%", paddingRight: ".5rem", display: 'inline-block' }}>
                        <AreaChart data={covidUtilRates.data} options={covidUtilRates.pdfOptions} />
                    </div>
                    <div style={{ width: "50%", paddingLeft: ".5rem", display: 'inline-block' }}>
                        <ComboChart data={behaveNetPaid.data} options={behaveNetPaid.pdfOptions} />
                    </div>
                </Row>
                <Row style={{ marginTop: "2rem" }}>
                    <div style={{ width: "50%", paddingRight: ".5rem", display: 'inline-block' }}>
                        <ComboChart data={teleHealth.data} options={teleHealth.pdfOptions} />
                    </div>
                </Row>
            </Grid>

        </PDFContentPage>
    </div>
}

export { Covid19Dashboard, Covid19PDF };