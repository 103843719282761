import { ArrowRight16, DocumentExport20 } from '@carbon/icons-react';
import { Column, Dropdown, Row } from 'carbon-components-react';
import { simpleBarChart1, simpleBarChart2, simpleBarChart3, simpleBarChart4 } from '../../data/Dashboard/SimpleBarChart';
import StyledLink from '../SideNavigation/StyledLink';
import { desktopSvg } from '../Other/SVG';
import MillimanLogo from '../../images/MillimanLogo.png';
import { CustomizeMetricsModal } from '../Other/CustomizeMetricsModal';
import { KeyMetric } from '../Widget/KeyMetric/KeyMetric';
import { SpecialTopicBarChat } from '../Widget/SpecialTopic/SpecialTopicBarChart';

const ViewCarousel = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7">
                <path d="M14.0013 38.0001H34.0013V8.00012H14.0013V38.0001ZM4.00131 34.0001H12.0013V12.0001H4.00131V34.0001ZM36.0013 12.0001V34.0001H44.0013V12.0001H36.0013Z" fill="white"/>
            </g>
        </svg>
    )
}

const ViewCarouselDark = () => {
    return (
        <svg width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.50085 22.4998H22.5009V-0.000244141H7.50085V22.4998ZM0.000854492 19.4998H6.00085V2.99976H0.000854492V19.4998ZM24.0009 2.99976V19.4998H30.0009V2.99976H24.0009Z" fill="#39414D"/>
        </svg>
    )
}

const ArrowsHorizontal = () => {
    return (
    <svg width="40" height="40" viewBox="0 0 40 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.7">
            <path d="M14.02 18H0V22H14.02V28L22 20L14.02 12V18ZM25.98 16V10H40V6H25.98V0L18 8L25.98 16Z" fill="white"/>
        </g>
    </svg>
    )
}

const Time = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7">
            <path d="M23.99 4C12.94 4 4 12.95 4 24C4 35.05 12.94 44 23.99 44C35.04 44 44 35.05 44 24C44 12.95 35.04 4 23.99 4ZM24 40C15.16 40 8 32.84 8 24C8 15.16 15.16 8 24 8C32.84 8 40 15.16 40 24C40 32.84 32.84 40 24 40Z" fill="white"/>
            <path d="M25 14H22V26L32.49 32.3L34 29.84L25 24.5V14Z" fill="white"/>
            </g>
        </svg>
    )
}

const CustomizeMetric = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15 3.50006H12.45C12.2134 2.3349 11.1889 1.49756 10 1.49756C8.81106 1.49756 7.7866 2.3349 7.55 3.50006H1V4.50006H7.55C7.7866 5.66523 8.81106 6.50257 10 6.50257C11.1889 6.50257 12.2134 5.66523 12.45 4.50006H15V3.50006ZM10 5.50006C9.17157 5.50006 8.5 4.82849 8.5 4.00006C8.5 3.17164 9.17157 2.50006 10 2.50006C10.8284 2.50006 11.5 3.17164 11.5 4.00006C11.5 4.82849 10.8284 5.50006 10 5.50006ZM1 12.5001H3.55C3.7866 13.6652 4.81106 14.5026 6 14.5026C7.18894 14.5026 8.2134 13.6652 8.45 12.5001H15V11.5001H8.45C8.2134 10.3349 7.18894 9.49756 6 9.49756C4.81106 9.49756 3.7866 10.3349 3.55 11.5001H1V12.5001ZM4.5 12.0001C4.5 11.1716 5.17157 10.5001 6 10.5001C6.82843 10.5001 7.5 11.1716 7.5 12.0001C7.5 12.8285 6.82843 13.5001 6 13.5001C5.17157 13.5001 4.5 12.8285 4.5 12.0001Z" fill="#39414D"/>
        </svg>
    )
}

const Star = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.9859 0.000244141C6.70585 0.000244141 0.000854492 6.72024 0.000854492 15.0002C0.000854492 23.2802 6.70585 30.0002 14.9859 30.0002C23.2809 30.0002 30.0009 23.2802 30.0009 15.0002C30.0009 6.72024 23.2809 0.000244141 14.9859 0.000244141ZM21.3459 24.0002L15.0009 20.1752L8.65585 24.0002L10.3359 16.7852L4.74085 11.9402L12.1209 11.3102L15.0009 4.50024L17.8809 11.2952L25.2609 11.9252L19.6659 16.7702L21.3459 24.0002Z" fill="#39414D"/>
        </svg>
    )
}

const Idea = () => {
    return (
        <svg width="28" height="40" viewBox="0 0 28 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 38C8 39.1 8.9 40 10 40H18C19.1 40 20 39.1 20 38V36H8V38ZM14 0C6.27 0 0 6.27 0 14C0 18.76 2.38 22.95 6 25.48V30C6 31.1 6.9 32 8 32H20C21.1 32 22 31.1 22 30V25.48C25.62 22.95 28 18.76 28 14C28 6.27 21.73 0 14 0ZM19.71 22.2L18 23.39V28H10V23.4L8.29 22.21C5.6 20.33 4 17.27 4 14.01C4 8.5 8.49 4.01 14 4.01C19.51 4.01 24 8.5 24 14.01C24 17.26 22.4 20.32 19.71 22.2Z" fill="#727A7D"/>
        </svg>
    )
}

const exportItems = [
    {
        id: 'option-0',
        text: 'PDF',
    },
    {
        id: 'option-1',
        text: 'PNG',
    }
]

const metricDataNew = [{
                        title: "MEMBERSHIP", 
                        metric: [{ title: "Average Members", value: "55,670", description: "No change vs prior period"},
                        { title: "Average Employee", value: "26,750", description: "No change vs prior period"},
                        { title: "MARA Age/Gender Risk", value: "1.056", description: "-1% vs prior period"},
                        { title: "MARA Prospective Risk", value: "1.452", description: "+3.1% vs prior period"}
                    ]},
                    {
                        title: "COST", 
                        metric: [{ title: "Medical Net Paid PMPM", value: "$420", description: "+2.5% vs prior period"},
                        { title: "Rx Net Paid PMPM", value: "$115", description: "+1.7% vs prior period"},
                        { title: "High Cost Claimant Paid PMPM", value: "$145", description: "+2.2% vs prior period"},
                        { title: "Inpatient Admits/1000", value: "230.6", description: "-4.4% vs prior period"}
                    ]},
                    {
                        title: "EFFICIENCY", 
                        metric: [{ title: "In-Network Utilization", value: "96.5%", description: "No change vs prior period"},
                        { title: "Inpatient Avoidable Admit Rate", value: "13.4%", description: "-3.2% vs prior period"},
                        { title: "Inpatient Average Length of Stay", value: "4.7", description: "-0.5% vs prior period"},
                        { title: "Rx Generic Utilization Rate", value: "35.2%", description: "-0.75% vs prior period"}
                    ]}
                ];

const specialTopicData = [{
                            title: "Behavioral Health Net Paid PMPM", 
                            chart: simpleBarChart1,
                            link: ""
                        },
                        {
                            title: "Percentage of Population with COVID-19",
                            chart: simpleBarChart2,
                            link: "/Covid19"
                        },
                        {
                            title: "Telehealth Visits / 1,000",
                            chart: simpleBarChart3,
                            link: "/Telehealth"
                        },
                        {
                            title: "HCC Net Paid PMPM",
                            chart: simpleBarChart4,
                            link: ""
                        }];

const Home = () => {

    return (
        <Row style={{marginRight: "0", backgroundColor: "#eaf9ff", marginLeft: "48px"}}>
            <Column sm={4} md={8} lg={10} style={{maxWidth: "80%", backgroundColor: "#eaf9ff"}}>
                    <Row style={{marginTop: "10px", marginLeft: "0px", flexDirection: "column"}}>
                        <Column>
                            <Row style={{fontSize: "20px"}}><strong>Welcome Lauren</strong></Row>
                            <Row style={{color: "#727A7D", marginTop: "10px", fontSize: "15px"}}>Here are your analytics for today</Row>
                        </Column>
                    </Row>
                    <Row style={{marginTop: "30px"}}>
                        <Column style={{ display: "flex", alignItems: "center"}}><p><strong>LEADING INDICATORS</strong></p></Column>
                        <Column lg={7} style={{ display: "flex", justifyContent: "flex-end"}}>
                            <Row style={{marginTop: "0px", marginLeft: "20px"}}>
                                <div style={{cursor: "pointer", paddingLeft: "20px", padding: "5px", display: "flex", width: "200px", height: "40px"}}>
                                    <div style={{paddingLeft: "10px", height: "40px", backgroundColor: "#fdfdfd", display: "flex", alignItems: "center", marginTop: "0px"}}><CustomizeMetric /></div>
                                    <CustomizeMetricsModal/>
                                </div>
                            </Row>
                        </Column>
                        <Column style={{marginRight :"10%", display: "flex", justifyContent: "flex-end"}}>
                            <Row style={{marginLeft: "20px"}}>
                            <div style={{cursor: "pointer", paddingLeft: "15px", padding: "5px", display: "flex", width: "200px", height: "40px"}}>
                                    {/* <div style={{paddingLeft: "10px", height: "40px", backgroundColor: "#fdfdfd", display: "flex", alignItems: "center", marginTop: "0px"}}><DocumentExport20 /></div> */}
                                    <Dropdown
                                        style={{width: "100%"}}
                                        id="NewCustomization"
                                        className="NewCustomization"
                                        titleText=""
                                        helperText=""
                                        label={<><DocumentExport20 style={{margin:'0px 6px -6px 0px'}}/><span> Export Matrix</span></>}
                                        items={exportItems}
                                        itemToString={(item) => (item ? item.text : '')}
                                        // onChange={(event) => { onDropDownChange(event); }}
								    />
                                </div>
                            </Row>
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <KeyMetric data={metricDataNew}/>
                        </Column>
                    </Row>
                    
                    <Row style={{height: "auto", backgroundColor: "#f9f9f9", marginTop: "20px"}}>
                        <Column>
                            <Row style={{display: "flex", justifyContent: "space-between",
                                        marginLeft: "2px",
                                        marginRight: "30px",
                                        marginTop: "30px"}}>
                                <div><p><strong>SPECIAL TOPICS</strong></p></div>
                                <StyledLink to="/SpecialTopics" style={{float: "right", display: 'flex'}}><p style={{cursor: "pointer", color: "#0080E2"}}><u>See All</u></p></StyledLink>
                            </Row>
                            <Row style={{padding: "10px"}}>
                                <SpecialTopicBarChat chartData={specialTopicData}/>
                            </Row>
                        </Column>
                    </Row>

                    <Row style={{ paddingLeft: "100px", display: "flex", backgroundColor: "#004877", paddingTop: "30px", paddingBottom: "30px"}}>
                        <Column>
                            <div style={{flex: 1,
                                height: "150px",
                                left: "83px",
                                top: "778px",
                                marginRight: "60px",
                                padding: "10px",
                                color: "#FFF",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column"
                            }}>
                                <StyledLink to="/SmallGroupAnnual">
                                <a style= {{textDecoration: "none", color: "#FFF"}}>
                                <Row>
                                    <Column style={{fontSize: "25px"}}>Storyboards</Column>
                                    <Column><ViewCarousel /></Column>
                                </Row>
                                </a>
                                </StyledLink>
                                <Row style={{ lineHeight: "18px", opacity: "0.9", marginLeft: "0px", marginTop: "10px"}}>Curated, robust narratives capture<br /> total plan performance</Row>
                            </div>
                        </Column>
                        <Column>
                            <div style={{flex: 1,
                                height: "150px",
                                left: "83px",
                                top: "778px",
                                marginRight: "60px",
                                padding: "10px",
                                color: "#FFF",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column"
                            }}>
                                <StyledLink to="/CompareContrast">
                                <a style= {{textDecoration: "none", color: "#FFF"}}>
                                    <Row>
                                        <Column style={{fontSize: "25px"}}>Compare & Contrast</Column>
                                        <Column><ArrowsHorizontal/></Column>
                                    </Row>
                                </a>
                                </StyledLink>
                                <Row style={{ lineHeight: "18px", opacity: "0.9", marginLeft: "0px", marginTop: "10px"}}>Create custom key indicator<br /> comparisons</Row>
                            </div>
                        </Column>
                        <Column>
                            <div style={{flex: 1,
                                height: "150px",
                                left: "83px",
                                top: "778px",
                                marginRight: "60px",
                                padding: "10px",
                                color: "#FFF",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column"
                            }}>
                                <StyledLink to="/ReportScheduler">
                                <a style= {{textDecoration: "none", color: "#FFF"}}>
                                    <Row>
                                        <Column style={{fontSize: "25px"}}>Report &<br/> Scheduler</Column>
                                        <Column><Time /></Column>
                                    </Row>
                                </a>
                                </StyledLink>
                                <Row style={{ lineHeight: "18px", opacity: "0.9", marginLeft: "0px", marginTop: "10px"}}>Set-up and access reports<br /> in production queue</Row>
                            </div>
                        </Column>
                    </Row>
            </Column>
            <Column style={{ marginRight: "0", paddingLeft:"50px", backgroundColor: "#FFFFFF"}}>
                <Row style={{marginTop: "20px", fontSize: "18px"}}><strong>RECENTLY VIEWED</strong></Row>
                <Row style={{marginTop: "20px"}}>
                    <Column style={{maxWidth: "20%"}}><Star/></Column>
                    <Column style={{justifyContent: "center",
                            display: "flex",
                            flexDirection: "column",
                            paddingLeft: "6%"}}>
                            <Row style={{cursor: "pointer", paddingBottom: "5px", color: "#0080E2"}}><StyledLink to="/Diabetes">Diabetes</StyledLink></Row>
                            <Row style={{fontSize: "10px", color: "#727A7D"}}>Viewed today</Row>
                    </Column>
                </Row>
                <Row style={{marginTop: "20px"}}>
                <Column style={{maxWidth: "20%"}}><Star/></Column>
                    <Column style={{justifyContent: "center",
                            display: "flex",
                            flexDirection: "column",
                            paddingLeft: "6%"}}>
                            <Row style={{cursor: "pointer", paddingBottom: "5px", color: "#0080E2"}}><StyledLink to="/Covid19">COVID-19</StyledLink></Row>
                            <Row style={{fontSize: "10px", color: "#727A7D"}}>Viewed yesterday</Row>
                    </Column>
                </Row>
                <Row style={{marginTop: "20px"}}>
                <Column style={{maxWidth: "20%"}}><ViewCarouselDark /></Column>
                <Column style={{justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: "6%"}}>
                        <Row style={{cursor: "pointer", paddingBottom: "5px", color: "#0080E2"}}><StyledLink to="/SmallGroupAnnual">Large Group Quarterly</StyledLink></Row>
                        <Row style={{fontSize: "10px", color: "#727A7D"}}>Viewed last week</Row>
                </Column>
                </Row>
                <Row style={{marginTop: "20px"}}>
                <Column style={{maxWidth: "20%"}}><ViewCarouselDark /></Column>
                <Column style={{justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: "6%"}}>
                        <Row style={{cursor: "pointer", paddingBottom: "5px", color: "#0080E2"}}><StyledLink to="/SmallGroupAnnual">Large Group Semi-Annual</StyledLink></Row>
                        <Row style={{fontSize: "10px", color: "#727A7D"}}>Viewed last week</Row>
                </Column>
                </Row>
                <Row style={{margin: "40px 0px 40px 0px"}}>
                <div style={{
                    width: "250px",
                    height: "0px",
                    border: "1px solid #ECEEEF"}}>
                </div>
                </Row>

                <Row style={{fontSize: "18px"}}><strong>WHAT’S NEW</strong></Row>
                <Row style={{marginTop: "20px"}}>
                    <Column>
                        <Row>
                            <div style={{
                                width: "80%",
                                height: "80px",
                                left: "1525px",
                                top: "451px",
                                /* Steel HEX: #C6C9CA RGB: 198 / 201 / 202 */
                                background: "#C6C9CA",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                borderRadius: "8px"}}>
                                    <div style={{
                                        width: "40px",
                                        height: "50px",
                                        borderTop: "45px solid #00A562",
                                        borderRight: "46px solid transparent"}}></div>
                                </div>
                        </Row>
                        <Row style={{marginTop: "5px", fontSize: "12px", justifyContent: "center", marginRight: "10px"}}><strong>COVID-19</strong>&nbsp;Special</Row>
                        <Row style={{fontSize: "12px", display: "flex", justifyContent: "center", marginRight: "10px"}}>Topics</Row>
                    </Column>
                    <Column>
                    <Row>
                        <div style={{
                            width: "80%",
                            height: "80px",
                            left: "1525px",
                            top: "451px",
                            background: "#C6C9CA",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                            borderRadius: "8px"}}>
                                <div style={{
                                width: "40px",
                                height: "50px",
                                borderTop: "45px solid #FFA100",
                                borderRight: "46px solid transparent"}}></div>
                        </div>
                    </Row>
                    <Row style={{marginTop: "5px", fontSize: "12px", justifyContent: "center", marginRight: "10px"}}><strong>Large Group</strong></Row>
                    <Row style={{fontSize: "12px", display: "flex", justifyContent: "center", marginRight: "10px"}}><span><strong>Quarterly</strong></span>&nbsp;Storyboard</Row>
                </Column>
                </Row>
                <Row style={{marginTop: "20px", color: "#0080E2", display: "flex", alignItems: "canter", justifyContent: "center"}}><u style={{cursor: "pointer"}}>See All Product Updates</u></Row>

                <Row style={{margin: "40px 0px 40px 0px"}}>
                    <div style={{
                        width: "250px",
                        height: "0px",
                        border: "1px solid #ECEEEF"}}>
                    </div>
                </Row>

                <Row style={{fontSize: "18px"}}><strong>UPCOMING EVENTS</strong></Row>

                <Row style={{marginTop: "20px"}}>
                    <Column style={{minWidth: "67%"}}>
                        <Row><strong>9.20.22 - </strong><span style={{padding: "0px 5px 0px 5px"}}>{desktopSvg()}</span><em>Webinar</em></Row>
                        <Row>Navigating Employer Group Insights</Row>
                    </Column>
                    <Column style={{color: "#0080E2", display: "flex", alignItems: "center"}}>
                        <u style={{cursor: "pointer"}}>Register</u>
                    </Column>
                </Row>
                <Row style={{marginTop: "20px"}}>
                     <Column style={{minWidth: "67%"}}>
                        <Row><strong>10.20.22 - </strong><span style={{padding: "0px 5px 0px 5px"}}>{desktopSvg()}</span><em>Webinar</em></Row>
                        <Row>Getting Valuable Insights in Employer Group Insights</Row>
                    </Column>
                    <Column style={{color: "#0080E2", display: "flex", alignItems: "center"}}>
                        <u style={{cursor: "pointer"}}>Register</u>
                    </Column>
                </Row>
                
                <Row style={{marginTop: "30px"}}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "91%",
                    height: "120px",
                    left: "0px",
                    top: "0px",
                    padding: "12px 12px 20px 12px",
                    background: "linear-gradient(180deg, #006B3F 0%, rgba(65, 161, 234, 0.75) 100%)"}}>
                    <Row style={{margin: "0px", display: "flex", justifyContent: "space-between", color: "#FFF"}}>
                        <p style={{fontSize: "15px"}}>SAVE THE DATE</p>
                        <img src={MillimanLogo} alt={"Milliman Logo"}  style={{ marginTop: "4px", maxWidth: '100%', maxHeight: '70%' }}/>
                    </Row>
                    <Row style={{ lineHeight: "25px", marginTop: "auto", marginLeft: "0px", color: "#FFF", fontSize: "17px"}}>Virtual Client Forum 2021</Row>
                    <Row style={{ marginLeft: "0px", color: "#FFF", fontSize: "15px"}}>October 19-20, 2022</Row>
                </div>
                </Row>

                <Row style={{marginTop: "70px"}}>
                <div style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "91%",
                        minHeight: "200px",
                        backgroundColor: "rgba(80, 190, 225, 0.1)",
                        padding: "15px",
                        borderRadius: "8px"}}>
                    <Row style={{margin: "0px", display: "flex", justifyContent: "space-between"}}>
                        <p style={{fontSize: "24px", color: "#39414D"}}>Did you know?</p>
                        <p><Idea /></p>
                    </Row>
                    <Row style={{color: "#39414D", marginTop: "15px", marginLeft: "0px", width: "98%", display: "flex", justifyContent: "center", lineHeight: "18px"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum gravida eu eget lorem tortor non.</Row>
                    <Row style={{marginTop: "auto", color: "#0080E2", marginLeft: "auto", paddingRight: "15px", fontSize: "13px", float: "right", cursor: "pointer"}}>Browse How Tos & Tech Specs <ArrowRight16 /></Row>
                </div>
                </Row>
            </Column>
        </Row>
    );
};

export default Home;