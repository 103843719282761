import React from 'react';
import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderMenuButton,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SideNav,
  Search
} from 'carbon-components-react';
import SideNavigation from '../SideNavigation/SideNavigation';
import { PortalFilterModal } from '../PortalFilters/PortalFilterModal';
import StyledLink from '../SideNavigation/StyledLink';

const UserAvatar = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.00004 7.00001C8.84171 7.00001 10.3334 5.50418 10.3334 3.66668C10.3334 1.82501 8.84171 0.333344 7.00004 0.333344C5.15837 0.333344 3.66671 1.82501 3.66671 3.66668C3.66671 5.50418 5.15837 7.00001 7.00004 7.00001ZM7.00004 8.66668C4.77921 8.66668 0.333374 9.77918 0.333374 12V13.6667H13.6667V12C13.6667 9.77918 9.22087 8.66668 7.00004 8.66668Z" fill="#EFF0F1" />
    </svg>
  )
}

const Help = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.99996 0.666656C4.39579 0.666656 0.666626 4.39582 0.666626 8.99999C0.666626 13.6042 4.39579 17.3333 8.99996 17.3333C13.6041 17.3333 17.3333 13.6042 17.3333 8.99999C17.3333 4.39582 13.6041 0.666656 8.99996 0.666656ZM9.83329 14.8333H8.16663V13.1667H9.83329V14.8333ZM11.5541 8.37916L10.8083 9.14582C10.2083 9.74582 9.83329 10.25 9.83329 11.5H8.16663V11.0833C8.16663 10.1625 8.54163 9.32916 9.14163 8.72499L10.1791 7.67499C10.4791 7.37499 10.6666 6.95832 10.6666 6.49999C10.6666 5.57916 9.92079 4.83332 8.99996 4.83332C8.07913 4.83332 7.33329 5.57916 7.33329 6.49999H5.66663C5.66663 4.65832 7.15829 3.16666 8.99996 3.16666C10.8416 3.16666 12.3333 4.65832 12.3333 6.49999C12.3333 7.23332 12.0375 7.89582 11.5541 8.37916Z" fill="white" />
    </svg>
  )
}

const Notification = () => {
  return (
    <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.00004 16.3333C7.92087 16.3333 8.66671 15.5875 8.66671 14.6667H5.33337C5.33337 15.5875 6.07921 16.3333 7.00004 16.3333ZM12 11.3333V7.16668C12 4.60418 10.6375 2.46668 8.25004 1.90001V1.33334C8.25004 0.641677 7.69171 0.0833435 7.00004 0.0833435C6.30837 0.0833435 5.75004 0.641677 5.75004 1.33334V1.90001C3.36254 2.46668 2.00004 4.60418 2.00004 7.16668V11.3333L0.333374 13V13.8333H13.6667V13L12 11.3333Z" fill="#EFF0F1" />
    </svg>
  )
}

const Timer = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 6.4375H9.84588L12.1334 4.0875C9.85838 1.83333 6.17504 1.75 3.90004 4.00417C1.62504 6.25833 1.62504 9.90833 3.90004 12.1625C6.17504 14.4167 9.85838 14.4167 12.1334 12.1625C13.2667 11.0375 13.8334 9.73333 13.8292 8.08333H15.5C15.5 9.73333 14.7667 11.875 13.3 13.3292C10.375 16.225 5.62504 16.225 2.70004 13.3292C-0.224956 10.4333 -0.245789 5.7375 2.67921 2.84167C5.60004 -0.0541667 10.3 -0.0541667 13.2209 2.84167L15.5 0.5V6.4375ZM8.41671 4.66667V8.20833L11.3334 9.94167L10.7334 10.95L7.16671 8.83333V4.66667H8.41671Z" fill="#EFF0F1" />
    </svg>
  )
}

const PortalSwitch = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.333328 3.66665H3.66666V0.333313H0.333328V3.66665ZM5.33333 13.6666H8.66666V10.3333H5.33333V13.6666ZM0.333328 13.6666H3.66666V10.3333H0.333328V13.6666ZM0.333328 8.66665H3.66666V5.33331H0.333328V8.66665ZM5.33333 8.66665H8.66666V5.33331H5.33333V8.66665ZM10.3333 0.333313V3.66665H13.6667V0.333313H10.3333ZM5.33333 3.66665H8.66666V0.333313H5.33333V3.66665ZM10.3333 8.66665H13.6667V5.33331H10.3333V8.66665ZM10.3333 13.6666H13.6667V10.3333H10.3333V13.6666Z" fill="#EFF0F1" />
    </svg>
  )
}

const dataLoadedThrough = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  const month = date.toLocaleString('default', {month: 'long'});
  const year = date.getFullYear(); 
  return (month + " " + year);
}

const PageHeader = () => (
  <HeaderContainer
    render={({ isSideNavExpanded, onClickSideNavExpand }) => (
      <>
        <Header style={{ position: "sticky" }} aria-label="EGI Portal Header Bar">
          <HeaderMenuButton
            aria-label="Open menu"
            isCollapsible
            onClick={onClickSideNavExpand}
            isActive={isSideNavExpanded}
          />
          <StyledLink to="/">
            <HeaderName prefix="MedInsight Employer Group Insights">
            </HeaderName>
          </StyledLink>
          <HeaderGlobalBar>
            <div style={{ width: "30%", marginRight: "20px", display: "flex", alignItems: "center" }}>
              <Search style={{ height: "35px" }} placeholder="Search / Filter" labelText="" id="search-5" />
            </div>
            <PortalFilterModal />
            <HeaderGlobalAction
              aria-label={`Data available through ${dataLoadedThrough()}`}>
              <Timer />
            </HeaderGlobalAction>
            <HeaderGlobalAction
              aria-label="Notifications">
              <Notification />
            </HeaderGlobalAction>
            <HeaderGlobalAction
              aria-label="Resources"
              tooltipAlignment="end">
              <Help />
            </HeaderGlobalAction>
            <HeaderGlobalAction
              aria-label="Portal Switch"
              tooltipAlignment="end">
              <PortalSwitch />
            </HeaderGlobalAction>
            <HeaderGlobalAction
              aria-label="My Account"
              tooltipAlignment="end">
              <UserAvatar />
            </HeaderGlobalAction>
          </HeaderGlobalBar>
          <SideNav
            aria-label="Side navigation"
            isRail
            expanded={isSideNavExpanded}>
            <SideNavigation />
          </SideNav>
        </Header>
      </>
    )}
  />
);

export default PageHeader;