import { SimpleBarChart } from "@carbon/charts-react"
import { ArrowRight16 } from "@carbon/icons-react"
import { Column, Row } from "carbon-components-react"
import StyledLink from "../../SideNavigation/StyledLink"

interface IData {
    group: string;
    value: number;
}
interface IBarChart {
     data: IData[];
     options: any;
}
interface ISpecialTopicProps {
    title: string;
    chart: IBarChart;
    link: string;
}
interface IProps {
    chartData: ISpecialTopicProps[];
}

export const SpecialTopicBarChat: React.FC<IProps> = (props) => {

    return (
        <>
        {props.chartData.map(item => {
            return <Column className="SpecialTopicContainer" style={{ marginBottom: "30px"}}>
                        <div style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 1,
                                height: "350px",
                                left: "83px",
                                top: "778px",
                                background: "#FFFFFF",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                borderRadius: "8px",
                                marginRight: "20px",
                                padding: "20px"
                            }}>
                            <Row style={{ marginLeft: "0", width: "70%"}}>
                                <p style={{paddingBottom: "10px"}}><strong>{item.title}</strong></p>
                            </Row>
                            <SimpleBarChart
                                data={item.chart.data}
                                options={item.chart.options}
                            />
                            <p style={{cursor: "pointer", marginLeft: "auto", bottom: "0px", marginTop: "auto",  color: "#0080E2", float: "right", display: "flex", alignItems: "center"}}><StyledLink to={item.link}><span style={{marginRight: "5px"}}>Explore</span><ArrowRight16 /></StyledLink></p>
                        </div>
                </Column>
        })}
        </>
    )
}