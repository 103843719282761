import {
  Select,
  SelectItem,
  SelectItemProps,
  DatePicker,
  DatePickerInput,
  Column,
  Row,
  Grid,
  Toggle,
  TooltipIcon
} from 'carbon-components-react';
import incurredTimePeriods from '../../data/Filters/TimePeriods.json';
import employerGroups from '../../data/Filters/EmployerGroups.json';
import locations from '../../data/Filters/Locations.json';
import memberRelationship from '../../data/Filters/MemberRelationships.json';
import plans from '../../data/Filters/Plans.json';
import groupUDD from '../../data/Filters/FilterList.json';
import { Reset16 } from '@carbon/icons-react';

const resetButton = () => <TooltipIcon
tooltipText="Reset"
children={<Reset16 style={{marginTop:"35px", marginLeft:"10px", float:"right"}} />}
/>;

const FilterList = ({ componentIDSuffix }: { componentIDSuffix: string }) => (
  <Grid id="filtersInModal">
    {/* Employer Group */}
    <Row>
      <Select
        data-modal-primary-focus
        id={"employer-group-filter"}
        labelText="Employer Group"
        defaultValue="All"
      >
        {employerGroups.map((timePeriod: SelectItemProps, index: number) => <SelectItem value={timePeriod.value} text={timePeriod.text} key={'employerGroup' + index} />)}
      </Select>{resetButton()}
    </Row>
    <Row style={{paddingTop:"20px"}}>
      <Column lg={4}>
      </Column>
      <Column lg={4}>
        <p className="modalText">CURRENT</p>
      </Column>
      <Column lg={4}>
        <p className="modalText">PRIOR</p>
      </Column>
    </Row>
    <Row>
      <Column style={{paddingLeft: "0px"}}>
        <Select id="select-incurred-filter" defaultValue="Q2-YTD" labelText="Incurred Time Period" style={{ float: "left" }}>
          {incurredTimePeriods.map((timePeriod: SelectItemProps, index: number) => <SelectItem value={timePeriod.value} text={timePeriod.text} key={'incurred' + index} />)}
        </Select>
      </Column>
      <Column md={1} lg={1} style={{ position: "relative" }}>
        <span className="modalText" style={{ position: "absolute", height: "100%", top: "50%" }}>between</span>
      </Column>
      <Column>
        <DatePicker datePickerType="range" style={{ float: "right" }}>
          <DatePickerInput
            id={"date-picker-input-id-start"}
            placeholder="mm/dd/yyyy"
            labelText="Start date"
            defaultValue="01/01/2021"
          />
          <DatePickerInput
            id={"date-picker-input-id-finish"}
            placeholder="mm/dd/yyyy"
            labelText="End date"
            defaultValue="06/30/2021"
          />
        </DatePicker>
      </Column>
      <Column>
        <p className="modalText" style={{marginTop: "35px"}}>01/01/2020 - 06/30/2020</p>
      </Column>
      {resetButton()}
    </Row>
    <Row style={{paddingTop:"20px"}}>
      <Column style={{paddingLeft: "0px"}}>
        <Select id={"select-paid-filter"} defaultValue="+1 month" labelText="Paid Time Period" style={{ float: "left" }}>
          {[1, 2, 3].map((val: number, index: number) => <SelectItem value={val} text={"+" + val + " months"} key={'paid' + index} />)}
        </Select>
      </Column>
      <Column md={1} lg={1} style={{ position: "relative" }}>
        <span className="modalText" style={{ position: "absolute", height: "100%", top: "50%" }}>between</span>
      </Column>
      <Column>
        <DatePicker datePickerType="range" style={{ float: "right" }}>
          <DatePickerInput
            id={"date-picker-input-id-start"}
            placeholder="mm/dd/yyyy"
            labelText="Start date"
            defaultValue="01/01/2021"
          />
          <DatePickerInput
            id={"date-picker-input-id-finish"}
            placeholder="mm/dd/yyyy"
            labelText="End date"
            defaultValue="07/31/2021"
          />
        </DatePicker>
      </Column>
      <Column>
        <p></p>
        <p className="modalText" style={{marginTop: "35px"}}>01/01/2020 - 07/31/2020</p>
      </Column>
      {resetButton()}
    </Row>
    <Row style={{paddingTop:"20px"}}>
      <p className="bx--label">Continuously Enrolled</p>
    </Row>
    <Row className="continuouslyEnrolled">
      <Column lg={2} md={1} style={{ position: "relative" }}>
        <p className="modalText" style={{ position: "absolute", height: "100%", top: "50%" }}>between</p>
      </Column>
      <Column>
        <DatePicker datePickerType="range" style={{ float: "right" }}>
          <DatePickerInput
            id={"date-picker-input-id-start"}
            placeholder="mm/dd/yyyy"
            labelText="Start date"
            defaultValue="01/01/2021"
          />
          <DatePickerInput
            id={"date-picker-input-id-finish"}
            placeholder="mm/dd/yyyy"
            labelText="End date"
            defaultValue="06/30/2021"
          />
        </DatePicker>
      </Column>
      {resetButton()}
    </Row>
    <Row>
      <h6 style={{padding: "20px 0px", width:"230px"}}>High Cost Claimant Threshold</h6>
      <span style={{float:"right", borderBottom: "2px solid #e8e9ea", width:"650px", margin:"30px 0px 0px 0px", height: "2px"}}></span>
    </Row>
    <Row>
      <Toggle id={"toggleRX" + componentIDSuffix} labelText="Include RX" labelA="No" labelB="Yes" />
      {resetButton()}
    </Row>
    <Row style={{paddingTop:"20px"}}>
      <Select id={"select-payment-filter"} defaultValue="$50K" labelText="Payment Level">
        {["$10K", "$20K", "$30K", "$40K", "$50K"].map((value: string, index: number) => <SelectItem value={value} text={value} key={value} />)}
      </Select>
      {resetButton()}
    </Row>
    <Row>
      <span style={{float:"right", borderBottom: "2px solid #e8e9ea", width:"880px", margin:"30px 0px 0px 0px"}}></span>
    </Row>
    <Row style={{paddingTop:"20px"}}>
      <Toggle id={"toggleMedicarePrimary" + componentIDSuffix} labelText="Include Medicare Primary" labelA="No" labelB="Yes" />
      {resetButton()}
    </Row>
    {/* Location */}
    <Row style={{paddingTop:"20px"}}>
      <Select id={"select-location-filter"} defaultValue="All" labelText="Location">
        {locations.map((location: SelectItemProps, index: number) => <SelectItem value={location.value} text={location.text} key={'location' + index} />)}
      </Select>
      {resetButton()}
    </Row>
    {/* Member Relationship */}
    <Row style={{paddingTop:"20px"}}>
      <Select id={"select-ee-filter"} defaultValue="All" labelText="Member Relationship">
        {memberRelationship.map((relationship: SelectItemProps, index: number) => <SelectItem value={relationship.value} text={relationship.text} key={'relationship' + index} />)}
      </Select>
      {resetButton()}
    </Row>

    {/* Plan */}
    <Row style={{paddingTop:"20px"}}>
      <Select id={"select-plan-filter"} defaultValue="All" labelText="Plan">
        {plans.map((plan: SelectItemProps, index: number) => <SelectItem value={plan.value} text={plan.text} key={'plan' + index} />)}
      </Select>
      {resetButton()}
    </Row>

    {/* Group UDD */}
    <Row style={{paddingTop:"20px"}}>
      <Select id={"select-Group-UDD-filter"} defaultValue="All" labelText="Group UDD">
        {groupUDD.map((item, index) => <SelectItem key={index} text={item.name} value={item.id} />)}
      </Select>
      {resetButton()}
    </Row>

    <Row style={{paddingTop:"20px"}}>
    <Select id={"care-management-engagement-filter"} defaultValue="All" labelText="Care Management Engagement">
        {["All", "All Eligible", "Qualified", "Engaged", "Qualified, Not Engaged", "Not Qualified"].map((item, index) => <SelectItem key={index} text={item} value={item} />)}
      </Select>
      {resetButton()}
    </Row>
  </Grid>
);

export default FilterList;