import { TooltipIcon } from "carbon-components-react";
import { Information16 } from '@carbon/icons-react';

const FiltersReview = () => (
    <>
        <p className="labelHeading" style={{ paddingBottom: "12px" }}>Employer Group</p>
        <p className="labelText" style={{ paddingBottom: "30px" }}>Robertson Removal, Inc.</p>
        <p className="labelHeading" style={{ paddingBottom: "0px" }}>Incurred Time Period</p>
        <div style={{ display: "inline-block", paddingBottom: "20px", width: "100%" }}>
            <div>
                <p className="labelDateTimeTextExtend">CURRENT</p>
            </div>
            <div style={{ display: "inline-block", width: "100%" }}>
                <p className="labelHeading" style={{ float: "left", fontSize: "14px" }}>Q2 YTD</p>
                <TooltipIcon
                    direction="bottom"
                    tooltipText="Prior Period: 01/01/2020 - 06/30/2020"
                    children={<Information16 />}
                    style={{float:"right", margin: "1px 5px 0 5px"}}
                />
                <p className="labelDateTimeText">01/01/2021 - 06/30/2021</p>
            </div>
        </div>
        <p className="labelHeading" style={{ paddingBottom: "0px" }}>Paid Time Period</p>
        <div style={{ display: "inline-block", paddingBottom: "20px", width: "100%" }}>
            <div>
                <p className="labelDateTimeTextExtend">CURRENT</p>
            </div>
            <div style={{ display: "inline-block", width: "100%" }}>
                <p className="labelHeading" style={{ float: "left", fontSize: "14px" }}>+1 month</p>
                <TooltipIcon
                    direction="bottom"
                    tooltipText="Prior Period: 01/01/2020 - 07/31/2020"
                    children={<Information16 />}
                    style={{float:"right", margin: "1px 5px 0 5px"}}
                />
                <p className="labelDateTimeText">01/01/2021 - 07/31/2021</p>
            </div>
        </div>
        <p className="labelHeading">Continuously Enrolled</p>
        <div style={{ display: "inline-block", paddingBottom: "10px", width: "100%" }}>
            <div style={{ display: "inline-block", width: "100%" }}>
                <p className="labelHeading" style={{ float: "left", fontSize: "14px" }}>between</p>
                <p className="labelDateTimeText">01/01/2020 - 06/30/2021</p>
            </div>
        </div>
        <div className="lineSeparator"></div>

        <p className="labelHeading">High Cost Claimant Threshold</p>
        <p className="labelSubHeading">Rx Included</p>
        <p className="labelSubText">Yes</p>
        <p className="labelSubHeading">Payment Level</p>
        <p className="labelSubText">$50K</p>
        <div className="lineSeparator"></div>

        <p className="labelHeading" style={{ paddingBottom: "12px" }}>Medicare Primary Included</p>
        <p className="labelText">No</p>
    </>
);

export default FiltersReview;
