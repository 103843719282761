import { createStore, applyMiddleware } from 'redux';
// no middleware libraries yet
import rootReducer from './rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';

export default function configureStore() {
  return createStore(rootReducer, composeWithDevTools(
    applyMiddleware(),
  // other store enhancers if any
  ));
}
