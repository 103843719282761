import { Column, Row } from "carbon-components-react";
import { connect } from "react-redux";

interface OverviewProps {
    description?: string,
    maxPerSection: number,
    sections: string[],
    specialTopic?: boolean
}

/**
 * Overview Box component that is found often at the top of dashboards.
 * @param {string} description Text that will appear right below the Overview title. Omit if not wanted
 * @param {number} maxPerSection Maximum number of bulleted sections allowed in one list column
 * @param {string[]} sections Name of each bulleted section, in desired order.  
 */
const OverviewBox = (props: OverviewProps & {reportCreatedDate: string}) => {
    const { maxPerSection, sections, description, reportCreatedDate, specialTopic } = props;
    let formattedSections: JSX.Element[] = [];
    for (let i = 0; i*maxPerSection < sections.length; i++) {
        const chunk = sections.slice(i*maxPerSection, (i*maxPerSection)+maxPerSection);
        const listEle = (chunk.map(section => (
            <li className='reportList' style={{ padding: '4px', color: '#0080E2'}}><a href="/" style={{ textDecoration: 'none' }}>{section}</a></li>
        )));
        formattedSections.push(<Column><ul>{listEle}</ul></Column>);
    }
    return <Column>
        <Row>
            <h1 style={{ marginTop: "18px" }}>Overview</h1>
        </Row>
        {description ? <Row style={{ marginTop: "10px", width: "832px" }}><p>{description}</p></Row> : null}        
        {specialTopic ? null : 
            <Row style={{ marginTop: "20px", display: "flex", justifyContent: "flex-start"}}>
                <p>Report Created: {reportCreatedDate}</p>
            </Row>
        }
        
        <Row style={{ marginTop: "20px", display: "flex", justifyContent: "flex-start" }}>
            {formattedSections}
        </Row>
    </Column>
}

const mapStateToProps = (state: any) => {
    return {
      reportCreatedDate: state.root.reportCreatedDate
    };
}

export default connect(mapStateToProps)(OverviewBox);