const OutpatientSurgeryRow = [
    {
        "id": "1",
        "title": "Net Paid PMPM",
        "prior": "$9.51",
        "current": "-$9.00",
        "norm": "$10.45",
    },
    {
        "id": "2",
        "title": "Utils per 1000",
        "prior": "249.8",
        "current": "-238.3",
        "norm": "255.7",
    },
    {
        "id": "3",
        "title": "Average Paid per Util",
        "prior": "$456.58",
        "current": "-$453.31",
        "norm": "$487.00    ",
    }

]

const OutpatientSurgeryHeader = [
    {
        "key": "title",
        "header": ""
    },
    {
        "key": "prior",
        "header": "Prior"
    },
    {
        "key": "current",
        "header": "Current"
    },
    {
        "key": "norm",
        "header": "Norm"
    }
]
const LabServicesRow = [
    {
        "id": "1",
        "title": "Net Paid PMPM",
        "prior": "$8.02",
        "current": "+$8.47",
        "norm": "$10.45",
    },
    {
        "id": "2",
        "title": "Utils per 1000",
        "prior": "738.7",
        "current": "+757.0",
        "norm": "255.7",
    },
    {
        "id": "3",
        "title": "Average Paid per Util",
        "prior": "$130.27",
        "current": "+$134.32",
        "norm": "$487.00",
    }

]

const LabServicesHeader = [
    {
        "key": "title",
        "header": ""
    },
    {
        "key": "prior",
        "header": "Prior"
    },
    {
        "key": "current",
        "header": "Current"
    },
    {
        "key": "norm",
        "header": "Norm"
    }
]
const ImagingServicesRow = [
    {
        "id": "1",
        "title": "Net Paid PMPM",
        "prior": "$1.28",
        "current": "+$1.46",
        "norm": "$135.00",
    },
    {
        "id": "2",
        "title": "Utils per 1000",
        "prior": "125.4",
        "current": "+132.0",
        "norm": "129.8",
    },
    {
        "id": "3",
        "title": "Average Paid per Util",
        "prior": "$122.39",
        "current": "+122.39",
        "norm": "$125.00",
    }

]

const ImagingServicesHeader = [
    {
        "key": "title",
        "header": ""
    },
    {
        "key": "prior",
        "header": "Prior"
    },
    {
        "key": "current",
        "header": "Current"
    },
    {
        "key": "norm",
        "header": "Norm"
    }
]


export {
    OutpatientSurgeryRow, OutpatientSurgeryHeader, LabServicesHeader, LabServicesRow, ImagingServicesRow, ImagingServicesHeader
}