import React, { useEffect, useState } from "react";
import { Tabs, Tab } from 'carbon-components-react';
import DemographicsDashboard from "./DemographicsDashboard";
import FinancialDashboard from "./FinancialDashboard";
import { OutpatientEvents } from "./OutpatientEvents";
import { EmergentCare } from "./EmergentCare";
import { InpatientAdmissions } from "./InpatientAdmissions";
import { BehavioralHealth } from "./BehavioralHealth";
import { PharmacyDashboard } from "./PharmacyDashboard";
import { PreventionAndCompliance } from "./PreventionAndCompliance";
import { HighCostClaimants } from "./HighCostClaimants";
import { ExecutiveSummaryDashboard } from "./ExecutiveSummaryDashboard";
import { useDispatch } from "react-redux";
import { actions } from "../../../redux/Dashboard/actions";

const SmallGroupAnnual = () => {
    const [selected, setSelected] = useState(0);
  const dispatch = useDispatch();

  const onTabClick = (index: number, name: string) => {
    setSelected(index);
    dispatch(actions.setActiveDashboardTab({ index, name }));
  }
  useEffect(() => {
    return () => {
      dispatch(actions.resetActiveDashboardTab())
    };
  }, [dispatch]);

    return (<Tabs selected={selected}>
      <Tab onClick={() => onTabClick(0, "Executive Summary")} id="tab-0" label="Executive Summary" className='storyboardTabs'>
        <ExecutiveSummaryDashboard />
      </Tab>
      <Tab onClick={() => onTabClick(1, "Financial")} id="tab-1" label="Financial" className='storyboardTabs'>
        <FinancialDashboard />
      </Tab>
      <Tab onClick={() => onTabClick(2, "Demographics")} id="tab-2" label="Demographics" className='storyboardTabs'>
        <DemographicsDashboard />
      </Tab>
      <Tab onClick={() => onTabClick(3, "Inpatient Admissions")} id="tab-3" label="Inpatient Admissions" className='storyboardTabs'>
        <InpatientAdmissions />
        </Tab>
      <Tab onClick={() => onTabClick(4, "Outpatient Events")} id="tab-4" label="Outpatient Events" className='storyboardTabs'>
          <OutpatientEvents />
        </Tab>
      <Tab onClick={() => onTabClick(5, "Physician Visits")} id="tab-5" label="Physician Visits" className='storyboardTabs'>
        <PharmacyDashboard />
        </Tab>
      <Tab onClick={() => onTabClick(6, "Emergent Care")} id="tab-6" label="Emergent Care" className='storyboardTabs'>
          <EmergentCare />
        </Tab>
      <Tab onClick={() => onTabClick(7, "Behavioral Health")} id="tab-7" label="Behavioral Health" className='storyboardTabs'>
        <BehavioralHealth />
        </Tab>
      {/* <Tab onClick={() => onTabClick(7, "Pharmacy")} id="tab-7" label="Pharmacy" className='storyboardTabs'>
        <PharmacyDashboard />
        </Tab> */}
      <Tab onClick={() => onTabClick(8, "Prevention & Compliance")} id="tab-8" label="Prevention & Compliance" className='storyboardTabs'>
        <PreventionAndCompliance />
        </Tab>
      <Tab onClick={() => onTabClick(9, "High Cost Claimants")} id="tab-9" label="High Cost Claimants" className='storyboardTabs'>
        <HighCostClaimants />
        </Tab>
      {/* <Tab
          onClick={() => setSelected(2)}
          id="tab-4"
          label="Care Management"
          title="Tab label 4 shows truncation">
          <p>Content for fourth tab goes here.</p>
        </Tab>
        <Tab label={<div>Custom Label</div>} onClick={() => setSelected(3)}>
          <p>Content for fifth tab goes here.</p>
        </Tab> */}
      </Tabs>);
}

export default SmallGroupAnnual;