import { Breadcrumb, BreadcrumbItem, Column, Row, Table, TableHead, TableHeader, TableRow, TableBody, TableCell, Button } from 'carbon-components-react';
import { Link } from 'react-router-dom';
import { ChevronDown20, DocumentExport20, Time20 } from '@carbon/icons-react';
import StyledLink from '../../SideNavigation/StyledLink';

export const HeaderBreadcrumb = () => (
  <Breadcrumb>
    <BreadcrumbItem><Link to="/#">Breadcrumb 1</Link></BreadcrumbItem>
    <BreadcrumbItem><Link to="#">Breadcrumb 2</Link></BreadcrumbItem>
  </Breadcrumb>
);

const EditGlyp = () => {
	return (
		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M8.4868 4.84778L9.15125 5.51222L2.60791 12.0556H1.94347V11.3911L8.4868 4.84778ZM11.0868 0.5C10.9062 0.5 10.7185 0.572222 10.5812 0.709444L9.25958 2.03111L11.9679 4.73944L13.2896 3.41778C13.5712 3.13611 13.5712 2.68111 13.2896 2.39944L11.5996 0.709444C11.4551 0.565 11.2746 0.5 11.0868 0.5ZM8.4868 2.80389L0.499023 10.7917V13.5H3.20736L11.1951 5.51222L8.4868 2.80389Z" fill="#0067B5"/>
		</svg>
	)
}

export const BasicTable = () => {
    const rows = [
      {
        id: 'load-balancer-1',
        Measures: '% of Total Membership',
        Pulaski: '24%',
        Benton: '12%',
        Sebastian: '14%',
        Washington: '15%',
        Faulkner: '17%',
        TotalPopulation: '100%'      
      },
      {
        id: 'load-balancer-2',
        Measures: '% of Total Spend',
        Pulaski: '29%',
        Benton: '18%',
        Sebastian: '13%',
        Washington: '11%',
        Faulkner: '12%',
        TotalPopulation: '100%'   
      },
      {
        id: 'load-balancer-3',
        Measures: 'Allowed PMPM',
        Pulaski: '$100.23',
        Benton: '$219.23',
        Sebastian: '$129.33',
        Washington: '$208.49',
        Faulkner: '$250.49',
        TotalPopulation: '$222.39'
      },
      {
        id: 'load-balancer-4',
        Measures: 'Paid PMPM',
        Pulaski: '$89.40',
        Benton: '$169.39',
        Sebastian: '$100.31',
        Washington: '$179.20',
        Faulkner: '$212.28',
        TotalPopulation: '$182.34'
      },
      {
        id: 'load-balancer-5',
        Measures: 'Admits per 1,000',
        Pulaski: '70.4',
        Benton: '61.3',
        Sebastian: '45.8',
        Washington: '50.2',
        Faulkner: '44.3',
        TotalPopulation: '52.4'
      },
	  {
        id: 'load-balancer-6',
        Measures: 'ED Cases per 1,000',
        Pulaski: '102.3',
        Benton: '204.5',
        Sebastian: '158.4',
        Washington: '260.4',
        Faulkner: '300.5',
        TotalPopulation: '198.5'
      },
	  {
        id: 'load-balancer-7',
        Measures: '% Chronic',
        Pulaski: '30%',
        Benton: '42%',
        Sebastian: '29%',
        Washington: '32%',
        Faulkner: '38%',
        TotalPopulation: '32%'
      },
	  {
        id: 'load-balancer-8',
        Measures: 'Top 3 Chronic Conditions',
        Pulaski: 'Cancer, COPD, Asthma',
        Benton: 'Diabetes, CAD, Psychosis',
        Sebastian: 'Dementia, Cancer, COPD',
        Washington: 'Liver Disease, Cancer, COPD',
        Faulkner: 'Dementia, COPD, Cancer',
        TotalPopulation: 'Cancer, Diabetes, COPD'
      },
    ];
    const headers = ['Measure', 'Pulaski', 'Benton', 'Sebastian', 'Washington', 'Faulkner', 'Total Population'];
  
    return (
      <Table className="CompareContrastTable">
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableHeader key={header}>{header}</TableHeader>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              {Object.entries(row)
                .filter(([key, value]) => key !== 'id')
                .map(([key, value]) => {
                        return <TableCell style={key === 'Measures' ? {backgroundColor: "#e8e9ea"} : {backgroundColor: "#FFFFFF"}} key={key}>{value}</TableCell>;
                })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };
  
export const CompareContrastReport = () => {
  return (
  <>
    <Row className="CompareContrastContainer" style={{height: "70vh", marginLeft: '48px', paddingBottom: "100px", backgroundColor: "#f9f9f9"}}>
        <Column sm={4} md={8} lg={10} style={{marginTop: "50px", paddingLeft: "100px", paddingRight: "50px"}}>
			<Row style={{color: "#0067B5", display: "flex", alignItems: "center", justifyContent: "flex-end", padding: "0px 17px 10px 0px"}}>
        <StyledLink to="/CompareContrast"><div style={{display: "flex", flexDirection: "row", cursor: "pointer"}}><span style={{display: "flex", alignItems: "center", paddingRight: "8px"}}><EditGlyp /></span>Edit Selections</div></StyledLink>
			</Row>
            <BasicTable />
        </Column>
        <Column>
        	<Row style={{marginTop: "57px", marginLeft: "20px"}}>
				<div style={{cursor: "pointer", paddingLeft: "15px", padding: "5px", display: "flex", width: "200px", height: "40px", backgroundColor: "white"}}>
				<Column style={{padding: "0", display: "flex", alignItems: "center"}} lg={2}><DocumentExport20 /></Column>
				<Column style={{padding: "0", display: "flex", alignItems: "center"}} lg={6}>Export Data</Column>
				<Column style={{padding: "0px 10px 0px 0px", display: "flex", alignItems: "center", justifyContent: "flex-end"}} lg={4}><ChevronDown20 /></Column>
				</div>
			</Row>
        </Column>
    </Row>
  </>
);
}

