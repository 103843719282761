import { Column, Row } from "carbon-components-react";
import StyledLink from "../SideNavigation/StyledLink";

export type RelatedTopic = {
    title: string;
    type: string;
    href: string;
};

export const ExploreRelatedTopics = ({ relatedTopics }: { relatedTopics: RelatedTopic[] }) => {
    // this returns the deleted elements (2nd half) and makes "relatedTopics" into the first half in place
    // this basicaly splits into 2 arrays for the 2 columns of the display
    const topics = JSON.parse(JSON.stringify(relatedTopics));
    var topicsSecondHalf = topics.splice(0, (Math.ceil(relatedTopics.length / 2)));

    return (
        <>
            <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Explore Related Topics</h3></Row>
            <Row id="tableLink" style={{marginLeft: "0px", maxWidth: '80%', marginBottom: '60px' }}>
                <Column style={{ padding: '0px', marginTop: '30px' }}>
                    <ul>
                        {topics.map((topic: RelatedTopic) =>
                        <li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
                            <StyledLink to={topic.href}>{topic.title}<span style={{ color: 'gray' }}> ({topic.type})</span>  </StyledLink>
                        </li>
                        )}
                    </ul>
                </Column>
                <Column style={{ padding: '0px', marginTop: '30px' }}>
                    <ul>
                        {topicsSecondHalf.map((topic: RelatedTopic) =>
                            <li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
                                <StyledLink to={topic.href}>{topic.title}<span style={{ color: 'gray' }}> ({topic.type})</span>  </StyledLink>
                            </li>
                        )}
                    </ul>
                </Column>
            </Row>
        </>
    );
}