import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import { Row, Grid, Column } from 'carbon-components-react';

/**
 * Helper function to create the table of contents page after the pdf has been rendered. Intended to be used in the
 * top most PDF component's useEffect() hook.
 * @param {string} containerName Id of the div that the PDF page will be put in
 * @param {string} docName Name of dashboard
 * @param {string} docCategory Dashboard's category 
 * @param {string} dateGenerated Date the dashboard was created
 */
function createTOC(containerName: string, docName: string, docCategory: string, dateGenerated: string) {
    const pages = document.getElementsByClassName("PDFContentPage");
    const tocElement = (        
        <PDFTOCPage 
            docName={docName} 
            docCategory={docCategory} 
            dateGenerated={dateGenerated} 
            pages={pages} />
    );
    const tocContainer = document.getElementById(containerName);
    ReactDOM.render(tocElement, tocContainer);
}

type PDFViewProps = {
    content: JSX.Element[]
}

/**
 * Unfished component. Idea was to have this be the top component in the /pdf route that way the pdf
 * content could be dynamically changed based on which page is being exported. PDFView would included
 * both the title page and table of contents page which would be dynamically filled in based on the content
 * pages. Might make more sense to have content be apart of the components state rather than its props. By
 * just passing in PdfContentPages, without a title and TOC page, bundled pdfs could also be implemented.
 * @param {JSX.Element[]} content Content pages that you want included in the final pdf
 */
const PDFView: FunctionComponent<PDFViewProps> = ({content}) => {
    return (
        <div id="PDFContainer">
            {content.map( contentPage => contentPage)}
        </div>
    );
}

type PDFPageProps = {
    className: string,
    children?: React.ReactNode
}

/**
 * Most general component, used only to define the proper size of an exported pdf page. Intended to be more
 * specified by other components such as PDFContentPage or PDFTitlePage.
 * @param {string} className Class name of the component. Used by more specific components to distinguish types of pages
 * @param {React.ReactNode} children Child components of the PDFPage
 */
const PDFPage: FunctionComponent<PDFPageProps> = ({className, children}) => {
    return(
        <div className={className} style={{width: "825px", height: "1067px", paddingLeft: "3rem", paddingRight: "3rem", paddingTop: "2rem", paddingBottom: "2rem", display: "flex", flexDirection: "column"}}>
            {children}
        </div>
    );
}

type PDFContentPageProps = {
    pageNum: number,
    docName: string,
    docCategory: string,
    dateGenerated: string,
    children: JSX.Element
}

/**
 * PDF page component for the internal content pages. Has both a header and footer component for all
 * pages whih contain reportlets.
 * @param {number} pageNum Page number for the current page
 * @param {string} docName Name of the dashboard
 * @param {string} docCategory Dashboard's category
 * @param {string} docGenerated The date the dashboard was created
 * @param {JSX.Element} children The children components consisting of content for the page
 */
const PDFContentPage: FunctionComponent<PDFContentPageProps> = ({pageNum, docName, docCategory, dateGenerated, children}) => {
    return (
        <PDFPage className="PDFContentPage">
            <div style={{display: "inline-block", marginBottom: "1rem", marginLeft: "5px"}}>
                <pre style={{fontSize: "12px", display: "inline-block"}}>{docCategory} / {docName}    Date report generated: </pre>
                <pre style={{fontSize: "12px", fontWeight: "bold", display: "inline-block"}}>{dateGenerated}</pre>
            </div>
            {children}
            <PDFFooter docName={docName} docCategory={docCategory} pageNum={pageNum} />
        </PDFPage>
    );
}

type TitlePageProps = {
    docName: string,
    docCategory: string,
    dateGenerated: string
}

/**
 * PDF page component for the title page.
 * @param {string} docName Name of the dashboard
 * @param {string} docCategory Dashboard's category
 * @param {string} docGenerated The date the dashboard was created
 */
const PDFTitlePage: FunctionComponent<TitlePageProps> = ({docName, docCategory, dateGenerated}) => {
    return <PDFPage className="PDFTitlePage">
        <Grid>
            <Row style={{marginTop: "96px"}}>
                <Column style={{display: "flex", justifyContent: "space-evenly"}}>
                    <div style={{border: "1px dotted black", width: "250px", height: "250px"}}>
                        <p style={{textAlign: "center", lineHeight: "125px"}}>Client Logo</p>
                    </div>
                </Column>
            </Row>
        </Grid>
        <div style={{width: "100%", height: "100%", position: "relative"}}>
            <div style={{width: "100%", position: "absolute", bottom: "0", left: "0"}}>
                <div style={{marginTop: "64px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="12" viewBox="0 0 53 12" fill="none">
                        <path d="M0 11.756H1.03937V1.96068H1.07087C1.32283 2.79533 1.51181 3.28352 1.79528 4.03942L4.8189 11.756H5.84252L8.86614 4.03942C9.14961 3.28352 9.43307 2.51187 9.59055 1.99218H9.62205V11.756H10.7402V0.102417H9.25984L5.38583 10.3071H5.35433L1.44882 0.102417H0V11.756Z" fill="#727A7D"/>
                        <path d="M12.7574 1.53549H13.907V0.370132H12.7574V1.53549ZM12.7889 11.756H13.8755V3.45675H12.7889V11.756Z" fill="#727A7D"/>
                        <path d="M15.7847 11.756H16.8713V0.102417H15.7847V11.756Z" fill="#727A7D"/>
                        <path d="M18.7805 11.756H19.8671V0.102417H18.7805V11.756Z" fill="#727A7D"/>
                        <path d="M21.7448 1.53549H22.8944V0.370132H21.7448V1.53549ZM21.7763 11.756H22.8629V3.45675H21.7763V11.756Z" fill="#727A7D"/>
                        <path d="M24.6777 11.756H25.7643V5.67722C26.6934 4.77958 27.5438 4.2284 28.5359 4.2284C29.717 4.2284 30.1107 4.79533 30.1107 5.96068V11.756H31.1973V5.67722C32.1265 4.77958 32.9611 4.2284 33.969 4.2284C35.1344 4.2284 35.5281 4.79533 35.5281 5.96068V11.756H36.6147V5.75596C36.6147 4.2599 35.9218 3.29927 34.221 3.29927C32.9926 3.29927 31.9533 3.97643 31.0871 4.79533C30.8351 3.91344 30.2052 3.29927 28.8036 3.29927C27.5595 3.29927 26.5989 3.94494 25.7643 4.76383V3.45675H24.6777V11.756Z" fill="#727A7D"/>
                        <path d="M40.3483 11.0001C39.4192 11.0001 38.8365 10.5434 38.8365 9.67722C38.8365 8.59061 39.8286 7.70872 43.3877 7.11029V9.69297C42.5373 10.5119 41.5137 11.0001 40.3483 11.0001ZM40.1121 11.8977C41.3562 11.8977 42.427 11.441 43.3877 10.5749C43.3877 11.0473 43.4664 11.4095 43.5766 11.756H44.6948C44.5215 11.3308 44.4585 10.7166 44.4585 9.70872V5.94494C44.4585 4.29139 43.6554 3.29927 41.7971 3.29927C40.1593 3.29927 39.0097 3.92919 38.0176 4.84257L38.6003 5.51974C39.4664 4.76383 40.3955 4.21265 41.7026 4.21265C42.7892 4.21265 43.3877 4.76383 43.3877 5.91344V6.33864C39.1672 6.98431 37.7656 8.18116 37.7656 9.81895C37.7656 11.1103 38.6318 11.8977 40.1121 11.8977Z" fill="#727A7D"/>
                        <path d="M46.0944 11.756H47.181V5.67722C48.1101 4.77958 49.0078 4.2284 50.0786 4.2284C51.2125 4.2284 51.7322 4.79533 51.7322 5.96068V11.756H52.8188V5.75596C52.8188 4.21265 51.9841 3.29927 50.3463 3.29927C49.0392 3.29927 47.9841 3.97643 47.181 4.76383V3.45675H46.0944V11.756Z" fill="#727A7D"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="81" height="16" viewBox="0 0 81 16" fill="none">
                        <path d="M1.87401 12.7559V3.98425H1.90551C2.15747 5.10236 2.3307 5.6063 2.61417 6.44095L4.86614 12.7559H6.61417L8.88188 6.44095C9.1496 5.62205 9.40157 4.86614 9.5748 4.06299H9.60629V12.7559H11.5118V1.10236H8.86614L5.84251 10.3465H5.81102L2.69291 1.10236H0.0944824V12.7559H1.87401Z" fill="#0080E2"/>
                        <path d="M16.6863 12.9291C18.1823 12.9291 19.3319 12.378 20.1981 11.4173L19.1745 10.4094C18.5445 11.0236 17.9146 11.3858 16.828 11.3858C15.5996 11.3858 14.6705 10.7244 14.5918 9.2126H20.3083V8.75591C20.3083 5.35433 18.513 4.23622 16.639 4.23622C14.9067 4.23622 12.6548 5.40157 12.6548 8.58268C12.6548 11.6535 14.5918 12.9291 16.6863 12.9291ZM14.6233 7.76378C14.8122 6.34646 15.6784 5.81102 16.6233 5.81102C17.7886 5.81102 18.4185 6.48819 18.5288 7.76378H14.6233Z" fill="#0080E2"/>
                        <path d="M24.7091 11.2913C23.4651 11.2913 22.7091 10.4094 22.7091 8.64567C22.7091 6.7874 23.3076 5.84252 24.7721 5.84252C25.4651 5.84252 26.221 6.20472 26.7406 6.88189V10.2362C26.284 10.7874 25.5123 11.2913 24.7091 11.2913ZM24.0792 12.8819C25.3233 12.8819 26.1265 12.3307 26.7406 11.622C26.7406 12.0472 26.8194 12.5197 26.9296 12.7559H28.9296C28.7406 12.3307 28.6777 11.7165 28.6777 10.7087V1.10236H26.7406V5.29134C26.1265 4.59842 25.2446 4.23622 24.3469 4.23622C22.2052 4.23622 20.7721 5.70079 20.7721 8.59842C20.7721 11.4961 22.1737 12.8819 24.0792 12.8819Z" fill="#0080E2"/>
                        <path d="M32.4151 12.7559V1.10236H30.4308V12.7559H32.4151Z" fill="#0080E2"/>
                        <path d="M36.1091 12.7559V6.92913C36.8178 6.26772 37.5264 5.84252 38.3768 5.84252C39.2587 5.84252 39.6367 6.31496 39.6367 7.22835V12.7559H41.5737V6.67717C41.5737 5.02362 40.6288 4.23622 39.1012 4.23622C37.7154 4.23622 36.739 5.00787 36.1091 5.73228V4.3937H34.1721V12.7559H36.1091Z" fill="#0080E2"/>
                        <path d="M45.9129 12.9291C47.8184 12.9291 49.4089 12.1102 49.4089 10.3465C49.4089 8.3937 47.6609 8.0315 46.2278 7.71654C45.2357 7.49606 44.4011 7.29134 44.4011 6.61417C44.4011 6.14173 44.7475 5.76378 45.8184 5.76378C46.5743 5.76378 47.5664 6.17323 48.1806 6.67717L49.1097 5.41732C48.2436 4.69291 47.2672 4.23622 45.6767 4.23622C43.8341 4.23622 42.5743 5.22835 42.5743 6.69291C42.5743 8.62992 44.2908 9.00787 45.7239 9.33858C46.7318 9.5748 47.5822 9.77953 47.5822 10.4567C47.5822 11.1496 46.9523 11.4173 45.9129 11.4173C44.842 11.4173 43.9129 10.9606 43.283 10.4252L42.2278 11.6063C42.9838 12.378 44.3696 12.9291 45.9129 12.9291Z" fill="#0080E2"/>
                        <path d="M52.3607 2.94488V1.25984H50.3765V2.94488H52.3607ZM52.345 12.7559V4.3937H50.3922V12.7559H52.345Z" fill="#0080E2"/>
                        <path d="M57.0137 8.86614C55.9428 8.86614 55.2971 8.31496 55.2971 7.29134C55.2971 6.26772 55.927 5.68504 56.9979 5.68504C58.1003 5.68504 58.6987 6.25197 58.6987 7.29134C58.6987 8.31496 58.0688 8.86614 57.0137 8.86614ZM56.9664 16C59.5491 16 61.2814 15.1654 61.2814 13.4488C61.2814 11.9843 60.305 11.3228 57.8326 11.1654L56.3522 11.0709C55.6121 11.0079 55.3916 10.8976 55.3916 10.5669C55.3916 10.3465 55.5648 10.1732 55.8168 10.0315C56.2262 10.1417 56.5097 10.2047 56.9822 10.2047C59.1554 10.2047 60.4625 8.97638 60.4625 7.22835C60.4625 6.7874 60.3522 6.3937 60.1633 6.09449C60.7144 5.92126 61.2499 5.87402 61.6278 5.87402V4.29921C60.9349 4.3622 60.0845 4.69291 59.5491 5.11811C58.9822 4.61417 58.1318 4.23622 56.9822 4.23622C54.7932 4.23622 53.4703 5.51181 53.4703 7.22835C53.4703 8.25197 53.9428 9.10236 54.7932 9.63779C54.1633 10.0157 53.6593 10.5354 53.6593 11.1654C53.6593 11.622 53.8798 11.9685 54.305 12.189C53.4546 12.5197 52.9349 13.1181 52.9349 13.9528C52.9349 15.1654 54.0845 16 56.9664 16ZM57.2656 14.5984C55.5648 14.5984 54.7144 14.3622 54.7144 13.622C54.7144 13.1968 54.9349 12.8032 55.4861 12.5512C55.6593 12.5827 55.8168 12.5984 55.99 12.5984L57.4389 12.6929C59.1396 12.8032 59.5648 13.0236 59.5648 13.622C59.5648 14.2205 58.6829 14.5984 57.2656 14.5984Z" fill="#0080E2"/>
                        <path d="M64.2249 12.7559V6.97638C64.9336 6.33071 65.6422 5.88976 66.4926 5.88976C67.343 5.88976 67.7367 6.31496 67.7367 7.24409V12.7559H69.6895V6.75591C69.6895 5.10236 68.7446 4.23622 67.28 4.23622C65.7682 4.23622 64.7603 4.96063 64.2249 5.52756V1.10236H62.2721V12.7559H64.2249Z" fill="#0080E2"/>
                        <path d="M74.2176 12.8661C74.7058 12.8661 75.4302 12.8032 75.635 12.7559V11.2283C75.446 11.2913 75.0838 11.3386 74.8003 11.3386C73.8397 11.3386 73.572 10.9764 73.572 10.0787V5.93701H75.7294V4.3937H73.572V1.48032H71.635V4.3937H70.1861V5.93701H71.635V10.5669C71.635 12.0787 72.0917 12.8661 74.2176 12.8661Z" fill="#0080E2"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M77.9055 0.314961C76.9053 0.314961 76.0945 1.12578 76.0945 2.12598C76.0945 3.12618 76.9053 3.93701 77.9055 3.93701C78.9057 3.93701 79.7165 3.12618 79.7165 2.12598C79.7165 1.12578 78.9057 0.314961 77.9055 0.314961ZM75.7795 2.12598C75.7795 0.951836 76.7313 0 77.9055 0C79.0796 0 80.0315 0.951836 80.0315 2.12598C80.0315 3.30013 79.0796 4.25197 77.9055 4.25197C76.7313 4.25197 75.7795 3.30013 75.7795 2.12598Z" fill="#0080E2"/>
                        <path d="M77.4686 1.33542H77.897C78.2308 1.33542 78.4041 1.41731 78.4041 1.68502C78.4041 1.96219 78.2277 2.04408 77.9001 2.04408H77.4686V1.33542ZM77.4686 3.30707V2.38738H77.8781L78.4009 3.30707H78.823L78.2749 2.33699C78.5993 2.25825 78.7915 2.04723 78.7915 1.67557C78.7915 1.19998 78.4702 0.976361 77.9159 0.976361H77.0812V3.30707H77.4686Z" fill="#0080E2"/>
                    </svg>
                </div>
                <div style={{marginTop: "15px"}}>
                    <p>{docCategory}</p>
                </div>
                <div>
                    <h1 style={{fontSize: "96px", color: "#004877"}}>{docName}</h1>
                </div>
                <div>
                    <div style={{backgroundColor: "#004877", width: "100%", height: "58px", display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column", padding: "16px"}}>
                        <p style={{color: "white", fontSize: "20px", lineHeight: "26px"}}>Date report generated: {dateGenerated}</p>
                    </div>
                </div>
                <div style={{width: "100%", marginTop: "1rem"}}>
                    <div style={{ width: "100%"}}>
                        <p style={{display: "inline-block", float: "left", fontSize: "14px"}}>Copyright © 2021 Milliman, Inc. All Rights Reserved.</p>
                        <p style={{display: "inline-block", float: "right", fontSize: "14px"}}>{docCategory}: {docName}</p>
                    </div>
                </div>
            </div>
        </div>
    </PDFPage>
}

type TOCPageProps = {
    docName: string,
    docCategory: string,
    dateGenerated: string,
    pages: HTMLCollectionOf<Element>
};

/**
 * PDF page component for the TOC page.
 * @param {string} docName Name of the dashboard
 * @param {string} docCategory Dashboard's category
 * @param {string} docGenerated The date the dashboard was created
 * @param {HTMLCollectionOf<Element>} pages All of the ContentPages in the PDF document 
 */
const PDFTOCPage: FunctionComponent<TOCPageProps> = ({docName, docCategory, dateGenerated, pages}) => {
    return (
        <PDFPage className="PDFTocPage">
            <div style={{marginTop: "1rem"}}>
                <p style={{fontSize: "1.5rem", color: "#616771"}}>{docCategory}: {docName}</p>
            </div>
            <Grid style={{marginTop: "3rem"}}>
                <Row>
                    <TableOfContents dashboardName={docName} pages={pages} />
                </Row>
            </Grid>
            <div style={{width: "100%", height: "100%", position: "relative"}}>
                <div style={{width: "100%", position: "absolute", bottom: "0"}}>
                    <div style={{backgroundColor: "#004877", width: "100%", height: "58px", display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column", padding: "16px"}}>
                        <p style={{color: "white", fontSize: "20px", lineHeight: "26px"}}>Date report generated: {dateGenerated}</p>
                    </div>
                    <div style={{marginTop: "1rem", display: "flex", justifyContent: "space-between"}}>
                        <div>
                            <MilliMILogo/>
                            <p style={{fontSize: "12px", display:"inline-block"}}>Copyright © 2021 Milliman, Inc. All Rights Reserved.</p>
                        </div>
                        <p style={{fontSize: "12px"}}>{docCategory}: {docName} Storyboard</p>
                    </div>
                </div>
            </div>
        </PDFPage>
    );
};

type TableOfContentsProps = {
    dashboardName: string,
    pages: HTMLCollectionOf<Element>
}

/**
 * Represents the main body of the table of contents. Gets each section and its pagenumber dynamically
 * based on the IDs of the elements on the page.
 * ID Formatt: "TOC:{desired name of section}"
 * Example: "TOC:COVID-19 Trends"
 * @param {string} dashboardName Name of the dashboard, displayed below the "Table of Contents"
 * @param {HTMLCollectionOf<Element>} pages All of the ContentPages in the PDF document
 */
const TableOfContents: FunctionComponent<TableOfContentsProps> = ({dashboardName, pages}) => {

    let pageArr = Array.from(pages);
    let tocElements: {name: string, pageNum: number[]}[] = [];
    for (let pageNum = 0; pageNum < pageArr.length; pageNum++) {
        let elementsOnPage = Array.from(pageArr[pageNum].querySelectorAll('*[id^="TOC:"]'));
        elementsOnPage.forEach(element => {
            let name = element.id;
            name = name.slice(name.indexOf(":") + 1);
            const ind = tocElements.map((element) => element.name).indexOf(name);
            // Catch categories that go over multiple pages
            if (ind !== -1) {
                tocElements[ind].pageNum.push(pageNum+2);
            }
            else {
                tocElements.push({name: name, pageNum: [pageNum+2]})
            }
        });
    }

    return (
        <Column style={{width:"400px"}}>
            <Row>
                <p style={{fontSize: "48px", fontWeight: "lighter", color: "#004877"}}>Table of Contents</p>
            </Row>
            <Row style={{marginTop: "1rem"}}>
                <p style={{fontSize: "24px", fontWeight: "bold"}}>{dashboardName}</p>
            </Row>
            {tocElements.map((val) => {
                return (
                <Row style={{display:"flex", justifyContent:"space-between", marginTop: ".5rem"}}>
                        <p style={{}}>{val.name}</p>
                        <p style={{}}>{val.pageNum.join(", ")}</p>
                </Row>)
            })}
        </Column>
    );
}

type FooterProps = {
    docName: string,
    docCategory: string,
    pageNum: number
}

/**
 * PDF footer component, sits at the very bottom of the pdf page.
 * @param {string} docName Name of the dashboard
 * @param {string} docCategory Dashboard's category
 * @param {number} pageNum Page number for the current page
 */
const PDFFooter: FunctionComponent<FooterProps> = ({docName, docCategory, pageNum}) => {
    return <div className="footer" style={{width: "100%", height: "100%", position: "relative"}}>
        <div style={{position: "absolute", bottom: "0", width:"100%"}}>
            <div style={{float: "left", display:"inline-block"}}>
                <MilliMILogo/>
                <p style={{fontSize: "12px", display:"inline-block"}}>Copyright © 2021 Milliman, Inc. All Rights Reserved.</p>
            </div>
            <p style={{float: "right", fontSize: "12px", display:"inline-block"}}>{docCategory}: {docName} Page {pageNum}</p>
        </div>
    </div>
}

/**
 * SVG for the Milliman Medinsight logo displayed on the footer component
 */
const MilliMILogo: FunctionComponent = () => {
    return (
        <div style={{display:"inline-block"}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path d="M15.9991 0H11.4893V4.51067H15.9991V0Z" fill="#727A7D"/>
                <path d="M11.4893 11.5875V4.54858L15.5724 8.66693C15.8284 8.94169 15.9795 9.29737 16 9.67231V16.1348H0V0H6.40871C6.7818 0.0210873 7.13538 0.173931 7.40676 0.430877L11.4893 4.54858L4.51072 4.54793V11.5869H11.4899L11.4893 11.5875Z" fill="#727A7D"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="53" height="12" viewBox="0 0 53 12" fill="none">
                <path d="M0 11.756H1.03937V1.96068H1.07087C1.32283 2.79533 1.51181 3.28352 1.79528 4.03942L4.8189 11.756H5.84252L8.86614 4.03942C9.14961 3.28352 9.43307 2.51187 9.59055 1.99218H9.62205V11.756H10.7402V0.102417H9.25984L5.38583 10.3071H5.35433L1.44882 0.102417H0V11.756Z" fill="#727A7D"/>
                <path d="M12.7574 1.53549H13.907V0.370132H12.7574V1.53549ZM12.7889 11.756H13.8755V3.45675H12.7889V11.756Z" fill="#727A7D"/>
                <path d="M15.7847 11.756H16.8713V0.102417H15.7847V11.756Z" fill="#727A7D"/>
                <path d="M18.7805 11.756H19.8671V0.102417H18.7805V11.756Z" fill="#727A7D"/>
                <path d="M21.7448 1.53549H22.8944V0.370132H21.7448V1.53549ZM21.7763 11.756H22.8629V3.45675H21.7763V11.756Z" fill="#727A7D"/>
                <path d="M24.6777 11.756H25.7643V5.67722C26.6934 4.77958 27.5438 4.2284 28.5359 4.2284C29.717 4.2284 30.1107 4.79533 30.1107 5.96068V11.756H31.1973V5.67722C32.1265 4.77958 32.9611 4.2284 33.969 4.2284C35.1344 4.2284 35.5281 4.79533 35.5281 5.96068V11.756H36.6147V5.75596C36.6147 4.2599 35.9218 3.29927 34.221 3.29927C32.9926 3.29927 31.9533 3.97643 31.0871 4.79533C30.8351 3.91344 30.2052 3.29927 28.8036 3.29927C27.5595 3.29927 26.5989 3.94494 25.7643 4.76383V3.45675H24.6777V11.756Z" fill="#727A7D"/>
                <path d="M40.3483 11.0001C39.4192 11.0001 38.8365 10.5434 38.8365 9.67722C38.8365 8.59061 39.8286 7.70872 43.3877 7.11029V9.69297C42.5373 10.5119 41.5137 11.0001 40.3483 11.0001ZM40.1121 11.8977C41.3562 11.8977 42.427 11.441 43.3877 10.5749C43.3877 11.0473 43.4664 11.4095 43.5766 11.756H44.6948C44.5215 11.3308 44.4585 10.7166 44.4585 9.70872V5.94494C44.4585 4.29139 43.6554 3.29927 41.7971 3.29927C40.1593 3.29927 39.0097 3.92919 38.0176 4.84257L38.6003 5.51974C39.4664 4.76383 40.3955 4.21265 41.7026 4.21265C42.7892 4.21265 43.3877 4.76383 43.3877 5.91344V6.33864C39.1672 6.98431 37.7656 8.18116 37.7656 9.81895C37.7656 11.1103 38.6318 11.8977 40.1121 11.8977Z" fill="#727A7D"/>
                <path d="M46.0944 11.756H47.181V5.67722C48.1101 4.77958 49.0078 4.2284 50.0786 4.2284C51.2125 4.2284 51.7322 4.79533 51.7322 5.96068V11.756H52.8188V5.75596C52.8188 4.21265 51.9841 3.29927 50.3463 3.29927C49.0392 3.29927 47.9841 3.97643 47.181 4.76383V3.45675H46.0944V11.756Z" fill="#727A7D"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="81" height="16" viewBox="0 0 81 16" fill="none">
                <path d="M1.87401 12.7559V3.98425H1.90551C2.15747 5.10236 2.3307 5.6063 2.61417 6.44095L4.86614 12.7559H6.61417L8.88188 6.44095C9.1496 5.62205 9.40157 4.86614 9.5748 4.06299H9.60629V12.7559H11.5118V1.10236H8.86614L5.84251 10.3465H5.81102L2.69291 1.10236H0.0944824V12.7559H1.87401Z" fill="#0080E2"/>
                <path d="M16.6863 12.9291C18.1823 12.9291 19.3319 12.378 20.1981 11.4173L19.1745 10.4094C18.5445 11.0236 17.9146 11.3858 16.828 11.3858C15.5996 11.3858 14.6705 10.7244 14.5918 9.2126H20.3083V8.75591C20.3083 5.35433 18.513 4.23622 16.639 4.23622C14.9067 4.23622 12.6548 5.40157 12.6548 8.58268C12.6548 11.6535 14.5918 12.9291 16.6863 12.9291ZM14.6233 7.76378C14.8122 6.34646 15.6784 5.81102 16.6233 5.81102C17.7886 5.81102 18.4185 6.48819 18.5288 7.76378H14.6233Z" fill="#0080E2"/>
                <path d="M24.7091 11.2913C23.4651 11.2913 22.7091 10.4094 22.7091 8.64567C22.7091 6.7874 23.3076 5.84252 24.7721 5.84252C25.4651 5.84252 26.221 6.20472 26.7406 6.88189V10.2362C26.284 10.7874 25.5123 11.2913 24.7091 11.2913ZM24.0792 12.8819C25.3233 12.8819 26.1265 12.3307 26.7406 11.622C26.7406 12.0472 26.8194 12.5197 26.9296 12.7559H28.9296C28.7406 12.3307 28.6777 11.7165 28.6777 10.7087V1.10236H26.7406V5.29134C26.1265 4.59842 25.2446 4.23622 24.3469 4.23622C22.2052 4.23622 20.7721 5.70079 20.7721 8.59842C20.7721 11.4961 22.1737 12.8819 24.0792 12.8819Z" fill="#0080E2"/>
                <path d="M32.4151 12.7559V1.10236H30.4308V12.7559H32.4151Z" fill="#0080E2"/>
                <path d="M36.1091 12.7559V6.92913C36.8178 6.26772 37.5264 5.84252 38.3768 5.84252C39.2587 5.84252 39.6367 6.31496 39.6367 7.22835V12.7559H41.5737V6.67717C41.5737 5.02362 40.6288 4.23622 39.1012 4.23622C37.7154 4.23622 36.739 5.00787 36.1091 5.73228V4.3937H34.1721V12.7559H36.1091Z" fill="#0080E2"/>
                <path d="M45.9129 12.9291C47.8184 12.9291 49.4089 12.1102 49.4089 10.3465C49.4089 8.3937 47.6609 8.0315 46.2278 7.71654C45.2357 7.49606 44.4011 7.29134 44.4011 6.61417C44.4011 6.14173 44.7475 5.76378 45.8184 5.76378C46.5743 5.76378 47.5664 6.17323 48.1806 6.67717L49.1097 5.41732C48.2436 4.69291 47.2672 4.23622 45.6767 4.23622C43.8341 4.23622 42.5743 5.22835 42.5743 6.69291C42.5743 8.62992 44.2908 9.00787 45.7239 9.33858C46.7318 9.5748 47.5822 9.77953 47.5822 10.4567C47.5822 11.1496 46.9523 11.4173 45.9129 11.4173C44.842 11.4173 43.9129 10.9606 43.283 10.4252L42.2278 11.6063C42.9838 12.378 44.3696 12.9291 45.9129 12.9291Z" fill="#0080E2"/>
                <path d="M52.3607 2.94488V1.25984H50.3765V2.94488H52.3607ZM52.345 12.7559V4.3937H50.3922V12.7559H52.345Z" fill="#0080E2"/>
                <path d="M57.0137 8.86614C55.9428 8.86614 55.2971 8.31496 55.2971 7.29134C55.2971 6.26772 55.927 5.68504 56.9979 5.68504C58.1003 5.68504 58.6987 6.25197 58.6987 7.29134C58.6987 8.31496 58.0688 8.86614 57.0137 8.86614ZM56.9664 16C59.5491 16 61.2814 15.1654 61.2814 13.4488C61.2814 11.9843 60.305 11.3228 57.8326 11.1654L56.3522 11.0709C55.6121 11.0079 55.3916 10.8976 55.3916 10.5669C55.3916 10.3465 55.5648 10.1732 55.8168 10.0315C56.2262 10.1417 56.5097 10.2047 56.9822 10.2047C59.1554 10.2047 60.4625 8.97638 60.4625 7.22835C60.4625 6.7874 60.3522 6.3937 60.1633 6.09449C60.7144 5.92126 61.2499 5.87402 61.6278 5.87402V4.29921C60.9349 4.3622 60.0845 4.69291 59.5491 5.11811C58.9822 4.61417 58.1318 4.23622 56.9822 4.23622C54.7932 4.23622 53.4703 5.51181 53.4703 7.22835C53.4703 8.25197 53.9428 9.10236 54.7932 9.63779C54.1633 10.0157 53.6593 10.5354 53.6593 11.1654C53.6593 11.622 53.8798 11.9685 54.305 12.189C53.4546 12.5197 52.9349 13.1181 52.9349 13.9528C52.9349 15.1654 54.0845 16 56.9664 16ZM57.2656 14.5984C55.5648 14.5984 54.7144 14.3622 54.7144 13.622C54.7144 13.1968 54.9349 12.8032 55.4861 12.5512C55.6593 12.5827 55.8168 12.5984 55.99 12.5984L57.4389 12.6929C59.1396 12.8032 59.5648 13.0236 59.5648 13.622C59.5648 14.2205 58.6829 14.5984 57.2656 14.5984Z" fill="#0080E2"/>
                <path d="M64.2249 12.7559V6.97638C64.9336 6.33071 65.6422 5.88976 66.4926 5.88976C67.343 5.88976 67.7367 6.31496 67.7367 7.24409V12.7559H69.6895V6.75591C69.6895 5.10236 68.7446 4.23622 67.28 4.23622C65.7682 4.23622 64.7603 4.96063 64.2249 5.52756V1.10236H62.2721V12.7559H64.2249Z" fill="#0080E2"/>
                <path d="M74.2176 12.8661C74.7058 12.8661 75.4302 12.8032 75.635 12.7559V11.2283C75.446 11.2913 75.0838 11.3386 74.8003 11.3386C73.8397 11.3386 73.572 10.9764 73.572 10.0787V5.93701H75.7294V4.3937H73.572V1.48032H71.635V4.3937H70.1861V5.93701H71.635V10.5669C71.635 12.0787 72.0917 12.8661 74.2176 12.8661Z" fill="#0080E2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M77.9055 0.314961C76.9053 0.314961 76.0945 1.12578 76.0945 2.12598C76.0945 3.12618 76.9053 3.93701 77.9055 3.93701C78.9057 3.93701 79.7165 3.12618 79.7165 2.12598C79.7165 1.12578 78.9057 0.314961 77.9055 0.314961ZM75.7795 2.12598C75.7795 0.951836 76.7313 0 77.9055 0C79.0796 0 80.0315 0.951836 80.0315 2.12598C80.0315 3.30013 79.0796 4.25197 77.9055 4.25197C76.7313 4.25197 75.7795 3.30013 75.7795 2.12598Z" fill="#0080E2"/>
                <path d="M77.4686 1.33542H77.897C78.2308 1.33542 78.4041 1.41731 78.4041 1.68502C78.4041 1.96219 78.2277 2.04408 77.9001 2.04408H77.4686V1.33542ZM77.4686 3.30707V2.38738H77.8781L78.4009 3.30707H78.823L78.2749 2.33699C78.5993 2.25825 78.7915 2.04723 78.7915 1.67557C78.7915 1.19998 78.4702 0.976361 77.9159 0.976361H77.0812V3.30707H77.4686Z" fill="#0080E2"/>
            </svg>
        </div>
    )
}

export {PDFPage, PDFContentPage, PDFTOCPage, PDFTitlePage, PDFFooter, PDFView, createTOC}