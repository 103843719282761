import React from 'react';
import { Form, FormGroup, Select, SelectItem } from 'carbon-components-react';
import FilterList from '../../data/Filters/FilterList.json'
import FiltersReview from '../Other/FiltersReview';

const DashboardFilters = () => {
    return (
        <Form style={{ background: "white", flexBasis: "100%"}}>
            <div id = "sidePanelFilter">
            <FormGroup legendText="" style={{ marginTop: "5px", marginBottom: "10px", float: "left", width: "100%"}}>
                <h5 style={{paddingBottom: "20px"}}>Site Filters</h5>
                <FiltersReview />
                <div className = "lineSeparator"></div>

                <div id="pageLevelFilters">
                    <h6>Page Level Filters</h6>
                    <Select id="1" className="Dashboard-Filters" labelText={"Location"}>
                        <SelectItem key={1} text="ALL" value="ALL" />
                    </Select>
                    <Select id="2" className="Dashboard-Filters" labelText={"Member Relationship" }>
                        <SelectItem key={1} text="ALL" value="ALL" />
                        <SelectItem key={1} text="SUBSCRIBER" value="SUBSCRIBER" />
                        <SelectItem key={1} text="SPOUSE" value="SPOUSE" />
                        <SelectItem key={1} text="DEPENDENT" value="DEPENDENT" />
                    </Select>
                    <Select id="3" className="Dashboard-Filters" labelText={"Plan"}>
                        <SelectItem key={1} text="ALL" value="ALL" />
                    </Select>
                    <Select id="4" className="Dashboard-Filters" labelText={"Group UDD"}>
                        {FilterList.map((item, index) => <SelectItem key={index} text={item.name} value={item.id} />)}
                    </Select>
                    <Select id="5" className="Dashboard-Filters" labelText={"Care Management Engagement"}>
                        <SelectItem key={1} text="ALL" value="ALL" />
                    </Select>
                </div>
                <div id = "refreshPageFilter">
                    <svg width="14" height="14" style={{float: "left"}} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.29166 2.29183C3.49583 1.0835 5.15833 0.333496 6.99999 0.333496C10.6833 0.333496 13.6583 3.31683 13.6583 7.00016C13.6583 10.6835 10.6833 13.6668 6.99999 13.6668C3.89583 13.6668 1.29583 11.5418 0.558327 8.66683H2.29166C2.97916 10.6085 4.82083 12.0002 6.99999 12.0002C9.76249 12.0002 12 9.76266 12 7.00016C12 4.23766 9.76249 2.00016 6.99999 2.00016C5.62083 2.00016 4.38333 2.57516 3.47916 3.47933L6.16666 6.16683H0.333326V0.333496L2.29166 2.29183Z" fill="#0080E2"/>
                    </svg>
                    <p>Reset Page-Level Filters</p>
                </div>
            </FormGroup>
            </div>
        </Form>
    );
}

export default DashboardFilters;