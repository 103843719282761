import { ScaleTypes } from '@carbon/charts/interfaces/enums';

export const simpleBarChart1 = {
    data: [
      {
        "group": "Prior",
        "value": 3
      },
      {
        "group": "Current",
        "value": 3.5
      },
      {
        "group": "Norm",
        "value": 4.5
      }
    ],
    options: {
      "title": "",
      "axes": {
        "left": {
          "mapsTo": "value",
          "ticks": {
            "values": [
              1,
              2,
              3,
              4,
              5
            ]
          }
        },
        "bottom": {
          "mapsTo": "group",
          "scaleType": ScaleTypes.LABELS
        }
      },
      "height": "400px",
      "data": {
        "loading": false
      },
      "color": {
        "scale": {
            "Prior": "#50BEE1",
            "Current": "#004877",
            "Norm": "#FFC800"
        }
    }
    }
  }
  
  
export const simpleBarChart2 = {
  data: [
    {
      "group": "Prior",
      "value": 3
    },
    {
      "group": "Current",
      "value": 3.5
    },
    {
      "group": "Norm",
      "value": 4.5
    }
  ],
  options: {
    "title": "",
    "axes": {
      "left": {
        "mapsTo": "value",
        "ticks": {
          "values": [
            1,
            2,
            3,
            4,
            5
          ]
        }
      },
      "bottom": {
        "mapsTo": "group",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "height": "400px",
    "data": {
      "loading": false
    },
    "color": {
      "scale": {
          "Prior": "#50BEE1",
          "Current": "#004877",
          "Norm": "#FFC800"
      }
  }
  }
}


export const simpleBarChart3 = {
  data: [
    {
      "group": "Prior",
      "value": 3
    },
    {
      "group": "Current",
      "value": 5
    },
    {
      "group": "Norm",
      "value": 3
    }
  ],
  options: {
    "title": "",
    "axes": {
      "left": {
        "mapsTo": "value",
        "ticks": {
          "values": [
            1,
            2,
            3,
            4,
            5
          ]
        }
      },
      "bottom": {
        "mapsTo": "group",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "height": "400px",
    "data": {
      "loading": false
    },
    "color": {
      "scale": {
          "Prior": "#50BEE1",
          "Current": "#004877",
          "Norm": "#FFC800"
      }
  }
  }
}


export const simpleBarChart4 = {
  data: [
    {
      "group": "Prior",
      "value": 5
    },
    {
      "group": "Current",
      "value": 3
    },
    {
      "group": "Norm",
      "value": 4
    }
  ],
  options: {
    "title": "",
    "axes": {
      "left": {
        "mapsTo": "value",
        "ticks": {
          "values": [
            1,
            2,
            3,
            4,
            5
          ]
        }
      },
      "bottom": {
        "mapsTo": "group",
        "scaleType": ScaleTypes.LABELS
      }
    },
    "height": "400px",
    "data": {
      "loading": false
    },
    "color": {
      "scale": {
          "Prior": "#50BEE1",
          "Current": "#004877",
          "Norm": "#FFC800"
      }
  }
  }
}
