const EmergencyDepartmentRow = [
    {
        "id": "1",
        "title": "Net Paid PMPM",
        "prior": "$24",
        "current": "$22",
        "norm": "-$27",
    },
    {
        "id": "2",
        "title": "Cases per 1000",
        "prior": "176.4",
        "current": "158.3",
        "norm": "-149.6",
    },
    {
        "id": "3",
        "title": "Average Paid per ED Case",
        "prior": "$1,116",
        "current": "$1,342",
        "norm": "+$1,204",
    }

]

const EmergencyDepartmentHeader = [
    {
        "key": "title",
        "header": ""
    },
    {
        "key": "prior",
        "header": "Prior"
    },
    {
        "key": "current",
        "header": "Current"
    },
    {
        "key": "norm",
        "header": "Norm"
    }
]
const UrgentCareRow = [
    {
        "id": "1",
        "title": "Net Paid PMPM",
        "prior": "$2",
        "current": "$1",
        "norm": "-$2",
    },
    {
        "id": "2",
        "title": "Utils per 1000",
        "prior": "226.7",
        "current": "113.5",
        "norm": "-189.5",
    },
    {
        "id": "3",
        "title": "Average Paid per Util",
        "prior": "$110",
        "current": "$119",
        "norm": "+$116",
    }

]

const UrgentCareHeader = [
    {
        "key": "title",
        "header": ""
    },
    {
        "key": "prior",
        "header": "Prior"
    },
    {
        "key": "current",
        "header": "Current"
    },
    {
        "key": "norm",
        "header": "Norm"
    }
]
const PhysicianOfficeRow = [
    {
        "id": "1",
        "title": "Net Paid PMPM",
        "prior": "$25",
        "current": "$19",
        "norm": "-$23",
    },
    {
        "id": "2",
        "title": "Utils per 1000",
        "prior": "3,032.6",
        "current": "1,996.7",
        "norm": "-2,251",
    },
    {
        "id": "3",
        "title": "Average Paid per Util",
        "prior": "$100",
        "current": "$102",
        "norm": "+$105",
    }

]

const PhysicianOfficeHeader = [
    {
        "key": "title",
        "header": ""
    },
    {
        "key": "prior",
        "header": "Prior"
    },
    {
        "key": "current",
        "header": "Current"
    },
    {
        "key": "norm",
        "header": "Norm"
    }
]
const TelemedicineRow = [
    {
        "id": "1",
        "title": "Net Paid PMPM",
        "prior": "$0.98",
        "current": "$0.59",
        "norm": "-$0.85",
    },
    {
        "id": "2",
        "title": "Utils per 1000",
        "prior": "8.9",
        "current": "5.4",
        "norm": "-580",
    },
    {
        "id": "3",
        "title": "Average Paid per Util",
        "prior": "$72",
        "current": "$79",
        "norm": "+$73",
    }

]

const TelemedicineHeader = [
    {
        "key": "title",
        "header": ""
    },
    {
        "key": "prior",
        "header": "Prior"
    },
    {
        "key": "current",
        "header": "Current"
    },
    {
        "key": "norm",
        "header": "Norm"
    }
]

export {
    EmergencyDepartmentRow, EmergencyDepartmentHeader, UrgentCareRow, UrgentCareHeader, PhysicianOfficeRow, PhysicianOfficeHeader,
    TelemedicineRow, TelemedicineHeader
}