const EmergentCareReports = [
    'Key Service Settings',
    'Optimize Utilization',
    'Geographic Hot Spots',
    'Demographic Drivers',
    'Diagnostic Drivers',
    'ED Utilizers Comparison',
    'Frequent Fliers Clinical Drivers',
    'Performance to Benchmark'
]

const EmergencyUtilizerProfileRow = [
    {
        "id": "1",
        "title": "Medical PMPM",
        "zeroVisit": "$155",
        "oneVisit": "$285",
        "twoVisit": "$165",
        "frequentFlyer": "$210",
    },
    {
        "id": "2",
        "title": "RX PMPM",
        "zeroVisit": "$110",
        "oneVisit": "$150",
        "twoVisit": "$125",
        "frequentFlyer": "$165",
    },
    {
        "id": "3",
        "title": "Average Age",
        "zeroVisit": "38.9",
        "oneVisit": "34.5",
        "twoVisit": "35.6",
        "frequentFlyer": "41.2",
    },
    {
        "id": "4",
        "title": "MARA Risk",
        "zeroVisit": "1.234",
        "oneVisit": "2.658",
        "twoVisit": "4.589",
        "frequentFlyer": "5.625%",
    },
    {
        "id": "5",
        "title": "High Cost Claimants per 1000",
        "zeroVisit": "110.0",
        "oneVisit": "150.6",
        "twoVisit": "150.6",
        "frequentFlyer": "225.6",
    },
    {
        "id": "6",
        "title": "PCP Visits PMPY",
        "zeroVisit": "1.8",
        "oneVisit": "1.7",
        "twoVisit": "1.7",
        "frequentFlyer": "1.6",
    },
    {
        "id": "7",
        "title": "Inpat Admits per 1000",
        "zeroVisit": "100.5",
        "oneVisit": "230.5",
        "twoVisit": "230.5",
        "frequentFlyer": "560.2",
    },
    {
        "id": "8",
        "title": "EBM Rate",
        "zeroVisit": "65.7%",
        "oneVisit": "54.5%",
        "twoVisit": "55.2%",
        "frequentFlyer": "38.9%",
    },
    {
        "id": "9",
        "title": "Care Management Engagement Re",
        "zeroVisit": "4.5%",
        "oneVisit": "3.8%",
        "twoVisit": "6.5%",
        "frequentFlyer": "6.7%",
    },


]

const EmergencyUtilizerProfileHeader = [
    {
        "key": "title",
        "header": ""
    },
    {
        "key": "zeroVisit",
        "header": "0 Visits "
    },
    {
        "key": "oneVisit",
        "header": "1Visit"
    },
    {
        "key": "twoVisit",
        "header": "2 Visits"
    },
    {
        "key": "frequentFlyer",
        "header": "Frequent Flyer 3/+ Visits "
    }
]

export { EmergentCareReports, EmergencyUtilizerProfileRow, EmergencyUtilizerProfileHeader }