import { Column, Row } from "carbon-components-react"
import { downTrendSvg, upTrendSvg } from "../../Other/SVG"

interface IKeyMetric {
    title: string;
    value: string;
    description: string;
}
interface IKeyMetricProps {
    title: string;
    metric: IKeyMetric[];
}
interface IProps {
    data: IKeyMetricProps[]
}

export const KeyMetric: React.FC<IProps> = (props) => {

    const item = (props.data).map(item => {
        return (
            <Row className="KeyMetricContainer">
                <Column sm={1} md={2} lg={2}>
                    <div className="title">
                        <p><strong>{item.title}</strong></p>
                    </div>
                </Column>
                <Column>
                    <div className="KeyMetric">
                        <div style={{display: "flex", height: "100%"}}>
                            {item.metric.map((item: any) => item).map((item: any, index: number) => {
                                return (
                                    <><Column style={{ flex: 1, marginLeft: index === 0 ? "0px": "10px" }}>
                                        <p style={{ color: "#727A7D", minHeight: "32px", lineHeight: item.title.length >= 28 ? "16px" : "1.5em" }}>{item.title}</p>
                                        <p style={{ marginTop: "8px", fontSize: "30px", color: item.description.charAt(0) === '+' ? "#A5203F" : item.description.charAt(0) === '-' ? "#00A562" : "" }}>{item.value}</p>
                                        <p style={{ color: "#727A7D", fontSize: "14px" }}>
                                            {(item.description.charAt(0) !== '+' && item.description.charAt(0) !== '-') ? null : 
                                            ((item.description.charAt(0) === '-') ? downTrendSvg() : upTrendSvg())}
                                            <span style={{ color: item.description.charAt(0) === '+' ? "#A5203F" : "#00A562" }}>
                                                {(item.description.charAt(0) === '+' || item.description.charAt(0) === '-') ? item.description.slice(0, item.description.indexOf('%')+1) : null }
                                            </span>
                                            {(item.description.charAt(0) === '+' || item.description.charAt(0) === '-') ? item.description.slice(item.description.indexOf('%')+1, item.description.length) : item.description}
                                        </p>
                                    </Column>
                                    {index !== 3 ? <span style={{ borderRight: "1px solid #ECEEEF" }}></span> : null }
                                    </>
                                );
                            })}
                        </div>
                    </div>
                </Column>
            </Row>);
    });

    return (<>{item}</>)
}