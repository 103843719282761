const EmergentCareReports = [
    'Key Service Settings',
    'Optimize Utilization',
    'Geographic Hot Spots',
    'Demographic Drivers',
    'Diagnostic Drivers',
    'ED Utilizers Comparison',
    'Frequent Fliers Clinical Drivers',
    'Performance to Benchmark'
]

const HighCostUtilizerProfileRow = [
    {
        "id": "1",
        "title": "Percentage of Members",
        "prior": "$155",
        "current": "$285",
        "change": "$165",
        "nonHighCostClaimants": "$210",
        "variance": "24.5%"
    },
    {
        "id": "2",
        "title": "RX PMPM",
        "prior": "$110",
        "current": "$150",
        "change": "$125",
        "nonHighCostClaimants": "$165",
        "variance": "24.5%"
    },
    {
        "id": "3",
        "title": "Average Age",
        "prior": "38.9",
        "current": "34.5",
        "change": "35.6",
        "nonHighCostClaimants": "41.2",
        "variance": "24.5%"
    },
    {
        "id": "4",
        "title": "MARA Risk",
        "prior": "1.234",
        "current": "2.658",
        "change": "4.589",
        "nonHighCostClaimants": "5.625%",
        "variance": "24.5%"
    },
    {
        "id": "5",
        "title": "High Cost Claimants per 1000",
        "prior": "110.0",
        "current": "150.6",
        "change": "150.6",
        "nonHighCostClaimants": "225.6",
        "variance": "24.5%"
    },
    {
        "id": "6",
        "title": "PCP Visits PMPY",
        "prior": "1.8",
        "current": "1.7",
        "change": "1.7",
        "nonHighCostClaimants": "1.6",
        "variance": "24.5%"
    },
    {
        "id": "7",
        "title": "Inpat Admits per 1000",
        "prior": "100.5",
        "current": "230.5",
        "change": "230.5",
        "nonHighCostClaimants": "560.2",
        "variance": "24.5%"
    },
    {
        "id": "8",
        "title": "EBM Rate",
        "prior": "65.7%",
        "current": "54.5%",
        "change": "55.2%",
        "nonHighCostClaimants": "38.9%",
        "variance": "24.5%"
    },
    {
        "id": "9",
        "title": "Care Management Engagement Re",
        "prior": "4.5%",
        "current": "3.8%",
        "change": "6.5%",
        "nonHighCostClaimants": "6.7%",
        "variance": "24.5%"
    }

]

const HighCostUtilizerProfileHeader = [
    {
        "key": "title",
        "header": ""
    },
    {
        "key": "prior",
        "header": "Prior "
    },
    {
        "key": "current",
        "header": "Current "
    },
    {
        "key": "change",
        "header": "Change "
    },
    {
        "key": "nonHighCostClaimants",
        "header": "on-High Cost Claimants "
    },
    {
        "key": "variance",
        "header": "Variance "
    }
]

const HighCostUtilizationDetailsHeader = [
    {
        "key": "title",
        "header": "Metric"
    },
    {
        "key": "prior",
        "header": "Prior "
    },
    {
        "key": "current",
        "header": "Current "
    },
    {
        "key": "groupAvg",
        "header": "Group Avg "
    }
]

const HighCostUtilizationDetailsRow = [
    {
        "id": "1",
        "title": "Inpatient Days PMPM",
        "prior": "14.9",
        "current": "15.3",
        "groupAvg": "0.3"
    },
    {
        "id": "2",
        "title": "Inpatient Admits per 1000",
        "prior": "1,851.2",
        "current": "2,030.6",
        "groupAvg": "60.1"
    },
    {
        "id": "3",
        "title": "Outpatient Surgeries per 1000",
        "prior": "1,609.9",
        "current": "1,499.8",
        "groupAvg": "146.9"
    },
    {
        "id": "4",
        "title": "Emergency Utilization per 1000",
        "prior": "756.0",
        "current": "922.1",
        "groupAvg": "154.0"
    },
    {
        "id": "5",
        "title": "Pharmacy Scripts per 1000",
        "prior": "20,037.1",
        "current": "17,757.4",
        "groupAvg": "3,837.2"
    },
    {
        "id": "6",
        "title": "PCP Visits per 1000",
        "prior": "1,100.9",
        "current": "917.8",
        "groupAvg": "293.0"
    },
    {
        "id": "7",
        "title": "Specialist Visits per 1000",
        "prior": "10,019.2",
        "current": "9,442.9",
        "groupAvg": "1,448.9"
    }
]

export { EmergentCareReports, HighCostUtilizerProfileRow, HighCostUtilizerProfileHeader,
    HighCostUtilizationDetailsHeader, HighCostUtilizationDetailsRow  }