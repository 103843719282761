import { Row, Column, DataTable, TableHead, TableRow, TableBody, TableCell, TableContainer, Table, TableHeader, DataTableHeader, DataTableCell, DenormalizedRow, OverflowMenu } from 'carbon-components-react';
import { DonutChart, ComboChart, SimpleBarChart } from '@carbon/charts-react';
import { adequacyOfBenefits, INNNetworkUtilization, allServices, Medical, pharmacy, facilityInpatient, facilityOutpatient, ancillary, chronicConditions, highCostClaimants, yearToYearPaidPMPMChanges, totalNetPaidByHCG } from "../../../data/Chart1/chartData"
import { useSelector } from 'react-redux';
import StyledLink from '../../SideNavigation/StyledLink';

const netPaidPMPMByHCG = require("../../../data/ExecutiveSummaryDashboard/NetPaidPMPMByHCG.json");
const averageMembers = require("../../../data/ExecutiveSummaryDashboard/AverageMembers.json");

const ExecutiveSummaryDashboard = () => {
    const reportCreatedDate = useSelector((state: any) => state.root.reportCreatedDate);
    return <Column style={{paddingBottom: '200px'}}>
        <Row style={{marginLeft: "0px"}}>
            <Column>
                <Row><h1 style={{color: "#39414D", marginTop: '18px'}}>Overview</h1></Row>
                <Row style={{ marginTop: "10px", width: "832px" }}><p>The executive summary captures total plan performance.  Explore high level drivers of cost, utilization, and contribution to trend.  Continue to navigate through your storyboard to get deeper insights on specific areas of interest.</p></Row>
                <Row style={{ marginTop: "20px", width: "900px" }}><p>{`Report Created: ${reportCreatedDate}`}</p></Row>
                <Row style={{ marginTop: "20px", width: "900px" }}>
                    <Column style={{ padding: '0px' }}>
                            <ul>
                                <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href="/" style={{ textDecoration: 'none' }}>Key Financial Indicators</a></li>
                                <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href="/" style={{ textDecoration: 'none' }}>Contribution to Trend by HCG</a></li>
                                <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href="/" style={{ textDecoration: 'none' }}>Membership & Utilization Trend Details</a></li>
                            </ul>
                    </Column>
                </Row>
            </Column>
        </Row>

        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Key Financial Indicators</h3></Row>
        <Row style={{ marginTop: "30px", display: "flex", justifyContent: "flex-start", marginLeft: '0px' }}>
            <Column id={"Total Net Paid"} style={{ width: '289px', height: '156px', color: '#39414D', background: '#FFFFFF', marginRight: '24px', marginLeft: '0px' }}>
                <Row style={{ fontSize: "16px", lineHeight: '22px', marginTop: '16px', marginLeft: '0px', flex: 1 }}><h5>{"Total Net Paid"}</h5></Row>
                <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: '25px' }}><p style={{ textAlign: "center", fontSize: "36px", lineHeight: '40px'}}>{"$410,446,137"}</p></Row>
            </Column>
            <Column id={"Medical Net Paid"} style={{ width: '289px', height: '156px', color: '#39414D', background: '#FFFFFF', marginRight: '24px', marginLeft: '0px' }}>
                <Row style={{ fontSize: "16px", lineHeight: '22px', marginTop: '16px', marginLeft: '0px', flex: 1 }}><h5>{"Medical Net Paid"}</h5></Row>
                <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: '25px' }}><p style={{ textAlign: "center", fontSize: "36px", lineHeight: '40px'}}>{"$345,816,999"}</p></Row>
            </Column>
            <Column id={"Pharmacy Net Paid"} style={{ width: '289px', height: '156px', color: '#39414D', background: '#FFFFFF', marginLeft: '0px' }}>
                <Row style={{ fontSize: "16px", lineHeight: '22px', marginTop: '16px', marginLeft: '0px', flex: 1 }}><h5>{"Pharmacy Net Paid"}</h5></Row>
                <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: '25px' }}><p style={{ textAlign: "center", fontSize: "36px", lineHeight: '40px'}}>{"$64,629,138"}</p></Row>
            </Column>
        </Row>
        <Row style={{ paddingTop: '30px', marginLeft: '0px'}}>
            <Column style={{ backgroundColor: "#FFF", marginRight: "24px", justifyContent: "center" }}>
              <div style={{marginTop: "20px", display: "flex", width: '640px', marginLeft: '0px'}}>
                  <DataTable rows={netPaidPMPMByHCG.rows} headers={netPaidPMPMByHCG.headers}>
                      {({
                          rows = netPaidPMPMByHCG.rows,
                          headers = netPaidPMPMByHCG.headers,
                          getHeaderProps,
                          getRowProps,
                          getTableProps,
                          getTableContainerProps,
                      }: { rows: DenormalizedRow[], headers: DataTableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
                          <TableContainer
                              title="Net Paid PMPM by HCG"
                              {...getTableContainerProps()}
                              style={{backgroundColor: "#FFF", flexGrow: 1, width: "100%"}}>
                              <Table {...getTableProps()} style={{backgroundColor: "#FFF", width: "100%"}}>
                                  <TableHead>
                                      <TableRow>
                                          {
                                          headers.map((header: DataTableHeader) => (
                                              <TableHeader
                                                  key={header.key}
                                                  {...getHeaderProps({ header })}>
                                                  {header.header}
                                              </TableHeader>
                                          ))}
                                      </TableRow>
                                  </TableHead>
                                  
                                  <TableBody>
                                      {rows.map((row: DenormalizedRow) => (
                                          <TableRow key={row.id} {...getRowProps({ row })}>
                                              {row.cells.map((cell: DataTableCell) => (
                                                  <TableCell key={cell.id} style={{ backgroundColor: "#FFF" }}>{cell.value}</TableCell>
                                              ))}
                                          </TableRow>
                                      ))}
                                  </TableBody>
                              </Table>
                          </TableContainer>
                      )}
                  </DataTable>
              </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", justifyContent: "center" }}>
                <div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "100%", display: "flex"}}>
                    <DonutChart options={totalNetPaidByHCG.options} data={totalNetPaidByHCG.data} />
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>

        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Contribution to Trend by HCG</h3></Row>
        <Row style={{ paddingTop: '30px', marginLeft: "0px"}}>
            <Column style={{ backgroundColor: "#FFF", justifyContent: "center" }}>
                <div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "100%", display:"flex"}}>
                    <SimpleBarChart options={yearToYearPaidPMPMChanges.options} data={yearToYearPaidPMPMChanges.data}/>
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>

        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Membership & Utilization Details</h3></Row>
        <Row style={{ marginTop: "22px", marginLeft: '0px' }}>
            <Column style={{ backgroundColor: "#FFF", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px' }}>
						<SimpleBarChart options={averageMembers.options} data={averageMembers.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px'  }}>
						<SimpleBarChart options={adequacyOfBenefits.options} data={adequacyOfBenefits.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px'  }}>
						<SimpleBarChart options={INNNetworkUtilization.options} data={INNNetworkUtilization.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>
        <Row style={{ marginTop: "22px", marginLeft: '0px' }}>
            <Column style={{ backgroundColor: "#FFF", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px' }}>
						<ComboChart options={allServices.options} data={allServices.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px'  }}>
						<ComboChart options={Medical.options} data={Medical.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px'  }}>
						<ComboChart options={pharmacy.options} data={pharmacy.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>
        <Row style={{ marginTop: "22px", marginLeft: '0px' }}>
            <Column style={{ backgroundColor: "#FFF", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px' }}>
						<ComboChart options={facilityInpatient.options} data={facilityInpatient.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px'  }}>
						<ComboChart options={facilityOutpatient.options} data={facilityOutpatient.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px'  }}>
						<ComboChart options={ancillary.options} data={ancillary.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>
        <Row style={{ marginTop: "22px", marginLeft: '0px', marginRight: '368px' }}>
            <Column style={{ backgroundColor: "#FFF", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px' }}>
						<ComboChart options={chronicConditions.options} data={chronicConditions.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px'  }}>
						<ComboChart options={highCostClaimants.options} data={highCostClaimants.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>

        <Row style={{ padding: '3rem 0rem 1rem', marginLeft: '0px' }}>
				<h1>Explore Related Topics</h1>
			</Row>
			<Row id="tableLink" style={{ maxWidth: '80%', marginLeft: '0px', marginBottom: '60px' }}>
				<Column style={{ padding: '0px' }}>
					<ul>
						<li className='relatedList' style={{ padding: '6px 4px', color: '#0067B5' }}>
							<StyledLink to="#" >Financial <span style={{ color: 'gray' }}>(Storyboard Page)</span> </StyledLink>
						</li>
						<li className='relatedList' style={{ padding: '6px 4px', color: '#0067B5' }}>
							<StyledLink to="#" >Demographics <span style={{ color: 'gray' }}>(Storyboard Page)</span> </StyledLink>
						</li>
						<li className='relatedList' style={{ padding: '6px 4px', color: '#0067B5' }}>
							<StyledLink to="#">Inpatient Admissions <span style={{ color: 'gray' }}>(Storyboard Page)</span>  </StyledLink>
						</li>
					</ul>
				</Column>
				<Column style={{ padding: '0px' }}>
					<ul>
                    <li className='relatedList' style={{ padding: '6px 4px', color: '#0067B5' }}>
							<StyledLink to="#" >Outpatient Events <span style={{ color: 'gray' }}>(Storyboard Page)</span> </StyledLink>
						</li>
						<li className='relatedList' style={{ padding: '6px 4px', color: '#0067B5' }}>
							<StyledLink to="#" >Pharmacy <span style={{ color: 'gray' }}>(Storyboard Page)</span> </StyledLink>
						</li>
						<li className='relatedList' style={{ padding: '6px 4px', color: '#0067B5' }}>
							<StyledLink to="#">High Cost Claimants <span style={{ color: 'gray' }}>(Storyboard Page)</span>  </StyledLink>
						</li>
					</ul>
				</Column>
			</Row>
    </Column>
}

export {ExecutiveSummaryDashboard};