import { Row, Column, DataTable, TableHead, TableRow, TableBody, TableCell, TableContainer, Table, TableHeader, DataTableHeader, DataTableCell, DenormalizedRow, OverflowMenu } from 'carbon-components-react';
import { GroupedBarChart, LineChart } from '@carbon/charts-react';
import { telehealthVisitsPercentOfOfficeVisits } from "../../data/Chart1/chartData"

const telehealthUtils1000 = require("../../data/Telehealth/TelehealthUtils1000ByMonth.json");
// const telehealthVisitsPercentOfOfficeVisits = require("../../data/Telehealth/TelehealthVisitsPercentOfOfficeVisits.json");
const telehealthUtils1000ByAge = require("../../data/Telehealth/TelehealthUtils1000ByAgeBand.json");
const telehealthUtils1000ByProviderSpecialty = require("../../data/Telehealth/TelehealthUtils1000ByProviderSpecialty.json");
const telehealthUtils1000ByDiagnosis = require("../../data/Telehealth/TelehealthUtils1000ByDiagnosis.json");
const telehealthProviderDetails = require("../../data/Telehealth/TelehealthProviderDetails.json");


type SumProps = {
    title: string,
    subTitle: string,
    mainValue: string,
    changeValue: string,
    normValue: string
}

const SummaryBox = ({title, subTitle, mainValue, changeValue, normValue}: SumProps) => {
    return <Column id={title} style={{ width: '289px', height: '220px', color: '#39414D', background: '#FFFFFF', marginRight: '24px', marginLeft: '0px' }}>
        <div style={{display: "flex"}}>
            <Row style={{ fontSize: "16px", lineHeight: '22px', marginTop: '16px', marginLeft: '0px', flex: 1 }}><h5>{title}</h5></Row>
            <OverflowMenu style={{paddingLeft: '5px', paddingTop: '16px'}}/>
        </div>
        {subTitle ? <Row><p style={{ fontSize: "14px", lineHeight: '18px', color: '#616771', marginTop: '4px', marginLeft: '16px' }}>{subTitle}</p></Row> : <div style={{height: '18px'}}/>}
        <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: '8px' }}><p style={{ textAlign: "center", fontSize: "54px", lineHeight: '64px'}}>{mainValue}</p></Row>
        <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: '24px' }}><p style={{ textAlign: "center", fontSize: "12px", lineHeight: '16px', color: '#616771' }}>Change: <b style={{fontWeight: 600}}>{changeValue}</b></p></Row>
        <Row style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><p style={{ textAlign: "center", fontSize: "12px", lineHeight: '16px', color: '#616771' }}>Normal: <b style={{fontWeight: 600}}>{normValue}</b></p></Row>
    </Column>
}

const TelehealthDashboard = () => {
    return <Column style={{paddingLeft: "34px", paddingBottom: '200px'}}>
        <Row style={{marginLeft: "0px"}}>
            <Column>
                <Row><h1 style={{color: "#39414D", fontSize: '42px'}}>Overview</h1></Row>
                <Row style={{ marginTop: "20px", width: "900px", fontSize: '16px' }}>
                    <Column style={{ padding: '0px' }}>
                            <ul>
                                <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href="/" style={{ textDecoration: 'none' }}>Key Indicators</a></li>
                                <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href="/" style={{ textDecoration: 'none' }}>Utilization Trends</a></li>
                                <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href="/" style={{ textDecoration: 'none' }}>Provider Type</a></li>
                            </ul>
                    </Column>
                    <Column style={{ padding: '0px' }}>
                            <ul>
                                <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href="/" style={{ textDecoration: 'none' }}>Diagnostic Drivers</a></li>
                                <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href="/" style={{ textDecoration: 'none' }}>Top Providers</a></li>
                                <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href="/" style={{ textDecoration: 'none' }}>More Topics to Explore</a></li>
                            </ul>
                    </Column>
                </Row>
            </Column>
        </Row>
        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Telehealth Key Indicators</h3>
            <Row style={{ marginTop: "30px", display: "flex", justifyContent: "flex-start", marginLeft: '0px' }}>
                <SummaryBox 
                    title="Telehealth Utilization"
                    subTitle="Per 1,000"
                    mainValue="2,120"
                    changeValue="+45%"
                    normValue="2,096"
                />
                <SummaryBox 
                    title="Allowed per Utilization"
                    subTitle=""
                    mainValue="$100.00"
                    changeValue="5.6%"
                    normValue="$98.00"
                />
                <SummaryBox 
                    title="Percentage Female"
                    subTitle=""
                    mainValue="54.6%"
                    changeValue="+3.4%"
                    normValue="57.8%"
                />
                <SummaryBox 
                    title="Average Age"
                    subTitle=""
                    mainValue="31.5"
                    changeValue="+0.5%"
                    normValue="30.9"
                />
            </Row>
        </Row>
        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Utilization Trends</h3></Row>
        <Row style={{ paddingTop: '30px'}}>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", marginRight: "10px", justifyContent: "center" }}>
                <div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "100%", display: "flex"}}>
                    <LineChart options={telehealthUtils1000.options} data={telehealthUtils1000.data} />
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>
        <Row style={{ paddingTop: '30px'}}>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", marginRight: "10px", justifyContent: "center" }}>
                <div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "100%", display: 'flex'}}>
                    <GroupedBarChart options={telehealthVisitsPercentOfOfficeVisits.options} data={telehealthVisitsPercentOfOfficeVisits.data} />
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>
        <Row style={{ paddingTop: '30px'}}>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", marginRight: "10px", justifyContent: "center" }}>
                <div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "100%", display: "flex"}}>
                    <GroupedBarChart options={telehealthUtils1000ByAge.options} data={telehealthUtils1000ByAge.data} />
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>

        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Provider Type</h3></Row>
        <Row style={{ paddingTop: '30px'}}>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", marginRight: "10px", justifyContent: "center" }}>
                <div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "100%", display: 'flex'}}>
                    <LineChart options={telehealthUtils1000ByProviderSpecialty.options} data={telehealthUtils1000ByProviderSpecialty.data} />
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>

        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Diagnostic Drivers</h3></Row>
        <Row style={{ paddingTop: '30px'}}>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", marginRight: "10px", justifyContent: "center" }}>
                <div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "100%", display: "flex"}}>
                    <GroupedBarChart options={telehealthUtils1000ByDiagnosis.options} data={telehealthUtils1000ByDiagnosis.data} />
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>

        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Top Providers</h3></Row>
        <Row style={{marginLeft: '0px'}}>
            <div style={{marginTop: "20px", display: "flex", width: '100%'}}>
                <DataTable rows={telehealthProviderDetails.rows} headers={telehealthProviderDetails.headers}>
                    {({
                        rows = telehealthProviderDetails.rows,
                        headers = telehealthProviderDetails.headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps,
                        getTableContainerProps,
                    }: { rows: DenormalizedRow[], headers: DataTableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
                        <TableContainer
                            title="Telehealth Provider Details"
                            {...getTableContainerProps()}
                            style={{backgroundColor: "#FFF", flexGrow: 1, width: "100%"}}>
                            <Table {...getTableProps()} style={{backgroundColor: "#FFF", width: "100%"}}>
                                <TableHead>
                                    <TableRow>
                                        {
                                        headers.map((header: DataTableHeader) => (
                                            <TableHeader
                                                key={header.key}
                                                {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row: DenormalizedRow) => (
                                        <TableRow key={row.id} {...getRowProps({ row })}>
                                            {row.cells.map((cell: DataTableCell) => (
                                                <TableCell key={cell.id} style={{ backgroundColor: "#FFF" }}>{cell.value}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </DataTable>
            </div>
        </Row>
    </Column>
}

export {TelehealthDashboard};