import React, { useState } from 'react';
import { HeaderGlobalAction, Modal } from 'carbon-components-react';
import { Filter20, Reset16 } from '@carbon/icons-react';
import incurredTimePeriods from '../../data/Filters/TimePeriods.json';
import { ModalStateManager } from '../Other/ModalStateManager';
import FilterList from '../Other/FilterList';

const Filter = () => {
  return (
    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z" fill="#EFF0F1" />
    </svg>
  )
}

export const PortalFilterModal = () => {
  return (
      <ModalStateManager
        renderLauncher={({ setOpen }: any) => (
          <div onClick={() => setOpen(true)}>
            <HeaderGlobalAction aria-label="Portal Filters">
              <Filter />
            </HeaderGlobalAction>
          </div>
        )}>
        {({ open, setOpen }: any) => (
          <Modal
            modalHeading="Portal Filters"
            // modalLabel="Account resources"
            primaryButtonText="Apply Filters"
            secondaryButtonText="Cancel"
            open={open}
            onRequestClose={() => setOpen(false)}
            onRequestSubmit={() => setOpen(false)}
          >
            <div style={{ padding: "0px 0px 25px 25px" }}>Select filters to apply to all data throughout the portal<span style={{ float: "right", color: "#0080E2" }}><Reset16 /><span style={{ paddingLeft: "6px" }}>Reset all filters</span></span></div>
            <FilterList componentIDSuffix={'RS'} />

          </Modal>
        )}
      </ModalStateManager>
  );
};
