import { DonutChartOptions, Alignments } from "@carbon/charts/interfaces";

const comorbidCostData = [
    {
        "group": "Hypertension",
        "value": 40
    },
    {
        "group": "Diabetes w/o CAD",
        "value": 30
    },
    {
        "group": "Depression",
        "value": 20
    },
    {
        "group": "Asthma",
        "value": 5
    },
    {
        "group": "Chronic Musculoskeletal",
        "value": 5
    }
];

const comorbidCostOptions: DonutChartOptions = {
    "title": "Comorbid Cost",
    "resizable": true,
    "legend": {
        "alignment": Alignments.CENTER,
        "truncation": {
            "numCharacter": 50
        }
    },
    "donut": {
        "center": {
            "number": 2410372,
            label: "Total Allowed Cost",
            numberFormatter: (x: number) => "$" + x.toLocaleString("en-US")
        },
        "alignment": Alignments.CENTER
    },
    "height": "350px",
    "color": {
        "scale": {
            "Hypertension": "#0080E2",
            "Diabetes w/o CAD": "#50BEE1",
            "Depression": "#74C061",
            "Asthma": "#FFC800",
            "Chronic Musculoskeletal": "#FFA200"
        }
    }
};
const comorbidCostPDFOptions: DonutChartOptions = {
    "title": "Comorbid Cost",
    "resizable": true,
    "legend": {
        "alignment": Alignments.CENTER,
        "truncation": {
            "numCharacter": 50
        }
    },
    "donut": {
        "center": {
            "number": 2410372,
            label: "Total Allowed Cost",
            numberFormatter: (x: number) => "$" + x.toLocaleString("en-US")
        },
        "alignment": Alignments.CENTER
    },
    "height": "250px",
    "width": "250px",
    "color": {
        "scale": {
            "Hypertension": "#0080E2",
            "Diabetes w/o CAD": "#50BEE1",
            "Depression": "#74C061",
            "Asthma": "#FFC800",
            "Chronic Musculoskeletal": "#FFA200"
        }
    }
};

export {comorbidCostData, comorbidCostOptions, comorbidCostPDFOptions};