import { Row, Column, DataTable, TableHead, TableRow, TableBody, TableCell, TableContainer, Table, TableHeader, DataTableCell, DenormalizedRow, OverflowMenu } from 'carbon-components-react';
import { DonutChart, ComboChart, PieChart, StackedBarChart, AreaChart, TreemapChart } from '@carbon/charts-react';
import { PCPOfficeVisitsRow, PCPOfficeVisitsHeader,TelehealthOfficeVisitsRow, TelehealthOfficeVisitsHeader, SpecialistVisitsRow, SpecialistVisitsHeader,OBGYNVisitsRow, OBGYNVisitsHeader,
    PhysicianOfficeVisits, PhysicianOfficeVisitsByRelationship, PCPVisitsPer1000, OfficeVisits, PCPOfficeVisitsUtilsPer1000, TelehealthVisitsPercentOfAllOfficeVisits, TelehealthUtilization,
    AttributedPCPClinicalDetailComparisonRow, AttributedPCPClinicalDetailComparisonHeader, TopPCPCClinics, TopProviderSpecialties, SpecialistUtilizationByNetworkStatus } from '../../../data/PhysicianVisits/PhysicianVisitsData';
import { ChartTypes } from "../../Widget/DataDisplays/ChartTypes"
import { downTrendSvg, upTrendSvg } from "../../Other/SVG"
import { useSelector } from 'react-redux';
import StyledLink from '../../SideNavigation/StyledLink';


export const PharmacyDashboard = () => {
    const reportCreatedDate = useSelector((state: any) => state.root.reportCreatedDate);
	return <Column style={{paddingBottom: '200px'}}>
        <Row style={{marginLeft: "0px"}}>
            <Column>
                <Row><h1 style={{color: "#39414D", marginTop: '18px'}}>Overview</h1></Row>
                <Row style={{ marginTop: "10px", width: "832px" }}><p>Physician visits comprise a large portion of plan spend.  Use this storyboard to explore which physician visit types are most prevalent or costly, see breakouts for preventive visits and telehealth utilization, analyze specialist visit detail and spend, and explore variations within member risk, cost efficiency, and total cost of care for top attributed PCP clinics.</p></Row>
                <Row style={{ marginTop: "20px", width: "900px" }}><p>{`Report Created: ${reportCreatedDate}`}</p></Row>
                <Row style={{ marginTop: "20px", width: "900px" }}>
                    <Column style={{ padding: '0px' }}>
                            <ul>
                                <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href="/" style={{ textDecoration: 'none' }}>Physicians Visits Key Indicators</a></li>
                                <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href="/" style={{ textDecoration: 'none' }}>Utilization Details</a></li>
                                <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href="/" style={{ textDecoration: 'none' }}>Telehealth Utilization</a></li>
                            </ul>
                    </Column>
                    <Column style={{ padding: '0px' }}>
                            <ul>
                                <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href="/" style={{ textDecoration: 'none' }}>Provider Type Utilization Comparison</a></li>
                                <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href="/" style={{ textDecoration: 'none' }}>Top PCP Clinics Comparison</a></li>
                                <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href="/" style={{ textDecoration: 'none' }}>Attributed PCP Clinic Detail Comparison</a></li>
                            </ul>
                    </Column>
                </Row>
            </Column>
        </Row>

        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Physicians Visits Key Indicators</h3></Row>
        <Row style={{ marginLeft: "0px", paddingBottom: '1rem', marginTop: "30px" }}>
			<Column style={{ padding: "0px 10px 0px 0px" }}>
				<DataTable rows={PCPOfficeVisitsRow} headers={PCPOfficeVisitsHeader} isSortable>
					{({
						rows = PCPOfficeVisitsRow,
						headers = PCPOfficeVisitsHeader,
						getHeaderProps,
						getRowProps,
						getTableProps,
						getTableContainerProps
					}: { rows: any, headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
						<TableContainer
                            title={
                                <div style={{display: "flex", justifyContent: "space-between", lineHeight: "20px"}}>
                                    <p style={{fontSize: "20px"}}>PCP Office Visits</p>
                                    <OverflowMenu></OverflowMenu>
                                </div>
                            }
						    description="Excluding Telehealth"
							{...getTableContainerProps()}
						>
							<Table {...getTableProps()} isSortable>
								<TableHead>
									<TableRow>
										{headers.map((header) => (
											<TableHeader
												key={header.key}
												{...getHeaderProps({ header })}
												isSortable
											>
												{header.header}
											</TableHeader>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.map((row: any) => (
										<TableRow {...getRowProps({ row })}>
											{row.cells.map((cell: any) => {
												if (cell.value[0] === "+") {
													return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
														{upTrendSvg()}{cell.value.substring(1)}</TableCell>)
												} else if (cell.value[0] === "-") {
													return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
														{downTrendSvg()}{cell.value.substring(1)}</TableCell>)
												} else {
													return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>{cell.value}</TableCell>)
												}
											})}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</DataTable>
			</Column>
			<Column style={{ padding: "0px 0px 0px 20px" }}>
				<DataTable rows={TelehealthOfficeVisitsRow} headers={TelehealthOfficeVisitsHeader} isSortable>
					{({
						rows = TelehealthOfficeVisitsRow,
						headers = TelehealthOfficeVisitsHeader,
						getHeaderProps,
						getRowProps,
						getTableProps,
						getTableContainerProps
					}: { rows: any, headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
						<TableContainer
                            title={
                                <div style={{display: "flex", justifyContent: "space-between", lineHeight: "20px"}}>
                                    <p style={{fontSize: "20px"}}>Telehealth Office Visits</p>
                                    <OverflowMenu></OverflowMenu>
                                </div>
                            }	
							{...getTableContainerProps()}
						>
							<div style={{paddingBottom: "18px", backgroundColor: "#ffffff"}}/>
                            <Table {...getTableProps()} isSortable>
                            
								<TableHead>
									<TableRow>
										{headers.map((header) => (
											<TableHeader
												key={header.key}
												{...getHeaderProps({ header })}
												isSortable
											>
												{header.header}
											</TableHeader>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.map((row: any) => (
										<TableRow {...getRowProps({ row })}>
											{row.cells.map((cell: any) => {
												if (cell.value[0] === "+") {
													return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
														{upTrendSvg()}{cell.value.substring(1)}</TableCell>)
												} else if (cell.value[0] === "-") {
													return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
														{downTrendSvg()}{cell.value.substring(1)}</TableCell>)
												} else {
													return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>{cell.value}</TableCell>)
												}
											})}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</DataTable>
			</Column>
		</Row>
		<Row style={{ marginLeft: "0px", paddingTop: '1rem', paddingBottom: '1rem' }}>
			<Column style={{ padding: "0px 10px 0px 0px" }}>
				<DataTable rows={SpecialistVisitsRow} headers={SpecialistVisitsHeader} isSortable>
					{({
						rows = SpecialistVisitsRow,
						headers = SpecialistVisitsHeader,
						getHeaderProps,
						getRowProps,
						getTableProps,
						getTableContainerProps
					}: { rows: any, headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
						<TableContainer
                            title={
                                <div style={{display: "flex", justifyContent: "space-between", lineHeight: "20px"}}>
                                    <p style={{fontSize: "20px"}}>Specialist Visits</p>
                                    <OverflowMenu></OverflowMenu>
                                </div>
                            }	
							description="Excluding OB / GYN"
							{...getTableContainerProps()}
						>
							<Table {...getTableProps()} isSortable>
								<TableHead>
									<TableRow>
										{headers.map((header) => (
											<TableHeader
												key={header.key}
												{...getHeaderProps({ header })}
												isSortable
											>
												{header.header}
											</TableHeader>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.map((row: any) => (
										<TableRow {...getRowProps({ row })}>
											{row.cells.map((cell: any) => {
												if (cell.value[0] === "+") {
													return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
														{upTrendSvg()}{cell.value.substring(1)}</TableCell>)
												} else if (cell.value[0] === "-") {
													return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
														{downTrendSvg()}{cell.value.substring(1)}</TableCell>)
												} else {
													return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>{cell.value}</TableCell>)
												}
											})}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</DataTable>
			</Column>
			<Column style={{ padding: "0px 0px 0px 20px" }}>
				<DataTable rows={OBGYNVisitsRow} headers={OBGYNVisitsHeader} isSortable>
					{({
						rows = OBGYNVisitsRow,
						headers = OBGYNVisitsHeader,
						getHeaderProps,
						getRowProps,
						getTableProps,
						getTableContainerProps
					}: { rows: any, headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
						<TableContainer
						title={
                            <div style={{display: "flex", justifyContent: "space-between", lineHeight: "20px"}}>
                                <p style={{fontSize: "20px"}}>OB / GYN Visits</p>
                                <OverflowMenu></OverflowMenu>
                            </div>
                        }	
						{...getTableContainerProps()}
						>
							<div style={{paddingBottom: "18px", backgroundColor: "#ffffff"}}/>
                            <Table {...getTableProps()} isSortable>
								<TableHead>
									<TableRow>
										{headers.map((header) => (
											<TableHeader
												key={header.key}
												{...getHeaderProps({ header })}
												isSortable
											>
												{header.header}
											</TableHeader>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.map((row: any) => (
										<TableRow {...getRowProps({ row })}>
											{row.cells.map((cell: any) => {
												if (cell.value[0] === "+") {
													return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
														{upTrendSvg()}{cell.value.substring(1)}</TableCell>)
												} else if (cell.value[0] === "-") {
													return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
														{downTrendSvg()}{cell.value.substring(1)}</TableCell>)
												} else {
													return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>{cell.value}</TableCell>)
												}
											})}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</DataTable>
			</Column>
		</Row>

        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Utilization Details </h3></Row>
        <Row style={{ paddingTop: '30px', marginLeft: "0px", height: "auto"}}>
            <Column style={{ backgroundColor: "#FFF", justifyContent: "center", marginRight: '25px', height: "auto" }}>
                <div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "auto", display:"flex"}}>
                    <PieChart options={PhysicianOfficeVisits.options} data={PhysicianOfficeVisits.data}/>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", justifyContent: "center", marginRight: '25px', height: "auto" }}>
                <div id="Export-admitsPer1000" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "auto", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px' }}>
						<h5>Physician Office Visits</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>By Relationship</p>
						<StackedBarChart options={PhysicianOfficeVisitsByRelationship.options} data={PhysicianOfficeVisitsByRelationship.data}/>
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", justifyContent: "center", height: "auto" }}>
                <div id="Export-admitsPer1000" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "auto", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px' }}>
						<h5>PCP Visits per 1,000</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>By CCHG Chronic Condition Status</p>
						<PieChart options={PCPVisitsPer1000.options} data={PCPVisitsPer1000.data}/>
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>
        <Row style={{ paddingTop: '12px', marginLeft: "0px", marginRight: '371px'}}>
            <Column style={{ backgroundColor: "#FFF", justifyContent: "center", marginRight: '25px' }}>
                <div id="Export-admitsPer1000" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px' }}>
						<h5>Office Visits</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>Percent Preventive Visits</p>
						<DonutChart options={OfficeVisits.options} data={OfficeVisits.data}/>
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", justifyContent: "center" }}>
                <div id="Export-admitsPer1000" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px' }}>
						<h5>PCP Office Visits Utils per 1,000</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>By Relationship and Provider Network Status</p>
						<StackedBarChart options={PCPOfficeVisitsUtilsPer1000.options} data={PCPOfficeVisitsUtilsPer1000.data}/>
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>

        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Telehealth Utilization</h3></Row>
        <Row style={{ marginTop: "22px", marginLeft: '0px' }}>
            <Column style={{ backgroundColor: "#FFF", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px', width: '640px'  }}>
						<AreaChart options={TelehealthVisitsPercentOfAllOfficeVisits.options} data={TelehealthVisitsPercentOfAllOfficeVisits.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px'  }}>
						<h5>Telehealth Utilization</h5>
						<p style={{ color: "#616771", paddingBottom: '20px' }}>By Gender</p>
						<PieChart options={TelehealthUtilization.options} data={TelehealthUtilization.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>

        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Provider Type Utilization Comparison</h3></Row>
        <Row style={{ marginTop: "22px", marginLeft: '0px' }}>
            <Column style={{ backgroundColor: "#FFF", justifyContent: "center" }}>
                <div id="Export-admitsPer1000" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px', width: "500px" }}>
						<h5>Top Provider Specialties</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>By Utilization per 1,000</p>
						<TreemapChart options={TopProviderSpecialties.options} data={TopProviderSpecialties.data}/>
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex", minWidth: '95%'}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px', minWidth: "100%"  }}>
						<StackedBarChart options={SpecialistUtilizationByNetworkStatus.options} data={SpecialistUtilizationByNetworkStatus.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>

        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Top PCP Clinics Comparison</h3></Row>
        <Row style={{ marginTop: "22px", marginLeft: '0px' }}>
            <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "20px", height: "100%", display: "flex", width: "100%"}}>
                <div style={{ backgroundColor: "white", paddingBottom: '10px', width: '100%'  }}>
					<ComboChart options={TopPCPCClinics.options} data={TopPCPCClinics.data} />
				</div>
                <OverflowMenu/>
            </div>
        </Row>

        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Attributed PCP Clinic Detail Comparison</h3></Row>
        <Row style={{ marginTop: "22px", marginLeft: '0px' }}>
            <DataTable rows={AttributedPCPClinicalDetailComparisonRow} headers={AttributedPCPClinicalDetailComparisonHeader} isSortable>
				{({
					rows = AttributedPCPClinicalDetailComparisonRow,
					headers = AttributedPCPClinicalDetailComparisonHeader,
					getHeaderProps,
					getRowProps,
					getTableProps,
					getTableContainerProps
				}: { rows: any, headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
					<TableContainer
                        title={
                            <div style={{display: "flex", justifyContent: "space-between", height: "1.5rem"}}>
                                <p style={{fontSize: "20px"}}>Attributed PCP Clinic Detail Comparison</p>
                                <OverflowMenu></OverflowMenu>
                            </div>
                        }
                        style={{paddingBottom: '40px'}}
						{...getTableContainerProps()}
					>
						<Table {...getTableProps()} isSortable>
							<TableHead style={{paddingBottom: "40px"}}>
								<TableRow>
									{headers.map((header) => (
										<TableHeader
											key={header.key}
											{...getHeaderProps({ header })}
											isSortable
										>
											{header.header}
										</TableHeader>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
                                      {rows.map((row: DenormalizedRow) => (
                                          <TableRow key={row.id} {...getRowProps({ row })}>
                                              {row.cells.map((cell: DataTableCell) => (
                                                  <TableCell key={cell.id} style={{ backgroundColor: "#FFF" }}>{cell.value}</TableCell>
                                              ))}
                                          </TableRow>
                                      ))}
                                  </TableBody>
						</Table>
					</TableContainer>
				)}
			</DataTable>
        </Row>

		<Row id="TOC:Explore Related Content" style={{ marginLeft: "0px", padding: '3rem 0rem 1rem' }}>
            <h1>Explore Related Topics</h1>
        </Row>
        <Row id="tableLink" style={{marginLeft: "0px", maxWidth: '80%', marginBottom: '60px' }}>
            <Column style={{ padding: '0px' }}>
                <ul>
                    <li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
                                <StyledLink to="/Telehealth" onClick={() => window.scrollTo(0, 0)}>Telehealth<span style={{ color: 'gray' }}> (Special Topic)</span> </StyledLink>
                    </li>
                    <li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
                        <StyledLink to="#">Attributed PCPs<span style={{ color: 'gray' }}> (Special Topic)</span>  </StyledLink>
                    </li>
                    <li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
                        <StyledLink to="#">Out of Network Providers<span style={{ color: 'gray' }}> (Special Topic)</span>  </StyledLink>
                    </li>
                </ul>
            </Column>
			<Column style={{ padding: '0px' }}>
                <ul>
                    <li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
                        <StyledLink to="#">Prevention and Compliance<span style={{ color: 'gray' }}> (Storyboard Page)</span> </StyledLink>
                    </li>
                </ul>
            </Column>
        </Row>
    </Column>
}