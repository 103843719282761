import React from "react";
import { TreemapChart } from '@carbon/charts-react';
const data = require("../../../data/TreeChartNetDollarsByCCHG/data.json");

const options = {
    "title": "Top 10 Net Paid Dollars by CCHG",
    "height": "400px",
    "color": {
        "scale": {
            "Cancer": "#0080E2",
            "Diabetes without": "#0073CB",
            "Hypertension": "#00335A",
            "Neurological": "#004D88",
            "Healthy Female": "#004071",
            "Depression, Sub...": "#005A9E",
            "Renal Failure": "#002644",
            "Healthy F...": "#001A2D",
            "Healthy Fe...": "#000D17"
        }
    },
    "legend": {
        "enabled": false
    }
}

const optionsPdf = {
    "title": "Top 10 Net Paid Dollars by CCHG",
    "height": "300px",
    "color": {
        "scale": {
            "Cancer": "#0080E2",
            "Diabetes without": "#0073CB",
            "Hypertension": "#00335A",
            "Neurological": "#004D88",
            "Healthy Female": "#004071",
            "Depression, Sub...": "#005A9E",
            "Renal Failure": "#002644",
            "Healthy F...": "#001A2D",
            "Healthy Fe...": "#000D17"
        }
    },
    "legend": {
        "enabled": false
    }
}

const TreeChartNetDollarsByCCHG = (props: any) => {
    return <TreemapChart options={props.isPdf ? optionsPdf : options} data={data} />;
}

export default TreeChartNetDollarsByCCHG;