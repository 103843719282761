import { DonutChartOptions, LegendPositions } from "@carbon/charts/interfaces";

const inpatientAdmissionsData = [
        {
            "group": "Diabetes short term complication",
            "value": 2
        },
        {
            "group": "Uncontrolled diabetes admission",
            "value": 6
        },
        {
            "group": "Lower extremity amputation",
            "value": 3
        },
        {
            "group": "Diabetes long term complication",
            "value": 4
        },
        {
            "group": "Other inpatient admission",
            "value": 85
        }
    ];

const inpatientAdmissionsOptions: DonutChartOptions = {
        title: "Inpatient Admissions",
        resizable: true,
        donut: {
            center: {
                number: 77.8,
                label: "Total Admits/1000",
                numberFormatter: (x: any) => x
            }
        },
        pie: {
            labels:{
                enabled:false
            }
        },
        height: "100%",
        color: {
            scale: {
                "Diabetes short term complication": "#FFC800",
                "Uncontrolled diabetes admission": "#FFA200",
                "Lower extremity amputation": "#FF6400",
                "Diabetes long term complication": "#A52040",
                "Other inpatient admission": "#0080E2"
            }
        },
        legend: {
            position: LegendPositions.RIGHT,
            truncation: {
                numCharacter: 50
            }
        }
    };

export {inpatientAdmissionsData, inpatientAdmissionsOptions};