import {  Information16, OverflowMenuHorizontal32, Reset16 } from '@carbon/icons-react';
import { Column, Dropdown, Checkbox, Modal, ProgressIndicator, ProgressStep, Row, Toggle, TextInput, Search, DataTable, TableContainer, Table, TableHead, TableRow, OverflowMenu, OverflowMenuItem, TableBody, TableCell, TableHeader, RadioButton } from 'carbon-components-react';
import React, { useState } from 'react';
import { SavedCustomizationHeader, SavedCustomizationRow } from '../../data/CustomizeMetrics/SavedCustomizeMetrics';
import { ChartTypes } from '../Widget/DataDisplays/ChartTypes';
import { ModalStateManager } from './ModalStateManager';

const FourCross = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.889 8.72943L14.6124 5.45289C14.4643 5.30483 14.343 5.3552 14.343 5.56509V7.47316H10.5268V3.65701L12.4227 3.64709C12.6326 3.64556 12.683 3.52345 12.5341 3.37538L9.2698 0.11105C9.12174 -0.0370166 8.87826 -0.0370166 8.7302 0.11105L5.45366 3.38759C5.30559 3.53566 5.35596 3.65701 5.56585 3.65701H7.47392V7.47316H3.65778V5.56509C3.65778 5.3552 3.53642 5.30483 3.38759 5.45366L0.11105 8.7302C-0.0370166 8.87826 -0.0370166 9.12174 0.11105 9.2698L3.38759 12.5463C3.53642 12.6944 3.65778 12.644 3.65778 12.4342V10.5261H7.47392V14.3422H5.56585C5.35596 14.3422 5.30559 14.4636 5.45366 14.6124L8.7302 17.889C8.87826 18.037 9.12174 18.037 9.2698 17.889L12.5463 14.6124C12.6952 14.4636 12.6448 14.3422 12.4349 14.3422H10.5268V10.5261H14.343V12.4342C14.343 12.644 14.4643 12.6944 14.6124 12.5456L17.889 9.26904C18.0378 9.12097 18.0378 8.87827 17.889 8.72943Z" fill="#39414D" />
        </svg>
    )
}

const items = [
    {
      id: 'option-0',
      text: 'New Customization',
    },
    {
      id: 'option-1',
      text: 'Saved Customizations',
    }
];

const membershipIndicators = () => {
    return [
        { key: 0, text: 'Average Members', selected: true, disabled: false },
        { key: 1, text: 'Average Employee', selected: true, disabled: false },
        { key: 2, text: 'MARA Age/Gender Risk', selected: true, disabled: false },
        { key: 3, text: 'MARA Conc or Prosp Risk', selected: true, disabled: false },
        { key: 4, text: 'Claimants', selected: false, disabled: true },
        { key: 5, text: '% Population Chronic', selected: false, disabled: true },
        { key: 6, text: '% Population Care Management', selected: false, disabled: true },
        { key: 7, text: '% Female', selected: false, disabled: true },
        { key: 8, text: 'Average Age', selected: false, disabled: true },
        { key: 9, text: '[Insert Metric]', selected: false, disabled: true },
        { key: 10, text: '[Insert Metric]', selected: false, disabled: true },
        { key: 11, text: '[Insert Metric]', selected: false, disabled: true }
    ]
};
const costIndicators = () => {
    return [
        { key: 0, text: 'Allowed PMPM', selected: true, disabled: false },
        { key: 1, text: 'Net Paid PMPM', selected: true, disabled: false },
        { key: 2, text: 'Adequacy of Benefits', selected: true, disabled: false },
        { key: 3, text: 'Inpatient Admits/1000', selected: true, disabled: false },
        { key: 4, text: 'HCC Paid PMPM', selected: false, disabled: true },
        { key: 5, text: '% of Spend HCC', selected: false, disabled: true },
        { key: 6, text: 'Rx Paid PMPM', selected: false, disabled: true },
        { key: 7, text: 'Specialty PMPM', selected: false, disabled: true },
        { key: 8, text: '[Insert Metric]', selected: false, disabled: true },
        { key: 9, text: '[Insert Metric]', selected: false, disabled: true },
        { key: 10, text: '[Insert Metric]', selected: false, disabled: true },
        { key: 11, text: '[Insert Metric]', selected: false, disabled: true }
    ];
}
const efficiencyIndicators = () => {
    return [
        { key: 0, text: 'In-Network Utilization', selected: true, disabled: false },
        { key: 1, text: 'Inpatient Avoidable Admit Rate', selected: true, disabled: false },
        { key: 2, text: 'Inpatient Average Length of Stay', selected: true, disabled: false },
        { key: 3, text: 'Rx Generic Utilization Rate', selected: true, disabled: false },
        { key: 4, text: '% Generic', selected: false, disabled: true },
        { key: 5, text: 'Re-Admit Rate', selected: false, disabled: true },
        { key: 6, text: 'Avoidable Admits', selected: false, disabled: true },
        { key: 7, text: 'Avoidable ED Visits', selected: false, disabled: true },
        { key: 8, text: 'Average Length of Stay', selected: false, disabled: true },
        { key: 9, text: '[Insert Metric]', selected: false, disabled: true },
        { key: 10, text: '[Insert Metric]', selected: false, disabled: true },
        { key: 11, text: '[Insert Metric]', selected: false, disabled: true }
    ];
}

enum category  {
    member = 'member',
    cost = 'cost',
    efficiency = 'efficiency'
}




export const CustomizeMetricsModal = () => {
    const [selectedItem, setSelectedItem] = useState({ id: "default", text: "Customize Metrics" });
    const [selectedTab, setSelectedTab] = useState(0);
    const [showWarning, setShowWarning] = useState(false);
    const [isValidated, setIsValidated] = useState(false);

    const [disableMember, setDisableMember] = useState(true);
    const [disableCost, setDisableCost] = useState(true);
    const [disableEfficiency, setDisableEfficiency] = useState(true);

    const [defaultMemberState, setDefaultMemberState] = useState(true);
    const [defaultCostState, setDefaultCostState] = useState(true);
    const [defaultEfficiencyState, setDefaultEfficiencyState] = useState(true);

	const [membershipIndicatorsList, setMembershipIndicatorsList] = useState(membershipIndicators());
	const [costIndicatorsList, setCostIndicatorsList] = useState(costIndicators());
	const [efficiencyIndicatorsList, setEfficiencyIndicatorsList] = useState(efficiencyIndicators());

    const onCheckMembershipIndicators = (event: any, index: number) => {
        membershipIndicatorsList[index].selected = event ? true : false;

        if (membershipIndicatorsList.filter((list) => list.selected === true).length === 4) {
            // disabled all unselected item 
            membershipIndicatorsList.forEach((list) => {
                if (list.selected === false) {
                    list.disabled = true
                }
            })
            setDisableMember(true);  
        } else {
            membershipIndicatorsList.forEach((list) => { list.disabled = false })
            setDisableMember(false);
        }
        setMembershipIndicatorsList([...membershipIndicatorsList] )
        if (isValidated){
            validateNext();
        }
        // check deafultState
        setDefaultMemberState(true)
        membershipIndicatorsList.slice(0,4).forEach((list)=>{
            if(list.selected === false){
                setDefaultMemberState(false)
            }
        });

    }
    
    const onCheckCostIndicatorsList = (event: any, index: number) => {
        costIndicatorsList[index].selected = event ? true : false;
        
        if (costIndicatorsList.filter((list) => list.selected === true).length === 4) {
            // disabled all unselected item 
            costIndicatorsList.forEach((list) => {
                if (list.selected === false) {
                    list.disabled = true
                }
            })
            setDisableCost(true);
            
        } else {
            costIndicatorsList.forEach((list) => { list.disabled = false })
            setDisableCost(false);
        }
        setCostIndicatorsList([...costIndicatorsList] )
        if (isValidated){
            validateNext();
        }
        // check deafultState
        setDefaultCostState(true);
        costIndicatorsList.slice(0,4).forEach((list)=>{
            if(list.selected === false){
                setDefaultCostState(false)
            }
        });
    }
    const onCheckEfficiencyIndicatorsList = (event: any, index: number) => {
        efficiencyIndicatorsList[index].selected = event ? true : false;
        if (efficiencyIndicatorsList.filter((list) => list.selected === true).length === 4) {
            // disabled all unselected item 
            efficiencyIndicatorsList.forEach((list) => {
                if (list.selected === false) {
                    list.disabled = true
                }
            })
            setDisableEfficiency(true);
    
        } else {
            efficiencyIndicatorsList.forEach((list) => { list.disabled = false })
            setDisableEfficiency(false);
        }
        setEfficiencyIndicatorsList([...efficiencyIndicatorsList] )
        if (isValidated){
            validateNext();
        }
        // check deafultState
        setDefaultEfficiencyState(true);
        efficiencyIndicatorsList.slice(0,4).forEach((list)=>{
            if(list.selected === false){
                setDefaultEfficiencyState(false)
            }
        });
    }

    const resetAllToDefault = () => {
        resetMemberStateToDefault()
        resetCostStateToDefault()
        resetEfficiencyStateToDefault()
        setShowWarning(false);

    }
    const resetMemberStateToDefault = () => {
        setMembershipIndicatorsList(membershipIndicators());
        setDefaultMemberState(true);
        setDisableMember(true);

    }
    const resetCostStateToDefault = () => {
        setCostIndicatorsList(costIndicators());
        setDefaultCostState(true);
        setDisableCost(true);

    }
    const resetEfficiencyStateToDefault = () => {
        setEfficiencyIndicatorsList(efficiencyIndicators());
        setDefaultEfficiencyState(true);
        setDefaultEfficiencyState(true);
    }





    const validateNext = () => {
        if (disableMember && disableCost && disableEfficiency ){
            setShowWarning(false);
        } else {
            setShowWarning(true);
        }
    }
    
    

    const generateRow = (listArray: typeof membershipIndicatorsList, type: category) => {
        return listArray.map((item) => {
            return (
                <Row className='listItem' style={{ marginLeft: "0", alignItems: 'center', marginRight:'20px' }}>
                    <Checkbox id={`${item.key}${type}`} checked={item.selected} style={{ height: "0" }} labelText={item.text} disabled={item.disabled} onChange={(event: any) => {
                        if (type === category.member) {
                            onCheckMembershipIndicators(event, item.key);
                        } else if (type === category.cost) {
                            onCheckCostIndicatorsList(event, item.key);
                        } else if (category.efficiency) {
                            onCheckEfficiencyIndicatorsList(event, item.key);
                        }
                    }} />
                    <Information16 className='infosign' />
                </Row>
            )
        })
    }


    const selectMetrics = (
        <Column id='selectMetricsView' style={{ padding: "0", height: "600px" }}>
            {!(defaultMemberState && defaultCostState && defaultEfficiencyState) ?
                <Row style={{ margin: "0px", justifyContent: 'flex-end', color: '#0080E2' }}> <span onClick={resetAllToDefault} style={{cursor:'pointer'}}><Reset16 style={{ marginRight: '5px' }} />Reset Metrics</span></Row> :
                <Row style={{ margin: "0px", height:'20.44px' }}> </Row>}
            <div style={(!disableMember && showWarning)? {padding: "5px", backgroundColor: 'rgb(245 224 229)'}:{ padding: "5px" }}>
                <Row style={{ marginLeft: "0px" }}>
                    <h5>Membership Indicators</h5>
                    <Column style={{ marginLeft: "10px", padding: "0", display: "flex", alignItems: "center" }}>
                        <div style={{
                            width: "95%",
                            height: "0px",
                            border: "1px solid #ECEEEF"
                        }}>
                        </div>
                    </Column>
                    {(!disableMember && showWarning) ? <Column>Please select 4 metrics in this category to continue</Column>:null}
                </Row>
                <Row style={{ marginLeft: "0px", marginTop: "20px", position:'relative' }}>
                    {defaultMemberState? null: <Reset16 onClick={resetMemberStateToDefault} style={{cursor:'pointer', marginRight:'5px', position:'absolute', right: '1%', top:'3%', color:'black'}}/>}
                    <Column style={{ paddingLeft: "0" }}>
                        {generateRow(membershipIndicatorsList.slice(0, 4), category.member)}
                    </Column>
                    <Column>
                        {generateRow(membershipIndicatorsList.slice(4, 8), category.member)}
                    </Column>
                    <Column>
                        {generateRow(membershipIndicatorsList.slice(8, 12), category.member)}
                    </Column>
                </Row>
            </div>
            <div style={(!disableCost && showWarning)? {padding: "5px",marginTop: "30px", backgroundColor: 'rgb(245 224 229)'}:{ padding: "5px",marginTop: "30px" }}>
                <Row style={{ marginLeft: "0px" }}>
                    <h5>Cost Indicators</h5>
                    <Column style={{ marginLeft: "10px", padding: "0", display: "flex", alignItems: "center" }}>
                        <div style={{
                            width: "95%",
                            height: "0px",
                            border: "1px solid #ECEEEF"
                        }}>
                        </div>
                    </Column>
                    {(!disableCost && showWarning) ? <Column>Please select 4 metrics in this category to continue</Column>:null}
                </Row>
                <Row style={{ marginLeft: "0px", marginTop: "20px", position:'relative' }}>
                   { defaultCostState ? null : <Reset16 onClick={resetCostStateToDefault} style={{cursor:'pointer', marginRight:'5px', position:'absolute', right: '1%', top:'3%', color:'black'}}/>}

                    <Column style={{ paddingLeft: "0" }}>
                        {generateRow(costIndicatorsList.slice(0, 4), category.cost)}
                    </Column>
                    <Column>
                        {generateRow(costIndicatorsList.slice(4, 8), category.cost)}
                    </Column>
                    <Column>
                        {generateRow(costIndicatorsList.slice(8, 12), category.cost)}
                    </Column>
                </Row>
            </div>
            <div style={(!disableEfficiency && showWarning)? {padding: "5px", marginTop: "30px", backgroundColor: 'rgb(245 224 229)'}: { padding: "5px", marginTop: "30px" }}>
                <Row style={{ marginLeft: "0px" }}>
                    <h5>Efficiency Indicators</h5>
                    <Column style={{ marginLeft: "10px", padding: "0", display: "flex", alignItems: "center" }}>
                        <div style={{
                            width: "95%",
                            height: "0px",
                            border: "1px solid #ECEEEF"
                        }}>
                        </div>
                    </Column>
                    {(!disableEfficiency && showWarning) ? <Column>Please select 4 metrics in this category to continue</Column>:null}
                </Row>
                <Row style={{ marginLeft: "0px", marginTop: "20px", position:'relative' }}>
                    {defaultEfficiencyState ? null: <Reset16 onClick={resetEfficiencyStateToDefault} style={{cursor:'pointer',marginRight:'5px', position:'absolute', right: '1%', top:'3%', color:'black'}}/>}

                    <Column style={{ paddingLeft: "0" }}>
                        {generateRow(efficiencyIndicatorsList.slice(0, 4), category.efficiency)}
                    </Column>
                    <Column>
                        {generateRow(efficiencyIndicatorsList.slice(4, 8), category.efficiency)}
                    </Column>
                    <Column>
                        {generateRow(efficiencyIndicatorsList.slice(8, 12), category.efficiency)}
                    </Column>
                </Row>
            </div>
        </Column>
    )

    const ArrangeView = (
        <Column style={{ padding: "0", height: "auto" }}>
            <Row style={{ marginLeft: "0px" }}>
                <h5>Membership Indicators</h5>
                <Column style={{ marginLeft: "10px", padding: "0", display: "flex", alignItems: "center" }}>
                    <div style={{
                        width: "95%",
                        height: "0px",
                        border: "1px solid #ECEEEF"
                    }}>
                    </div>
                </Column>
            </Row>
            <Row style={{ marginLeft: "0px", padding: '23px 37px 53px 1px' }}>
                <Column style={{ height: '127px', backgroundColor: 'white', position: 'relative' }}>
                    <div style={{ position: 'absolute', borderLeft: '2px solid #ECEEEF', height: '100px', right: '0px', top: '13px' }} />
                    <p style={{ padding: '14px 0px' }}>{FourCross()}</p>
                    <p style={{ color: '#727A7D', textAlign: 'center', paddingInline: '5px' }}>MARA Age/Gender Risk</p>
                </Column>
                <Column style={{ height: '127px', backgroundColor: 'white', position: 'relative' }}>
                    <div style={{ position: 'absolute', borderLeft: '2px solid #ECEEEF', height: '100px', right: '0px', top: '13px' }} />
                    <p style={{ padding: '14px 0px' }}>{FourCross()}</p>
                    <p style={{ color: '#727A7D', textAlign: 'center', paddingInline: '5px' }}>Average Members</p>
                </Column>
                <Column style={{ height: '127px', backgroundColor: 'white', position: 'relative' }}>
                    <div style={{ position: 'absolute', borderLeft: '2px solid #ECEEEF', height: '100px', right: '0px', top: '13px' }} />
                    <p style={{ padding: '14px 0px' }}>{FourCross()}</p>
                    <p style={{ color: '#727A7D', textAlign: 'center', paddingInline: '5px' }}>MARA Conc or Prosp Risk</p>
                </Column>
                <Column style={{ height: '127px', backgroundColor: 'white', position: 'relative' }}>
                    <p style={{ padding: '14px 0px' }}>{FourCross()}</p>
                    <p style={{ color: '#727A7D', textAlign: 'center', paddingInline: '5px' }}>% Female</p>
                </Column>
            </Row>

            {/* // -------------- */}
            <Row style={{ marginLeft: "0px" }}>
                <h5>Cost Indicators</h5>
                <Column style={{ marginLeft: "10px", padding: "0", display: "flex", alignItems: "center" }}>
                    <div style={{
                        width: "95%",
                        height: "0px",
                        border: "1px solid #ECEEEF"
                    }}>
                    </div>
                </Column>
            </Row>
            <Row style={{ marginLeft: "0px", padding: '23px 37px 53px 1px' }}>
                <Column style={{ height: '127px', backgroundColor: 'white', position: 'relative' }}>
                    <div style={{ position: 'absolute', borderLeft: '2px solid #ECEEEF', height: '100px', right: '0px', top: '13px' }} />
                    <p style={{ padding: '14px 0px' }}>{FourCross()}</p>
                    <p style={{ color: '#727A7D', textAlign: 'center', paddingInline: '5px' }}>Allowed PMPM</p>
                </Column>
                <Column style={{ height: '127px', backgroundColor: 'white', position: 'relative' }}>
                    <div style={{ position: 'absolute', borderLeft: '2px solid #ECEEEF', height: '100px', right: '0px', top: '13px' }} />
                    <p style={{ padding: '14px 0px' }}>{FourCross()}</p>
                    <p style={{ color: '#727A7D', textAlign: 'center', paddingInline: '5px' }}>Net Paid PMPM</p>
                </Column>
                <Column style={{ height: '127px', backgroundColor: 'white', position: 'relative' }}>
                    <div style={{ position: 'absolute', borderLeft: '2px solid #ECEEEF', height: '100px', right: '0px', top: '13px' }} />
                    <p style={{ padding: '14px 0px' }}>{FourCross()}</p>
                    <p style={{ color: '#727A7D', textAlign: 'center', paddingInline: '5px' }}>Inpatient Admits/1000</p>
                </Column>
                <Column style={{ height: '127px', backgroundColor: 'white', position: 'relative' }}>
                    <p style={{ padding: '14px 0px' }}>{FourCross()}</p>
                    <p style={{ color: '#727A7D', textAlign: 'center', paddingInline: '5px' }}>Rx Paid PMPM</p>
                </Column>
            </Row>
            {/* // -------------- */}
            <Row style={{ marginLeft: "0px" }}>
                <h5>Efficiency Indicators</h5>
                <Column style={{ marginLeft: "10px", padding: "0", display: "flex", alignItems: "center" }}>
                    <div style={{
                        width: "95%",
                        height: "0px",
                        border: "1px solid #ECEEEF"
                    }}>
                    </div>
                </Column>
            </Row>
            <Row style={{ marginLeft: "0px", padding: '23px 37px 53px 1px' }}>
                <Column style={{ height: '127px', backgroundColor: 'white', position: 'relative' }}>
                    <div style={{ position: 'absolute', borderLeft: '2px solid #ECEEEF', height: '100px', right: '0px', top: '13px' }} />
                    <p style={{ padding: '14px 0px' }}>{FourCross()}</p>
                    <p style={{ color: '#727A7D', textAlign: 'center', paddingInline: '5px' }}>Inpatient Avoidable Rate</p>
                </Column>
                <Column style={{ height: '127px', backgroundColor: 'white', position: 'relative' }}>
                    <div style={{ position: 'absolute', borderLeft: '2px solid #ECEEEF', height: '100px', right: '0px', top: '13px' }} />
                    <p style={{ padding: '14px 0px' }}>{FourCross()}</p>
                    <p style={{ color: '#727A7D', textAlign: 'center', paddingInline: '5px' }}>Inpatient Average Length of Stay</p>
                </Column>
                <Column style={{ height: '127px', backgroundColor: 'white', position: 'relative' }}>
                    <div style={{ position: 'absolute', borderLeft: '2px solid #ECEEEF', height: '100px', right: '0px', top: '13px' }} />
                    <p style={{ padding: '14px 0px' }}>{FourCross()}</p>
                    <p style={{ color: '#727A7D', textAlign: 'center', paddingInline: '5px' }}>Rx Generic Utilization Rate</p>
                </Column>
                <Column style={{ height: '127px', backgroundColor: 'white', position: 'relative' }}>
                    <p style={{ padding: '14px 0px' }}>{FourCross()}</p>
                    <p style={{ color: '#727A7D', textAlign: 'center', paddingInline: '5px' }}>Avoidable ED Visits</p>
                </Column>
            </Row>
        </Column>
    )

    const SaveView = (
        <Column className='saveView' style={{ padding: "0", height: "auto" }}>
            <Row style={{ marginLeft: '0px', flexDirection: 'column' }}>
                <Row style={{ marginInline: '0px' }}> <h6>Save Customization for Later</h6><p style={{ marginLeft: '5px' }}>{<Information16 />}</p></Row>
                <Toggle id="saveForLaterToggle"
                    labelText=''
                    labelA='No'
                    labelB='Yes'
                    style={{ paddingTop: '0px' }}
                    defaultToggled />
            </Row>

            <Row style={{ marginLeft: '0px', width: '80%', paddingTop: '30px' }} >
                <TextInput id="packetTitleInput" labelText="Name" placeholder="Enter name" defaultValue="" />
            </Row>

            <Row style={{ marginLeft: '0px', width: '80%', paddingTop: '37px' }}>
                <TextInput id="packetTitleInput" labelText="Description (optional)" placeholder="Enter description" defaultValue="" />
            </Row>

            <Row style={{ marginLeft: '0px', paddingTop: '37px' }}>
                <Toggle
                    id="accountToggle"
                    labelText="Set as Account Default"
                    labelA='No'
                    labelB='Yes' />
            </Row>
            <Row style={{ marginLeft: '0px', paddingTop: '37px' }}>
                <Toggle
                    id="organizationToggle"
                    labelText="Share with Organization"
                    labelA='No'
                    labelB='Yes'
                    defaultToggled />
            </Row>
        </Column>
    )

    const getModalContent = () => {
        switch (selectedTab) {
            case 0:
                return selectMetrics;
            case 1:
                return ArrangeView;
            case 2:
                return SaveView;

            default:
                return selectMetrics;
        }
    }

    const getModalSubHeading = () => {
        switch (selectedTab) {
            case 0:
                return (<p>Select 4 leading indicator metrics in each category</p>);
            case 1:
                return (<p>Arrange selected metrics by dragging and dropping</p>);
            case 2:
                return (<p>Save this custom view for future access</p>);
            default:
                return selectMetrics;
        }

    }
    return (
        <ModalStateManager
            renderLauncher={({ setOpen }: any) => (
                <div>
                    <Dropdown
                        style={{ width: "100%" }}
                        id="customizeMetricsDropdown"
                        className="NewCustomization"
                        titleText=""
                        helperText=""
                        label={<span><OverflowMenuHorizontal32/> other text</span>}
                        items={items}
                        selectedItem={selectedItem}
                        itemToString={(item) => (item ? item.text : '')}
                        onChange={({ selectedItem }) => { setOpen(true); setSelectedItem(selectedItem!) }}
                    />
                </div>
            )}>
            {({ open, setOpen }: any) => (
                selectedItem.text === items[0].text ?
                    (<Modal
                        className="reportPacketBuilder"
                        open={open}
                        modalHeading={<div><h3>Customize Metrics</h3>{getModalSubHeading()}</div>}
                        modalLabel={
                            <ProgressIndicator id='metricsIndicator' style={{ float: "right" }} currentIndex={selectedTab} >
                                <ProgressStep id="tab-1" label="Select Metrics" className='storyboardTabs'>
                                </ProgressStep>
                                <ProgressStep label="Arrange View" id="tab-2">
                                </ProgressStep>
                                <ProgressStep label="Save View " id="tab-3">
                                </ProgressStep>
                            </ProgressIndicator>
                        }
                        onRequestClose={() => { setSelectedItem({ id: "default", text: "Customize Metrics" }); setOpen(false); setIsValidated(false); setSelectedTab(0) }}
                        onSecondarySubmit={() => {if (selectedTab === 0) {setOpen(false);setSelectedItem({ id: "default", text: "Customize Metrics" });} else{ setSelectedTab(selectedTab - 1);}}}
                        onRequestSubmit={() => {
                            if (selectedTab === 2) {
                                setSelectedTab(0);
                                setSelectedItem({ id: "default", text: "Customize Metrics" });
                                setOpen(false);
                            } else if (selectedTab=== 0){
                                if (!disableCost || !disableMember || !disableEfficiency) {
                                    validateNext();
                                    setIsValidated(true);
                                } else if ( disableCost && disableMember && disableMember ){
                                    setShowWarning(false);
                                    setSelectedTab(selectedTab + 1)
                                }

                            }else { setSelectedTab(selectedTab + 1) }
                        }}
                        primaryButtonText={selectedTab === 2 ? "Submit" : "Next"}
                        secondaryButtonText={selectedTab === 0 ? "Cancel" : "Back"}
                    >
                        {getModalContent()}
                    </Modal>) :
                    (
                        <SavedMetrics setSelectedItem={setSelectedItem} setOpen={setOpen} open={open}/>
                    )
            )}
        </ModalStateManager>
    );
}



export const SavedMetrics = (props: any) => {
    const [isMyCustomization, setIsMyCustomization] = useState(false)
    const [row, setRow] = useState(SavedCustomizationRow())



    const allActionsMenu = () => {
        return (<OverflowMenu renderIcon={OverflowMenuHorizontal32}>
            <OverflowMenuItem itemText={<span> Make a Copy</span>}  />
            <OverflowMenuItem itemText={<span> Export</span>}  />
            <OverflowMenuItem itemText={<span> Delete</span>} hasDivider isDelete />
        </OverflowMenu>)
    }
    const myActionsMenu = () => {
        return (<OverflowMenu renderIcon={OverflowMenuHorizontal32} >
            <OverflowMenuItem itemText={<span> Edit</span>}  />
            <OverflowMenuItem itemText={<span> Make a Copy</span>}  />
            <OverflowMenuItem itemText={<span> Share</span>} hasDivider/>
            <OverflowMenuItem itemText={<span> Export</span>} hasDivider/>
            <OverflowMenuItem itemText={<span> Delete</span>} hasDivider isDelete/>
        </OverflowMenu>)
    }

    const onClickSetRow = (state: any, id: string) =>{
        row.forEach((item) =>{
            if (item.id === id){
                item.CustomizationName.toggled = state
            } else if (state){ // only allow on toggle at a time
                item.CustomizationName.toggled = false
            }
        })
        console.log(row)
        setRow([...row]);

    }    
    return (
        <Modal
            className="savedMetrics"
            open={props.open}
            modalHeading={<div><h4>Saved Customizations</h4> <p> Displaying <strong>{isMyCustomization? 'MY': 'ALL'}</strong> customizations</p></div>}
            onRequestClose={() => { props.setSelectedItem({ id: "default", text: "Customize Metrics" }); props.setOpen(false) }}
            onRequestSubmit={() => { props.setSelectedItem({ id: "default", text: "Customize Metrics" }); props.setOpen(false) }}
            primaryButtonText={'Apply'}
            secondaryButtonText={'Cancel'}
        >
            <Column id='savedMetricsParent' style={{ padding: "0" }}>
                <Row style={{ margin: '0px 0px 20px 0px', alignItems: 'center',  }}>
                    <Column style={{ padding: '0px' }}><div id='searchByName'><Search placeholder="Search by name or owner" labelText="" /></div></Column>
                    <Column style={{ padding: '0px' }}>
                        <Checkbox
                            id='myCustomizationCheckbox'
                            checked={isMyCustomization}
                            style={{ height: "0" }}
                            labelText={'Show only my customizations'}
                            onClick={() => setIsMyCustomization(!isMyCustomization)} />
                    </Column>
                </Row>

                {/* {} */}
                <Row style={{ marginLeft: "0", marginRight: "0px", marginTop: "10px" }} className='savedCustomizationTable'>
                    <DataTable rows={row} headers={SavedCustomizationHeader} isSortable >
                        {({
                            rows = row,
                            headers = SavedCustomizationHeader,
                            getHeaderProps,
                            getRowProps,
                            getTableProps,
                            getTableContainerProps
                        }: { rows: any, headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
                            <TableContainer
                                style={{ width: "100%" }}
                                {...getTableContainerProps()}
                            >
                                <Table {...getTableProps()} isSortable>
                                    <TableHead>
                                        <TableRow>
                                            {headers.map((header) => (
                                                <TableHeader
                                                    key={header.key}
                                                    {...getHeaderProps({ header })}
                                                    isSortable
                                                >
                                                    {header.header}
                                                </TableHeader>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row: any) => {
                                            console.log({ row })
                                            return (
                                                <><TableRow {...getRowProps({ row })}>
                                                    {row.cells.map((cell: any) => {
                                                        if (cell.value === "actions") {
                                                            return (<TableCell style={{ backgroundColor: "##EFF0F1" }} key={cell.id}>{isMyCustomization ? myActionsMenu() : allActionsMenu()}</TableCell>)
                                                        }
                                                        else if (cell.value.name && cell.value.name.includes("Name ")) {
                                                            return (<TableCell style={{ backgroundColor: "##EFF0F1" }} key={cell.id}>
                                                                <div style={{ display: 'flex', marginRight: '10px', marginBottom: '1px' }}>
                                                                    <RadioButton value="radio-1" id={cell.value.name + row.id} checked={cell.value.toggled}
                                                                        onClick={() => onClickSetRow(!cell.value.toggled, row.id)} />
                                                                    <div>{cell.value.name}</div>
                                                                </div>
                                                            </TableCell>)
                                                        } else {
                                                            return (<TableCell style={{ backgroundColor: "##EFF0F1" }} key={cell.id}>{cell.value}</TableCell>)
                                                        }
                                                    })}
                                                </TableRow>
                                                    {row.cells[0].value?.toggled ?
                                                        <TableRow >
                                                            <TableCell colSpan={4} style={{ backgroundColor: 'white' }}>
                                                                <p style={{ padding: '10px', marginLeft: '30px', maxWidth: '55%' }}> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tincidunt sodales placerat. Praesent feugiat viverra sem, a ullamcorper est posuere dignissim. Duis sit amet feugiat libero. Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p> </TableCell>
                                                        </TableRow> : null}
                                                </>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </DataTable>
                </Row>
            </Column>


            <Column style={{ padding: "0" }}>
                <Row style={{ marginLeft: '0px', paddingTop: '37px' }}>
                    <Toggle
                        id="accountToggle"
                        labelText="Set as Account Default"
                        labelA='No'
                        labelB='Yes' />
                </Row>
            </Column>
        </Modal>
        
    )

}