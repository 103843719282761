import { SideNavItems, SideNavMenuItem, SideNavMenu, SideNavLink } from 'carbon-components-react';
import { Link } from 'react-router-dom';
import StyledLink from './StyledLink';

const Home = () => {
    return (
        <svg  width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.66671 11.3333V7.33333H8.33337V11.3333H11.6667V6H13.6667L7.00004 0L0.333374 6H2.33337V11.3333H5.66671Z" fill="white"/>
        </svg>
    );
}

const ViewCarousel = () => {
    return (
        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.66671 10.6667H10.3334V0.666748H3.66671V10.6667ZM0.333374 9.33342H3.00004V2.00008H0.333374V9.33342ZM11 2.00008V9.33342H13.6667V2.00008H11Z" fill="white"/>
        </svg>
    )
}

const Stars = () => {
    return (
        <svg width="14" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.99337 0.333252C3.31337 0.333252 0.333374 3.31992 0.333374 6.99992C0.333374 10.6799 3.31337 13.6666 6.99337 13.6666C10.68 13.6666 13.6667 10.6799 13.6667 6.99992C13.6667 3.31992 10.68 0.333252 6.99337 0.333252ZM9.82004 10.9999L7.00004 9.29992L4.18004 10.9999L4.92671 7.79325L2.44004 5.63992L5.72004 5.35992L7.00004 2.33325L8.28004 5.35325L11.56 5.63325L9.07337 7.78659L9.82004 10.9999Z" fill="white"/>
        </svg>
    )
}

const ArrowsHorizontal = () => {
    return (
        <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.00671 6.33325H0.333374V7.66659H5.00671V9.66659L7.66671 6.99992L5.00671 4.33325V6.33325ZM8.99337 5.66659V3.66659H13.6667V2.33325H8.99337V0.333252L6.33337 2.99992L8.99337 5.66659Z" fill="white"/>
        </svg>
        
    )
}

const Timer = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99671 1.33325C4.31337 1.33325 1.33337 4.31659 1.33337 7.99992C1.33337 11.6833 4.31337 14.6666 7.99671 14.6666C11.68 14.6666 14.6667 11.6833 14.6667 7.99992C14.6667 4.31659 11.68 1.33325 7.99671 1.33325ZM8.00004 13.3333C5.05337 13.3333 2.66671 10.9466 2.66671 7.99992C2.66671 5.05325 5.05337 2.66659 8.00004 2.66659C10.9467 2.66659 13.3334 5.05325 13.3334 7.99992C13.3334 10.9466 10.9467 13.3333 8.00004 13.3333Z" fill="white"/>
            <path d="M8.33337 4.66675H7.33337V8.66675L10.83 10.7667L11.3334 9.94675L8.33337 8.16675V4.66675Z" fill="white"/>
        </svg>
    )
}

const SideNavigation = () => (
    <SideNavItems>
        <StyledLink to="/">
            <SideNavLink renderIcon={Home} style={{textDecoration: "none"}}>
                My Dashboard
            </SideNavLink>
        </StyledLink>
        <SideNavMenu renderIcon={ViewCarousel} title="Storyboards">
            <StyledLink to="/SmallGroupAnnual">
                <SideNavMenuItem>Monthly Engagements</SideNavMenuItem>
            </StyledLink>
            <StyledLink to="/SmallGroupAnnual">
                <SideNavMenuItem>Small Group Semi-Annual</SideNavMenuItem>
            </StyledLink>
            <StyledLink to="/SmallGroupAnnual">
                <SideNavMenuItem>Small Group Annual</SideNavMenuItem>
            </StyledLink>
            <StyledLink to="/SmallGroupAnnual">
                <SideNavMenuItem>Large Group Quarterly</SideNavMenuItem>
            </StyledLink>
            <StyledLink to="/SmallGroupAnnual">
                <SideNavMenuItem>Large Group Semi-Annual</SideNavMenuItem>
            </StyledLink>
            <StyledLink to="/SmallGroupAnnual">
                <SideNavMenuItem>Large Group Annual</SideNavMenuItem>
            </StyledLink>
        </SideNavMenu>
        <StyledLink to="/SpecialTopics">
            <SideNavLink renderIcon={Stars} style={{textDecoration: "none"}}>
                Special Topics
            </SideNavLink>
        </StyledLink>
        <StyledLink to="/CompareContrast">
            <SideNavLink renderIcon={ArrowsHorizontal}>
                Compare/Contrast
            </SideNavLink>
        </StyledLink>
        <StyledLink to="/ReportScheduler">
            <SideNavLink renderIcon={Timer}>
                Report Scheduler
            </SideNavLink>
        </StyledLink>
    </SideNavItems>
);

export default SideNavigation;