import { Row, Column, DataTable, TableHead, TableRow, TableBody, TableCell, TableContainer, Table, TableHeader, DataTableHeader, DataTableCell, DenormalizedRow, OverflowMenu } from 'carbon-components-react';
import { StackedBarChart, GroupedBarChart, TreemapChart, SimpleBarChart } from '@carbon/charts-react';
import { utilizationByDiagnosis, relationshipType, paidPMPMbyAgeBand, EBMCompliance, TelemedVisits, InpatientAdmitsVisits, EmergencyDepartment, membersWithHighOpiodUse, rxGenericUtilizationRate, rxMailOrderRate, populationImpact,
    paidPMPMByDiagnosis, pharmacyUtilizationByTherapeuticClass, pharmacyUtilizationPer1000ByTherapeuticClass, MHSAClaimantsSpendByCCHG, totalCostImpactOfBehavioralComorbidity } from "../../../data/Chart1/chartData"
import StyledLink from '../../SideNavigation/StyledLink';
import OverviewBox from '../../Other/OverviewBox';
const keyIndicators = require("../../../data/BehavioralHealth/BehavioralHealthKeyIndicators.json");

export const BehavioralHealth = () => {
    return <Column style={{paddingBottom: '200px'}}>
        <Row>
            <Column>
                <OverviewBox
                    maxPerSection={3}
                    description="Claimants with behavioral health diagnoses contribute to a higher percentage of overall plan spend than non-behavioral health diagnosed claimants. Explore the utilization trends associated with behavioral health breakouts. Examine the impact on pharmacy spend and overall key take-aways on the financial impact of behavioral health co-morbidities."
                    sections={['Cost & Utilization Summary', "Demographic Profile", "Medical Utilization",  "Pharmacy Utilization", "Understand The Impact of Behavioral Health" ]}
                />
            </Column>
        </Row>

        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>{'Cost & Utilization Summary'}</h3></Row>
        <Row style={{marginLeft: '0px'}}>
            <div style={{marginTop: "20px", display: "flex", width: '100%', marginLeft: '0px'}}>
                <DataTable rows={keyIndicators.rows} headers={keyIndicators.headers}>
                    {({
                        rows = keyIndicators.rows,
                        headers = keyIndicators.headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps,
                        getTableContainerProps,
                    }: { rows: DenormalizedRow[], headers: DataTableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
                        <TableContainer
                            title="Behavioral Health Key Indicators"
                            {...getTableContainerProps()}
                            style={{backgroundColor: "#FFF", flexGrow: 1, width: "100%"}}>
                            <Table {...getTableProps()} style={{backgroundColor: "#FFF", width: "100%"}}>
                                <TableHead>
                                    <TableRow>
                                        {
                                        headers.map((header: DataTableHeader) => (
                                            <TableHeader
                                                key={header.key}
                                                {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                
                                <TableBody>
                                    {rows.map((row: DenormalizedRow) => (
                                        <TableRow key={row.id} {...getRowProps({ row })}>
                                            {row.cells.map((cell: DataTableCell) => (
                                                <TableCell key={cell.id} style={{ backgroundColor: "#FFF" }}>{cell.value}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </DataTable>
            </div>
        </Row>

        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Demographic Profile</h3></Row>
        <Row style={{ paddingTop: '30px', marginLeft: "0px"}}>
            <Column style={{ backgroundColor: "#FFF", justifyContent: "center", width: '30%' }}>
                <div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "100%", display:"flex"}}>
                    <StackedBarChart options={relationshipType.options} data={relationshipType.data} />
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", justifyContent: "center", marginLeft: '25px', width: "65%" }}>
                <div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "100%", display:"flex"}}>
                    <StackedBarChart options={paidPMPMbyAgeBand.options} data={paidPMPMbyAgeBand.data}/>
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>

        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Medical Utilization</h3></Row>
        <Row style={{ marginTop: "22px", marginLeft: '0px' }}>
            <Column style={{ backgroundColor: "#FFF", display: "flex", alignItems: "center", width: '100%' }}>
                <div id="Export-utilizationByDiagnosis" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex", width: '100%' }}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px', flex:1 }}>
						<h5 style={{paddingBottom: '40px'}}>Utils per 1,000 by Diagnosis</h5>
						<GroupedBarChart options={utilizationByDiagnosis.options} data={utilizationByDiagnosis.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", display: "flex", alignItems: "center", width: '100%', marginLeft: '24px'  }}>
                <div id="Export-utilizationByDiagnosis" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex", width: '100%' }}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px', flex:1 }}>
						<h5 style={{paddingBottom: '40px'}}>Paid PMPM by Diagnosis</h5>
						<GroupedBarChart options={paidPMPMByDiagnosis.options} data={paidPMPMByDiagnosis.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>
        <Row style={{ marginTop: "22px", marginLeft: '0px' }}>
            <Column style={{ backgroundColor: "#FFF", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px' }}>
						<h5 style={{paddingBottom: '40px'}}>Telehealth Visits per 1,000</h5>
						<GroupedBarChart options={TelemedVisits.options} data={TelemedVisits.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px'  }}>
						<h5 style={{paddingBottom: '40px'}}>EBM Compliance</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>{""}</p>
						<GroupedBarChart options={EBMCompliance.options} data={EBMCompliance.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px'  }}>
						<h5 style={{paddingBottom: '30px'}}>Inpatient Admits per 1,000</h5>
						<GroupedBarChart options={InpatientAdmitsVisits.options} data={InpatientAdmitsVisits.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px' }}>
						<h5 style={{paddingBottom: '40px'}}>ED Visits per 1,000</h5>
						<GroupedBarChart options={EmergencyDepartment.options} data={EmergencyDepartment.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>

        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Pharmacy Utilization</h3></Row>
        <Row style={{ marginTop: "22px", marginLeft: '0px' }}>
            <Column style={{ backgroundColor: "#FFF", display: "flex", alignItems: "center", width: '100%' }}>
                <div id="Export-utilizationByDiagnosis" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex", width: '100%'}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px', flex: 1 }}>
						<h5>Rx Paid per Script by Therapeutic Class</h5>
						<p style={{ color: "#616771", paddingBottom: '30px' }}>Limited to MH Therapeutic Classes</p>
						<GroupedBarChart options={pharmacyUtilizationByTherapeuticClass.options} data={pharmacyUtilizationByTherapeuticClass.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", display: "flex", alignItems: "center", width: '100%', marginLeft: '24px' }}>
                <div id="Export-utilizationByDiagnosis" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex", width: '100%'}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px', flex: 1 }}>
						<h5>Rx Scripts per 1,000 by Therapeutic Class</h5>
						<p style={{ color: "#616771", paddingBottom: '30px' }}>Limited to MH Therapeutic Classes</p>
						<GroupedBarChart options={pharmacyUtilizationPer1000ByTherapeuticClass.options} data={pharmacyUtilizationPer1000ByTherapeuticClass.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>
        <Row style={{ marginTop: "22px", marginLeft: '0px' }}>
            <Column style={{ backgroundColor: "#FFF", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px' }}>
						<h5 style={{ paddingBottom: '40px' }}>Members with High Opioid Use</h5>
						<GroupedBarChart options={membersWithHighOpiodUse.options} data={membersWithHighOpiodUse.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px'  }}>
						<h5 style={{ paddingBottom: '40px' }}>Rx Generic Utilization Rate</h5>
						<GroupedBarChart options={rxGenericUtilizationRate.options} data={rxGenericUtilizationRate.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px'  }}>
						<h5 style={{paddingBottom: '40px'}}>Rx Mail Order Rate per 1,000</h5>
						<GroupedBarChart options={rxMailOrderRate.options} data={rxMailOrderRate.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>

        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Understand the Impact of Behavioral Health</h3></Row>
        <Row style={{ paddingTop: '30px', marginLeft: '0px', display: 'flex'}}>
            <Column style={{ backgroundColor: "#FFF", justifyContent: "center", maxWidth: '715px' }}>
                <div id="Export-utilizationByDiagnosis" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex", width: '715px' }}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px', flex:1 }}>
						<TreemapChart options={MHSAClaimantsSpendByCCHG.options} data={MHSAClaimantsSpendByCCHG.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{paddingRight:'0px'}}>
                <Row style={{ paddingTop: "17px", height: '409px', backgroundColor: '#FFFFFF', marginLeft: '22px', flex: 1, marginRight: '0px'}}>
                    <p style={{width: '350px', color: '#39414D', lineHeight: '26px', fontSize: '20px', marginLeft: '21px', marginTop: '12px', fontWeight: 600}}>How to Track Comorbid Impact</p>
                    <p style={{width: '350px', color: '#39414D', lineHeight: '18px', fontSize: '16px', marginLeft: '21px', marginTop: '-150px'}}>The dollars associated with behavioral claims themselves only account for about 10% or less of medical and pharmacy cost.  In order to understand the broader impact of beevaioral health, it is best to look at how cost and health are impacted when a member has a behavioral health comorbidity.</p>
                </Row>
            </Column>
        </Row>
        <Row style={{ paddingTop: '30px', marginLeft: "0px"}}>
            <Column style={{ backgroundColor: "#FFF", marginRight: "24px", justifyContent: "center" }}>
                <div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "100%", display:"flex"}}>
                    <StackedBarChart options={populationImpact.options} data={populationImpact.data} />
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", justifyContent: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px', width: '100%'  }}>
						<h5 style={{paddingBottom: '40px'}}>Total Cost Impact of Behavioral Comorbidity</h5>
						<SimpleBarChart options={totalCostImpactOfBehavioralComorbidity.options} data={totalCostImpactOfBehavioralComorbidity.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>

        <Row id="TOC:Explore Related Content" style={{ marginLeft: "0px", padding: '3rem 0rem 1rem' }}>
            <h1>Explore Related Topics</h1>
        </Row>
        <Row id="tableLink" style={{marginLeft: "0px", maxWidth: '80%', marginBottom: '60px' }}>
            <Column style={{ padding: '0px' }}>
                <ul>
                    <li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
                        <StyledLink to="#" onClick={() => window.scrollTo(0, 0)}>Behavioral Specialists<span style={{ color: 'gray' }}> (Special Topic)</span> </StyledLink>
                    </li>
                    <li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
                        <StyledLink to="Telehealth">Telehealth<span style={{ color: 'gray' }}> (Special Topic)</span>  </StyledLink>
                    </li>
                    <li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
                        <StyledLink to="#">High Cost Claimants<span style={{ color: 'gray' }}> (Special Topic)</span>  </StyledLink>
                    </li>
                </ul>
            </Column>
			<Column style={{ padding: '0px' }}>
                <ul>
                    <li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
                        <StyledLink to="#" onClick={() => window.scrollTo(0, 0)}>High Cost Claimant Member Detail<span style={{ color: 'gray' }}> (Special Topic)</span> </StyledLink>
                    </li>
                    <li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
                        <StyledLink to="Telehealth">Opioids<span style={{ color: 'gray' }}> (Special Topic)</span>  </StyledLink>
                    </li>
                    <li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
                        <StyledLink to="#">Members with Chronic Conditions<span style={{ color: 'gray' }}> (Special Topic)</span>  </StyledLink>
                    </li>
                </ul>
            </Column>
        </Row>
    </Column>
}