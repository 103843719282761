import { Information16, Reset16 } from "@carbon/icons-react";
import { Grid, Column, Modal, UnorderedList, Row, Checkbox, Search, Dropdown, FormGroup, TextInput, Toggle, ProgressIndicator, ProgressStep, FileUploaderDropContainer, Accordion, AccordionItem } from "carbon-components-react";
import { useState } from "react";
import { dragSvg } from "../CompareContrast/CompareContrast/index";
import FilterList from "../Other/FilterList";
import { ModalStateManager } from "../Other/ModalStateManager";
import { PacketSummary } from "./PacketSummary";
const availableReports = require("../../data/ReportScheduler/ContentSelection.json");
const folderSvg = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path xmlns="http://www.w3.org/2000/svg" d="M32 0H4C1.79 0 0 1.79 0 4V32C0 34.21 1.79 36 4 36H32C34.21 36 36 34.21 36 32V4C36 1.79 34.21 0 32 0ZM28 20H20V28H16V20H8V16H16V8H20V16H28V20Z" fill="#0080E2"/>
        </svg>
    )
}

const packetFrequencyOptions = [
    {
        text: 'Every Month'
    },
    {
        text: 'Every 3 Months'
    },
    {
        text: 'Every 6 Months'
    },
    {
        text: 'Every 9 Months'
    },
    {
        text: 'Annually'
    }
];

const fileTypeOptions = ["PDF", "XLSX", "PPT"];

const ReportPacketBuilder = ({setShowSuccessNotification}: {setShowSuccessNotification: any}) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedContent, setSelectedContent]: any[] = useState([]);
    let tempSelectedContent = [...selectedContent];

    const toggleCheckbox = (str: string) => {
        if (tempSelectedContent.includes(str)) {
            tempSelectedContent = tempSelectedContent.filter((x: string) => x !== str);
        } else {
            tempSelectedContent = tempSelectedContent.concat(str);
        }
    }

    const toggleCheckboxGroup = (value: any) => {
        if (typeof value === 'string') {
            toggleCheckbox(value);
        } else {
            var entries = Object.entries(value);
            entries.forEach(([key, value]: any) => {
                if (typeof value === 'string') {
                    toggleCheckbox(value);
                } else {
                    toggleCheckboxGroup(value);
                }
            });
        }
        setSelectedContent(tempSelectedContent);
    }
    const reportsList = () => (
        Object.entries(availableReports).map(([key, value]: [string, any]) =>
        (
            <>
                <Row className="titleRow" style={{margin: "0px"}}>{isSelected(value) ? <Checkbox indeterminate onChange={() => toggleCheckboxGroup(value)} id={key} labelText={key} /> : <Checkbox onChange={() => toggleCheckboxGroup(value)} checked={false} id={key} labelText={key}/> }</Row>
                <UnorderedList nested>
                    {value.map((y: any) => {
                        if (typeof y === 'string') {
                            return <Row><Checkbox checked={isSelected(y)} onChange={() => toggleCheckboxGroup(y)} id={y} labelText={y} /></Row>;
                        } else {
                            var hideDetails = key === "Storyboards" && !isSelected(y);
                            return Object.entries(y).map(([yKey, yValue]: [string, any]) => (
                                <>
                                    <Row>{isSelected(yValue) ?  <Checkbox onChange={() => toggleCheckboxGroup(yValue)} id={yKey} labelText={yKey} indeterminate /> : <Checkbox checked={isSelected(yValue)} onChange={() => toggleCheckboxGroup(yValue)} id={yKey} labelText={yKey} />}</Row>
                                    {hideDetails ? null: <UnorderedList nested>
                                        {yValue.map((report: string) => <Row><Checkbox checked={isSelected(report)} onChange={() => toggleCheckboxGroup(report)} id={report} labelText={report} /></Row>)}
                                    </ UnorderedList>}
                                </>
                            ))
                        }
                    })}
                </UnorderedList>
            </>
        )
    ));

    const selectedReports = () => (
        Object.entries(availableReports).map(([key, value]: [string, any]) =>
        (
            <>
            {/* don't show the top title */}
                {/* {isSelected(value) ? <Row style={{ marginLeft: "0" }}><span style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>{dragSvg()}</span><Checkbox style={{ height: "0" }} labelText={key} defaultChecked id={key} /></Row> : null} */}
                <UnorderedList nested>
                    {value.map((y: any) => {
                        if (typeof y === 'string') {
                            return isSelected(y) ? <Row style={{ marginLeft: "0" }}><span style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>{dragSvg()}</span><Checkbox style={{ height: "0" }} labelText={y} defaultChecked id={y} /></Row> : null;
                        } else {
                            return Object.entries(y).map(([yKey, yValue]: [string, any]) => (
                                <>
                                    {isSelected(yValue) ? <Row style={{ marginLeft: "0" }}><span style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>{dragSvg()}</span><Checkbox style={{ height: "0" }} labelText={yKey} defaultChecked id={yKey} /></Row> : null}
                                    <UnorderedList nested>
                                        {isSelected(yValue) ? yValue.map((report: string) => isSelected(report) ? <Row style={{ marginLeft: "0" }}><span style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>{dragSvg()}</span><Checkbox style={{ height: "0" }} labelText={report} defaultChecked id={report} /></Row> : null) : null}
                                    </ UnorderedList>
                                </>
                            ))
                        }
                    })}
                </UnorderedList>
            </>
        )
    ));

    const isSelected: any = (values: any) => {
        if (typeof values === 'string') {
            if (tempSelectedContent.includes(values)) {
                return true;
            } else {
                return false;
            }
        } else {
            return Object.entries(values).filter(([k, v]: [string, any]) => isSelected(v)).length > 0;
        }
    }

    const selectContentStep = (<>
        <Column style={{ float: "left", padding: "0px 30px" }} md={4}>
            <p className="faq">Select content from the list below to build your packet</p>
            <Search placeholder="Search / filter" labelText="" />
            <UnorderedList>
                {reportsList()}
            </UnorderedList>
        </Column>
        <Column style={{ float: "right", margin: "0px", padding:"0px 20px", height: "100%"}} md={4}>
            <p className="faq">Drag-and-drop selections to change the order in which they will appear</p>
            <div style = {{ backgroundColor: "#0080E2", color: "white", padding: "5px"}}><span style={{padding: "8px"}}>{selectedContent.length} Items Selected</span><span style={{float: "right", textDecoration: "underline"}} >Clear All</span></div>
            <div className="selectedReports">
                <UnorderedList>
                    {selectedReports()}
                </UnorderedList>
            </div>
        </Column></>);

    const filterStep = 
    <><div style={{ padding: "0px 25px 25px 25px" }}>Select parameters to apply to the entire packet<span style={{float: "right", color: "#0080E2"}}><Reset16 /><span style={{paddingLeft: "6px"}}>Reset all filters</span></span></div>
    <FilterList componentIDSuffix={''} /></>;

    const frequencyStep = (<Dropdown
        style={{ width: "250px", margin: "0 auto", paddingTop: "30px"}}
        id="reportFrequency"
        titleText={<>Select how often this packet will run <Information16 /></>}
        label="Dropdown menu options"
        items={packetFrequencyOptions}
        initialSelectedItem={packetFrequencyOptions[0]}
        itemToString={(item) => (item ? item.text : '')}
        onChange={() => { }}
    />);

    const reviewStep = (<Grid className="reviewStep">
        <Row>
            <Column sm={2} md={4}>
                <PacketSummary />
            </Column>
            <Column sm={2} md={4}>
                <FormGroup legendText="Export Details" className="reviewForm">
                    <Row>
                    <Dropdown
                        initialSelectedItem={fileTypeOptions[0]}
                        id="default"
                        titleText="File Type"
                        label=""
                        items={fileTypeOptions}
                        onChange={() => { }}
                    />
                    </Row>
                    <Row>
                    <TextInput id="packetTitleInput" labelText="Packet Title" placeholder="Enter Packet Title" defaultValue="Robertson Remove Inc. Semi-Annual Performance Review" />
                    </Row>
                    <Row>
                    <TextInput id="packetDescriptionInput" labelText="Packet Description (Optional)" placeholder="Enter Packet Description" />
                    </Row>
                    <Row>
                    <Toggle id="includeFilterInfoToggle" labelText="Include Filter Info" />
                    </Row>
                    <Row>
                    <p className="bx--label">Add Secondary Logo (Optional)</p>
                    <p className="faq">Only .jpg and .png files. 500kb max file size.</p>
                    <FileUploaderDropContainer
                        labelText="Drag and drop files here or upload"
                    />
                    </Row>
                    <Row>
                    <p className="bx--label">Frequently Asked Questions</p>
                    <Accordion>
                        <AccordionItem title="When will my report packet be ready?">
                            <p className="faq">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin felis dolor at diam. Accumsan mi, leo sed sit id massa pharetra enim, orci. Cursus tortor mauris facilisis rhoncus velit porttitor elementum. Ornare libero et dis in. Molestie erat sagittis, scelerisque vel ut morbi euismod blandit. Potenti semper laoreet condimentum massa bibendum proin.</p>
                        </AccordionItem>
                        <AccordionItem title="How will I know when my packet is ready?">
                            <p className="faq">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin felis dolor at diam. Accumsan mi, leo sed sit id massa pharetra enim, orci. Cursus tortor mauris facilisis rhoncus velit porttitor elementum. Ornare libero et dis in. Molestie erat sagittis, scelerisque vel ut morbi euismod blandit. Potenti semper laoreet condimentum massa bibendum proin.</p>
                        </AccordionItem>
                        <AccordionItem title="Where will my packet be made available?">
                            <p className="faq">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin felis dolor at diam. Accumsan mi, leo sed sit id massa pharetra enim, orci. Cursus tortor mauris facilisis rhoncus velit porttitor elementum. Ornare libero et dis in. Molestie erat sagittis, scelerisque vel ut morbi euismod blandit. Potenti semper laoreet condimentum massa bibendum proin.</p>
                        </AccordionItem>
                    </Accordion>
                    </Row>
                </FormGroup>
            </Column>
        </Row>
    </Grid>);
    const getModalContent = () => {
        switch (selectedTab) {
            case 0:
                return selectContentStep;
            case 1:
                return filterStep;
            case 2:
                return frequencyStep;
            case 3:
                return reviewStep;
            default:
                return reviewStep;
        }
    }
    return (
        <ModalStateManager
            renderLauncher={({ setOpen }: any) => (
                <span onClick={() => setOpen(true)}>{folderSvg()}</span>
            )}>
            {({ open, setOpen }: any) => (
                <Modal
                    className="reportPacketBuilder"
                    open={open}
                    modalHeading="Report Packet Builder"
                    modalLabel={<ProgressIndicator style={{ float: "right" }} currentIndex={selectedTab}>
                        <ProgressStep onClick={() => setSelectedTab(0)} id="tab-1" label="Select Content" className='storyboardTabs'>
                        </ProgressStep>
                        <ProgressStep onClick={() => setSelectedTab(1)} label="Select Parameters">
                        </ProgressStep>
                        <ProgressStep onClick={() => setSelectedTab(2)} label="Select Frequency">
                        </ProgressStep>
                        <ProgressStep onClick={() => setSelectedTab(3)} label="Review">
                        </ProgressStep>
                    </ProgressIndicator>}
                    onRequestClose={() => setOpen(false)}
                    onSecondarySubmit={() => selectedTab === 0 ? setOpen(false) : setSelectedTab(selectedTab - 1)}
                    onRequestSubmit={() => {
                        if (selectedTab == 3) {
                            setSelectedTab(0);
                            // props.submitPacket(); //pretend to submit packet, not actually submitting for now.
                            setOpen(false);
                            setShowSuccessNotification(true);
                        } else { setSelectedTab(selectedTab + 1) }
                    }}
                    primaryButtonText={selectedTab === 3 ? "Submit" : "Next"}
                    secondaryButtonText={selectedTab === 0 ? "Cancel" : "Back"}
                >
                <div style={{height: "100%"}}>
                    {getModalContent()}
                </div>
                </Modal>)}
        </ModalStateManager>
    );
}

export default ReportPacketBuilder;
