import { Report16, Timer16 } from "@carbon/icons-react";
import { ListItem, UnorderedList } from "carbon-components-react";
import FiltersReview from "../Other/FiltersReview";
const availableReports = require("../../data/ReportScheduler/ContentSelection.json");

export const PacketSummary = () => {
    const parametersIcon = <svg width="16" height="16" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 28V32H12V28H0ZM0 4V8H20V4H0ZM20 36V32H36V28H20V24H16V36H20ZM8 12V16H0V20H8V24H12V12H8ZM36 20V16H16V20H36ZM24 12H28V8H36V4H28V0H24V12Z" fill="black" />
    </svg>

    return (<><div className="bx--label">Summary </div>
        <div style={{ backgroundColor: "white", padding: "20px 30px" }}>
            <div><Report16 style={{ marginLeft: "-16px" }} /><span style={{ fontWeight: 700 }}> CONTENT</span><span style={{ float: "right" }}>Edit</span></div>
            <div>Small Group Quarterly/Semi-Annual</div>
            <UnorderedList nested>
                {availableReports.Storyboards[1]["Small Group Quarterly/Semi-Annual"].map((x: string) => <ListItem>{x}</ListItem>)}
            </UnorderedList>
            <div>Diabetes</div>
            <div>COVID</div>
        </div>
        <div style={{ backgroundColor: "white", marginTop: "12px", padding: "20px 30px" }} id="packetFiltersReview">
            <div><span style={{ marginLeft: "-16px" }}>{parametersIcon}</span><span style={{ fontWeight: 700 }}> PARAMETERS</span><span style={{ float: "right" }}>Edit</span></div>
            <FiltersReview />
        </div>
        <div style={{ backgroundColor: "white", marginTop: "12px", padding: "20px 30px" }}>
            <div><Timer16 style={{ marginLeft: "-16px" }} /><span style={{ fontWeight: 700 }}>FREQUENCY</span><span style={{ float: "right" }}>Edit</span></div>
            <span>Every 6 Months</span>
        </div>
        </>
    );
}
