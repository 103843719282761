
const headers= [
      {
        "key": "memberId",
        "header": "Member ID"
      },
      {
        "key": "age",
        "header": "Age"
      },
      {
        "key": "gender",
        "header": "Gender"
      },
      {
        "key": "relationship",
        "header": "Relationship"
      },
      {
        "key": "employmentStatus",
        "header": "Employment Status"
      },
      {
        "key": "totalAllowed",
        "header": "Total Allowed"
      },
      {
        "key": "totalMedical",
        "header": "Total Medical"
      },
      {
        "key": "totalPharmacy",
        "header": "Total Pharmacy"
      },
      {
        "key": "priorStatus",
        "header": "Prior Status"
      },
      {
        "key": "totalInpatientDays",
        "header": "Total Inpatient Days"
      },
      {
        "key": "totalInpatientAdmit",
        "header": "Total Inpatient Admit"
      },
      {
        "key": "totalEdCases",
        "header": "Total ED Cases"
      },
      {
        "key": "embComp",
        "header": "EMB Comp"
      },
      {
        "key": "cchgLabel",
        "header": "CCHG Lable"
      },
      {
        "key": "maraProspectiveRisk",
        "header": "MARA Prospective Risk"
      },
      {
        "key": "risingRisk",
        "header": "Rising Risk"
      }
    ]
    const rows= [
      {
          "id":"1",
        "memberId": "MS0001000226247",
        "age": "0",
        "gender": "F",
        "relationship": "Dependent",
        "employmentStatus": "Active",
        "totalAllowed":"$2,515,732",
        "totalMedical":"$1,886,799",
        "totalPharmacy":"$629,933",
        "priorStatus":"Not Enrolled",
        "totalInpatientDays":"160",
        "totalInpatientAdmit":"3",
        "totalEdCases":"2",
        "embComp":"13.6%",
        "cchgLabel":[
          " Intellectual disability",
          false
        ],
        "maraProspectiveRisk":"5.64",
        "risingRisk":"No"
      },
      {
          "id":"2",
        "memberId": "MS0001000007172",
        "age": "0",
        "gender": "F",
        "relationship": "Dependent",
        "employmentStatus": "Active",
        "totalAllowed":"$2,185,260",
        "totalMedical":"$1,638,945",
        "totalPharmacy":"$546,315",
        "priorStatus":"Not Enrolled",
        "totalInpatientDays":"218",
        "totalInpatientAdmit":"3",
        "totalEdCases":"2",
        "embComp":"13.6%",
        "cchgLabel":[
          "Severe heart failure/transplant/rheumatic heart disease/non-rheumatic valvular heart disease ",
          false
        ],
        "maraProspectiveRisk":"4.33",
        "risingRisk":"Yes"
      },
      {
          "id":"3",
        "memberId": "MS0001000198977",
        "age": "0",
        "gender": "F",
        "relationship": "Dependent",
        "employmentStatus": "Active",
        "totalAllowed":"$1,754,676",
        "totalMedical":"$1,316,007",
        "totalPharmacy":"$438,669",
        "priorStatus":"Not Enrolled",
        "totalInpatientDays":"46",
        "totalInpatientAdmit":"2",
        "totalEdCases":"2",
        "embComp":"13.6%",
        "cchgLabel":[
          "Severe heart failure/transplant/rheumatic heart disease/non-rheumatic valvular heart disease",
          false
        ],
        "maraProspectiveRisk":"5.6.23",
        "risingRisk":"Yes"
      },
      {
          "id":"4",
        "memberId": "MS0001000199395",
        "age": "13",
        "gender": "M",
        "relationship": "Dependent",
        "employmentStatus": "Active",
        "totalAllowed":"$1,700,966",
        "totalMedical":"$1,275,710",
        "totalPharmacy":"$425,237",
        "priorStatus":"Prior Non HCC",
        "totalInpatientDays":"83",
        "totalInpatientAdmit":"9",
        "totalEdCases":"2",
        "embComp":"13.6%",
        "cchgLabel":[
          "Cancer",
          false
        ],
        "maraProspectiveRisk":"2.37",
        "risingRisk":"No"
      },
      {
          "id":"5",
        "memberId": "MS0001000173414",
        "age": "19",
        "gender": "M",
        "relationship": "Dependent",
        "employmentStatus": "Active",
        "totalAllowed":"$1,494,281",
        "totalMedical":"$1,120,711",
        "totalPharmacy":"$373,570",
        "priorStatus":"Prior Non HCC",
        "totalInpatientDays":"71",
        "totalInpatientAdmit":"1",
        "totalEdCases":"2",
        "embComp":"13.6%",
        "cchgLabel":[
          "Cancer",
          false
        ],
        "maraProspectiveRisk":"7.34",
        "risingRisk":"No"
      },
      {
        "id":"6",
        "memberId": "MS0001000051546",
        "age": "4",
        "gender": "M",
        "relationship": "Dependent",
        "employmentStatus": "Active",
        "totalAllowed":"$1,330,217",
        "totalMedical":"$997,663",
        "totalPharmacy":"$332,517",
        "priorStatus":"Prior Non HCC",
        "totalInpatientDays":"88",
        "totalInpatientAdmit":"5",
        "totalEdCases":"2",
        "embComp":"13.6%",
        "cchgLabel":[
          "Cancer",
          false
        ],
        "maraProspectiveRisk":"3.46",
        "risingRisk":"No"
      },
      {
        "id":"7",
        "memberId": "MS0001000051546",
        "age": "3",
        "gender": "M",
        "relationship": "Dependent",
        "employmentStatus": "Active",
        "totalAllowed":"$1,302,609",
        "totalMedical":"$976,552",
        "totalPharmacy":"$302,767",
        "priorStatus":"Prior Non HCC",
        "totalInpatientDays":"127",
        "totalInpatientAdmit":"2",
        "totalEdCases":"2",
        "embComp":"13.6%",
        "cchgLabel":[
          "Cancer",
          false
        ],
        "maraProspectiveRisk":"7.46",
        "risingRisk":"No"
      },
      {
        "id":"8",
        "memberId": "MS0001000226247",
        "age": "0",
        "gender": "M",
        "relationship": "Dependent",
        "employmentStatus": "Active",
        "totalAllowed":"$1,287,939",
        "totalMedical":"$965,954",
        "totalPharmacy":"$270,480",
        "priorStatus":"Not Enrolled",
        "totalInpatientDays":"246",
        "totalInpatientAdmit":"1",
        "totalEdCases":"2",
        "embComp":"13.6%",
        "cchgLabel":[
          "No CCHG Category",
          false
        ],
        "maraProspectiveRisk":"5.44",
        "risingRisk":"No"
      },
      {
        "id":"9",
        "memberId": "MS0001000226247",
        "age": "0",
        "gender": "F",
        "relationship": "Dependent",
        "employmentStatus": "Active",
        "totalAllowed":"$1,211,067",
        "totalMedical":"$908,300",
        "totalPharmacy":"$255,615",
        "priorStatus":"Not Enrolled",
        "totalInpatientDays":"26",
        "totalInpatientAdmit":"6",
        "totalEdCases":"2",
        "embComp":"13.6%",
        "cchgLabel":[
          "Neurological Disorders",
          false
        ],
        "maraProspectiveRisk":"2.46",
        "risingRisk":"No"
      }/*,
      {
        "id":"10",
        "memberId": "MS0001000226247",
        "age": "0",
        "gender": "M",
        "relationship": "Dependent",
        "employmentStatus": "Active",
        "totalAllowed":"$1,081,920",
        "totalMedical":"$811,440",
        "totalPharmacy":"$191,248",
        "priorStatus":"Not Enrolled",
        "totalInpatientDays":"34",
        "totalInpatientAdmit":"3",
        "totalEdCases":"2",
        "embComp":"13.6%",
        "cchgLabel":[
          "Neurological Disorders",
          false
        ],
        "maraProspectiveRisk":"2.46",
        "risingRisk":"No"
      }*/
    ]
  
    export {headers,rows};