const FrequentFlyersTableDataRow = [
    {
        "id": "1",
        "title": "Mental Health & Substance Abuse",
        "prior": "$696,438.09",
        "current": "$741,540.805",
        "change": "+6.5%",
    },
    {
        "id": "2",
        "title": "Asthma",
        "prior": "$617,832.83",
        "current": "$586,769.85",
        "change": "-5.0%",
    },
    {
        "id": "3",
        "title": "Healthy Female (16-40)",
        "prior": "$355,457.60",
        "current": "$525,810.12",
        "change": "+47.9%",
    },
    {
        "id": "4",
        "title": "Hypertension (Includes stroke & peripheral vascular disease)",
        "prior": "$594,840.94",
        "current": "$469,494.85",
        "change": "-21.1%",
    },
    {
        "id": "5",
        "title": "Neurologic disorders",
        "prior": "$287,822.97",
        "current": "$454,752.42",
        "change": "+58.0%",
    },
    {
        "id": "6",
        "title": "Diabetes without CAD",
        "prior": "$290,867.03",
        "current": "$280,790.01",
        "change": "-3.5%",
    },
    {
        "id": "7",
        "title": "Hemophilia & sickle cell & chronic blood disorders",
        "prior": "$176,991.56",
        "current": "$219,722.97",
        "change": "+24.1%",
    },
    {
        "id": "8",
        "title": "Cancer",
        "prior": "$373,381.19",
        "current": "$171,606.33",
        "change": "-54.0%",
    },
    {
        "id": "9",
        "title": "Liver disease (Hepatitis, Cirrhosis) - post transplant",
        "prior": "$143,224.11",
        "current": "$161,099.95",
        "change": "+12.5%",
    },
    {
        "id": "10",
        "title": "Renal failure and-or post kidney transplant",
        "prior": "$87,034.74",
        "current": "$133,675.82",
        "change": "+53.6%",
    },
    {
        "id": "11",
        "title": "Severe heart failure/transplant/rheumatic heart disease/non-rheumatic...",
        // "title": "Severe heart failure/transplant/rheumatic heart disease/non-rheumatic valvular heart disease",
        "prior": "$37,608.90",
        "current": "$127,708.81",
        "change": "+239.6%",
    },
    {
        "id": "12",
        "title": "Other chronic conditions",
        "prior": "$158,029.49",
        "current": "$124,475.56",
        "change": "-21.2%",
    },
    {
        "id": "13",
        "title": "Intellectual disability",
        "prior": "$115,165.58",
        "current": "$116,066.94",
        "change": "*0.8%",
    },
    {
        "id": "14",
        "title": "Gastrointenstinal disorders",
        "prior": "$110,130.24",
        "current": "$104,935.73",
        "change": "-4.7%",
    },
    {
        "id": "15",
        "title": "Severe rheumatic & other connective tissue disease",
        "prior": "$234,389.41",
        "current": "$91,171.22",
        "change": "-61.1%",
    },
    {
        "id": "16",
        "title": "Major psychosis",
        "prior": "$168,558.85",
        "current": "$89,353.86",
        "change": "-47.0%",
    },
    {
        "id": "17",
        "title": "Healthy Male (16-40)",
        "prior": "$63,130.52",
        "current": "$63,983.26",
        "change": "*1.4%",
    },
    {
        "id": "18",
        "title": "CAD without diabetes",
        "prior": "$71,358.91",
        "current": "$59,701.25",
        "change": "-16.3%",
    },
    {
        "id": "19",
        "title": "Healthy Infant (0-1)",
        "prior": "$43,795.56",
        "current": "$38,705.40",
        "change": "-11.6%",
    },
    {
        "id": "20",
        "title": "Chronic musculoskeletal/osteo arthritis/osteoporosis",
        "prior": "$29,197.57",
        "current": "$34,125.39",
        "change": "+16.9%",
    },
    {
        "id": "21",
        "title": "Both CAD & diabetes",
        "prior": "$176,105.19",
        "current": "$33,181.79",
        "change": "-81.2%",
    },
    {
        "id": "22",
        "title": "Healthy Male (41-64)",
        "prior": "$12,109.11",
        "current": "$30,511.85",
        "change": "+152.0%",
    },
    {
        "id": "23",
        "title": "Healthy Female (41-64)",
        "prior": "$47,153.66",
        "current": "$29,142.04",
        "change": "-38.2%",
    },
    {
        "id": "24",
        "title": "Healthy Child (2-5)",
        "prior": "$106,824.42",
        "current": "$29,011.03",
        "change": "-72.8%",
    },
    {
        "id": "25",
        "title": "Congenital anomalies",
        "prior": "$123,288.95",
        "current": "$26,842.64",
        "change": "-78.2%",
    },
    {
        "id": "26",
        "title": "Thyroid disorders",
        "prior": "$17,805.33",
        "current": "$24,693.14",
        "change": "+38.7%",
    },
    {
        "id": "27",
        "title": "Healthy Female (6-15)",
        "prior": "$35,308.20",
        "current": "$20,540.96",
        "change": "-41.8%",
    },
    {
        "id": "28",
        "title": "Healthy Male (6-15)",
        "prior": "$22,547.23",
        "current": "$18,771.17",
        "change": "-16.7%",
    },
    {
        "id": "29",
        "title": "COPD",
        "prior": "$68,192.32",
        "current": "$16,553.51",
        "change": "-75.7%",
    },
    {
        "id": "30",
        "title": "HIV",
        "prior": "$28,379.53",
        "current": "$15,283.42",
        "change": "-46.1%",
    },
    {
        "id": "31",
        "title": "Unhealthy newborns and preemies",
        "prior": "$23,831.15",
        "current": "$10,979.38",
        "change": "-53.9%",
    },
    {
        "id": "32",
        "title": "Healthy Male (65-69)",
        "prior": "$9,740.80",
        "current": "$4,182.25",
        "change": "-57.1%",
    },
    {
        "id": "33",
        "title": "Severe dementia",
        "prior": "$14,909.40",
        "current": "$299.70",
        "change": "-98.0%",
    }

]


const FrequentFlyersTableDataHeader = [
    {
        "key": "id",
        "header": ""
    },
    {
        "key": "title",
        "header": "CCHG"
    },
    {
        "key": "prior",
        "header": "Prior"
    },
    {
        "key": "current",
        "header": "Current"
    },
    {
        "key": "change",
        "header": "Change"
    }

]


export { FrequentFlyersTableDataRow, FrequentFlyersTableDataHeader }