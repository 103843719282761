import { ChevronDown20, DocumentExport20, Time20 } from "@carbon/icons-react";
import { Column, DataTable, Row, Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow } from "carbon-components-react";
import { FrequentFlyersTableDataHeader, FrequentFlyersTableDataRow } from "../../data/FrequentFlyersData/FrequentFlyersData";
import { ChartTypes } from "../Widget/DataDisplays/ChartTypes";

const FrequentFliers = () => {
    return (
        <Column style={{ backgroundColor: "#f9f9f9" }} id='frequentFlyers'>
            <Row style={{ margin: '0px' }}>
                <Column>
                    <Row><h2 style={{ marginBottom: '5px' }}>Frequent Fliers</h2></Row>
                    <Row><h4 style={{ marginBottom: '25px' }}>Patients with 3 or more Emergency Department Visits</h4></Row>
                    <Row style={{ marginBottom: '10px' }}>
                        <Column lg={6} style={{ padding: '0px' }}><p style={{ margin: '8px 0px' }}>Showing data for 33 values</p></Column>
                        <Column lg={6}>
                            <Row style={{ flexDirection: 'row-reverse' }} >
                                {/* <div style={{ height: '40px', width: '190px', marginLeft: '10px', padding: '5px', backgroundColor: '#39414D', color: 'white', display: 'flex', alignItems: 'center' }}>
                                    <span style={{ margin: '0px 10px' }}><Time20 />
                                    </span>  <span>Schedule Report</span>
                                </div> */}
                                <div style={{ height: '40px', width: '190px', marginLeft: '10px', padding: '5px', backgroundColor: '#39414D', color: 'white', display: 'flex', alignItems: 'center' }}>
                                    <span style={{ margin: '0px 10px' }}><DocumentExport20 /> </span>
                                    <span style={{ marginRight: '40px' }}>Export Data</span>
                                    <span><ChevronDown20 /></span>
                                </div>
                            </Row>
                        </Column>
                    </Row>
                </Column>
            </Row>
            <Row>
                <Column>
                    <DataTable rows={FrequentFlyersTableDataRow} headers={FrequentFlyersTableDataHeader} isSortable>
                        {({
                            rows = FrequentFlyersTableDataRow,
                            headers = FrequentFlyersTableDataHeader,
                            getHeaderProps,
                            getRowProps,
                            getTableProps,
                            getTableContainerProps
                        }: { rows: any, headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
                            <TableContainer
                                {...getTableContainerProps()}
                            >
                                <Table {...getTableProps()} isSortable>
                                    <TableHead>
                                        <TableRow>
                                            {headers.map((header) => (
                                                <TableHeader
                                                    key={header.key}
                                                    {...getHeaderProps({ header })}
                                                    isSortable
                                                >
                                                    {header.header}
                                                </TableHeader>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row: any) => (
                                            <TableRow {...getRowProps({ row })}>
                                                {row.cells.map((cell: any) => {
                                                    if (cell.value[0] === "+") {
                                                        return (<TableCell className='tableCell' style={{ backgroundColor: "#f0c3ce" }} key={cell.id}>
                                                            {cell.value.substring(1)}</TableCell>)
                                                    } else if (cell.value[0] === "-") {
                                                        return (<TableCell className='tableCell' style={{ backgroundColor: "#bae2d1" }} key={cell.id}>
                                                            {cell.value}</TableCell>)
                                                    }
                                                    else if (cell.value[0] === "*") {
                                                        return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
                                                            {cell.value.substring(1)}</TableCell>)
                                                    } else {
                                                        return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>{cell.value}</TableCell>)
                                                    }
                                                })}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </DataTable>
                </Column>
            </Row>
        </Column>

    )
}

export default FrequentFliers;

