import { Column, DataTable, Grid, Row, Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow } from "carbon-components-react"
import { ChartTypes } from "../Widget/DataDisplays/ChartTypes"
import { downTrendSvg, upTrendSvg } from "./SVG"


export const PriorCurrentNormTable = ({ tableRows, tableHeaders, title }: { tableRows: any[], tableHeaders: any[], title: string }) => (
    <DataTable rows={tableRows} headers={tableHeaders} isSortable>
        {({
            rows = tableRows,
            headers = tableHeaders,
            getHeaderProps,
            getRowProps,
            getTableProps,
            getTableContainerProps
        }: { rows: any, headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
            <TableContainer
                title={title}
                // description="Additional description if needed"
                {...getTableContainerProps()}
            >
                <Table {...getTableProps()} isSortable>
                    <TableHead>
                        <TableRow>
                            {headers.map((header) => (
                                <TableHeader
                                    key={header.key}
                                    {...getHeaderProps({ header })}
                                    isSortable
                                >
                                    {header.header}
                                </TableHeader>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row: any) => (
                            <TableRow {...getRowProps({ row })}>
                                {row.cells.map((cell: any) => {
                                    if (cell.value[0] === "+") {
                                        return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
                                            {upTrendSvg()}{cell.value.substring(1)}</TableCell>)
                                    } else if (cell.value[0] === "-") {
                                        return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
                                            {downTrendSvg()}{cell.value.substring(1)}</TableCell>)
                                    } else {
                                        return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>{cell.value}</TableCell>)
                                    }
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )}
    </DataTable>
);
