import { ScaleTypes } from '@carbon/charts/interfaces/enums';

export const CurrentEnrollmentStatus = {
    "data": [
        {
            "group": "New Enrollee: 27.6%",
            "value": 70
        },
        {
            "group": "Disenrolled: 19.3%",
            "value": 49
        },
        {
            "group": "Continuing Enrollee: 53.1%",
            "value": 135
        }
    ],
    "options": {
      "title": "Current Enrollment Status",
      "donut": {
        "center": {
            "label": "High Cost Claimants"
        }
      },
      "description": "",
        "resizable": true,
        "height": "350px",
        "color": {
            "scale": {
                "New Enrollee: 27.6%": "#50BEE1",
                "Disenrolled: 19.3%": "#FFA200",
                "Continuing Enrollee: 53.1%": "#00A663"
            }
        },
        "legend": {
          "truncation": {
            "numCharacter": 50
          }
        }
    }
}

export const PriorCostStatus = {
    "data": [
        {
            "group": "New Enrollee: 0%",
            "value": 0
        },
        {
            "group": "High Cost: 21.3%",
            "value": 54
        },
        {
            "group": "Non-High Cost: 78.7%",
            "value": 200
        }
    ],
    "options": {
      "title": "Prior Cost Status",
	  "donut": {
        "center": {
            "label": "High Cost Claimants"
        }
      },
      "description": "",
        "resizable": true,
        "height": "350px",
        "color": {
            "scale": {
                "New Enrollee: 0%": "#50BEE1",
                "High Cost: 21.3%": "#FFA200",
                "Non-High Cost: 78.7%": "#727A7D"
            }
        },
        "legend": {
          "truncation": {
            "numCharacter": 50
          }
        }
    }
}

export const RelationshipStatus = {
    "data": [
        {
            "group": "Spouse: 15.7%",
            "value": 40
        },
        {
            "group": "Dependent: 5.5%",
            "value": 14
        },
        {
            "group": "Subscriber: 78.7%",
            "value": 200
        }
    ],
    "options": {
      "title": "Relationship Status",
	  "donut": {
        "center": {
            "label": "High Cost Claimants"
        }
      },
      "description": "",
        "resizable": true,
        "height": "350px",
        "color": {
            "scale": {
                "Spouse: 15.7%": "#50BEE1",
                "Dependent: 5.5%": "#FFA200",
                "Subscriber: 78.7%": "#0080E2"
            }
        },
        "legend": {
          "truncation": {
            "numCharacter": 50
          }
        }
    }
}

export const DistributionByAgeBand = {
    "data": [
      {
        "group": "Percentage of High Cost",
        "age": "00-01",
        "value": 10
      },
      {
        "group": "Percentage of High Cost",
        "age": "02-04",
        "value": 10
      },
      {
        "group": "Percentage of High Cost",
        "age": "05-07",
        "value": 30
      },
      {
        "group": "Percentage of High Cost",
        "age": "08-14",
        "value": 50
      },
      {
        "group": "Percentage of High Cost",
        "age": "15-19",
        "value": 70
      },
      {
        "group": "Percentage of High Cost",
        "age": "20-24",
        "value": 90
      },
      {
        "group": "Percentage of High Cost",
        "age": "25-29",
        "value": 110
      },
      {
        "group": "Percentage of High Cost",
        "age": "30-34",
        "value": 120
      },
      {
        "group": "Percentage of High Cost",
        "age": "35-39",
        "value": 140
      },
      {
        "group": "Percentage of High Cost",
        "age": "40-44",
        "value": 180
      },
      {
        "group": "Percentage of High Cost",
        "age": "45-49",
        "value": 275
      },
      {
        "group": "Percentage of High Cost",
        "age": "50-54",
        "value": 120
      },
      {
        "group": "Percentage of High Cost",
        "age": "55-59",
        "value": 100
      },
      {
        "group": "Percentage of High Cost",
        "age": "60-64",
        "value": 40
      },
      {
        "group": "Percentage of High Cost",
        "age": "65-69",
        "value": 25
      },
      {
        "group": "Percentage of Total Members",
        "age": "00-01",
        "value": 10
      },
      {
        "group": "Percentage of Total Members",
        "age": "02-04",
        "value": 10
      },
      {
        "group": "Percentage of Total Members",
        "age": "05-07",
        "value": 40
      },
      {
        "group": "Percentage of Total Members",
        "age": "08-14",
        "value": 65
      },
      {
        "group": "Percentage of Total Members",
        "age": "15-19",
        "value": 70
      },
      {
        "group": "Percentage of Total Members",
        "age": "20-24",
        "value": 100
      },
      {
        "group": "Percentage of Total Members",
        "age": "25-29",
        "value": 80
      },
      {
        "group": "Percentage of Total Members",
        "age": "30-34",
        "value": 215
      },
      {
        "group": "Percentage of Total Members",
        "age": "35-39",
        "value": 240
      },
      {
        "group": "Percentage of Total Members",
        "age": "40-44",
        "value": 160
      },
      {
        "group": "Percentage of Total Members",
        "age": "45-49",
        "value": 140
      },
      {
        "group": "Percentage of Total Members",
        "age": "50-54",
        "value": 110
      },
      {
        "group": "Percentage of Total Members",
        "age": "55-59",
        "value": 120
      },
      {
        "group": "Percentage of Total Members",
        "age": "60-64",
        "value": 40
      },
      {
        "group": "Percentage of Total Members",
        "age": "65-69",
        "value": 23
      }
    ],
    "options": {
      "title": "Distribution By Age Band",
      "axes": {
        "bottom": {
          "mapsTo": "age",
          "scaleType": ScaleTypes.LABELS
        },
        "left": {
          "mapsTo": "value",
          "scaleType": ScaleTypes.LINEAR
        }
      },
      "height": "400px",
      "color": {
        "scale": {
          "Percentage of High Cost": "#004877",
          "Percentage of Total Members": "#FF6400"
        }
      },
      "legend": {
        "truncation": {
          "numCharacter": 50
        }
      }
    }
  }


  
export const CostUtilizationSummaryRow = [
    {
        "id": "1",
        "title": "Percentage of Members",
        "prior": "0.6%",
        "current": "0.7%",
        "change": "0.0",
        "nonHighCostClaimants": "99.3%",
        "variance": "--"
    },
    {
        "id": "2",
        "title": "Average Age",
        "prior": "52.9",
        "current": "53.2",
        "change": "1%",
        "nonHighCostClaimants": "42.8",
        "variance": "24.5%"
    },
    {
        "id": "3",
        "title": "Percentage of Females",
        "prior": "51.4%",
        "current": "49.2%",
        "change": "-2.2",
        "nonHighCostClaimants": "53.4%",
        "variance": "-7.9%"
    },
    {
        "id": "4",
        "title": "Percentage of Total Allowed",
        "prior": "20.9%",
        "current": "31.2%",
        "change": "2.3",
        "nonHighCostClaimants": "68.8%",
        "variance": "--"
    },
    {
        "id": "5",
        "title": "Medical Allowed PMPM",
        "prior": "$17, 636",
        "current": "$17,651",
        "change": "0%",
        "nonHighCostClaimants": "$354",
        "variance": "4,882.5%"
    },
    {
        "id": "6",
        "title": "Rx Allowed PMPM",
        "prior": "$1,204",
        "current": "$1,157",
        "change": "13%",
        "nonHighCostClaimants": "$41",
        "variance": "2,726.1%"
    },
    {
        "id": "7",
        "title": "Combined Allowed PMPM",
        "prior": "$18,661",
        "current": "$18,808",
        "change": "1%",
        "nonHighCostClaimants": "$395",
        "variance": "4,659.1%"
    },
    {
        "id": "8",
        "title": "Percentage of Total Paid",
        "prior": "29.0%",
        "current": "31.2%",
        "change": "2.3",
        "nonHighCostClaimants": "68.8%",
        "variance": "--"
    },
    {
        "id": "9",
        "title": "Medical Net Paid PMPM",
        "prior": "$1,675",
        "current": "$774",
        "change": "-56%",
        "nonHighCostClaimants": "$289",
        "variance": "156.6%"
    },
    {
        "id": "10",
        "title": "Rx Net Paid PMPM",
        "prior": "$655",
        "current": "$770",
        "change": "17.%",
        "nonHighCostClaimants": "$55",
        "variance": "1,299.1%"
    },
    {
        "id": "11",
        "title": "Combined",
        "prior": "$2,331",
        "current": "$1,514",
        "change": "-35%",
        "nonHighCostClaimants": "$344",
        "variance": "340.1%"
    },
    {
        "id": "12",
        "title": "Medical Adequacy of Benefits",
        "prior": "88.7%",
        "current": "88.5%",
        "change": "0.7",
        "nonHighCostClaimants": "79.4%",
        "variance": "11.5%"
    },
    {
        "id": "13",
        "title": "Rx Adequacy of Benefits",
        "prior": "64.0%",
        "current": "66.5%",
        "change": "2.5",
        "nonHighCostClaimants": "58.3%",
        "variance": "14.0%"
    },
    {
        "id": "14",
        "title": "Med +Rx Adequacy of Benefits",
        "prior": "84.5%",
        "current": "85.6%",
        "change": "1.1",
        "nonHighCostClaimants": "78.6%",
        "variance": "8.9%"
    }
]

export const CostUtilizationSummaryHeader = [
    {
        "key": "title",
        "header": ""
    },
    {
        "key": "prior",
        "header": "Prior "
    },
    {
        "key": "current",
        "header": "Current "
    },
    {
        "key": "change",
        "header": "Change "
    },
    {
        "key": "nonHighCostClaimants",
        "header": "Non-High Cost Claimants "
    },
    {
        "key": "variance",
        "header": "Variance "
    }
]


export const ProspectiveRiskStrataforNextPlanYear1Rows = [
    {
        "id": "1",
        "ProspectiveRiskStatus": "Very High ",
        "AverageMemberCount": "342",
        "PercentofMembers": "55.4%"
    },
    {
        "id": "2",
        "ProspectiveRiskStatus": "High",
        "AverageMemberCount": "173",
        "PercentofMembers": "28.0%"
    },
    {
        "id": "3",
        "ProspectiveRiskStatus": "Moderately High",
        "AverageMemberCount": "98",
        "PercentofMembers": "15.8%"
    },
    {
        "id": "4",
        "ProspectiveRiskStatus": "Moderate",
        "AverageMemberCount": "2",
        "PercentofMembers": "0.3%"
    },
    {
        "id": "5",
        "ProspectiveRiskStatus": "Low",
        "AverageMemberCount": "3",
        "PercentofMembers": "0.5%"
    },
    {
        "id": "6",
        "ProspectiveRiskStatus": "Very Low",
        "AverageMemberCount": "0",
        "PercentofMembers": "0.0%"
    }
]

export const ProspectiveRiskStrataforNextPlanYear1Header = [
    {
      "key": "ProspectiveRiskStatus",
      "header": "Current High Cost and Prospective Risk Status"
    },
    {
      "key": "AverageMemberCount",
      "header": "Average Member Count"
    },
    {
      "key": "PercentofMembers",
      "header": "Percent of Members"
    }
]


export const ProspectiveRiskStrataforNextPlanYear2Rows = [
    {
        "id": "1",
        "ProspectiveRiskStatus": "Very High ",
        "AverageMemberCount": "456",
        "PercentofMembers": "0.5%"
    },
    {
        "id": "2",
        "ProspectiveRiskStatus": "High",
        "AverageMemberCount": "1,2119",
        "PercentofMembers": "2.3%"
    },
    {
        "id": "3",
        "ProspectiveRiskStatus": "Moderately High",
        "AverageMemberCount": "23,948",
        "PercentofMembers": "25.8%"
    },
    {
        "id": "4",
        "ProspectiveRiskStatus": "Moderate",
        "AverageMemberCount": "6,422",
        "PercentofMembers": "6.9%"
    },
    {
        "id": "5",
        "ProspectiveRiskStatus": "Low",
        "AverageMemberCount": "21,650",
        "PercentofMembers": "23.3%"
    },
    {
        "id": "6",
        "ProspectiveRiskStatus": "Very Low",
        "AverageMemberCount": "38,286",
        "PercentofMembers": "41.2%"
    }
]

export const ProspectiveRiskStrataforNextPlanYear2Header = [
    {
      "key": "ProspectiveRiskStatus",
      "header": "Current Non-High Cost and Prospective Risk Status"
    },
    {
      "key": "AverageMemberCount",
      "header": "Average Member Count"
    },
    {
      "key": "PercentofMembers",
      "header": "Percent of Members"
    }
]