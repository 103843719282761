import { Checkbox, Column, DataTable, DataTableCell, FileUploaderDropContainer, Modal, ProgressIndicator, ProgressStep, Row, Search, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow, TableSelectAll, TableSelectRow, TableToolbar, TableToolbarContent, TableToolbarSearch, Tabs, TextInput, Toggle, UnorderedList } from 'carbon-components-react';
import { ModalStateManager } from '../Other/ModalStateManager';
import { useState } from "react";
import { SpecialTopicsContentHeader, specialTopicsHotTopicsRows, specialTopicsStoryboardsRows } from "../../data/SpecialTopicsExport/SpecialTopicsContentData";
import { ChartTypes } from "../Widget/DataDisplays/ChartTypes";
import { AjaxAction } from '../../ajax/AjaxActions';
import { Information16 } from '@carbon/icons-react';
import { dragSvg } from "../CompareContrast/CompareContrast/index";
const availableReports = require("../../data/ReportScheduler/ContentSelection.json");



export const SpecialTopicsExportModal = (props: { pageLink: string }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [exportToggle, setExportToggle] = useState(false);
    const [selected, setSelected] = useState(0);
    const [firstToggle, setFirstToggle] = useState(false);
    const [secondToggle, setSecondToggle] = useState(false);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [selectedContent, setSelectedContent]: any[] = useState([]);
    let tempSelectedContent = [...selectedContent];
    const getModalContent = () => {
        switch (selectedTab) {
            case 0:
                return stepOne;
            case 1:
                return stepTwo;

            default:
                return stepOne;
        }
    }
    const isSelected: any = (values: any) => {
        if (typeof values === 'string') {
            if (tempSelectedContent.includes(values)) {
                return true;
            } else {
                return false;
            }
        } else {
            return Object.entries(values).filter(([k, v]: [string, any]) => isSelected(v)).length > 0;
        }
    }

    const toggleCheckbox = (str: string) => {
        if (tempSelectedContent.includes(str)) {
            tempSelectedContent = tempSelectedContent.filter((x: string) => x !== str);
        } else {
            tempSelectedContent = tempSelectedContent.concat(str);
        }
    }
    const toggleCheckboxGroup = (value: any) => {
        if (typeof value === 'string') {
            toggleCheckbox(value);
        } else {
            var entries = Object.entries(value);
            entries.forEach(([key, value]: any) => {
                if (typeof value === 'string') {
                    toggleCheckbox(value);
                } else {
                    toggleCheckboxGroup(value);
                }
            });
        }
        setSelectedContent(tempSelectedContent);
    }
    const reportsList = () => (
        Object.entries(availableReports).map(([key, value]: [string, any]) =>
        (
            <>
                <Row className="titleRow" style={{margin: "0px"}}>{isSelected(value) ? <Checkbox indeterminate onChange={() => toggleCheckboxGroup(value)} id={key} labelText={key} /> : <Checkbox onChange={() => toggleCheckboxGroup(value)} checked={false} id={key} labelText={key}/> }</Row>
                <UnorderedList nested>
                    {value.map((y: any) => {
                        if (typeof y === 'string') {
                            return <Row><Checkbox checked={isSelected(y)} onChange={() => toggleCheckboxGroup(y)} id={y} labelText={y} /></Row>;
                        } else {
                            var hideDetails = key === "Storyboards" && !isSelected(y);
                            return Object.entries(y).map(([yKey, yValue]: [string, any]) => (
                                <>
                                    <Row>{isSelected(yValue) ?  <Checkbox onChange={() => toggleCheckboxGroup(yValue)} id={yKey} labelText={yKey} indeterminate /> : <Checkbox checked={isSelected(yValue)} onChange={() => toggleCheckboxGroup(yValue)} id={yKey} labelText={yKey} />}</Row>
                                    {hideDetails ? null: <UnorderedList nested>
                                        {yValue.map((report: string) => <Row><Checkbox checked={isSelected(report)} onChange={() => toggleCheckboxGroup(report)} id={report} labelText={report} /></Row>)}
                                    </ UnorderedList>}
                                </>
                            ))
                        }
                    })}
                </UnorderedList>
            </>
        )
    ));
    const selectedReports = () => (
        Object.entries(availableReports).map(([key, value]: [string, any]) =>
        (
            <>
            {/* don't show the top title */}
                {/* {isSelected(value) ? <Row style={{ marginLeft: "0" }}><span style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>{dragSvg()}</span><Checkbox style={{ height: "0" }} labelText={key} defaultChecked id={key} /></Row> : null} */}
                <UnorderedList nested>
                    {value.map((y: any) => {
                        if (typeof y === 'string') {
                            return isSelected(y) ? <Row style={{ marginLeft: "0" }}><span style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>{dragSvg()}</span><Checkbox style={{ height: "0" }} labelText={y} defaultChecked id={y} /></Row> : null;
                        } else {
                            return Object.entries(y).map(([yKey, yValue]: [string, any]) => (
                                <>
                                    {isSelected(yValue) ? <Row style={{ marginLeft: "0" }}><span style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>{dragSvg()}</span><Checkbox style={{ height: "0" }} labelText={yKey} defaultChecked id={yKey} /></Row> : null}
                                    <UnorderedList nested>
                                        {isSelected(yValue) ? yValue.map((report: string) => isSelected(report) ? <Row style={{ marginLeft: "0" }}><span style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>{dragSvg()}</span><Checkbox style={{ height: "0" }} labelText={report} defaultChecked id={report} /></Row> : null) : null}
                                    </ UnorderedList>
                                </>
                            ))
                        }
                    })}
                </UnorderedList>
            </>
        )
    ));
    const stepOne = (
        <Row style={{ backgroundColor: "#f9f9f9" }} id='frequentFlyers'>
            <Column style={{ margin: '0px' }}>
                <Column>
                    <Row><h4 style={{ marginBottom: '25px' }}>1. Select content from the list below to export</h4></Row>
                    <Column style={{ float: "left", padding: "0px 30px" }}>
                        <Search placeholder="Search / filter" labelText="" />
                        <UnorderedList>
                            {reportsList()}
                        </UnorderedList>
                    </Column>
                </Column>
            </Column>
            <Column>
                <Row><h4 style={{ marginBottom: '25px' }}>2. Drag-and-drop selections to change their order of appearance</h4></Row>
                <Row style={{width:'100%'}}>
                    <div style={{height: '32px', backgroundColor: '#0080E2', width: '100%', color: '#FFFFFF', lineHeight: '32px', paddingLeft: '16px'}}>
                        <span style={{padding: "8px"}}>{selectedContent.length} Items Selected</span>
                        <div onClick={() => setSelectedContent([])} style={{color: "#FFFFFF", float: 'right', lineHeight: '32px', paddingRight: '12px', textDecoration: "underline", cursor: 'pointer'}}>Clear All</div>
                    </div>
                    <Column style={{ float: "right", margin: "0px", padding: '0px', height: "100%", width: '100%'}}>
                        <div className="selectedReports">
                            <UnorderedList>
                                {selectedReports()}
                            </UnorderedList>
                        </div>
                    </Column>
                </Row>
                <div style={{height: '90%', background: '#E8E9EA', marginLeft: '-1rem', marginRight: '1rem'}}>{selectedRows}</div>     
            </Column>
        </Row>
    );

    const stepTwo = (
        <Column style={{ backgroundColor: "#f9f9f9", padding: '0px' }} id='customizeExportDetails'>
            <Row style={{ margin: '0px' }}>
                <Column style={{padding: '0px'}}>
                    <Column style={{padding: '0px'}}>
                        <Row><h4 style={{ marginBottom: '25px', paddingLeft: '16px' }}>3. Customize export details</h4></Row>
                        <TextInput
                            id="test2"
                            invalidText="A valid value is required"
                            labelText="File Type"
                            placeholder="PDF"
                            style={{marginBottom: '30px'}}
                        />
                        <Row style={{ marginInline: '0px' }}> <h6>Export as Separate Files</h6><p style={{ marginLeft: '5px' }}>{<Information16 />}</p></Row>
                        <Row style={{ marginLeft: '0px' }}>
                            <Toggle
                                id="exportAsSeparateFilesToggle"
                                labelText=""
                                labelA='No'
                                labelB='Yes'
                                onToggle={() => { setExportToggle(!exportToggle); setFirstToggle(!firstToggle);}}
                                defaultToggled={firstToggle}
                            />
                        </Row>
                        {!exportToggle ? (
                            <div style={{paddingTop: '30px'}}>
                                <div style={{marginBottom: '30px'}}>
                                    <TextInput
                                        id="test2"
                                        invalidText="A valid value is required"
                                        labelText="Report Title"
                                        placeholder="Enter title name"
                                    />
                                </div>
                                <div style={{marginBottom: '30px'}}>
                                    <TextInput
                                        id="test2"
                                        invalidText="A valid value is required"
                                        labelText="Report Description (optional)"
                                        placeholder="Enter description"
                                    />
                                </div>
                            </div>
                        ) : (
                            <Tabs selected={selected} style={{paddingTop: '30px'}}>
                                <Tab onClick={() => setSelected(0)} id="tab-0" label="Small Plan Monthly" className='storyboardTabs'>
                                    <div style={{marginBottom: '30px'}}>
                                        <TextInput
                                            id="test2"
                                            invalidText="A valid value is required"
                                            labelText="Title"
                                            placeholder="Enter title name"
                                        />
                                    </div>
                                    <div style={{marginBottom: '30px'}}>
                                        <TextInput
                                            id="test2"
                                            invalidText="A valid value is required"
                                            labelText="Description (optional)"
                                            placeholder="Enter description"
                                        />
                                    </div>
                                </Tab>
                                <Tab onClick={() => setSelected(1)} id="tab-1" label="Small Plan Quarterly" className='storyboardTabs'>
                                    <div style={{marginBottom: '30px'}}>
                                        <TextInput
                                            id="test2"
                                            invalidText="A valid value is required"
                                            labelText="Title"
                                            placeholder="Enter title name"
                                        />
                                    </div>
                                    <div style={{marginBottom: '30px'}}>
                                        <TextInput
                                            id="test2"
                                            invalidText="A valid value is required"
                                            labelText="Description (optional)"
                                            placeholder="Enter description"
                                        />
                                    </div>
                                </Tab>
                            </Tabs>)}

                        <Row style={{ marginLeft: '0px', marginBottom: '30px' }}>
                            <Toggle 
                                id="includeFilterToggle"
                                labelText='Include Filter Info'
                                labelA='No'
                                labelB='Yes'
                                onToggle={() => { setSecondToggle(!secondToggle);}}
                                defaultToggled={secondToggle}
                            />
                        </Row>
                        <div>
                            <p className="bx--label">Add Secondary Logo (Optional)</p>
                            <p className="faq">Only .jpg and .png files. 500kb max file size.</p>
                            <FileUploaderDropContainer
                                accept={[
                                    '.jpg',
                                    '.png'
                                ]}
                                labelText="Drag and drop files here or upload"
                            />
                        </div>              
                    </Column>
                </Column>
            </Row>
        </Column>
    );

    return (
        <ModalStateManager
            renderLauncher={({ setOpen }: any) => (
                <div onClick={() => setOpen(true)}>
                    <span style={{color: '#39414D'}}>Export Data</span>
                </div>
            )}>
            {({ open, setOpen }: any) => (
                    (<Modal
                        className="exportBuilder"
                        open={open}
                        modalHeading={<div><h3>Export Data</h3></div>}
                        modalLabel={
                            <ProgressIndicator id='exportDataIndicator' style={{ float: "right", paddingLeft: '30%' }} currentIndex={selectedTab} >
                                <ProgressStep label="Select Content" id="selectContentTab">
                                </ProgressStep>
                                <ProgressStep label="Customize Export" id="customizeExportTab">
                                </ProgressStep>
                            </ProgressIndicator>
                        }
                        onRequestClose={() => { setSelectedTab(0); setOpen(false);}}
                        onSecondarySubmit={() => {setSelectedTab(selectedTab - 1);}}
                        onRequestSubmit={() => {
                            if (selectedTab === 1) {
                                setSelectedTab(0);
                                setOpen(false);
                                // need to add option to track which dash
                                new AjaxAction().downloadPDF(props.pageLink);
                            } else {
                                setSelectedTab(selectedTab + 1)
                            }
                        }}
                        primaryButtonText={selectedTab === 1 ? "Submit" : "Next"}
                        secondaryButtonText={selectedTab === 1 ? "Back" : "Cancel"}
                    >
                        {getModalContent()}
                    </Modal>)
            )}
        </ModalStateManager>
    );
};
