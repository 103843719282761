import { ToastNotification } from "carbon-components-react";

const PacketSuccessNotification = () => (
    <div>
    <ToastNotification
        iconDescription="Dismiss"
        timeout={10000} // stay for 10 seconds
        title="Report Packet Created"
        kind="success"
        
    />
    </div>
);

export default PacketSuccessNotification;