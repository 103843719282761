const SavedCustomizationRow = () =>  [
    {
        "id": "1",
        "CustomizationName": {
            name: "Name 1",
            toggled: false
        },
        "Author": "user 1",
        "SaveDate": "MM/DD/YYYY",
        "Actions": "actions"        
    },
    {
        "id": "2",
        "CustomizationName": {
            name: "Name 2",
            toggled: false
        },
        "Author": "user 1",
        "SaveDate": "MM/DD/YYYY",
        "Actions": "actions"        
    },
    {
        "id": "3",
        "CustomizationName": {
            name: "Name 3",
            toggled: false
        },
        "Author": "user 1",
        "SaveDate": "MM/DD/YYYY",
        "Actions": "actions"        
    },
    {
        "id": "4",
        "CustomizationName": {
            name: "Name 4",
            toggled: false
        },
        "Author": "user 1",
        "SaveDate": "MM/DD/YYYY",
        "Actions": "actions"        
    }
    ,
    {
        "id": "5",
        "CustomizationName": {
            name: "Name 5",
            toggled: false
        },
        "Author": "user 1",
        "SaveDate": "MM/DD/YYYY",
        "Actions": "actions"        
    },
    {
        "id": "6",
        "CustomizationName": {
            name: "Name 6",
            toggled: false
        },
        "Author": "user 2",
        "SaveDate": "MM/DD/YYYY",
        "Actions": "actions"        
    },
    {
        "id": "7",
        "CustomizationName": {
            name: "Name 7",
            toggled: false
        },
        "Author": "user 2",
        "SaveDate": "MM/DD/YYYY",
        "Actions": "actions"        
    },
    {
        "id": "8",
        "CustomizationName": {
            name: "Name 8",
            toggled: false
        },
        "Author": "user 2",
        "SaveDate": "MM/DD/YYYY",
        "Actions": "actions"        
    },
    {
        "id": "9",
        "CustomizationName": {
            name: "Name 9",
            toggled: false
        },
        "Author": "user 3",
        "SaveDate": "MM/DD/YYYY",
        "Actions": "actions"        
    },
    {
        "id": "10",
        "CustomizationName": {
            name: "Name 10",
            toggled: false
        },
        "Author": "user 3",
        "SaveDate": "MM/DD/YYYY",
        "Actions": "actions"        
    },
    {
        "id": "11",
        "CustomizationName": {
            name: "Name 11",
            toggled: false
        },
        "Author": "user 3",
        "SaveDate": "MM/DD/YYYY",
        "Actions": "actions"        
    },
    {
        "id": "12",
        "CustomizationName": {
            name: "Name 12",
            toggled: false
        },
        "Author": "user 4",
        "SaveDate": "MM/DD/YYYY",
        "Actions": "actions"        
    },

]


const SavedCustomizationHeader = [
    {
        "key": "CustomizationName",
        "header": "Customization Name"
    },
    {
        "key": "Author",
        "header": "Author"
    },
    {
        "key": "SaveDate",
        "header": "Save Date"
    },
    {
        "key": "Actions",
        "header": "Actions"
    }]

export {
    SavedCustomizationRow, SavedCustomizationHeader
}
