import {
  LOGIN,
  TOGGLE_ACTIVITY_AND_INFO_MODAL, SET_ACTIVE_DASHBOARD_TAB, RESET_ACTIVE_DASHBOARD_TAB

} from './actionTypes';

import {
  ToggleActivityAndInfoModal,
  SetActiveDashboardTab,
  IActiveDashboardType,
  ResetActiveDashboardTab
} from './types';

const openActivityAndInfoModal = (open: boolean) : ToggleActivityAndInfoModal => {
  return {
      type: TOGGLE_ACTIVITY_AND_INFO_MODAL,
      payload: open
    };
}

const setActiveDashboardTab = (open: IActiveDashboardType): SetActiveDashboardTab => {
  return {
    type: SET_ACTIVE_DASHBOARD_TAB,
    payload: open
  };
}
const resetActiveDashboardTab = (): ResetActiveDashboardTab => {
  return {
    type: RESET_ACTIVE_DASHBOARD_TAB
  };
}

const login = () => {
  return {
    type: LOGIN
  };
}

export const actions = { openActivityAndInfoModal, login, setActiveDashboardTab, resetActiveDashboardTab };