import { Row, Column, DataTable, TableHead,OverflowMenu, TableRow, TableBody, TableCell, TableContainer, Table, TableHeader, DataTableHeader, DataTableCell, DenormalizedRow, Pagination } from 'carbon-components-react';
import { ChartMinimum16 ,ChevronDown20,ChevronUp20} from '@carbon/icons-react';
import React, { useState } from 'react';
import {headers,rows} from '../../../data/Telehealth/HighCostClaimants';
const telehealthProviderDetails = require("../../../data/Telehealth/HighCostClaimants.tsx");

const HighCostClaimants = () => {
    const [isMyCustomization, setIsMyCustomization] = useState(false)
    const [row, setRow] = useState(rows)
    const [isShown, setIsShown] = useState(false);

    const onClickSetRow = (state: any, id: string) =>{
        row.forEach((item) =>{
            if (item.id === id){
                item.cchgLabel[1] = state
            } else if (state){ // only allow on toggle at a time
                item.cchgLabel[1] = false
            }
        })
        setRow([...row])
    }
    
    return <Column className="HeadColumnContainer">
        <Row className="HeadRowContainer">
            <Column className="columnContainer">
                <Row><h1>Overview</h1></Row>
                <Row><p>This file contains the following data: Member ID; Age; Gender; Relationship; Employment Status, Total Allowed; Total Medical; Total Pharmacy; Prior Status, Total Inpatient Days; Total Inpatient Admits; Total ED Cases; EMB Comp; CCHG Label; MARA Prospective Risk; and Rising Risk.</p></Row>
                <Row className="SubRowContainer">
                    <Column className="List">
                            <ul>
                                <li className='reportList'><a href="/">TeleHealth Key Points</a></li>
                                <li className='reportList'><a href="/">Utilization Trends</a></li>
                            </ul>
                    </Column>
                </Row>
            </Column>
        </Row>
        <Row className="HighCostHeading"><h3>High-Cost Claimants</h3></Row>
        <Row className="HighCostTable">
            <div className="HighCostTableHeader">
                <DataTable  rows={telehealthProviderDetails.rows} headers={telehealthProviderDetails.headers}>
                    {({
                        rows = telehealthProviderDetails.rows,
                        headers = telehealthProviderDetails.headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps,
                        getTableContainerProps,
                    }: { rows: DenormalizedRow[], headers: DataTableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
                        <TableContainer
                            title={
                                <div className="HighCostTableTitle">
                                    <p>High-Cost Claimants</p>
                                    <OverflowMenu></OverflowMenu>
                                </div>
                            }
                            description="Note: this table scrolls horizontally and vertically."
                            
                            {...getTableContainerProps()}
                            className="HighCastTableContainer">
                            <Table {...getTableProps()} className="HighCast" > 
                                <TableHead>
                                    <TableRow >
                                        {
                                        headers.map((header: DataTableHeader) => (
                                            <TableHeader
                                                key={header.key}
                                                {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className='fixedHeight'>
                                    {rows.map((row: DenormalizedRow) => (
                                        <TableRow key={row.id} {...getRowProps({ row })}  >
                                             {row.cells.map((cell: any) => {
                                                if (Array.isArray(cell.value)){
                                                    if (Array.isArray(cell.value) &&  cell.value[0].length >= 41) {
                                                        return (<TableCell className='ArrowDown' onClick={() => onClickSetRow(!cell.value[1], row.id)} key={cell.id}>
                                                            <div>
                                                            
                                                            {
                                                                !cell.value[1]? (<><span   style={{width:"15px",height:"10px",position:"relative",left:"300px",top:'2px'}}><ChevronDown20  className="ArrowDownInside" id={cell.value[0] + row.id} 
                                                                 /></span><div className="cell1" style={{display:'flex',marginLeft:"-15px",right:'16px'}}>{cell.value[0].substr(0,41)+"..."}</div></>):(<><span  style={{width:"30px",height:"20px",position:"relative",left:"320px",top:'2px'}}><ChevronUp20 className="ArrowUpInside" id={cell.value[0] + row.id}
                                                                 /></span><div className="cell2" style={{display:'flex',marginLeft:"-20px",right:'20px'}}>{cell.value[0]}</div></>)
                                                            }
                                                            
                                                                    
                                                            </div>
                                                        </TableCell>)
                                                    } else {
                                                        return (<TableCell className="HighCostTableBody" key={cell.id}>{cell.value[0]}</TableCell>)
                                                    }
                                                }else{
                                                    return (<TableCell  key={cell.id}>{cell.value}</TableCell>)
                                                }
    
                                                    })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </DataTable>
                <Pagination
                    backwardText="Previous page"
                    forwardText="Next page"
                    itemsPerPageText="Items per page:"
                    page={1}
                    onChange={() => true}
                    pageNumberText="Page Number"
                    pageSize={10}
                    pageSizes={[
                    10,
                    20,
                    30,
                    40,
                    50
                    ]}
                    totalItems={530}
                />
            </div>
        </Row>
        <p className="ExploreRelatedTopic">Explore Related Topics</p>
        <li className="storyboardContainer"><a href="#">High Cost Claimants</a> (storyboard)</li>
    </Column>
}

export {HighCostClaimants};