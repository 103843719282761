import { Content } from 'carbon-components-react';
import './App.scss';
import PageHeader from './components/Header/Header';
import Home from './components/MyDashboard/Home';
import Dashboard from './components/Other/Dashboard';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom";
import React from 'react';
import { PDFView } from './components/Exporting/PDFComponents';
import { Covid19Dashboard, Covid19PDF } from './components/SpecialTopics/Covid19Dashboard/index';
import { DiabetesDashboard, DiabetesPDF } from './components/SpecialTopics/DiabetesDashboard';
import SmallGroupAnnual from './components/Storyboards/SmallGroupAnnual/SmallGroupAnnual';
// import { useHistory } from 'react-router-dom';
import { CompareContrast } from './components/CompareContrast/CompareContrast/index';
import ReportScheduler from './components/ReportScheduler/ReportScheduler';
import { CompareContrastReport } from './components/CompareContrast/CompareContrastReport/index';
import { actions } from './redux/Dashboard/actions';
import { connect } from 'react-redux';
import LoginPage from './components/HomePage/LoginPage';
import { TelehealthDashboard } from './components/SpecialTopics/TelehealthDashboard';
import FrequentFliers from './components/Other/FrequentFliers';
import SpecialTopicsIndex from './components/SpecialTopics/SpecialTopicsIndex';
import { FinancialSBPDF } from './components/Storyboards/SmallGroupAnnual/FinancialDashboard';
import { HighCostClaimants } from './components/SpecialTopics/HighCostClaimants/index';
import SQLPage from './components/SQLPage';

function App({ loginSuccessful, reportCreatedDate }: { loginSuccessful: boolean, reportCreatedDate: string }) {
  const reportCreated = <p style={{paddingTop: "40px"}}>Date Report Generated: {reportCreatedDate}</p>;
  return (
      <Router>
        <Switch>
          <Route exact path="/SQL"><SQLPage /></Route>
          <Route exact path="/Covid19/pdf"><PDFView content={[<Covid19PDF />]} /></Route>
          <Route exact path="/Diabetes/pdf"><PDFView content={[<DiabetesPDF />]} /></Route>
          <Route exact path="/Financial/pdf"><PDFView content={[<FinancialSBPDF />]} /></Route>
          <Route>
            <PageHeader />
            <div>
              <Switch>
                <Route exact path="/">{loginSuccessful ? <Home />: <LoginPage />}</Route>
                <Route exact path="/SmallGroupAnnual">
                  <Content style={{ paddingBottom: "0" }}><Dashboard dashboardContent={<SmallGroupAnnual />} isSpecialTopic={false} pageTitle={"Small Group Semi-Annual"} pageLink={"/SmallGroupAnnual"} /></Content>
                </Route>
                <Route exact path="/Diabetes">
                <div style={{ overflow: 'hidden' }}>
                  <div className="SpecialTopicBanner" id="DiabetesBanner">
                    <h1 style={{padding: "50px 0px"}}>Diabetes</h1>
                    <div className="bannerDescription">
                    <p>Diabetes is the most expensive chronic condition across the nation.  This Clinical Journey illustrates the experience of three key clinical subpopulations: pre-diabetics, controlled diabetics, and severe diabetics.  Compare key utilization and cost metrics, emerging markets, comorbid medical and pharmacy conditions, attributed PCP outcomes, and identify opportunities to improve treatment quality, compliance, and efficiency. </p>
                    </div>
                    {reportCreated}
                  </div>
                </div>
                  <Content style={{ paddingBottom: "0" }}><Dashboard dashboardContent={<DiabetesDashboard />} isSpecialTopic={true} pageTitle={"Diabetes"} pageLink={"/Diabetes"} /></Content>
                </Route>
                <Route exact path="/Covid19">
                <div style={{ overflow: 'hidden' }}>
                  <div className="SpecialTopicBanner" id="COVIDBanner">
                  <h1 style={{padding: "50px 0px"}}>COVID-19</h1>
                    <p className="bannerDescription">COVID-19 has presented an urgent need for data.  This Hot Topic has been compiled to help answer key questions about the unprecedented impact of this novel virus.  Measure cumulative diagnostic prevalence, geographic hot spots, quantify the cost of treating members with and without hospitalizations, evaluate key clinical comorbidities before and after a COVD-19 diagnosis, and view changes in utilization of connected services (telehealth, behavioral health, and elective surgeries).</p>
                      {reportCreated}
                  </div>
                </div>
                  <Content style={{ paddingBottom: "0" }}><Dashboard dashboardContent={<Covid19Dashboard />} isSpecialTopic={true} pageTitle={"COVID-19"} pageLink={"/Covid19"} /></Content>
                </Route>
                <Route exact path="/Telehealth">
                <div style={{ overflow: 'hidden' }}>
                  <div className="SpecialTopicBanner" id="TelehealthBanner">
                  <h1 style={{padding: "50px 0px"}}>Telehealth</h1>
                    <p className="bannerDescription">Telehealth has become a valuable resource in the effort to ensure members always have access to care.  There has been a significant uptick in utilization nationwide since March 2020.  This Hot Topic explores telehealth cost and utilization over time, demographic and diagnostic drivers, and telehealth provider details.  </p>
                      {reportCreated}
                  </div>
                </div>
                  <Content style={{ paddingBottom: "0" }}><Dashboard dashboardContent={<TelehealthDashboard />} isSpecialTopic={true} pageTitle={"Telehealth"} pageLink={"/Telehealth"} /></Content>
                </Route>
                <Route exact path="/HighCostClaimants">
                <div style={{ overflow: 'hidden' }}>
                  <div className="SpecialTopicBanner" id="HighCostClaimantsBanner">
                  <h1 style={{padding: "50px 0px"}}>High Cost Claimants Detail</h1>
                    <p className="bannerDescription">This member level high-cost claimant report lasers in on critical indicators of health, utilization, risk, and case complexity.  Observe patterns amongst this high severity population and identify specifics around each claimants individual needs that may qualify them for clinical support or outreach.  Please note: this report contains PHI.</p>
                      {reportCreated}
                  </div>
                </div>
                  <Content style={{ paddingBottom: "0" }}><Dashboard dashboardContent={<HighCostClaimants />} isSpecialTopic={true} pageTitle={"High Cost Claimants"} pageLink={"/HighCostClaimants"} /></Content>
                </Route>
                <Route exact path="/CompareContrast"><CompareContrast /></Route>
                <Route exact path="/ReportScheduler"><ReportScheduler /></Route>
                <Route exact path="/CompareContrastReport">
                <div style={{ overflow: 'hidden' }}>
                  <div className="SpecialTopicBanner" id="CompareContrastBanner">
                    <h1 style={{padding: "50px 0px"}}>Compare & Contrast</h1>
                    <p className="bannerDescription">Explore these results to reveal what lies beneath high-level, average plan performance and identify outlier experience or critical subpopulation differences.</p>
                      {reportCreated}
                  </div>
                </div>
                  <CompareContrastReport />
              </Route>
                <Route exact path="/SpecialTopics"><SpecialTopicsIndex /></Route>
              </Switch>
            </div>
          </Route>
        </Switch>
      </Router>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    toggleModal: (open: boolean) => dispatch(actions.openActivityAndInfoModal(open))
  };
}

const mapStateToProps = (state: any) => {
  return {
    loginSuccessful: state.root.loginSuccessful,
    reportCreatedDate: state.root.reportCreatedDate
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
