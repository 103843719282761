import { ScaleTypes } from '@carbon/charts/interfaces/enums';

const PCPOfficeVisitsRow = [
    {
        "id": "1",
        "title": "Net Paid PMPM",
        "prior": "$13.39",
        "current": "+$14.90",
        "norm": "$12.40",
    },
    {
        "id": "2",
        "title": "Utils per 1,000",
        "prior": "2038",
        "current": "+2109",
        "norm": "1945",
    },
    {
        "id": "3",
        "title": "Average Paid per Utilization",
        "prior": "$97",
        "current": "+$99",
        "norm": "$97",
    }

]

const PCPOfficeVisitsHeader = [
    {
        "key": "title",
        "header": ""
    },
    {
        "key": "prior",
        "header": "Prior"
    },
    {
        "key": "current",
        "header": "Current"
    },
    {
        "key": "norm",
        "header": "Norm"
    }
]
const TelehealthOfficeVisitsRow = [
    {
        "id": "1",
        "title": "Net Paid PMPM",
        "prior": "$5.70",
        "current": "+$7.40",
        "norm": "$4.50",
    },
    {
        "id": "2",
        "title": "Utils per 1,000",
        "prior": "400",
        "current": "+1100",
        "norm": "800",
    },
    {
        "id": "3",
        "title": "Average Paid per Utilization",
        "prior": "$45",
        "current": "+$55",
        "norm": "$52",
    }

]

const TelehealthOfficeVisitsHeader = [
    {
        "key": "title",
        "header": ""
    },
    {
        "key": "prior",
        "header": "Prior"
    },
    {
        "key": "current",
        "header": "Current"
    },
    {
        "key": "norm",
        "header": "Norm"
    }
]
const SpecialistVisitsRow = [
    {
        "id": "1",
        "title": "Net Paid PMPM",
        "prior": "$12.34",
        "current": "+$13.43",
        "norm": "$11.23",
    },
    {
        "id": "2",
        "title": "Utils per 1,000",
        "prior": "1300",
        "current": "+1500",
        "norm": "1400",
    },
    {
        "id": "3",
        "title": "Average Paid per Utilization",
        "prior": "$106",
        "current": "+$111",
        "norm": "$104",
    }

]

const SpecialistVisitsHeader = [
    {
        "key": "title",
        "header": ""
    },
    {
        "key": "prior",
        "header": "Prior"
    },
    {
        "key": "current",
        "header": "Current"
    },
    {
        "key": "norm",
        "header": "Norm"
    }
]
const OBGYNVisitsRow = [
    {
        "id": "1",
        "title": "Net Paid PMPM",
        "prior": "$7.10",
        "current": "+$7.70",
        "norm": "$6.89",
    },
    {
        "id": "2",
        "title": "Utils per 1,000",
        "prior": "400",
        "current": "+420",
        "norm": "414",
    },
    {
        "id": "3",
        "title": "Average Paid per Utilization",
        "prior": "$224",
        "current": "+$220",
        "norm": "$214",
    }

]

const OBGYNVisitsHeader = [
    {
        "key": "title",
        "header": ""
    },
    {
        "key": "prior",
        "header": "Prior"
    },
    {
        "key": "current",
        "header": "Current"
    },
    {
        "key": "norm",
        "header": "Norm"
    }
]

const PhysicianOfficeVisits = {
    data: [
        {
            "group": "PCP Office",
            "value": 45
        },
        {
            "group": "Telehealth",
            "value": 12
        },
        {
            "group": "Specialist",
            "value": 33
        },
        {
            "group": "OB / GYN",
            "value": 10
        }
    ],
    options: {
        "title": "Physician Office Visits",
        "resizable": true,
        "height": "400px",
        "color": {
            "scale": {
              "PCP Office": "#0080E2",
              "Telehealth": "#FF6400",
              "Specialist": "#74C061",
              "OB / GYN": "#FFC800"
            }
          }
    }
};

const PhysicianOfficeVisitsByRelationship = {
    data: [
        {
            "group": "PCP Office",
            "key": "Employee",
            "value": 43
        },
        {
            "group": "Telehealth",
            "key": "Employee",
            "value": 10
        },
        {
            "group": "Specialist",
            "key": "Employee",
            "value": 31
        },
        {
            "group": "OB / GYN",
            "key": "Employee",
            "value": 16
        },
        {
            "group": "PCP Office",
            "key": "Spouse",
            "value": 40
        },
        {
            "group": "Telehealth",
            "key": "Spouse",
            "value": 0
        },
        {
            "group": "Specialist",
            "key": "Spouse",
            "value": 40
        },
        {
            "group": "OB / GYN",
            "key": "Spouse",
            "value": 20
        },
        {
            "group": "PCP Office",
            "key": "Dependent",
            "value": 75
        },
        {
            "group": "Telehealth",
            "key": "Dependent",
            "value": 0
        },
        {
            "group": "Specialist",
            "key": "Dependent",
            "value": 21
        },
        {
            "group": "OB / GYN",
            "key": "Dependent",
            "value": 4
        },
    ],
    options: {
        "axes": {
            "left": {
                "mapsTo": "value",
                "scaleType": ScaleTypes.LINEAR,
                "stacked": true,
                "percentage": true,
                "ticks": {
                    "values": [ 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100 ],
                    "formatter": ((value: any)=>{ return value+'%' })
                  },
            },
            "bottom": {
                "scaleType": ScaleTypes.LABELS
            }
        },
        "height": "340px",
        "color": {
            "scale": {
              "PCP Office": "#0080E2",
              "Telehealth": "#FF6400",
              "Specialist": "#74C061",
              "OB / GYN": "#FFC800"
            }
        },
        "bars": {
            "width": 48
        }
    }
};

const PCPVisitsPer1000 = {
    data: [
        {
            "group": "Chronic",
            "value": 70.2
        },
        {
            "group": "Healthy",
            "value": 29.8
        }
    ],
    options: {
        "resizable": true,
        "height": "340px",
        "color": {
            "scale": {
              "Chronic": "#50BEE1",
              "Healthy": "#39414D"
            }
        }
    }
};

const OfficeVisits = {
    data: [
        {
            "group": "Preventive Visits",
            "value": 27
        },
        {
            "group": "Total Office Visits",
            "value": 73
        }
    ],
    options: {
        "resizable": true,
        "donut": {
            "center": {
                "label": "Preventive",
                "number": 27,
                "numberFormatter":((value: any)=>{return value+'%'}),
            }
        },
        "height": "340px",
        "color": {
            "scale": {
              "Preventive Visits": "#FF6400",
              "Total Office Visits": "#C6C9CA"
            }
        }
    }
};

const PCPOfficeVisitsUtilsPer1000 = {
    data: [
        {
            "group": "Out of Network",
            "key": "Employee",
            "value": 800
        },
        {
            "group": "In Network",
            "key": "Employee",
            "value": 1100
        },
        {
            "group": "Out of Network",
            "key": "Spouse",
            "value": 550
        },
        {
            "group": "In Network",
            "key": "Spouse",
            "value": 1300
        },
        {
            "group": "Out of Network",
            "key": "Dependent",
            "value": 500
        },
        {
            "group": "In Network",
            "key": "Dependent",
            "value": 800
        },
    ],
    options: {
        "axes": {
            "left": {
                "mapsTo": "value",
                "scaleType": ScaleTypes.LINEAR,
                "stacked": true,
            },
            "bottom": {
                "scaleType": ScaleTypes.LABELS
            }
        },
        "height": "340px",
        "color": {
            "scale": {
              "Out of Network": "#004877",
              "In Network": "#727A7D"
            }
        },
        "bars": {
            "width": 48
        }
    }
};

const TelehealthVisitsPercentOfAllOfficeVisits = {
    data: [
	    {
		    "group": "Percentage Telehealth",
		    "month": "2019 Q1",
		    "value": 6
	    },
        {
            "group": "Percentage Telehealth",
            "month": "2019 Q2",
            "value": 6
        },
        {
            "group": "Percentage Telehealth",
            "month": "2019 Q3",
            "value": 7
        },
        {
            "group": "Percentage Telehealth",
            "month": "2019 Q4",
            "value": 8
        },
        {
            "group": "Percentage Telehealth",
            "month": "2020 Q1",
            "value": 9
        },
        {
            "group": "Percentage Telehealth",
            "month": "2020 Q2",
            "value": 14
        },
        {
            "group": "Percentage Telehealth",
            "month": "2020 Q3",
            "value": 13
        },
        {
            "group": "Percentage Telehealth",
            "month": "2020 Q4",
            "value": 15
        },
        {
            "group": "Percentage Telehealth",
            "month": "2021 Q1",
            "value": 15
        },
        {
            "group": "Percentage Telehealth",
            "month": "2021 Q2",
            "value": 16
        },
        {
            "group": "Percentage Telehealth",
            "month": "2021 Q3",
            "value": 16
        },
        {
            "group": "Percentage Telehealth",
            "month": "2021 Q4",
            "value": 16
        }
    ],
    options: {
        "title": "Telehealth Visits Percent of All Office Visits",
        "axes": {
            "bottom": {
                "mapsTo": "month",
                "scaleType": ScaleTypes.LABELS
            },
        "left": {
            "mapsTo": "value",
            "ticks": {
                "values": [ 0, 2, 4, 6, 8, 10, 12, 14, 16, 18 ],
                "formatter": ((value: any)=>{ return value+'%' })
            }
            }
        },
        "height": "400px",
        "color": {
            "scale": {
            "Percentage Telehealth": "#FF6400",
            }
        }
    }
};

const TelehealthUtilization = {
    data: [
        {
            "group": "Female",
            "value": 33
        },
        {
            "group": "Male",
            "value": 45
        },
        {
            "group": "Other",
            "value": 10
        }
    ],
    options: {
        "resizable": true,
        "height": "400px",
        "color": {
            "scale": {
              "Female": "#FF7676",
              "Male": "#50BEE1",
              "Other": "#FFC387"
            }
        }
    }
};

const AttributedPCPClinicalDetailComparisonRow = [
    {
        "id": "1",
        "title": "Bay Shore Primary Care Associates",
        "medicalMemberMonths": "10,612",
        "allowedPMPMRiskAdjusted": "$1,012.80",
        "allowedPerRVU": "$62.68",
        "EBMRate": "29%",
        "percentIPAvoidableAdmits": "5%",
        "EDFrequentFlyerPercent": "3%"
    },
    {
        "id": "2",
        "title": "Choice Primary Care Physicians",
        "medicalMemberMonths": "9,742",
        "allowedPMPMRiskAdjusted": "$1,180.55",
        "allowedPerRVU": "$74.63",
        "EBMRate": "30%",
        "percentIPAvoidableAdmits": "10%",
        "EDFrequentFlyerPercent": "4%"
    },
    {
        "id": "3",
        "title": "Assured Primary Care Team",
        "medicalMemberMonths": "9,697",
        "allowedPMPMRiskAdjusted": "$1,235.22",
        "allowedPerRVU": "$69.05",
        "EBMRate": "12%",
        "percentIPAvoidableAdmits": "8%",
        "EDFrequentFlyerPercent": "4%"
    },
    {
        "id": "4",
        "title": "Holly Hill Primary Care Partners",
        "medicalMemberMonths": "9,096",
        "allowedPMPMRiskAdjusted": "$943.45",
        "allowedPerRVU": "$61.59",
        "EBMRate": "19%",
        "percentIPAvoidableAdmits": "7%",
        "EDFrequentFlyerPercent": "3%"
    },
    {
        "id": "5",
        "title": "Affilliated Primary Care Team",
        "medicalMemberMonths": "9,052",
        "allowedPMPMRiskAdjusted": "$1,074.51",
        "allowedPerRVU": "$70.65",
        "EBMRate": "21%",
        "percentIPAvoidableAdmits": "2%",
        "EDFrequentFlyerPercent": "5%"
    },
    {
        "id": "6",
        "title": "Choice Primary Care Physicians",
        "medicalMemberMonths": "8,882",
        "allowedPMPMRiskAdjusted": "$1,026.46",
        "allowedPerRVU": "$65.55",
        "EBMRate": "22%",
        "percentIPAvoidableAdmits": "12%",
        "EDFrequentFlyerPercent": "3%"
    },
    {
        "id": "7",
        "title": "Assured Primary Care",
        "medicalMemberMonths": "8,880",
        "allowedPMPMRiskAdjusted": "$1,408.55",
        "allowedPerRVU": "$74.54",
        "EBMRate": "33%",
        "percentIPAvoidableAdmits": "8%",
        "EDFrequentFlyerPercent": "2%"
    },
    {
        "id": "8",
        "title": "Canfield Primary Care Clinic",
        "medicalMemberMonths": "8,394",
        "allowedPMPMRiskAdjusted": "$1,373.94",
        "allowedPerRVU": "$67.79",
        "EBMRate": "32%",
        "percentIPAvoidableAdmits": "4%",
        "EDFrequentFlyerPercent": "4%"
    },
    {
        "id": "9",
        "title": "Affiliated Primary Care Alliance",
        "medicalMemberMonths": "8,145",
        "allowedPMPMRiskAdjusted": "$1,042.37",
        "allowedPerRVU": "$63.28",
        "EBMRate": "18%",
        "percentIPAvoidableAdmits": "9%",
        "EDFrequentFlyerPercent": "3%"
    }

]

const AttributedPCPClinicalDetailComparisonHeader = [
    {
        "key": "title",
        "header": ""
    },
    {
        "key": "medicalMemberMonths",
        "header": "Medical Member Months"
    },
    {
        "key": "allowedPMPMRiskAdjusted",
        "header": "Allowed PMPM Risk Adjusted"
    },
    {
        "key": "allowedPerRVU",
        "header": "Allowed per RVU"
    },
    {
        "key": "EBMRate",
        "header": "EBM Rate"
    },
    {
        "key": "percentIPAvoidableAdmits",
        "header": "% IP Avoidable Admits"
    },
    {
        "key": "EDFrequentFlyerPercent",
        "header": "ED Frequent Flyer %"
    }
]

const TopPCPCClinics = {
    "data": [
      {
        "clinic": "Assurance Primary Care Clinic",
        "group": "Medical Member Months",
        "value": 9000
      },
      {
        "clinic": "Canfield Primary Care Clinic",
        "group": "Medical Member Months",
        "value": 8700
      },
      {
        "clinic": "Assured Primary Care Team",
        "group": "Medical Member Months",
        "value": 9500
      },
      {
        "clinic": "Choice Primary Care Physicians",
        "group": "Medical Member Months",
        "value": 9700
      },
      {
        "clinic": "Affiliated Primary Care Team",
        "group": "Medical Member Months",
        "value": 9200
      },
      {
        "clinic": "Affiliated Primary Care Alliance",
        "group": "Medical Member Months",
        "value": 8300
      },
      {
        "clinic": "Affiliated Primary Care Network",
        "group": "Medical Member Months",
        "value": 9000
      },
      {
        "clinic": "Bay Shore Primary Care Associates",
        "group": "Medical Member Months",
        "value": 11000
      },
      {
        "clinic": "Central Primary Care Associates",
        "group": "Medical Member Months",
        "value": 8000
      },
      {
        "clinic": "Holly Hill Primary Care Partners",
        "group": "Medical Member Months",
        "value": 9000
      },
      {
        "clinic": "Assurance Primary Care Clinic",
        "group": "Allowed PMPM - Risk Adjusted",
        "allowed": 1500
      },
      {
        "clinic": "Canfield Primary Care Clinic",
        "group": "Allowed PMPM - Risk Adjusted",
        "allowed": 1450
      },
      {
        "clinic": "Assured Primary Care Team",
        "group": "Allowed PMPM - Risk Adjusted",
        "allowed": 1100
      },
      {
        "clinic": "Choice Primary Care Physicians",
        "group": "Allowed PMPM - Risk Adjusted",
        "allowed": 1050
      },
      {
        "clinic": "Affiliated Primary Care Team",
        "group": "Allowed PMPM - Risk Adjusted",
        "allowed": 950
      },
      {
        "clinic": "Affiliated Primary Care Alliance",
        "group": "Allowed PMPM - Risk Adjusted",
        "allowed": 900
      },
      {
        "clinic": "Affiliated Primary Care Network",
        "group": "Allowed PMPM - Risk Adjusted",
        "allowed": 900
      },
      {
        "clinic": "Bay Shore Primary Care Associates",
        "group": "Allowed PMPM - Risk Adjusted",
        "allowed": 900
      },
      {
        "clinic": "Central Primary Care Associates",
        "group": "Allowed PMPM - Risk Adjusted",
        "allowed": 850
      },
      {
        "clinic": "Holly Hill Primary Care Partners",
        "group": "Allowed PMPM - Risk Adjusted",
        "allowed": 800
      }
    ],
    "options": {
      "title": "Top PCP Clinics: Risk Adjusted Allowed PMPM and Member Months",
      "axes": {
        "left": {
          "mapsTo": "value",
          "scaleType": ScaleTypes.LINEAR,
          "correspondingDatasets": [
            "Medical Member Months"
          ]
        },
        "right": {
          "mapsTo": "allowed",
          "scaleType": ScaleTypes.LINEAR,
          "correspondingDatasets": [
            "Allowed PMPM - Risk Adjusted"
          ],
          "ticks": {
            formatter: ((value:any)=>{return '$'+value})
          }
        },
        "bottom": {
          "mapsTo": "clinic",
          "scaleType": ScaleTypes.LABELS
        }
      },
      "comboChartTypes": [
        {
          "type": "simple-bar",
          "correspondingDatasets": [
            "Medical Member Months"
          ]
        },
        {
          "type": "line",
          "options": {
            "points": {
              "radius": 5
            }
          },
          "correspondingDatasets": [
            "Allowed PMPM - Risk Adjusted"
          ]
        }
      ],
      "height": "400px",
      "color": {
        "scale": {
          "Medical Member Months": "#0067B5",
          "Allowed PMPM - Risk Adjusted": "#FF6400"
        }
      },
      "bars": {
        "width": 48
      }
    }
}

const TopProviderSpecialties = {
    data: [
        {
            "name": "Family Practice",
            "children": [
            {
                "name": "Family Practice",
                "value": 20,
                "showLabel": true
            }
            ]
        },
        {
            "name": "Nurse Practitioner",
            "children": [
            {
                "name": "Nurse Practitioner",
                "value": 15,
                "showLabel": true
            }
            ]
        },
        {
            "name": "Internal Medicine",
            "children": [
            {
                "name": "Internal Medicine",
                "value": 12,
                "showLabel": true
            }
            ]
        },
        {
            "name": "Obstetrics / Gynecology",
            "children": [
            {
                "name": "Obstetrics / Gynecology",
                "value": 8,
                "showLabel": true
            }
            ]
        },
        {
            "name": "Orthopedic Surgery",
            "children": [
            {
                "name": "Orthopedic Surgery",
                "value": 5,
                "showLabel": true
            }
            ]
        },
        {
            "name": "Pediatric Medicine",
            "children": [
            {
                "name": "Pediatric Medicine",
                "value": 10,
                "showLabel": true
            }
            ]
        },
        {
            "name": "Chiropractic",
            "children": [
            {
                "name": "Chiropractic",
                "value": 8,
                "showLabel": true
            }
            ]
        },
        {
            "name": "Physician Assistant",
            "children": [
            {
                "name": "Physician Assistant",
                "value": 8,
                "showLabel": true
            }
            ]
        },
        {
            "name": "Neurology",
            "children": [
            {
                "name": "Neur...",
                "value": 5,
                "showLabel": true
            }
            ]
        },
        {
            "name": "Clinical",
            "children": [
            {
                "name": "Clin...",
                "value": 3,
                "showLabel": true
            }
            ]
        }
    ],
    options: {
        "height": "293px",
        "color": {
            "scale": {
            "Family Practice": "#0080E2",
            "Nurse Practitioner": "#0073CB",
            "Internal Medicine": "#005A9E",
            "Obstetrics / Gynecology": "#004071",
            "Orthopedic Surgery": "#000D17",
            "Pediatric Medicine": "#004D88",
            "Chiropractic": "#00335A",
            "Physician Assistant": "#002644",
            "Neurology": "#001A2D",
            "Clinical": "#00150D"
            },
            "legend": {
                "enabled": false
            }
        }
    }
}

const SpecialistUtilizationByNetworkStatus = {
    data: [
        {
            "group": "In Network",
            "key": "Orthopedic Surgery",
            "value": 30
        },
        {
            "group": "In Network",
            "key": "Pathology",
            "value": 35
        },
        {
            "group": "In Network",
            "key": "Public Health / General Welfare Agencies",
            "value": 36
        },
        {
            "group": "In Network",
            "key": "Optomotrist",
            "value": 32
        },
        {
            "group": "In Network",
            "key": "Dentist",
            "value": 35
        },
        {
            "group": "In Network",
            "key": "Physician Assistant",
            "value": 24
        },
        {
            "group": "In Network",
            "key": "Obstetrics / Gynecology",
            "value": 30
        },
        {
            "group": "In Network",
            "key": "Physical Therapist (Indipendent Practice)",
            "value": 32
        },
        {
            "group": "In Network",
            "key": "Clinical Psychologist",
            "value": 31
        },
        {
            "group": "In Network",
            "key": "Nurse Practitioner",
            "value": 25
        },
        {
            "group": "In Network",
            "key": "Chriopracter",
            "value": 27
        },
        {
            "group": "In Network",
            "key": "Internal Medicine",
            "value": 45
        },
        {
            "group": "In Network",
            "key": "Pediatric Medicine",
            "value": 46
        },
        {
            "group": "In Network",
            "key": "Diagnostic Radiology",
            "value": 35
        },
        {
            "group": "In Network",
            "key": "Family Practice",
            "value": 28
        },
        {
            "group": "In Network",
            "key": "Clinical Laboratory (Billing Independently)",
            "value": 20
        },
        {
            "group": "In Network",
            "key": "Hospital",
            "value": 35
        },
        {
            "group": "Out of Network",
            "key": "Orthopedic Surgery",
            "value": 70
        },
        {
            "group": "Out of Network",
            "key": "Pathology",
            "value": 65
        },
        {
            "group": "Out of Network",
            "key": "Public Health / General Welfare Agencies",
            "value": 64
        },
        {
            "group": "Out of Network",
            "key": "Optomotrist",
            "value": 68
        },
        {
            "group": "Out of Network",
            "key": "Dentist",
            "value": 65
        },
        {
            "group": "Out of Network",
            "key": "Physician Assistant",
            "value": 76
        },
        {
            "group": "Out of Network",
            "key": "Obstetrics / Gynecology",
            "value": 70
        },
        {
            "group": "Out of Network",
            "key": "Physical Therapist (Indipendent Practice)",
            "value": 68
        },
        {
            "group": "Out of Network",
            "key": "Clinical Psychologist",
            "value": 69
        },
        {
            "group": "Out of Network",
            "key": "Nurse Practitioner",
            "value": 75
        },
        {
            "group": "Out of Network",
            "key": "Chriopracter",
            "value": 73
        },
        {
            "group": "Out of Network",
            "key": "Internal Medicine",
            "value": 55
        },
        {
            "group": "Out of Network",
            "key": "Pediatric Medicine",
            "value": 54
        },
        {
            "group": "Out of Network",
            "key": "Diagnostic Radiology",
            "value": 65
        },
        {
            "group": "Out of Network",
            "key": "Family Practice",
            "value": 72
        },
        {
            "group": "Out of Network",
            "key": "Clinical Laboratory (Billing Independently)",
            "value": 80
        },
        {
            "group": "Out of Network",
            "key": "Hospital",
            "value": 65
        }
    ],
    options: {
        "title": "Specialist Utilization by In and Out of Network Status",
        "axes": {
            "left": {
                "scaleType": ScaleTypes.LABELS
            },
            "bottom": {
                "mapsTo": "value",
                "scaleType": ScaleTypes.LINEAR,
                "stacked": true,
                "percentage": true,
                "ticks": {
                    "values": [ 0, 20, 40, 60, 80, 100 ],
                    "formatter": ((value: any)=>{ return value+'%' })
                },
            }
        },
        "height": "400px",
        "color": {
            "scale": {
              "In Network": "#0080E2",
              "Out of Network": "#FF6400"
            }
        }
    }
};

export {
    PCPOfficeVisitsRow, PCPOfficeVisitsHeader, TelehealthOfficeVisitsRow, TelehealthOfficeVisitsHeader, SpecialistVisitsRow, SpecialistVisitsHeader,
    OBGYNVisitsRow, OBGYNVisitsHeader, PhysicianOfficeVisits, PhysicianOfficeVisitsByRelationship, PCPVisitsPer1000, OfficeVisits, PCPOfficeVisitsUtilsPer1000,
    TelehealthVisitsPercentOfAllOfficeVisits, TelehealthUtilization, AttributedPCPClinicalDetailComparisonRow, AttributedPCPClinicalDetailComparisonHeader,
    TopPCPCClinics, TopProviderSpecialties, SpecialistUtilizationByNetworkStatus
}