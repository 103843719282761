import { Column, DataTableCell, DataTableHeader, DenormalizedRow, Dropdown, Grid, Modal, Row, Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow, Toggle } from 'carbon-components-react';
import { connect } from 'react-redux';
import { actions } from '../../redux/Dashboard/actions';
import { DataTable } from 'carbon-components-react';
import { CheckmarkFilled32, Download16, Information16 } from '@carbon/icons-react';
import { PacketSummary } from './PacketSummary';
import PacketDownloads from './PacketDownloads';

const ActivityAndInfoModal = ({ open, toggleModal }: { open: boolean, toggleModal: any }) => {
  return (

    <Modal
      modalHeading="Activity & Info"
      passiveModal
      secondaryButtonText="Close"
      open={open}
      onRequestClose={() => toggleModal(false)}
    >
      <Grid>
        <Row>
          <p>Check packet status, disable packet, preview sample packet, download files, and view packet summary</p>

        </Row>
        <Row>
          <p style={{ margin: '1rem', padding: '1rem' }}>
            Packet Status
          </p>
        </Row>
        <Row>
          <div>
            <span>
              <CheckmarkFilled32 style={{ fill: "green", display: 'inline-block', paddingTop: "8px"}} /><h5 style={{ display: 'inline-block' }}>Enabled</h5>
            </span>
            <div style={{ padding: "30px" }}>
              <p style={{ paddingBottom: "10px" }}>A new iteration of this report packet will run after your data is refreshed, every 3 months</p>
              <p>Next Run Estimate:  <span style={{ fontWeight: 700 }}>10/22/2021</span></p>
              <p>Last Run Completed:  <span style={{ fontWeight: 700 }}>7/22/2021</span></p>
            </div>

          </div>
        </Row>

        <Row>
          <Column>
            <h6>Disable Packet<span><Information16 /></span></h6>
            <Toggle id={"toggleEnabled"} labelText="" labelA="No" labelB="Yes" />
          </Column>
          <Column>
            <Dropdown
              style={{ width: "250px", margin: "0 auto", paddingTop: "30px" }}
              id="samplePacket"
              titleText="Preview Sample Packet"
              label="Dropdown menu options"
              items={["sample 1", "sample 2"]}
              initialSelectedItem="sample 1"
              onChange={() => { }}
            /><Download16 style={{float:"right"}} />
          </Column>
        </Row>


        <h5>Download Files</h5>
        <PacketDownloads />
        <h5>Packet Summary</h5>
        <PacketSummary />
      </Grid>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    toggleModal: (open: boolean) => dispatch(actions.openActivityAndInfoModal(open))
  };
}

const mapStateToProps = (state: any) => {
  return {
    open: state.root.activityAndInfoModalOpen
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityAndInfoModal);
