import { Button, TextInput } from "carbon-components-react";
import { useCallback, useState } from "react";
import { AjaxAction } from "../ajax/AjaxActions";

const SQLPage = () => {
    const [input, setInput] = useState('');
    const [res, setRes] = useState({});
    const handleChange = useCallback(({ target }) => {
        setInput(target.value);
      }, []);
    return (
        <div>
            <TextInput
                id="SQLInput"
                labelText="Custom Databricks SQL"
                value={input}
                onChange={handleChange}
            />
            <Button onClick={async () => {
                var queryResult: any = await new AjaxAction().runSQLQueryDatabricks(input);
                setRes(queryResult);
            }}>Run SQL</Button>

            <div>
                {JSON.stringify(res)}
            </div>
        </div>
    );
}

export default SQLPage;
