import { Column, DataTable, OverflowMenu, Row, Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow } from "carbon-components-react"
import { ChartTypes } from "../../Widget/DataDisplays/ChartTypes"
import { downTrendSvg, upTrendSvg } from "../../Other/SVG"
import { GroupedBarChart, SimpleBarChart, StackedBarChart, ComboChart } from "@carbon/charts-react"
import { ImagingServicesComboChart, ImagingServices_BarChart, LabServicesComboChart, LabServices_BarChart, OutOfNetworkUtilizationByMarket_GroupedChart, OutOfNetworkUtilization_GroupedChart, OutpatientAllowedPMPMVBenchmark, OutpatientSurgeriesComboChart, OutpatientSurgeries_BarChart, OutpatientUtilizationBenchmark, PotentialSiteOfServiceSavings_BarChart, UtilizationByRelationship_StackedBarChart, UtilzationByPlan_GroupedBarChart } from "../../../data/Chart1/chartData"
import { useSelector } from "react-redux"
import { ImagingServicesHeader, ImagingServicesRow, LabServicesHeader, LabServicesRow, OutpatientSurgeryHeader, OutpatientSurgeryRow } from "../../../data/OutpatientEventsData/OutpatientEventTable"
import StyledLink from "../../SideNavigation/StyledLink"

export const OutpatientEvents = () => {
    const reportCreatedDate = useSelector((state: any) => state.root.reportCreatedDate);
	return (
	<Column style={{paddingBottom: '200px'}}>
        <Row style={{marginLeft: "0px"}}>
            <Column>
                <Row><h1 style={{color: "#39414D", marginTop: '18px'}}>Overview</h1></Row>
                <Row style={{ marginTop: "10px", width: "832px" }}><p>Outpatient surgery, lab services, and imaging services are generally lower cost than their inpatient counterparts. Utilize this storyboard to distinguish financial variances between these key service types, obtain vital information on Site of Service optimization, and  compare the utilization and performance to benchmark for these Outpatient events.</p></Row>
                <Row style={{ marginTop: "20px", width: "900px" }}><p>{`Report Created: ${reportCreatedDate}`}</p></Row>
                <Row style={{ marginTop: "20px", width: "900px" }}>
                    <Column style={{ padding: '0px' }}>
                            <ul>
                                <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href="/" style={{ textDecoration: 'none' }}>Key Service Types</a></li>
                                <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href="/" style={{ textDecoration: 'none' }}>Site of Service Opportunity</a></li>
                                <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href="/" style={{ textDecoration: 'none' }}>Utilization Details</a></li>
                            </ul>
                    </Column>
                    <Column style={{ padding: '0px' }}>
                            <ul>
                                <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href="/" style={{ textDecoration: 'none' }}>Sevice Details</a></li>
                                <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href="/" style={{ textDecoration: 'none' }}>Out of Network Utilization</a></li>
                                <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href="/" style={{ textDecoration: 'none' }}>Performance to Benchmark</a></li>
                            </ul>
                    </Column>
                    <Column style={{ padding: '0px' }}>
                            <ul>
                                <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href="/" style={{ textDecoration: 'none' }}>Explore Related Topics</a></li>
                            </ul>
                    </Column>
                </Row>
            </Column>
        </Row>


            <Row style={{marginLeft: "0px", marginTop: "70px" }}><h2>Key Service Types</h2></Row>
			<Row style={{marginLeft: "0px", marginTop: "30px" }}>
				<Column style={{padding: "0"}}>
					<DataTable rows={OutpatientSurgeryRow} headers={OutpatientSurgeryHeader} isSortable>
						{({
							rows = OutpatientSurgeryRow,
							headers = OutpatientSurgeryHeader,
							getHeaderProps,
							getRowProps,
							getTableProps,
							getTableContainerProps
						}: { rows: any, headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
							<TableContainer
								title={
									<div style={{ display: "flex", justifyContent: "space-between", height: "1.5rem" }}>
										<h4>Outpatient Surgery</h4>
										<OverflowMenu></OverflowMenu>
                    				</div>
								}
								description=""
								{...getTableContainerProps()}
							>
								<Table {...getTableProps()} isSortable>
									<TableHead>
										<TableRow>
											{headers.map((header) => (
												<TableHeader
													key={header.key}
													{...getHeaderProps({ header })}
													isSortable
												>
													{header.header}
												</TableHeader>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.map((row: any) => (
											<TableRow {...getRowProps({ row })}>
												{row.cells.map((cell: any) => {
													if (cell.value[0] === "+") {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
															{upTrendSvg()}{cell.value.substring(1)}</TableCell>)
													} else if (cell.value[0] === "-") {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
															{downTrendSvg()}{cell.value.substring(1)}</TableCell>)
													} else {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>{cell.value}</TableCell>)
													}
												})}
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</DataTable>
				</Column>
				<Column>
					<DataTable rows={LabServicesRow} headers={LabServicesHeader} isSortable>
						{({
							rows = LabServicesRow,
							headers = LabServicesHeader,
							getHeaderProps,
							getRowProps,
							getTableProps,
							getTableContainerProps
						}: { rows: any, headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
							<TableContainer
								title={<div style={{ display: "flex", justifyContent: "space-between", height: "1.5rem" }}>
								<h4>Lab Services</h4>
								<OverflowMenu></OverflowMenu>
							</div>}
								description=""
								{...getTableContainerProps()}
							>
								<Table {...getTableProps()} isSortable>
									<TableHead>
										<TableRow>
											{headers.map((header) => (
												<TableHeader
													key={header.key}
													{...getHeaderProps({ header })}
													isSortable
												>
													{header.header}
												</TableHeader>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.map((row: any) => (
											<TableRow {...getRowProps({ row })}>
												{row.cells.map((cell: any) => {
													if (cell.value[0] === "+") {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
															{upTrendSvg()}{cell.value.substring(1)}</TableCell>)
													} else if (cell.value[0] === "-") {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
															{downTrendSvg()}{cell.value.substring(1)}</TableCell>)
													} else {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>{cell.value}</TableCell>)
													}
												})}
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</DataTable>
				</Column>
			</Row>
			<Row style={{marginLeft: "0px", marginTop: "30px"}}>
				<Column style={{padding: "0"}}>
					<DataTable rows={ImagingServicesRow} headers={ImagingServicesHeader} isSortable>
						{({
							rows = ImagingServicesRow,
							headers = ImagingServicesHeader,
							getHeaderProps,
							getRowProps,
							getTableProps,
							getTableContainerProps
						}: { rows: any, headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
							<TableContainer
								title={<div style={{ display: "flex", justifyContent: "space-between", height: "1.5rem" }}>
								<h4>Imaging Services</h4>
								<OverflowMenu></OverflowMenu>
							</div>}
								description=""
								{...getTableContainerProps()}
							>
								<Table {...getTableProps()} isSortable>
									<TableHead>
										<TableRow>
											{headers.map((header) => (
												<TableHeader
													key={header.key}
													{...getHeaderProps({ header })}
													isSortable
												>
													{header.header}
												</TableHeader>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.map((row: any) => (
											<TableRow {...getRowProps({ row })}>
												{row.cells.map((cell: any) => {
													if (cell.value[0] === "+") {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
															{upTrendSvg()}{cell.value.substring(1)}</TableCell>)
													} else if (cell.value[0] === "-") {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
															{downTrendSvg()}{cell.value.substring(1)}</TableCell>)
													} else {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>{cell.value}</TableCell>)
													}
												})}
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</DataTable>
				</Column>
				<Column>
				</Column>
			</Row>
			
            <Row style={{marginLeft: "0px", marginTop: "70px" }}><h2>Site of Service Opportunity</h2></Row>
			<Row style={{ marginLeft: "0px", marginTop: "30px" }}>
				<Column lg={8} style={{ padding: "0px 10px 0px 0px" }}>
					<div style={{ backgroundColor: "white", padding: "20px" ,display: "flex"}}>
						<SimpleBarChart
							data={PotentialSiteOfServiceSavings_BarChart.data}
							options={PotentialSiteOfServiceSavings_BarChart.options}
						/>
						<OverflowMenu />
					</div>
				</Column>
				<Column lg={4} style={{ backgroundColor: '#FFFFFF' }}>
                <p style={{ color: '#39414D', lineHeight: '18px', fontSize: '16px', marginLeft: '10px', marginTop: '20px', fontWeight:'bold'}}>Site of Service Savings Calculations </p>
                <p style={{ color: '#39414D', lineHeight: '18px', fontSize: '14px', marginLeft: '10px', marginTop: '20px'}}> <b style={{fontWeight:'bold'}}> Outpatient Surgical </b> - Savings is calculated based on average surgical costs at an ambulatory surgical center versus average surgical cost at an outpatient hospital</p>
                <p style={{ color: '#39414D', lineHeight: '18px', fontSize: '14px', marginLeft: '10px', marginTop: '15px'}}> <b style={{fontWeight:'bold'}}> Imaging </b> - Savings is calculated based on average imaging cost at a free standing imaging center versus an outpatient hospital.</p>
                <p style={{ color: '#39414D', lineHeight: '18px', fontSize: '14px', marginLeft: '10px', marginTop: '15px'}}> <b style={{fontWeight:'bold'}}> Lab </b> - Savings is  calculated based on average lab cost at an independent lab versus an outpatient hospital</p>
            </Column>
			</Row>
            <Row style={{marginLeft: "0px", marginTop: "70px"}}>
				<h2>Utilization Details</h2>
			</Row>
            <Row style={{marginLeft: "0px", marginTop: "30px"}}>
                <Column style={{padding: "0px 10px 0px 0px"}}>
                    <div style={{backgroundColor: "white", padding: "20px"}}>
                        <StackedBarChart
							data={UtilizationByRelationship_StackedBarChart.data}
							options={UtilizationByRelationship_StackedBarChart.options}
                        />
                    </div>

                </Column>
                <Column style={{padding: "0px 10px 0px 0px"}}>
                    <div style={{backgroundColor: "white", padding: "20px"}}>
						<GroupedBarChart
							data={UtilzationByPlan_GroupedBarChart.data}
							options={UtilzationByPlan_GroupedBarChart.options}
                        />
                    </div>
                </Column>
            </Row>


            <Row style={{marginLeft: "0px", marginTop: "70px"}}>
				<h2>Service Details</h2>
			</Row>
            <Row style={{marginLeft: "0px", marginTop: "30px"}}>
                <Column style={{padding: "0px 10px 0px 0px"}}>
                    <div style={{backgroundColor: "white", padding: "20px"}}>
						<h5 >Outpatient Surgeries</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>Utilization and Unit Cost</p>
						<ComboChart
							data={OutpatientSurgeriesComboChart.data}
							options={OutpatientSurgeriesComboChart.options}
                        />
                    </div>
                </Column>

                <Column style={{padding: "0px 10px 0px 0px"}}>
                    <div style={{backgroundColor: "white", padding: "20px"}}>
						<h5 >Lab Services</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>Utilization and Unit Cost</p>
						<ComboChart
							data={LabServicesComboChart.data}
							options={LabServicesComboChart.options}
                        />
                    </div>
                </Column>
                <Column style={{padding: "0px 10px 0px 0px"}}>
                    <div style={{backgroundColor: "white", padding: "20px"}}>
						<h5 >Imaging Services</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>Utilization and Unit Cost</p>
						<ComboChart
							data={ImagingServicesComboChart.data}
							options={ImagingServicesComboChart.options}
                        />
                    </div>
                </Column>
            </Row>
            <Row style={{marginLeft: "0px", marginTop: "70px"}}>
				<h2>Out of Network Utilization Details</h2>
			</Row>
            <Row style={{marginLeft: "0px", marginTop: "30px"}}>
                <Column style={{padding: "0px 10px 0px 0px"}}>
                    <div style={{backgroundColor: "white", padding: "20px"}}>
						<GroupedBarChart
							data={OutOfNetworkUtilization_GroupedChart.data}
							options={OutOfNetworkUtilization_GroupedChart.options}
                        />
                    </div>
                </Column>
                <Column style={{padding: "0px 10px 0px 0px"}}>
                    <div style={{backgroundColor: "white", padding: "20px"}}>
						<GroupedBarChart
							data={OutOfNetworkUtilizationByMarket_GroupedChart.data}
							options={OutOfNetworkUtilizationByMarket_GroupedChart.options}
                        />
                    </div>
                </Column>
            </Row>
            <Row style={{marginLeft: "0px", marginTop: "30px"}}>
                <Column style={{padding: "0px 10px 0px 0px"}}>
                    <div style={{backgroundColor: "white", padding: "20px"}}>
						<h5 >Outpatient Surgeries</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>Top Out of Network Providers Paid</p>
						<SimpleBarChart
							data={OutpatientSurgeries_BarChart.data}
							options={OutpatientSurgeries_BarChart.options}
                        />
                    </div>
                </Column>
                <Column style={{padding: "0px 10px 0px 0px"}}>
                    <div style={{backgroundColor: "white", padding: "20px"}}>
						<h5 >Lab Services</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>Top Out of Network Providers Paid</p>
						<SimpleBarChart
							data={LabServices_BarChart.data}
							options={LabServices_BarChart.options}
                        />
                    </div>
                </Column>
                <Column style={{padding: "0px 10px 0px 0px"}}>
                    <div style={{backgroundColor: "white", padding: "20px"}}>
						<h5 >Imaging Services</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>Top Out of Network Providers Paid</p>
						<SimpleBarChart
							data={ImagingServices_BarChart.data}
							options={ImagingServices_BarChart.options}
                        />
                    </div>
                </Column>
            </Row>
            <Row style={{marginLeft: "0px", marginTop: "70px"}}>
				<h2>Performance to Benchmarks</h2>
			</Row>
			<Row style={{ backgroundColor: "white", flexDirection: 'column', marginLeft: "0px", marginTop: '30px' }}>
				<Row style={{ padding: "2rem", flexDirection: 'column' }}>
					<h4 style={{ fontWeight: 'bold' }}>Allowed PMPM by Service vs. Benchmark</h4>
					<p style={{ paddingBottom: '15px'}}>Your allowed cost position relative to your benchmark target</p>
					<ComboChart data={OutpatientAllowedPMPMVBenchmark.data} options={OutpatientAllowedPMPMVBenchmark.options} />
				</Row>
			</Row >
			<Row style={{ backgroundColor: "white", flexDirection: 'column', marginLeft: "0px", marginTop: '30px' }}>
				<Row style={{ padding: "2rem", flexDirection: 'column' }}>
					<h4 style={{ fontWeight: 'bold' }}>Utilization per 1000  by Service vs. Benchmark</h4>
					<p style={{ paddingBottom: '15px'}}>Your utilization position relative to your benchmark target</p>
					<ComboChart data={OutpatientUtilizationBenchmark.data} options={OutpatientUtilizationBenchmark.options} />
				</Row>
			</Row >


			<Row style={{ padding: '3rem 0rem 1rem', marginLeft: '0px' }}>
				<h1>Explore Related Topics</h1>
			</Row>
			<Row id="tableLink" style={{ maxWidth: '80%', marginLeft: '0px', marginBottom: '60px' }}>
				<Column style={{ padding: '0px' }}>
					<ul>
						<li className='relatedList' style={{ padding: '6px 4px', color: '#0067B5' }}>
							<StyledLink to="#" >Outpatient Surgical <span style={{ color: 'gray' }}>(Special Topic)</span> </StyledLink>
						</li>
						<li className='relatedList' style={{ padding: '6px 4px', color: '#0067B5' }}>
							<StyledLink to="#" >Total Savings Opportunities <span style={{ color: 'gray' }}>(Special Topic)</span> </StyledLink>
						</li>
						<li className='relatedList' style={{ padding: '6px 4px', color: '#0067B5' }}>
							<StyledLink to="#">Emergent Care <span style={{ color: 'gray' }}>(Storyboard)</span>  </StyledLink>
						</li>
					</ul>
				</Column>
				<Column style={{ padding: '0px' }}>
					<ul>
						<li className='relatedList' style={{ padding: '6px 4px', color: '#0067B5' }}>
							<StyledLink to="#">Contribution to Trend <span style={{ color: 'gray' }}>(Storyboard)</span> </StyledLink>
						</li>
					</ul>
				</Column>
			</Row>
		</Column>
    )
}
