import {
  TOGGLE_ACTIVITY_AND_INFO_MODAL, LOGIN, SET_ACTIVE_DASHBOARD_TAB, RESET_ACTIVE_DASHBOARD_TAB, ADD_PACKET
  } from './actionTypes';
  
  import { Actions, RootState } from './types';
  const packetRows = require('../../data/ReportScheduler/rows.json');

  const reportCreatedDate = () => {
    const date = new Date();
    const month = date.toLocaleString('default', {month: 'long'});
    const year = date.getFullYear(); 
    const day = date.getDate();
    return (month + " " + day + ", " + year);
  }

  const initialState: RootState = {
    activityAndInfoModalOpen: false,
    loginSuccessful: false,
    activeDashboardTab: { index: 0, name: "Executive Summary" },
    reportSchedulerPackets: packetRows,
    reportCreatedDate: reportCreatedDate()
  };

  var root = (state = initialState, action: Actions) => {
    switch (action.type) {
      case TOGGLE_ACTIVITY_AND_INFO_MODAL:
        return {
          ...state,
          activityAndInfoModalOpen: action.payload
        }

      case SET_ACTIVE_DASHBOARD_TAB:
        return {
          ...state,
          activeDashboardTab: action.payload
        }

      case RESET_ACTIVE_DASHBOARD_TAB:
        return {
          ...state,
          activeDashboardTab: initialState.activeDashboardTab
        }

      case LOGIN:
        return {
          ...state,
          loginSuccessful: true
        }

      case ADD_PACKET:
        return {
          ...state,
          reportSchedulerPackets: [...state.reportSchedulerPackets, action.payload]
        }

      default:
        return {
          ...state,
        };
    }
  };

  export default root;