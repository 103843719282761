import { Column, DataTable, Row, Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow } from "carbon-components-react"
import { EmergencyDepartmentRow, EmergencyDepartmentHeader, UrgentCareRow, UrgentCareHeader, PhysicianOfficeRow, PhysicianOfficeHeader, TelemedicineHeader, TelemedicineRow } from '../../../data/EmergentCare/KeyServiceSettings'
import { EmergencyUtilizerProfileHeader, EmergencyUtilizerProfileRow, EmergentCareReports } from '../../../data/EmergentCare/EmergentCareDashboardData'
import { ChartTypes } from "../../Widget/DataDisplays/ChartTypes"
import { downTrendRedSvg, downTrendSvg, upTrendGreenSvg, upTrendSvg } from "../../Other/SVG"
import { ComboChart, GroupedBarChart, LollipopChart, StackedBarChart, TreemapChart } from "@carbon/charts-react"
import imgText from '../../../images/covidMap.png';
import PotentialAvoidable from '../../../images/PotentialAvoidable.png';
import FrequentFliers from '../../../images/FrequentFliers.png';
import { AllowedPMPMVBenchmark, EmergenctAndUrgentCare, EmergencyDepartmentAndUrgentCare, EmergencyDepartmentAndUrgentCareBarChart, FrequentFlyersTreeChart, UtilizationBarChart } from "../../../data/Chart1/chartData"
import StyledLink from "../../SideNavigation/StyledLink"
import { FrequentFliersModal } from "../../Other/FrequentFliersModal"
import { useSelector } from "react-redux"

export const EmergentCare = () => {
    const reportCreatedDate = useSelector((state: any) => state.root.reportCreatedDate);
	return (
		<Column >
			<Row style={{ marginLeft: "0px" }}>
				<Column style={{ maxWidth: '65%', marginLeft: '0px' }}>
					<Row><h1 style={{ color: "#39414D", marginTop: '18px' }}>Overview</h1> </Row>
					<Row style={{ marginTop: "10px", width: "832px" }}><p>Explore a variety of key metrics to understand what happens when members have an emergent health need.  This page allows you to track four key service areas that members access for emergent care and provides a deep dive on the service setting with the most opportunity – the emergency department.</p></Row>
					<Row style={{ marginTop: "20px", width: "900px" }}><p>{`Report Created: ${reportCreatedDate}`}</p></Row>

					<Row id="tableLink" style={{ marginTop: "20px", width: "900px" }}>
						<Column style={{ padding: '0px' }}>
							<ul>
								{EmergentCareReports.slice(0, 4).map((rep) => {
									return <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}><a href={`#${rep.replace(/ /g, '')}`} style={{ textDecoration: 'none' }}> {rep}</a></li>
								})}
							</ul>
						</Column>
						<Column style={{ padding: '0px' }}>
							<ul>
								{EmergentCareReports.slice(4, 8).map((rep) => {
									return <li className='reportList' style={{ padding: '4px', color: '#0080E2' }}> <a href={`#${rep.replace(/ /g, '')}`} style={{ textDecoration: 'none' }}>{rep} </a></li>
								})}
							</ul>
						</Column>
					</Row>
				</Column>
			</Row>


			<Row style={{ marginTop: "70px", marginLeft: '0px', marginBottom: '28px' }}><h2 id={EmergentCareReports[0].replace(/ /g, '')}>{EmergentCareReports[0]}</h2></Row>
			<Row style={{ marginLeft: "0px", paddingBottom: '1rem' }}>
				<Column style={{ padding: "0px 10px 0px 0px" }}>
					<DataTable rows={EmergencyDepartmentRow} headers={EmergencyDepartmentHeader} isSortable>
						{({
							rows = EmergencyDepartmentRow,
							headers = EmergencyDepartmentHeader,
							getHeaderProps,
							getRowProps,
							getTableProps,
							getTableContainerProps
						}: { rows: any, headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
							<TableContainer
								title="Emergency Department (ED)"
								// description="Additional description if needed"
								{...getTableContainerProps()}
							>
								<Table {...getTableProps()} isSortable>
									<TableHead>
										<TableRow>
											{headers.map((header) => (
												<TableHeader
													key={header.key}
													{...getHeaderProps({ header })}
													isSortable
												>
													{header.header}
												</TableHeader>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.map((row: any) => (
											<TableRow {...getRowProps({ row })}>
												{row.cells.map((cell: any) => {
													if (cell.value[0] === "+") {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
															{upTrendGreenSvg()}{cell.value.substring(1)}</TableCell>)
													} else if (cell.value[0] === "-") {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
															{downTrendRedSvg()}{cell.value.substring(1)}</TableCell>)
													} else {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>{cell.value}</TableCell>)
													}
												})}
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</DataTable>
				</Column>
				<Column style={{ padding: "0px 0px 0px 20px" }}>
					<DataTable rows={UrgentCareRow} headers={UrgentCareHeader} isSortable>
						{({
							rows = UrgentCareRow,
							headers = UrgentCareHeader,
							getHeaderProps,
							getRowProps,
							getTableProps,
							getTableContainerProps
						}: { rows: any, headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
							<TableContainer
								title="Urgent Care (UC)"
								// description="Additional description if needed"
								{...getTableContainerProps()}
							>
								<Table {...getTableProps()} isSortable>
									<TableHead>
										<TableRow>
											{headers.map((header) => (
												<TableHeader
													key={header.key}
													{...getHeaderProps({ header })}
													isSortable
												>
													{header.header}
												</TableHeader>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.map((row: any) => (
											<TableRow {...getRowProps({ row })}>
												{row.cells.map((cell: any) => {
													if (cell.value[0] === "+") {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
															{upTrendGreenSvg()}{cell.value.substring(1)}</TableCell>)
													} else if (cell.value[0] === "-") {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
															{downTrendRedSvg()}{cell.value.substring(1)}</TableCell>)
													} else {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>{cell.value}</TableCell>)
													}
												})}
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</DataTable>
				</Column>
			</Row>
			<Row style={{ marginLeft: "0px", paddingTop: '1rem', paddingBottom: '1rem' }}>
				<Column style={{ padding: "0px 10px 0px 0px" }}>
					<DataTable rows={PhysicianOfficeRow} headers={PhysicianOfficeHeader} isSortable>
						{({
							rows = PhysicianOfficeRow,
							headers = PhysicianOfficeHeader,
							getHeaderProps,
							getRowProps,
							getTableProps,
							getTableContainerProps
						}: { rows: any, headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
							<TableContainer
								title="Physician Office"
								// description="Additional description if needed"
								{...getTableContainerProps()}
							>
								<Table {...getTableProps()} isSortable>
									<TableHead>
										<TableRow>
											{headers.map((header) => (
												<TableHeader
													key={header.key}
													{...getHeaderProps({ header })}
													isSortable
												>
													{header.header}
												</TableHeader>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.map((row: any) => (
											<TableRow {...getRowProps({ row })}>
												{row.cells.map((cell: any) => {
													if (cell.value[0] === "+") {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
															{upTrendGreenSvg()}{cell.value.substring(1)}</TableCell>)
													} else if (cell.value[0] === "-") {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
															{downTrendRedSvg()}{cell.value.substring(1)}</TableCell>)
													} else {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>{cell.value}</TableCell>)
													}
												})}
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</DataTable>
				</Column>
				<Column style={{ padding: "0px 0px 0px 20px" }}>
					<DataTable rows={TelemedicineRow} headers={TelemedicineHeader} isSortable>
						{({
							rows = TelemedicineRow,
							headers = TelemedicineHeader,
							getHeaderProps,
							getRowProps,
							getTableProps,
							getTableContainerProps
						}: { rows: any, headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
							<TableContainer
								title="Telemedicine"
								// description="Additional description if needed"
								{...getTableContainerProps()}
							>
								<Table {...getTableProps()} isSortable>
									<TableHead>
										<TableRow>
											{headers.map((header) => (
												<TableHeader
													key={header.key}
													{...getHeaderProps({ header })}
													isSortable
												>
													{header.header}
												</TableHeader>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.map((row: any) => (
											<TableRow {...getRowProps({ row })}>
												{row.cells.map((cell: any) => {
													if (cell.value[0] === "+") {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
															{upTrendGreenSvg()}{cell.value.substring(1)}</TableCell>)
													} else if (cell.value[0] === "-") {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
															{downTrendRedSvg()}{cell.value.substring(1)}</TableCell>)
													} else {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>{cell.value}</TableCell>)
													}
												})}
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</DataTable>
				</Column>
			</Row>
			<Row style={{ marginTop: "70px", marginLeft: '0px', marginBottom: '28px' }}>
				<h2 id={EmergentCareReports[1].replace(/ /g, '')}>{EmergentCareReports[1]}</h2>
			</Row>
			<Row >
				<Column lg={3} >
					<div style={{ backgroundColor: "white" }}>
						{/* <GaugeChart options={PotentialAvoidableGauge.options} data={PotentialAvoidableGauge.data} /> */}
						<img src={PotentialAvoidable} alt={"Gauge Chart"} style={{ maxWidth: '106%' }} />

					</div>
				</Column>
				<Column lg={9} style={{ paddingRight: '0px' }}>
					<div style={{ backgroundColor: "white" }}>
						{/* <GaugeChart options={FrequentFliersGauge.options as any} data={FrequentFliersGauge.data} /> */}
						<img src={FrequentFliers} alt={"Gauge Chart"} style={{ maxWidth: '100%' }} />
					</div>
				</Column>
			</Row>
			<Row style={{ marginTop: "70px", marginLeft: '0px', marginBottom: '28px' }}>
				<h2 id={EmergentCareReports[2].replace(/ /g, '')}>{EmergentCareReports[2]}</h2>
			</Row>
			<Row style={{ marginTop: "30px", marginLeft: '2px', backgroundColor: "#FFF", padding: "10px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.10)" }}>
				<Row style={{ marginLeft: "0px", flexDirection: 'column' }}>
					<h5 >Map of Providers</h5>
					<p style={{ color: "#616771" }}>ED, UC, Walk-in, PCP utilized</p>
				</Row>
				<Row style={{ padding: "10px" }}>
					<img src={imgText} alt={"Carlie Anglemire"} style={{ maxWidth: '100%', maxHeight: '100%' }} />
				</Row>
			</Row>
			<Row style={{ marginTop: "70px", marginLeft: '0px', marginBottom: '28px' }}>
				<h2 id={EmergentCareReports[3].replace(/ /g, '')}>{EmergentCareReports[3]}</h2>
			</Row>

			<Row style={{ marginLeft: '0px' }}>
				<Column style={{ padding: "0px" }}>
					<div style={{ backgroundColor: "white", padding: "20px" }}>
						<h5 >Emergency Department & Urgent Care</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>Claimants per 1000</p>
						<StackedBarChart data={EmergencyDepartmentAndUrgentCareBarChart.data} options={EmergencyDepartmentAndUrgentCareBarChart.options} />
					</div>
				</Column>
				<Column style={{ padding: "0px", margin: '0px 30px' }}>
					<div style={{ backgroundColor: "white", padding: "20px" }}>
						<h5 >Utilization</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>By Relationship</p>
						<StackedBarChart data={UtilizationBarChart.data} options={UtilizationBarChart.options} />
					</div>
				</Column>
				<Column style={{ padding: "0px" }}>
					<div style={{ backgroundColor: "white", padding: "20px" }}>
						<h5>Emergency Department & Urgent Care</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>Utilization by Plan</p>
						<GroupedBarChart data={EmergencyDepartmentAndUrgentCare.data} options={EmergencyDepartmentAndUrgentCare.options} />
					</div>
				</Column>
			</Row>

			<Row style={{ marginTop: "70px", marginLeft: '0px', marginBottom: '28px' }}>
				<h2 id={EmergentCareReports[4].replace(/ /g, '')}>{EmergentCareReports[4]}</h2>
			</Row>
			<Row style={{ marginLeft: '0px' }}>
				<Column style={{ padding: '0px' }}>
					<div style={{ backgroundColor: "white", padding: "20px" }}>
						<h5 >Emergenct & Urgent Care</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>Claimants per 1000</p>
						<GroupedBarChart data={EmergenctAndUrgentCare.data} options={EmergenctAndUrgentCare.options} />
					</div>

				</Column>
			</Row>
			<Row style={{ marginTop: "70px", marginLeft: '0px', marginBottom: '28px' }}>
				<h2 id={EmergentCareReports[5].replace(/ /g, '')}>{EmergentCareReports[5]}</h2>
			</Row>

			<Row style={{ paddingTop: '1rem', paddingBottom: '1rem', marginLeft: '0px' }}>
				<Column style={{ padding: '0px' }}>
					<DataTable rows={EmergencyUtilizerProfileRow} headers={EmergencyUtilizerProfileHeader} isSortable>
						{({
							rows = EmergencyUtilizerProfileRow,
							headers = EmergencyUtilizerProfileHeader,
							getHeaderProps,
							getRowProps,
							getTableProps,
							getTableContainerProps
						}: { rows: any, headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
							<TableContainer
								title="Emergency Utilizer Profile"
								description="Additional description if needed"
								{...getTableContainerProps()}
							>
								<Table {...getTableProps()} isSortable>
									<TableHead>
										<TableRow>
											{headers.map((header) => (
												<TableHeader
													key={header.key}
													{...getHeaderProps({ header })}
													isSortable
												>
													{header.header}
												</TableHeader>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.map((row: any) => (
											<TableRow {...getRowProps({ row })}>
												{row.cells.map((cell: any) => {
													if (cell.value[0] === "+") {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
															{upTrendGreenSvg()}{cell.value.substring(1)}</TableCell>)
													} else if (cell.value[0] === "-") {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>
															{downTrendRedSvg()}{cell.value.substring(1)}</TableCell>)
													} else {
														return (<TableCell className='tableCell' style={{ backgroundColor: "#FFF" }} key={cell.id}>{cell.value}</TableCell>)
													}
												})}
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</DataTable>
				</Column>
			</Row>

			<Row style={{ marginTop: "70px", marginLeft: '0px', marginBottom: '28px' }}>
				<h2 id={EmergentCareReports[6].replace(/ /g, '')}>{EmergentCareReports[6]}</h2>
			</Row>
			<Row>
				<Column lg={7}>
					<div style={{ backgroundColor: "white", padding: "20px" }}>
						<h5 >Frequent Fliers</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>Patients with 3 or more Emergency Department Visits</p>
						<TreemapChart options={FrequentFlyersTreeChart.options} data={FrequentFlyersTreeChart.data} />
					</div>
				</Column>
			</Row>
			<Row style={{ flexDirection: 'row-reverse', paddingTop: '20px' }}>
				<Column lg={7} style={{marginRight:'20px'}}>
					<StyledLink >
						<FrequentFliersModal />
					</StyledLink>
				</Column>
			</Row>

			<Row style={{ marginTop: "70px", marginLeft: '0px', marginBottom: '28px' }}>
				<h2 id={EmergentCareReports[7].replace(/ /g, '')}>{EmergentCareReports[7]}</h2>
			</Row>
			<Row style={{ backgroundColor: "white", flexDirection: 'column', marginTop: '20px', marginLeft: '0px' }}>
				<Row style={{ flexDirection: 'column', padding: '21px 37px' }}>
					<h5 style={{ fontWeight: 'bold' }}>Utilization per 1000  by Service vs. Benchmark</h5>
					<p>Your utilization position relative to your benchmark target</p>
					<ComboChart data={AllowedPMPMVBenchmark.data} options={AllowedPMPMVBenchmark.options} />
				</Row>
			</Row >

			<Row style={{ padding: '3rem 0rem 1rem', marginLeft: '0px' }}>
				<h1>Explore Related Topics</h1>
			</Row>
			<Row id="tableLink" style={{ maxWidth: '80%', marginLeft: '0px', marginBottom: '60px' }}>
				<Column style={{ padding: '0px' }}>
					<ul>
						<li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
							<StyledLink to="/Telehealth" onClick={() => window.scrollTo(0, 0)} >Telehealth <span style={{ color: 'gray' }}>(Special Topic)</span> </StyledLink>
						</li>
						<li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
							<StyledLink to="#">ED Provider Performance <span style={{ color: 'gray' }}>(Special Topic)</span>  </StyledLink>
						</li>
						<li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
							<StyledLink to="#">Attributed PCPs <span style={{ color: 'gray' }}>(Special Topic)</span>  </StyledLink>
						</li>
					</ul>
				</Column>
				<Column style={{ padding: '0px' }}>
					<ul>
						<li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
							<StyledLink to="#">Benchmarks Summary <span style={{ color: 'gray' }}>(Special Topic)</span> </StyledLink>
						</li>
						<li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
							<StyledLink to="#">Physician <span style={{ color: 'gray' }}>((Storyboard page))</span>  </StyledLink>
						</li>
					</ul>
				</Column>
			</Row>

		</Column>
    )
}