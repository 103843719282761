export {AjaxAction}

class AjaxAction {

    downloadPDF(pageLink: string) {
        return new Promise((resolve, reject) => {

            const req = new XMLHttpRequest();
            req.responseType = "blob";
            var apiUrl = "/api/DownloadPDF";

            req.open("POST", apiUrl);
            
            req.onerror = () => {
                reject({
                    message: req.responseText || 'The HTTP request failed due to a network error.',
                    status: req.status
                });
            };

            req.onload = () => {
                var url = window.URL.createObjectURL(req.response);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.href = url;
                a.download = "styledSvg.pdf";
                a.click();
            };

            // IF the host is localhost, add the port, otherwise we are good with the env.location.hostname
            const url = window.location.hostname.replace("localhost", "localhost:3000") + pageLink + "/pdf";
            req.send(url);            
        });
    }

    runSQLQueryDatabricks(sql: string) {
        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            req.responseType = "text"; // not sure if this applied to query result. May need to change.
            var apiUrl = "/api/SQLQuery";

            req.open("POST", apiUrl);
            
            req.onerror = () => {
                reject({
                    message: req.responseText || 'The HTTP request failed due to a network error.',
                    status: req.status
                });
            };

            req.onload = () => {
                if (req.status == 200) {
                    resolve(JSON.parse(req.responseText));
                } else {
                    // error
                    reject({
						message: req.responseText || 'The HTTP request failed due to a network error.',
						status: req.status,
						req: req
					});
                }
                
            };

            // Just send the SQL query that we want to run.
            req.send(sql);            
        })
    }

}