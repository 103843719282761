import { Breadcrumb, BreadcrumbItem, Column, Grid, Row } from 'carbon-components-react';
import { Link } from 'react-router-dom';
import StyledLink from '../SideNavigation/StyledLink';

export const HeaderBreadcrumb = () => (
	<Breadcrumb>
		<BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
		<BreadcrumbItem><Link to="/SpecialTopics">Special Topics</Link></BreadcrumbItem>
	</Breadcrumb>
);

const SpecialTopicsIndex = () => {
	return (
		<Grid style={{ width: "100%", padding: "40px", marginLeft: "40px", maxWidth: "none" }}>
		<Row style={{ marginTop: "10px", marginLeft: "0px", flexDirection: "column" }}>
			<Column>
				<Row style={{ marginLeft: "0px" }}><HeaderBreadcrumb /></Row>
				<Row style={{ marginLeft: "0px", marginTop: "20px", fontSize: "25px" }}>Special Topics</Row>
			</Column>
		</Row>

			<Row style={{ marginLeft: "26px", marginTop: "40px", height: '1309px' }}>
				<Column>
					<Row style={{ marginLeft: "0", display: "flex", flexDirection: "column" }}>
						<div style={{ height: '35px', background: '#E8E9EA' }}>
							<p style={{ fontWeight: 600, fontSize: '16px', lineHeight: '22px', marginLeft: '15px', marginTop: '6px' }}>CLINICAL JOURNEY</p>
						</div>
						<p style={{ fontSize: "14px", paddingLeft: '15px', paddingTop: '9px' }}>Deep-dive analytics capture total health plan experience for targeted clinical sub-populations.</p>
						<div style={{ paddingLeft: '15px', paddingTop: '22px', fontSize: '16px', fontWeight: 600, lineHeight: '22px' }}>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Total Cost of Care</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Asthma</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Back Pain</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Cardiac</p></StyledLink>
							<StyledLink to="/Diabetes"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Diabetes</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Fertility</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Knee/Hip Surgeries</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Maternity & Neonatal</p></StyledLink>
							<StyledLink to="/Dashboard3"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Neoplasms</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", fontWeight: 600 }}>Obesity</p></StyledLink>
						</div>
					</Row>
					<Row style={{ marginLeft: "0", display: "flex", flexDirection: "column", paddingTop: '62px' }}>
						<div style={{ height: '35px', background: '#E8E9EA' }}>
							<p style={{ fontWeight: 600, fontSize: '16px', lineHeight: '22px', marginLeft: '15px', marginTop: '6px' }}>POPULATION PROFILE</p>
						</div>
						<p style={{ fontSize: "14px", paddingLeft: '15px', paddingTop: '9px' }}>Focused narratives on key population profiles impacting cost, utilization, and risk.</p>
						<div style={{ paddingLeft: '15px', paddingTop: '22px', fontSize: '16px', fontWeight: 600, lineHeight: '22px' }}>
							<StyledLink to="/HighCostClaimants"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>High Cost Claimant Member Detail</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Member with $0 Allowed</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Members with Chronic Conditions</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", fontWeight: 600 }}>Rising Risk</p></StyledLink>
						</div>
					</Row>
				</Column>
				<Column style={{ marginLeft: "65px" }}>
					<Row style={{ marginLeft: "0", display: "flex", flexDirection: "column" }}>
						<div style={{ height: '35px', background: '#E8E9EA' }}>
							<p style={{ fontWeight: 600, fontSize: '16px', lineHeight: '22px', marginLeft: '15px', marginTop: '6px' }}>CORE FINANCIALS</p>
						</div>
						<p style={{ fontSize: "14px", paddingLeft: '15px', paddingTop: '9px' }}>Supplemental details for core financial results and performance to benchmarks.</p>
						<div style={{ paddingLeft: '15px', paddingTop: '22px', fontSize: '16px', fontWeight: 600, lineHeight: '22px' }}>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Benchmarks Summary</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Cost and Utilization Report</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Member Cost Share</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", fontWeight: 600 }}>Monthly Trends</p></StyledLink>
						</div>
					</Row>
					<Row style={{ marginLeft: "0", display: "flex", flexDirection: "column", paddingTop: '62px' }}>
						<div style={{ height: '35px', background: '#E8E9EA' }}>
							<p style={{ fontWeight: 600, fontSize: '16px', lineHeight: '22px', marginLeft: '15px', marginTop: '6px' }}>OPTIMIZATION</p>
						</div>
						<p style={{ fontSize: "14px", paddingLeft: '15px', paddingTop: '9px' }}>Detail reports explore member decision making, compliance, avoidable care, and potential associated savings associated with improvement.</p>
						<div style={{ paddingLeft: '15px', paddingTop: '22px', fontSize: '16px', fontWeight: 600, lineHeight: '22px' }}>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>EBM Compliance</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Inpatient Avoidable Admissions</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Inpatient Re-Admissions</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Inpatient Savings Opportunities</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Preventive Wellness</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", fontWeight: 600 }}>Total Savings Opportunities</p></StyledLink>
						</div>
					</Row>
				</Column>
				<Column style={{ marginLeft: "65px" }}>
					<Row style={{ marginLeft: "0", display: "flex", flexDirection: "column" }}>
						<div style={{ height: '35px', background: '#E8E9EA' }}>
							<p style={{ fontWeight: 600, fontSize: '16px', lineHeight: '22px', marginLeft: '15px', marginTop: '6px' }}>HOT TOPICS</p>
						</div>
						<p style={{ fontSize: "14px", paddingLeft: '15px', paddingTop: '9px' }}>Current healthcare industry areas of interest.</p>
						<div style={{ paddingLeft: '15px', paddingTop: '22px', fontSize: '16px', fontWeight: 600, lineHeight: '22px' }}>
							<StyledLink to="/Covid19"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>COVID-19</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Opioids</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Specialty Pharmacy</p></StyledLink>
							<StyledLink to="/Telehealth"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Telehealth</p></StyledLink>
						</div>
					</Row>
					<Row style={{ marginLeft: "0", display: "flex", flexDirection: "column", paddingTop: '72px' }}>
						<div style={{ height: '35px', background: '#E8E9EA' }}>
							<p style={{ fontWeight: 600, fontSize: '16px', lineHeight: '22px', marginLeft: '15px', marginTop: '6px' }}>PROVIDER TOOLS</p>
						</div>
						<p style={{ fontSize: "14px", paddingLeft: '15px', paddingTop: '9px' }}>Provider level analytics to compare outcomes for pertinent specialties driving cost and utilization.</p>
						<div style={{ paddingLeft: '15px', paddingTop: '22px', fontSize: '16px', fontWeight: 600, lineHeight: '22px' }}>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Inpatient Provider Performance</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>ED Provider Performance</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Attributed PCPs</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Outpatient Surgical</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", paddingBottom: '12px', fontWeight: 600 }}>Behavioral Specialists</p></StyledLink>
							<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", fontWeight: 600 }}>Out of Network Providers</p></StyledLink>
						</div>
					</Row>
				</Column>
				<Column style={{ marginLeft: "65px", background: 'rgba(80,190,225,0.1)', paddingLeft: '26px', paddingTop: '22px' }}>
					<div><p style={{ fontSize: '16px', lineHeight: '22px', color: "#39414D", fontWeight: 600 }}>RECENTLY VIEWED</p></div>
					<StyledLink to="/Covid19"><p style={{ cursor: "pointer", color: "#0080E2", marginTop: '18px', fontWeight: 600, fontSize: '16px', lineHeight: '22px' }}>COVID-19</p></StyledLink>
					<div><p style={{ color: '#616771', fontSize: '12px', lineHeight: '16px', fontWeight: 300 }}>Viewed today</p></div>
					<StyledLink to="/Diabetes"><p style={{ cursor: "pointer", color: "#0080E2", marginTop: '12px', fontWeight: 600, fontSize: '16px', lineHeight: '22px' }}>Diabetes</p></StyledLink>
					<div><p style={{ color: '#616771', fontSize: '12px', lineHeight: '16px', fontWeight: 300 }}>Viewed yesterday</p></div>
					<StyledLink to="/SpecialTopics"><p style={{ cursor: "pointer", color: "#0080E2", marginTop: '12px', fontWeight: 600, fontSize: '16px', lineHeight: '22px' }}>Members with Chronic Conditions</p></StyledLink>
					<div><p style={{ color: '#616771', fontSize: '12px', lineHeight: '16px', fontWeight: 300 }}>Viewed last week</p></div>
					<StyledLink to="/Telehealth"><p style={{ cursor: "pointer", color: "#0080E2", marginTop: '12px', fontWeight: 600, fontSize: '16px', lineHeight: '22px' }}>Telehealth</p></StyledLink>
					<div><p style={{ color: '#616771', fontSize: '12px', lineHeight: '16px', fontWeight: 300 }}>Viewed last week</p></div>
					<StyledLink to="/HighCostClaimants"><p style={{ cursor: "pointer", color: "#0080E2", marginTop: '12px', fontWeight: 600, fontSize: '16px', lineHeight: '22px' }}>High-Cost Claimants</p></StyledLink>
					<div><p style={{ color: '#616771', fontSize: '12px', lineHeight: '16px', fontWeight: 300 }}>Viewed last week</p></div>
				</Column>
			</Row>
		</Grid>
	);
}

export default SpecialTopicsIndex;
