import { Row, Column, DataTable, TableHead, TableRow, TableBody, TableCell, TableContainer, Table, TableHeader, DataTableHeader, DataTableCell, DenormalizedRow, OverflowMenu } from 'carbon-components-react';
import { StackedBarChart, TreemapChart, DonutChart, ComboChart, SimpleBarChart } from '@carbon/charts-react';
import { inpatientAdmitOpportunity, inpatientAdmissionsTotalPaid, inpatientMemberMonths, inpatientAdmits, inpatientAdmissionsCostOfCare, percentAdmitsByCCHGGrouping, admitsByRelationship, admitsPer1000,inpatientAllowedCostPerAdmitVBenchmark } from "../../../data/Chart1/chartData"
import StyledLink from '../../SideNavigation/StyledLink';
import OverviewBox from '../../Other/OverviewBox';

const allInpatient = require("../../../data/InpatientAdmissions/InpatientAdmissionsAllInpatient.json");
const topDRGByAdmits = require("../../../data/InpatientAdmissions/InpatientAdmissionsTopDRGByAdmits.json");
const topDRGPerAdmits = require("../../../data/InpatientAdmissions/InpatientAdmissionsTop10DRGPerAdmit.json");
const topDRGByAdmitsForChronicMembers = require("../../../data/InpatientAdmissions/InpatientAdmissionsTopDRGByAdmitsForChronicMembers.json");
const admitClass = require("../../../data/InpatientAdmissions/InpatientAdmissionsAdmitClass.json");
const costOfCare = inpatientAdmissionsCostOfCare;

const InpatientAdmissions = () => {
    return <Column style={{paddingBottom: '200px'}}>
        <Row>
            <Column>
                <OverviewBox
                    maxPerSection={4}
                    description="The inpatient service setting is a top component of plan spend.  Explore top admit types, key clinical cohorts driving utilization and identify if there is opportunity to improve the quality or efficiency of these admits."
                    sections={["Cost & Utilization Summary", "DRG Summary", "Chronic Member Impact", "Avoidable Admissions", "Readmissions", "Provider Detail", "Performance to Benchmark"]}
                />
            </Column>
        </Row>
        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>{'Cost & Utilization Summary'}</h3></Row>
        <Row style={{marginLeft: '0px'}}>
            <div style={{marginTop: "20px", display: "flex", width: '100%', marginLeft: '0px'}}>
                <DataTable rows={allInpatient.rows} headers={allInpatient.headers}>
                    {({
                        rows = allInpatient.rows,
                        headers = allInpatient.headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps,
                        getTableContainerProps,
                    }: { rows: DenormalizedRow[], headers: DataTableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
                        <TableContainer
                            title="All Inpatient"
                            {...getTableContainerProps()}
                            style={{backgroundColor: "#FFF", flexGrow: 1, width: "100%"}}>
                            <Table {...getTableProps()} style={{backgroundColor: "#FFF", width: "100%"}}>
                                <TableHead>
                                    <TableRow>
                                        {
                                        headers.map((header: DataTableHeader) => (
                                            <TableHeader
                                                key={header.key}
                                                {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                
                                <TableBody>
                                    {rows.map((row: DenormalizedRow) => (
                                        <TableRow key={row.id} {...getRowProps({ row })}>
                                            {row.cells.map((cell: DataTableCell) => (
                                                <TableCell key={cell.id} style={{ backgroundColor: "#FFF" }}>{cell.value}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </DataTable>
            </div>
        </Row>
        <Row style={{ paddingTop: '30px', marginLeft: '0px'}}>
            <Column style={{ backgroundColor: "#FFF", marginRight: "10px", justifyContent: "center" }}>
                <div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "100%", display:"flex"}}>
                    <StackedBarChart options={admitsByRelationship.options} data={admitsByRelationship.data} />
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", justifyContent: "center" }}>
                <div id="Export-admitsPer1000" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px' }}>
						<h5>Admits per 1000 and Allowed per RVU by IP Service Category</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>By IP Service Categories</p>
						<ComboChart options={admitsPer1000.options} data={admitsPer1000.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>

        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>DRG Summary</h3></Row>
        <Row style={{ marginTop: "32px", height: '130px', backgroundColor: '#FFFFFF', marginLeft: '0px' }}>
            <p style={{width: '832px', color: '#39414D', lineHeight: '26px', fontSize: '20px', marginLeft: '21px', marginTop: '12px'}}>About DRGs</p>
            <p style={{width: '832px', color: '#39414D', lineHeight: '24px', fontSize: '16px', marginLeft: '21px', marginTop: '-10px'}}>Diagnosis-Related Groups are used to classify inpatient stays.  The DRG groups all charges associated with an inpatient stay, from time of admission to discharge.</p>
        </Row>
        <Row style={{ paddingTop: '30px', marginLeft: "0px"}}>
            <Column style={{ backgroundColor: "#FFF", justifyContent: "center" }}>
                <div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "100%", display:"flex"}}>
                    <TreemapChart options={topDRGByAdmits.options} data={topDRGByAdmits.data}/>
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>
        <Row style={{ paddingTop: '30px', marginLeft: '0px'}}>
            <Column style={{ backgroundColor: "#FFF", justifyContent: "center" }}>
                <div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "100%", display:"flex"}}>
                    <SimpleBarChart options={topDRGPerAdmits.options} data={topDRGPerAdmits.data}/>
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>

        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Chronic Member Impact</h3></Row>
        <Row style={{ marginTop: "22px", marginLeft: '0px' }}>
            <Column style={{ backgroundColor: "#FFF", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px' }}>
						<h5>Total Paid</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>Percentage Chronic</p>
						<DonutChart options={inpatientAdmissionsTotalPaid.options} data={inpatientAdmissionsTotalPaid.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px'  }}>
						<h5>Member Months</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>Percentage Chronic</p>
						<DonutChart options={inpatientMemberMonths.options} data={inpatientMemberMonths.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", display: "flex", alignItems: "center" }}>
                <div id="Export-ComorbidCost" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", paddingBottom: '10px'  }}>
						<h5>Admits</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>Percentage Chronic</p>
						<DonutChart options={inpatientAdmits.options} data={inpatientAdmits.data} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>
        <Row style={{ paddingTop: '30px', marginLeft: '0px'}}>
            <Column style={{ backgroundColor: "#FFF", marginRight: "30px", justifyContent: "center" }}>
                <div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "100%", display:"flex"}}>
                    <StackedBarChart options={percentAdmitsByCCHGGrouping.options} data={percentAdmitsByCCHGGrouping.data}/>
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ width: "500px", backgroundColor: '#FFFFFF' }}>
                <p style={{width: '450px', color: '#39414D', lineHeight: '26px', fontSize: '20px', marginLeft: '16px', marginTop: '20px'}}>What to Consider for Chronic Conditions</p>
                <p style={{width: '475px', color: '#39414D', lineHeight: '24px', fontSize: '16px', marginLeft: '16px', marginTop: '20px'}}>Members living with chronic conditions have a unique impact on inpatient hospital utilization.  When chronic condition management worsens, members can end up hospitalized instead of receiving care through a PCP or other outpatient setting.  Track the chronic conditions that are contributing to inpatient admissions and target decreasing inpatient activity overall for these members.</p>
            </Column>
        </Row>
        <Row style={{ paddingTop: '30px', marginLeft: "0px"}}>
            <Column style={{ backgroundColor: "#FFF", justifyContent: "center" }}>
                <div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "100%", display: "flex"}}>
                    <TreemapChart options={topDRGByAdmitsForChronicMembers.options} data={topDRGByAdmitsForChronicMembers.data}/>
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>

        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Optimization</h3></Row>
        <Row style={{ paddingTop: '30px', marginLeft: '0px'}}>
            <Column style={{ backgroundColor: "#FFF", justifyContent: "center", marginRight: '10px' }}>
                <div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "100%", display: "flex"}}>
                    <DonutChart options={admitClass.options} data={admitClass.data} />
                    <OverflowMenu/>
                </div>
            </Column>
            <Column style={{ backgroundColor: "#FFF", marginLeft: "20px", justifyContent: "center" }}>
                <div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "100%", display: "flex"}}>
                    <div style={{ backgroundColor: "white", width: '100%' }}>
						<h5 >Admit Opportunity</h5>
						<p style={{ color: "#616771", paddingBottom: '15px' }}>Total Admits</p>
						<StackedBarChart data={inpatientAdmitOpportunity.data} options={inpatientAdmitOpportunity.options} />
					</div>
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>

        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Provider Detail</h3></Row>
        <Row style={{ paddingTop: '30px', marginLeft: '0px'}}>
            <Column style={{ backgroundColor: "#FFF", justifyContent: "center" }}>
                <div id="Export-InpatientImpact" style={{backgroundColor: "white", padding: "20px", height: "100%", display:"flex"}}>
                    <ComboChart options={costOfCare.options} data={costOfCare.data} />
                    <OverflowMenu/>
                </div>
            </Column>
        </Row>

        <Row style={{ marginTop: "70px", marginLeft: '0px' }}><h3>Performance to Benchmarks</h3></Row>
        <Row style={{ paddingTop: '30px', marginLeft: '0px'}}>
            <div id="Export-admitsPer1000" style={{backgroundColor: "white", padding: "10px", paddingTop: "20px", height: "100%", display: "flex", width: '100%'}}>
                <div style={{ backgroundColor: "white", paddingBottom: '10px', width:'100%' }}>
					<h5>Inpatient Allowed Cost per Admit vs. Benchmark</h5>
					<p style={{ color: "#616771", paddingBottom: '15px' }}>Your utilization position relative to your benchmark target</p>
					<ComboChart options={inpatientAllowedCostPerAdmitVBenchmark.options} data={inpatientAllowedCostPerAdmitVBenchmark.data} />
				</div>
                <OverflowMenu/>
            </div>
        </Row>

        <Row id="TOC:Explore Related Content" style={{ marginLeft: "0px", padding: '3rem 0rem 1rem' }}>
            <h1>Explore Related Topics</h1>
        </Row>
        <Row id="tableLink" style={{marginLeft: "0px", maxWidth: '80%', marginBottom: '60px' }}>
            <Column style={{ padding: '0px' }}>
                <ul>
                    <li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
                        <StyledLink to="#" onClick={() => window.scrollTo(0, 0)}>Inpatient Provider Peformance<span style={{ color: 'gray' }}> (Special Topic)</span> </StyledLink>
                    </li>
                    <li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
                        <StyledLink to="#">Inpatient Avoidable Admissions<span style={{ color: 'gray' }}> (Special Topic)</span>  </StyledLink>
                    </li>
                    <li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
                        <StyledLink to="#">Inpatient Re-Admissions<span style={{ color: 'gray' }}> (Special Topic)</span>  </StyledLink>
                    </li>
                </ul>
            </Column>
			<Column style={{ padding: '0px' }}>
                <ul>
                    <li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
                        <StyledLink to="#" onClick={() => window.scrollTo(0, 0)}>Inpatient Savings Opportunity<span style={{ color: 'gray' }}> (Special Topic)</span> </StyledLink>
                    </li>
                    <li className='relatedList' style={{ padding: '6px 4px', color: '#0080E2' }}>
                        <StyledLink to="#">Benchmarks Summary<span style={{ color: 'gray' }}> (Storyboard Page)</span>  </StyledLink>
                    </li>
                </ul>
            </Column>
        </Row>
    </Column>
}

export {InpatientAdmissions};