import { DonutChartOptions } from "@carbon/charts/interfaces";

const pharmCostImpactData = [
    {
        "group": "Not Diagnosed with Diabetes",
        "value": 51372344
    },
    {
        "group": "Pre-Diabetes",
        "value": 5134816
    },
    {
        "group": "Controlled",
        "value": 1778710
    },
    {
        "group": "Severe",
        "value": 13515761
    }
];
const pharmCostImpactOptions:DonutChartOptions = {
    "title": "Diabetes Pharmacy Cost Impact",
    "resizable": true,
    "donut": {
        "center": {
            "label": "Total Pharmacy Cost",
            numberFormatter: (x: number) => "$" + x.toLocaleString("en-US")
        }
    },
    "height": "100%",
    "color": {
        "scale": {
            "Not Diagnosed with Diabetes": "#004877",
            "Pre-Diabetes": "#FFC800",
            "Controlled": "#FF6400",
            "Severe": "#A52040"
        }
    },
    "pie": {
        "labels":{
            "enabled":false
        }
    },
    "legend": {
        "truncation": {
            "numCharacter": 50
        }
    }
};

export {pharmCostImpactData, pharmCostImpactOptions};