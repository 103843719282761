import React, { FunctionComponent } from 'react';
import { Row, DenormalizedRow, OverflowMenu, Column } from 'carbon-components-react';

type ChartHolderProps = {
    isPDF: boolean,
    height?: string,
    children: React.ReactNode
}

/**
 * General container component meant to hold charts or other reportlets that need an overflow menu.
 * @param {boolean} isPDF Tells if the component is in a PDF or not and changes accordingly.
 * @param {string} height Desired height of internal chart. Can be any unit that would normally be in the height css property. The chart options must have a height of 100% to to work properly.
 * @param {React.ReactNode} children React components and html contained within the component. ie. Chart, table, image, etc.
 * @returns 
 */
const ChartHolder: FunctionComponent<ChartHolderProps> = ({isPDF, height, children}) => {
    if (isPDF) {
        return (
            <div style={{backgroundColor: "white", paddingLeft: "1rem", paddingTop: "1rem", paddingBottom: "1rem", paddingRight: ".5rem", display: "flex", height: height}}>
                {children}
            </div>
        );
    }
    else {
        return (
            <div style={{backgroundColor: "white", paddingLeft: "1rem", paddingTop: "1rem", paddingBottom: "1rem", paddingRight: ".5rem", display: "flex", height: height}}>
                {children}
                <OverflowMenu style={{transform: "translateY(-5px)"}}>
                </OverflowMenu>
            </div>
        );
    }
}



export {ChartHolder};