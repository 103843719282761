import React, { useState } from 'react';
import { Breadcrumb, BreadcrumbItem, Button, Checkbox, Column, Dropdown, Grid, Row, Search } from 'carbon-components-react';
import { Link } from 'react-router-dom';
import StyledLink from '../../SideNavigation/StyledLink';

export const HeaderBreadcrumb = () => (
	<Breadcrumb>
		<BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
		<BreadcrumbItem><Link to="/CompareContrast">Compare & Contrast</Link></BreadcrumbItem>
	</Breadcrumb>
);

export const dragSvg = () => {
	return (
		<svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M17.889 8.72943L14.6124 5.45289C14.4643 5.30483 14.343 5.3552 14.343 5.56509V7.47316H10.5268V3.65701L12.4227 3.64709C12.6326 3.64556 12.683 3.52345 12.5341 3.37538L9.2698 0.11105C9.12174 -0.0370166 8.87826 -0.0370166 8.7302 0.11105L5.45366 3.38759C5.30559 3.53566 5.35596 3.65701 5.56585 3.65701H7.47392V7.47316H3.65778V5.56509C3.65778 5.3552 3.53642 5.30483 3.38759 5.45366L0.11105 8.7302C-0.0370166 8.87826 -0.0370166 9.12174 0.11105 9.2698L3.38759 12.5463C3.53642 12.6944 3.65778 12.644 3.65778 12.4342V10.5261H7.47392V14.3422H5.56585C5.35596 14.3422 5.30559 14.4636 5.45366 14.6124L8.7302 17.889C8.87826 18.037 9.12174 18.037 9.2698 17.889L12.5463 14.6124C12.6952 14.4636 12.6448 14.3422 12.4349 14.3422H10.5268V10.5261H14.343V12.4342C14.343 12.644 14.4643 12.6944 14.6124 12.5456L17.889 9.26904C18.0378 9.12097 18.0378 8.87827 17.889 8.72943Z" fill="#39414D" />
		</svg>
	)
}

const items = [
	{
		id: 'option-0',
		text: 'Relationship',
	},
	{
		id: 'option-1',
		text: 'County',
	},
	{
		id: 'option-2',
		text: 'CCHG Grouping (Chronic, Healthy)',
	},
	{
		id: 'option-2',
		text: 'Plan ',
	}
];

const Measures = [{ key: 0, text: 'Medical Member Months', selected: false, category: "measure" }, { key: 1, text: 'RX Member Months', selected: false, category: "measure" }, { key: 2, text: 'Average Members', selected: false, category: "measure" }, { key: 3, text: 'Percent Female', selected: false, category: "measure" }, { key: 4, text: 'Average Member Age', selected: false, category: "measure" }, { key: 5, text: 'Percent Chronic', selected: true, category: "measure" }, { key: 6, text: 'Avg MARA Concurrent Risk', selected: false, category: "measure" }, { key: 7, text: 'Avg MARA Prospective Risk', selected: false, category: "measure" }, { key: 8, text: 'Avg MARA Agesex Risk', selected: false, category: "measure" }, { key: 9, text: '% Patients in Care Management', selected: false, category: "measure" }, { key: 10, text: 'In-Network Utilization', selected: false, category: "utilizations" }, { key: 11, text: 'PCP Visits PMPY', selected: false, category: "utilizations" }, { key: 12, text: 'Percent With Wellness Visit', selected: false, category: "utilizations" }, { key: 13, text: 'ED Cases per 1,000', selected: true, category: "utilizations" }, { key: 14, text: 'ED Visits Admitted', selected: false, category: "utilizations" }, { key: 15, text: 'Outpatient Surg per 1,000', selected: false, category: "utilizations" }, { key: 16, text: 'Admits per 1,000', selected: true, category: "utilizations" }, { key: 17, text: 'Avg LOS', selected: false, category: "utilizations" }, { key: 18, text: 'Days per 1,000', selected: false, category: "utilizations" },
{ key: 19, text: 'Allowed PMPM', selected: true, category: "financial" }, { key: 20, text: 'Paid PMPM', selected: true, category: "financial" }, { key: 21, text: 'Allowed per RVU', selected: false, category: "financial" }, { key: 22, text: 'RVUs PMPM', selected: false, category: "financial" }, { key: 23, text: 'Top 3 CCHGs By Spend', selected: false, category: "financial" }, { key: 24, text: 'Scripts per 1,000', selected: false, category: "pharmacy" }, { key: 25, text: 'Allowed per Script', selected: false, category: "pharmacy" }, { key: 26, text: '% Generic', selected: false, category: "pharmacy" }, { key: 27, text: '% Specialty', selected: false, category: "pharmacy" }];
const Dimentions = [{ key: 0, text: 'Baxter', selected: false }, { key: 1, text: 'Benton', selected: true }, { key: 2, text: 'Calhoun', selected: false }, { key: 3, text: 'Faulker', selected: true }, { key: 4, text: 'Jefferson', selected: false }, { key: 5, text: 'Newton', selected: false }, { key: 6, text: 'Pulaski', selected: true }, { key: 7, text: 'Sebastian', selected: true }, { key: 8, text: 'Union', selected: false }, { key: 9, text: 'Washington', selected: true }];

export const CompareContrast = () => {

	const [DimentionList, setDimentionList] = useState<any[]>(Dimentions);
	const [MeasuresList, setMeasuresList] = useState<any[]>(Measures);

	const onDropDownChange = (event: any) => {
		event.selectedItem.text === 'County' && setDimentionList([...Dimentions]);
	}

	const isCheckBoxSelected = () => {
		const dimSelection = DimentionList.find(item => item.selected === true);
		const mesSelection = MeasuresList.find(item => item.selected === true);
		return (dimSelection || mesSelection) ? true : false;
	}

	const onCheckBoxChangeDimention = (event: any, index: number) => {
		const position = DimentionList.findIndex(item => item.key === index);
		DimentionList[position].selected = event ? true : false;
		setDimentionList([...DimentionList]);
	}

	const onCheckBoxChangeMeasure = (event: any, index: number) => {
		const position = MeasuresList.findIndex(item => item.key === index);
		MeasuresList[position].selected = event ? true : false;
		setMeasuresList([...MeasuresList]);
	}

	const getDimElements = () => {
		let flag = false;
		DimentionList.forEach(item => { if (item.selected) { flag = true; } });
		if (!flag) {
			return <p style={{ fontSize: "14px", color: "#616771" }}><em>Selected Dimension values will appear here</em></p>;
		} else {
			return DimentionList.map((item) => {
				return item.selected ? <Row style={{ marginLeft: "0" }}><span style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>{dragSvg()}</span><Checkbox onChange={(event: any) => { onCheckBoxChangeDimention(event, item.key); }} style={{ height: "0" }} labelText={item.text} defaultChecked id={"dim_" + item.key} /></Row> : '';
			});
		}
	}

	const dimentionList = () => {
		let currentCheckItemCount = 0;
		let maxSelectionAllowed = 5;
		DimentionList.forEach(item => { if (item.selected) { currentCheckItemCount++; } });
		if (currentCheckItemCount === maxSelectionAllowed) {
			return DimentionList.map((item) => {
				return <Row style={{ marginLeft: "0" }}><Checkbox checked={item.selected} disabled={item.selected ? false : true} onChange={(event: any) => { onCheckBoxChangeDimention(event, item.key); }} style={{ height: "0" }} labelText={item.text} defaultChecked id={"dim_" + item.key} /></Row>;
			});
		}
		return DimentionList.length === 0 ? <p style={{ fontSize: "14px", color: "#616771" }}><em>Select a Dimension from the above list to populate values</em></p> : DimentionList.map((item, key) => {
			return <Row style={{ marginLeft: "0" }}><Checkbox checked={item.selected} onChange={(event: any) => { onCheckBoxChangeDimention(event, key); }} labelText={item.text} id={"" + key} /></Row>
		});
	}

	const getMembershipElements = () => {
		let currentCheckItemCount = 0;
		let maxSelectionAllowed = 10;
		MeasuresList.forEach(item => { if (item.selected) { currentCheckItemCount++; } });
		if (currentCheckItemCount === maxSelectionAllowed) {
			return MeasuresList.map((item) => {
				return item.category === 'measure' ? <Row style={{ marginLeft: "0" }}><Checkbox checked={item.selected} disabled={item.selected ? false : true} onChange={(event: any) => { onCheckBoxChangeMeasure(event, item.key); }} style={{ height: "0" }} labelText={item.text} id={"mes_" + item.key} /></Row> : '';
			});
		}
		return MeasuresList.map((item) => {
			return item.category === 'measure' ? <Row style={{ marginLeft: "0" }}><Checkbox checked={item.selected} onChange={(event: any) => { onCheckBoxChangeMeasure(event, item.key); }} style={{ height: "0" }} labelText={item.text} id={"mes_" + item.key} /></Row> : '';
		});
	}

	const getUtilizationElements = () => {
		let currentCheckItemCount = 0;
		let maxSelectionAllowed = 10;
		MeasuresList.forEach(item => { if (item.selected) { currentCheckItemCount++; } });
		if (currentCheckItemCount === maxSelectionAllowed) {
			return MeasuresList.map((item) => {
				return item.category === 'utilizations' ? <Row style={{ marginLeft: "0" }}><Checkbox checked={item.selected} disabled={item.selected ? false : true} onChange={(event: any) => { onCheckBoxChangeMeasure(event, item.key); }} style={{ height: "0" }} labelText={item.text} id={"mes_" + item.key} /></Row> : '';
			});
		}
		return MeasuresList.map((item) => {
			return item.category === 'utilizations' ? <Row style={{ marginLeft: "0" }}><Checkbox checked={item.selected} onChange={(event: any) => { onCheckBoxChangeMeasure(event, item.key); }} style={{ height: "0" }} labelText={item.text} id={"mes_" + item.key} /></Row> : '';
		});
	}

	const getFinancialElements = () => {
		let currentCheckItemCount = 0;
		let maxSelectionAllowed = 10;
		MeasuresList.forEach(item => { if (item.selected) { currentCheckItemCount++; } });
		if (currentCheckItemCount === maxSelectionAllowed) {
			return MeasuresList.map((item) => {
				return item.category === 'financial' ? <Row style={{ marginLeft: "0" }}><Checkbox checked={item.selected} disabled={item.selected ? false : true} onChange={(event: any) => { onCheckBoxChangeMeasure(event, item.key); }} style={{ height: "0" }} labelText={item.text} id={"mes_" + item.key} /></Row> : '';
			});
		}
		return MeasuresList.map((item) => {
			return item.category === 'financial' ? <Row style={{ marginLeft: "0" }}><Checkbox checked={item.selected} onChange={(event: any) => { onCheckBoxChangeMeasure(event, item.key); }} style={{ height: "0" }} labelText={item.text} id={"mes_" + item.key} /></Row> : '';
		});
	}

	const getPharmacyElements = () => {
		let currentCheckItemCount = 0;
		let maxSelectionAllowed = 10;
		MeasuresList.forEach(item => { if (item.selected) { currentCheckItemCount++; } });
		if (currentCheckItemCount === maxSelectionAllowed) {
			return MeasuresList.map((item) => {
				return item.category === 'pharmacy' ? <Row style={{ marginLeft: "0" }}><Checkbox checked={item.selected} disabled={item.selected ? false : true} onChange={(event: any) => { onCheckBoxChangeMeasure(event, item.key); }} style={{ height: "0" }} labelText={item.text} id={"mes_" + item.key} /></Row> : '';
			});
		}
		return MeasuresList.map((item) => {
			return item.category === 'pharmacy' ? <Row style={{ marginLeft: "0" }}><Checkbox checked={item.selected} onChange={(event: any) => { onCheckBoxChangeMeasure(event, item.key); }} style={{ height: "0" }} labelText={item.text} id={"mes_" + item.key} /></Row> : '';
		});
	}

	const getMeasuresElements = () => {
		let flag = false;
		MeasuresList.forEach(item => { if (item.selected) { flag = true; } })
		if (!flag) {
			return <p style={{ fontSize: "14px", color: "#616771" }}><em>Selected Measures values will appear here</em></p>;
		} else {
			return MeasuresList.map((item) => {
				return item.selected ? <Row style={{ marginLeft: "0" }}><span style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>{dragSvg()}</span><Checkbox onChange={(event: any) => { onCheckBoxChangeMeasure(event, item.key); }} style={{ height: "0" }} labelText={item.text} defaultChecked id={"mes_" + item.key} /></Row> : '';
			});
		}
	}

	const handleClearSelection = () => {
		DimentionList.forEach((element) => {
			element.selected = false;
		});
		MeasuresList.forEach((element) => {
			element.selected = false;
		});
		setDimentionList([...DimentionList]);
		setMeasuresList([...MeasuresList]);
	}

	return (
		<Grid style={{ backgroundColor: "#F8F8F8", width: "100%", maxWidth: "none", padding: "40px", marginLeft: "40px" }}>

			<Row style={{ marginTop: "10px", marginLeft: "0px", flexDirection: "column" }}>
				<Column>
					<Row style={{ marginLeft: "0px" }}><HeaderBreadcrumb /></Row>
					<Row style={{ marginLeft: "0px", marginTop: "20px", fontSize: "25px" }}>Compare & Contrast</Row>
					<Row style={{ marginLeft: "0px", fontSize: "14px", marginTop: "10px" }}>Create a comparison report using pre-defined dimensions and measures to compare up to 5 different States, Areas, Plan Types, and more.</Row>
				</Column>
			</Row>

			<Row style={{ marginLeft: "0px", marginTop: "40px" }}>
				<Column>
					<Row style={{ marginLeft: "0", display: "flex", flexDirection: "column" }}>
						<h4>Comparison Dimension</h4>
						<p style={{ fontSize: "14px" }}>Select a dimension from the list for comparison.</p>
					</Row>
					<Row style={{ marginLeft: "0", marginRight: "20px", marginTop: "20px" }}>
						<Dropdown
							style={{ width: "100%", color: "#FFF" }}
							id="ComparisonDropDown"
							className="ComparisonDropDown"
							titleText=""
							helperText=""
							label="Choose a Dimension"
							items={items}
							selectedItem={items[1]}
							itemToString={(item) => (item ? item.text : '')}
							onChange={(event) => { onDropDownChange(event); }}
						/>
					</Row>
					<Row style={{ marginLeft: "0", marginTop: "25%" }}>
						<h4>Comparison Values</h4>
						<p style={{ fontSize: "14px" }}>Attributes list will populate when a Dimension is chosen above.</p>
						<p style={{ fontSize: "14px" }}>Choose up to 5 attibutes for Comparison.</p>
					</Row>
					<div style={{
						height: "345px",
						overflow: "auto",
						marginTop: "20px",
						width: "100%",
						padding: "20px",
						display: "flex",
						flexDirection: "column",
						background: "#FFFFFF"
					}}>
						{dimentionList()}
					</div>
				</Column>
				<Column>
					<Row style={{ marginLeft: "0", display: "flex", flexDirection: "column" }}>
						<Row style={{ marginLeft: "0" }}><h4>Comparison Measures</h4></Row>
						<p style={{ fontSize: "14px" }}>Select up to 10 measures for comparison.</p>
					</Row>
					<Row style={{ marginLeft: "0", marginTop: "20px" }}>
						<Search className="ComparisonMeasureSearch" style={{ marginRight: "32px", backgroundColor: "#FFFFF" }} placeholder="Search by measure name" labelText="Search by measure name" id="search-1" />
					</Row>
					<div style={{
						overflow: "auto",
						width: "100%",
						height: "535px",
						padding: "20px",
						display: "flex",
						flexDirection: "column",
						background: "#FFFFFF"
					}}>
						<Row style={{ marginLeft: "0" }}><h6>Membership</h6></Row>
						{getMembershipElements()}
						<Row style={{ marginLeft: "0", marginTop: "15px" }}><h6 style={{ marginTop: "10px" }}>Utilization</h6></Row>
						{getUtilizationElements()}
						<Row style={{ marginLeft: "0", marginTop: "15px" }}><h6 style={{ marginTop: "10px" }}>Financial</h6></Row>
						{getFinancialElements()}
						<Row style={{ marginLeft: "0", marginTop: "15px" }}><h6 style={{ marginTop: "10px" }}>Pharmacy</h6></Row>
						{getPharmacyElements()}
					</div>
				</Column>
				<Column>
					<Row style={{ marginLeft: "0", display: "flex", flexDirection: "column" }}>
						<h4>Review</h4>
						<p style={{ fontSize: "14px" }}>Move dimensions and measures to desired order.</p>
					</Row>
					<div style={{
						marginTop: "20px",
						overflow: "auto",
						width: "100%",
						height: "280px",
						padding: "20px",
						display: "flex",
						flexDirection: "column",
						background: "#FFFFFF"
					}}>
						<h6 style={{ marginBottom: "10px" }}>Dimensions (Columns)</h6>
						{getDimElements()}
					</div>
					<div style={{ width: "85%", display: "flex", marginLeft: "28px", borderTop: "1px solid #E8E9EA" }}></div>
					<div style={{
						overflow: "auto",
						width: "100%",
						height: "300px",
						padding: "20px",
						display: "flex",
						flexDirection: "column",
						background: "#FFFFFF"
					}}>
						<h6 style={{ marginBottom: "5px" }}>Measures (Rows)</h6>
						{getMeasuresElements()}
					</div>
				</Column>
				<Column>
					{isCheckBoxSelected() ? <Row style={{ marginLeft: "20px" }}><StyledLink to="/CompareContrastReport"><Button style={{ paddingRight: "0px", width: "190px", color: "#FFFFFF", backgroundColor: "#0080E2" }}>Run Report</Button></StyledLink></Row> :
						<Row style={{ marginLeft: "20px" }}><Button style={{ paddingRight: "0px", width: "190px", color: "#727A7D", backgroundColor: "#E3E4E5", cursor: "not-allowed" }}>Run Report</Button></Row>}
						<Row style={{ marginTop: "20px", marginLeft: "20px" }}><Button onClick={handleClearSelection} style={{ paddingRight: "0px", width: "190px", color: "#39414D", backgroundColor: "#f9f9f9", borderColor: "#39414D" }}>Clear Selections</Button></Row>
				</Column>
			</Row>
		</Grid>
	);
}
