import React, { useState } from 'react';
import { Breadcrumb, BreadcrumbItem, Column, DataTable, Grid, Modal, OverflowMenu, OverflowMenuItem, Row, Search, Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow, TooltipIcon } from 'carbon-components-react';
import { ChartTypes } from '../Widget/DataDisplays/ChartTypes';
import ActivityAndInfoModal from './ActivityAndInfo';
import { actions } from '../../redux/Dashboard/actions';
import { connect } from 'react-redux';
import { Activity16, Download16, Edit16, TrashCan16 } from '@carbon/icons-react';
import ReportPacketBuilder from './ReportPacketBuilder';
import { Link } from 'react-router-dom';
import PacketDownloads from './PacketDownloads';
import PacketSuccessNotification from './PacketSuccessNotification';

export const HeaderBreadcrumb = () => (
    <Breadcrumb>
        <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
        <BreadcrumbItem><Link to="/ReportScheduler">Report Scheduler</Link></BreadcrumbItem>
    </Breadcrumb>
);
const packetHeaders = require('../../data/ReportScheduler/headers.json');

const fileRows = require('../../data/ReportScheduler/filesRows.json');
const fileHeaders = require('../../data/ReportScheduler/filesHeaders.json');

const ReportScheduler = (props: any) => {
    const [showSuccessNotification, setShowSuccessNotification] = useState(false);
    const [showDeleteWarning, setDeleteWarning] = useState(false);
    return (
        <Grid style={{ padding: "40px", marginLeft: "40px", maxWidth: "none", width: "100%" }}>
            <ActivityAndInfoModal />
            {showSuccessNotification ? PacketSuccessNotification() : null}
            {showDeleteWarning ? <Modal
                modalHeading="Delete Packet?"
                secondaryButtonText="Close"
                primaryButtonText="Delete"
                open={showDeleteWarning}
                onRequestClose={() => setDeleteWarning(false)}
                onRequestSubmit={() => setDeleteWarning(false)}
            >
                <div>Are you sure you want to delete?</div>
            </Modal> : null}
            <Row style={{ marginTop: "10px", marginLeft: "0px", flexDirection: "column" }}>
                <Column>
                    <Row>{HeaderBreadcrumb()}</Row>
                    <Row style={{ marginTop: "20px", fontSize: "25px" }}>Report Scheduler</Row>
                    <Row style={{ fontSize: "15px", marginTop: "10px" }}>Create custom packages of reports to automatically run after each data refresh.</Row>
                </Column>
            </Row>

            <Row style={{ marginTop: "20px", marginLeft: "0px" }}>
                <Column style={{ paddingRight: "70px" }} sm={4} md={4} lg={8}>
                    <Row>
                        <Column style={{ display: "flex", alignItems: "center" }}><h5>My Packets (10)</h5></Column>
                        <Column style={{ display: "flex", alignItems: "center", direction: "rtl", marginTop: "10px", paddingRight: "0" }}>
                            <TooltipIcon tooltipText="Create Packet">
                                <ReportPacketBuilder setShowSuccessNotification={(open: boolean) => setShowSuccessNotification(open)} />
                            </TooltipIcon>
                        </Column>
                        <Column>
                            <Search placeholder="Search by packet name" labelText="Search" id="search-1" />
                        </Column>
                    </Row>
                    <Row style={{ marginLeft: "0", marginRight: "0px", marginTop: "10px" }}>
                        <DataTable rows={props.packetRows} headers={packetHeaders} isSortable>
                            {({
                                rows = props.packetRows,
                                headers = packetHeaders,
                                getHeaderProps,
                                getRowProps,
                                getTableProps,
                                getTableContainerProps
                            }: { rows: ChartTypes.ITableRow[], headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
                                <TableContainer
                                    style={{ width: "100%" }}
                                    {...getTableContainerProps()}
                                >
                                    <Table {...getTableProps()} isSortable>
                                        <TableHead>
                                            <TableRow>
                                                {headers.map((header) => (
                                                    <TableHeader
                                                        key={header.key}
                                                        {...getHeaderProps({ header })}
                                                        isSortable
                                                    >
                                                        {header.header}
                                                    </TableHeader>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow {...getRowProps({ row })}>
                                                    {row.cells.map((cell) => (
                                                        cell.info.header === "edit" ? <TableCell key={cell.id}>
                                                            <OverflowMenu>
                                                                <OverflowMenuItem itemText={<><Activity16 /><span> Activity & Info</span></>} onClick={() => props.openActivityAndInfo(!props.open)} />
                                                                <OverflowMenuItem itemText={<><Edit16 /><span> Edit</span></>} />
                                                                <OverflowMenuItem itemText={<><TrashCan16 /><span> Delete</span></>} onClick={() => setDeleteWarning(true)}
                                                                    hasDivider
                                                                    isDelete
                                                                />
                                                            </OverflowMenu></TableCell> :
                                                            cell.info.header === "name" ? <TableCell key={cell.id} style={{ fontWeight: 700 }}>{cell.value}</TableCell> :
                                                                <TableCell key={cell.id}>{cell.value}</TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </DataTable>
                    </Row>
                </Column>
                <Column sm={2} md={3} lg={4}>
                    <Row style={{ marginTop: "20px" }}>
                        <Column style={{ display: "flex", alignItems: "center" }}><h5>Recent Files (8)</h5></Column>
                        <Column style={{ color: "#0080E2", display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: "45px" }}><p style={{ cursor: "pointer" }}>Clear List</p></Column>
                    </Row>
                    <Row style={{ marginTop: "15px", width: "100%" }}>
                        <PacketDownloads />
                    </Row>
                </Column>
            </Row>
        </Grid>
    );
}

const mapStateToProps = (state: any) => {
    return {
        open: state.root.activityAndInfoModalOpen,
        packetRows: state.root.reportSchedulerPackets
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        openActivityAndInfo: (open: boolean) => dispatch(actions.openActivityAndInfoModal(open))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportScheduler);
