import { Download16 } from "@carbon/icons-react";
import { DataTable, TableContainer, Table, TableRow, TableHeader, TableBody, TableHead, DataTableCell, TableCell } from "carbon-components-react";
import { ChartTypes } from "../Widget/DataDisplays/ChartTypes";
const fileHeaders = require("../../data/ReportScheduler/filesHeaders.json");
const fileRows = require("../../data/ReportScheduler/filesRows.json");

const greyFolderSvg = () => {
    return (
        <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.09083 0.818359H2.63629C1.6363 0.818359 0.827206 1.63654 0.827206 2.63654L0.818115 13.5456C0.818115 14.5456 1.6363 15.3638 2.63629 15.3638H17.1817C18.1817 15.3638 18.9999 14.5456 18.9999 13.5456V4.45472C18.9999 3.45472 18.1817 2.63654 17.1817 2.63654H9.90901L8.09083 0.818359Z" fill="#C6C9CA" />
        </svg>
    )
}

const PacketDownloads = () => {
    return (
        <DataTable rows={fileRows} headers={fileHeaders} isSortable>
            {({
                rows = fileRows,
                headers = fileHeaders,
                getHeaderProps,
                getRowProps,
                getTableProps,
                getTableContainerProps
            }: { rows: ChartTypes.ITableRow[], headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
                <TableContainer
                    style={{ width: "100%" }}
                    {...getTableContainerProps()}
                >
                    <Table {...getTableProps()} isSortable>
                        <TableHead>
                            <TableRow>
                                {headers.filter(x => x.header !== "Status").map((header) => (
                                    <TableHeader
                                        key={header.key}
                                        {...getHeaderProps({ header })}
                                        isSortable
                                    >
                                        {header.header}
                                    </TableHeader>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow {...getRowProps({ row })}>
                                    {row.cells.filter((x: any) => x.info.header !== "status").map((cell: any) => (
                                        <TableCell key={cell.id}>{cell.info.header === "fileLocation" ? <Download16 style={{ color: "#0080E2" }} /> : cell.info.header === "name" ? <span>{greyFolderSvg()}<span style={{ paddingLeft: "6px" }}>{cell.value}</span></span> : cell.value}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </DataTable>
    );
}

export default PacketDownloads;