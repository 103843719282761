import { ComboChart, GroupedBarChart, PieChart, SimpleBarChart, StackedBarChart, TreemapChart } from "@carbon/charts-react"
import { Column, DataTable, Grid, Row, Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow } from "carbon-components-react"
import { ChartHolder } from "../../Other/CommonUtil"
import { PriorCurrentNormTable } from "../../Other/PriorCurrentNormTable"
import mapImage from '../../../images/PreventiveOfficeVisitsMap.png';
import { ChartTypes } from "../../Widget/DataDisplays/ChartTypes";
import { ExploreRelatedTopics } from "../../Widget/ExploreRelatedTopics";
import OverviewBox from "../../Other/OverviewBox";

const PreventiveOfficeVisitsRow = [
    {
        "id": "1",
        "title": "Net Paid PMPM",
        "prior": "$6.22",
        "current": "+$6.45",
        "norm": "$6.75",
    },
    {
        "id": "2",
        "title": "Utilizations per 1000",
        "prior": "257.3",
        "current": "+276.3",
        "norm": "283.4",
    },
    {
        "id": "3",
        "title": "Paid per Utilization",
        "prior": "$199",
        "current": "+$235",
        "norm": "$215",
    }

]

const PreventiveOfficeVisitsHeader = [
    {
        "key": "title",
        "header": ""
    },
    {
        "key": "prior",
        "header": "Prior"
    },
    {
        "key": "current",
        "header": "Current"
    },
    {
        "key": "norm",
        "header": "Norm"
    }
]

const CancerScreeningRow = [
    {
        "id": "1",
        "title": "Breast Cancer",
        "prior": "72.4%",
        "current": "+75.6%",
        "norm": "78.3%",
    },
    {
        "id": "2",
        "title": "Cervical Cancer",
        "prior": "59.7%",
        "current": "+63.4%",
        "norm": "65.8%",
    },
    {
        "id": "3",
        "title": "Colorectal Cancr",
        "prior": "33.7%",
        "current": "+35.2%",
        "norm": "34.9%",
    }

]

const CancerScreeningHeader = [
    {
        "key": "title",
        "header": ""
    },
    {
        "key": "prior",
        "header": "Prior"
    },
    {
        "key": "current",
        "header": "Current"
    },
    {
        "key": "norm",
        "header": "Norm"
    }
]

const PreventiveOfficeVisit = {
    data: [
        {
            "group": "Without a Preventive Claim",
            "value": 55
        },
        {
            "group": "With a Preventive Claim",
            "value": 45
        }
    ],
    options: {
        "title": "Percent of Members with a Preventive Office Visit",
        "resizable": true,
        "height": "400px",
        "color": {
            "scale": {
                "With a Preventive Claim": "#0080E2",
                "Without a Preventive Claim": "#39414D"
            }
        }
    }
};

const MetabolicLabPanel = {
    data: [
        {
            "group": "Without Metabolic Lab Panel",
            "value": 56
        },
        {
            "group": "With Metabolic Lab Panel",
            "value": 44
        }

    ],
    options: {
        "title": "Percent of Members with a Metabolic Lab Panel",
        "resizable": true,
        "height": "400px",
        "color": {
            "scale": {
                "With Metabolic Lab Panel": "#0080E2",
                "Without Metabolic Lab Panel": "#39414D"
            }
        }
    }
};

const utilsByRelationship = require("../../../data/PreventionAndCompliance/UtilsByRelationship.json");
const utilsByGender = require("../../../data/PreventionAndCompliance/UtilsByGender.json");
const utilsByAgeBand = require("../../../data/PreventionAndCompliance/UtilsByAgeBand.json");
const avgMemberAge = require("../../../data/PreventionAndCompliance/AverageMemberAge.json");
const percentFemaleMembers = require("../../../data/PreventionAndCompliance/PercentFemaleMember.json");
const relationshipStatus = require("../../../data/PreventionAndCompliance/RelationshipStatus.json");
const paidByCCHG = require("../../../data/PreventionAndCompliance/PaidByCCHG.json");
const cancerScreening = require("../../../data/PreventionAndCompliance/CancerScreening.json");
const EBMComplianceAndEligible = require("../../../data/PreventionAndCompliance/EBMComplianceAndEligible.json");
const PCPClinical = require("../../../data/PreventionAndCompliance/PCPClinicalDetail.json");

export const PreventionAndCompliance = () => {
    percentFemaleMembers.options.axes.left.formatter = ((value: any) => { return value + '%' });
    return (
        <Column>
            <Row>
                <Column>
                    <OverviewBox
                        maxPerSection={3}
                        description="Evaluate the use of preventive office visits and compliance with targeted cancer screenings and chronic condition management measures."
                        sections={["Preventive Utilization - Key Indicators", "Preventive Office Visit Demographics", "Preventive Utilization Geographic Hot Spots", "Population Profile: Members without a Preventive Office Visit", "Cancer Screening EBM Compliance", "Select Chronic Conditions: EBM Compliance", "Top Attributed PCP Detail"]}
                    />
                </Column>
            </Row>
            <Row style={{ marginTop: "70px", marginLeft: '0px' }}>
                <h3>Preventive Utilization - Key Indicators</h3>
            </Row>
            <Row style={{ paddingTop: '30px', marginLeft: "0px"}}>
                <Column style={{paddingLeft: "0px"}}>
                        <PriorCurrentNormTable tableRows={PreventiveOfficeVisitsRow} tableHeaders={PreventiveOfficeVisitsHeader} title="Preventive Office Visits" />
                </Column>
                <Column>
                        <PriorCurrentNormTable tableRows={CancerScreeningRow} tableHeaders={CancerScreeningHeader} title="Cancer Screenings - EBM Compliance Rates" />
                </Column>
            </Row>
            <Row style={{ paddingTop: '30px', marginLeft: "0px"}}>
                <Column style={{paddingLeft: "0px"}}>
                    <ChartHolder isPDF={false}>
                        <PieChart options={PreventiveOfficeVisit.options} data={PreventiveOfficeVisit.data} />
                    </ChartHolder>
                </Column>
                <Column>
                    <ChartHolder isPDF={false}>
                        <PieChart options={MetabolicLabPanel.options} data={MetabolicLabPanel.data} />
                    </ChartHolder>
                </Column>

            </Row>
            <Row style={{ marginTop: "70px", marginLeft: '0px' }}>
                <h3>Preventive Office Visits - Demographic Details</h3>
            </Row>
            <Row style={{ paddingTop: '30px', marginLeft: "0px"}}>
                <Column style={{paddingLeft: "0px"}}>
                    <ChartHolder height="400px" isPDF={false}>
                        <GroupedBarChart data={utilsByRelationship.data} options={utilsByRelationship.options} />
                    </ChartHolder>
                </Column>
                <Column>
                    <ChartHolder height="400px" isPDF={false}>
                        <GroupedBarChart data={utilsByGender.data} options={utilsByGender.options} />
                    </ChartHolder>
                </Column>
            </Row>
            <Row style={{ paddingTop: '30px', marginLeft: "0px"}}>
                <Column style={{paddingLeft: "0px"}}>
                    <ChartHolder height="400px" isPDF={false}>
                        <GroupedBarChart data={utilsByAgeBand.data} options={utilsByAgeBand.options} />
                    </ChartHolder>
                </Column>
            </Row>
            <Row style={{ marginTop: "70px", marginLeft: '0px' }}>
                <h3>Preventive Visit Utilization Hot Spots</h3>
            </Row>
            <Row style={{ paddingTop: '30px', marginLeft: "0px"}}>
                <img src={mapImage} alt={"Preventive Office Visits Map"} width={"100%"} />
            </Row>
            <Row style={{ marginTop: "70px", marginLeft: '0px' }}>
                <h3>Population Profile: Members without a Preventive Office Visit</h3>
            </Row>
            <Row style={{ paddingTop: '30px', marginLeft: "0px"}}>
                <Column style={{paddingLeft: "0px"}}>
                    <ChartHolder height="400px" isPDF={false}>
                        <SimpleBarChart data={avgMemberAge.data} options={avgMemberAge.options} />
                    </ChartHolder>
                </Column>
                <Column>
                    <ChartHolder height="400px" isPDF={false}>
                        <StackedBarChart data={percentFemaleMembers.data} options={percentFemaleMembers.options} />
                    </ChartHolder>
                </Column>
                <Column>
                    <ChartHolder height="400px" isPDF={false}>
                        <StackedBarChart data={relationshipStatus.data} options={relationshipStatus.options} />
                    </ChartHolder>
                </Column>
            </Row>
            <Row style={{ paddingTop: '30px', marginLeft: "0px"}}>
                <Column style={{paddingLeft: "0px"}}>
                    <ChartHolder isPDF={false}>
                        <TreemapChart data={paidByCCHG.data} options={paidByCCHG.options} />
                    </ChartHolder>
                </Column>
            </Row>
            <Row style={{ marginTop: "70px", marginLeft: '0px' }}>
                <h3>Cancer Screening EBM Compliance</h3>
            </Row>
            <Row style={{ paddingTop: '30px', marginLeft: "0px"}}>
                <Column style={{paddingLeft: "0px"}}>
                    <ChartHolder isPDF={false}>
                        <ComboChart data={cancerScreening.data} options={cancerScreening.options} />
                    </ChartHolder>
                </Column>
            </Row>
            <Row style={{ marginTop: "70px", marginLeft: '0px' }}>
                <h3>Select Chronic Conditions EBM Compliance</h3>
            </Row>
            <Row style={{ paddingTop: '30px', marginLeft: "0px"}}>
                <Column style={{paddingLeft: "0px"}}>
                    <ChartHolder isPDF={false}>
                        <ComboChart data={EBMComplianceAndEligible.data} options={EBMComplianceAndEligible.options} />
                    </ChartHolder>
                </Column>
            </Row>
            <Row style={{ marginTop: "70px", marginLeft: '0px' }}>
                <h3>Top Attributed PCP Detail</h3>
            </Row>
            <Row style={{ paddingTop: '30px', marginLeft: "0px"}}>
                <Column style={{paddingLeft: "0px"}}>
                        <DataTable rows={PCPClinical.rows} headers={PCPClinical.headers} isSortable>
                            {({
                                rows = PCPClinical.rows,
                                headers = PCPClinical.headers,
                                getHeaderProps,
                                getRowProps,
                                getTableProps,
                                getTableContainerProps
                            }: { rows: ChartTypes.ITableRow[], headers: ChartTypes.ITableHeader[], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
                                <TableContainer
                                    style={{ width: "100%" }}
                                    title="Attributed PCP Clinical Detail Comparison"
                                    {...getTableContainerProps()}
                                >
                                    <Table {...getTableProps()} isSortable>
                                        <TableHead>
                                            <TableRow>
                                                {headers.map((header) => (
                                                    <TableHeader
                                                        key={header.key}
                                                        {...getHeaderProps({ header })}
                                                        isSortable
                                                        style={{padding: "6px 0px"}}
                                                    >
                                                        {header.header}
                                                    </TableHeader>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow {...getRowProps({ row })}>
                                                    {row.cells.map((cell: any) => (
                                                        <TableCell  className='tableCell' key={"PCPdetail-" + cell.id}>{cell.value}</TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </DataTable>
                </Column>
            </Row>
            <ExploreRelatedTopics relatedTopics={[
                { title:"Physician Visits", type:"Storyboard", href: "/" },
                { title:"Members with $0 Allowed", type:"Special Topic", href: "/" },
                { title:"Attributed PCPs", type:"Special Topic", href: "/" },
                { title:"Members with Chronic Conditions", type:"Special Topic", href: "/" }
            ]}/>
        </Column>
    );
}