import { Modal } from 'carbon-components-react';
import { ModalStateManager } from '../Other/ModalStateManager';
import FrequentFliers from './FrequentFliers';
import { fullScreenSvg } from './SVG';


export const FrequentFliersModal = () => {
    return (
        <ModalStateManager
            renderLauncher={({ setOpen }: any) => (
                <div onClick={() => setOpen(true)}>
                    <span>{fullScreenSvg()}</span>
                    <span>View All Categories</span>
                </div>
            )}>
            {({ open, setOpen }: any) => (
                <Modal
                    size="lg"
                    onRequestSubmit={() => setOpen(false)}
                    open={open}
                    onRequestClose={() => setOpen(false)}
                    id='frequentFliersDrillModal'
                >
                    <FrequentFliers />

                </Modal>
            )}
        </ModalStateManager>
    );
};