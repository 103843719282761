const desktopSvg = () => {
    return (
        <svg className='desktopSvg' width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.75 0.5H2.25C1.425 0.5 0.75 1.175 0.75 2V11C0.75 11.825 1.425 12.5 2.25 12.5H7.5L6 14.75V15.5H12V14.75L10.5 12.5H15.75C16.575 12.5 17.25 11.825 17.25 11V2C17.25 1.175 16.575 0.5 15.75 0.5ZM15.75 9.5H2.25V2H15.75V9.5Z" fill="#39414D" />
        </svg>
    )
}
const downArrowSvg = () => {
    return (
        <svg className='downArrowSvg' width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5575 0.442383L5 3.87738L8.4425 0.442383L9.5 1.49988L5 5.99988L0.5 1.49988L1.5575 0.442383Z" fill="#39414D" />
        </svg>
    )
}
const suitCaseSvg = () => {
    return (
        <svg className='suitCaseSvg' width="31" height="28" viewBox="0 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0001 20V18.5H1.51507L1.50007 24.5C1.50007 26.165 2.83507 27.5 4.50007 27.5H25.5001C27.1651 27.5 28.5001 26.165 28.5001 24.5V18.5H18.0001V20H12.0001ZM27.0001 6.5H20.9851V3.5L17.9851 0.5H11.9851L9.00007 3.5V6.5H3.00757C1.35007 6.5 0.00756836 7.8425 0.00756836 9.5V14C0.00756836 15.6575 1.35007 17 3.00757 17H12.0076V14H18.0076V17H27.0076C28.6651 17 30.0076 15.6575 30.0076 14V9.5C30.0001 7.8425 28.6576 6.5 27.0001 6.5ZM17.9926 6.5H11.9926V3.5H17.9926V6.5Z" fill="#39414D" />
        </svg>
    )
}

const fireSvg = () => {
    return (
        <svg className='fireSvg' width="24" height="32" viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.25 0.00488281C14.25 0.00488281 15.36 3.97988 15.36 7.20488C15.36 10.2949 13.335 12.8074 10.2375 12.8074C7.14 12.8074 4.8 10.3024 4.8 7.20488L4.8375 6.66488C1.8225 10.2724 0 14.9224 0 19.9999C0 26.6299 5.37 31.9999 12 31.9999C18.63 31.9999 24 26.6299 24 19.9999C24 11.9074 20.1075 4.69238 14.25 0.00488281ZM11.565 27.4999C8.895 27.4999 6.7275 25.3924 6.7275 22.7899C6.7275 20.3524 8.295 18.6424 10.95 18.1099C13.605 17.5774 16.35 16.3024 17.8725 14.2474C18.4575 16.1824 18.765 18.2224 18.765 20.2999C18.765 24.2674 15.54 27.4999 11.565 27.4999Z" fill="#39414D" />
        </svg>
    );
}
const downTrendSvg = () => {
    return (
        <svg className='downTrendSvg' style={{ marginRight: "5px" }} width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 12L16.29 9.71L11.41 4.83L7.41 8.83L0 1.41L1.41 0L7.41 6L11.41 2L17.71 8.29L20 6V12H14Z" fill="#00A562" />
        </svg>
    )
}
const downTrendRedSvg = () => {
    return (
        <svg className='downTrendRedSvg' style={{ marginRight: "5px" }} width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 12L16.29 9.71L11.41 4.83L7.41 8.83L0 1.41L1.41 0L7.41 6L11.41 2L17.71 8.29L20 6V12H14Z" fill="#841A33" />
        </svg>
    )
}

const upTrendSvg = () => {
    return (
        <svg className='upTrendSvg' style={{ marginRight: "5px" }} width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 0L16.29 2.29L11.41 7.17L7.41 3.17L0 10.59L1.41 12L7.41 6L11.41 10L17.71 3.71L20 6V0H14Z" fill="#A5203F" />
        </svg>
    );
}
const upTrendGreenSvg = () => {
    return (
        <svg className='upTrendGreenSvg' style={{ marginRight: "5px" }} width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 0L16.29 2.29L11.41 7.17L7.41 3.17L0 10.59L1.41 12L7.41 6L11.41 10L17.71 3.71L20 6V0H14Z" fill="#006B3F" />
        </svg>
    );
}
const fullScreenSvg = () => {
    return (
        <svg className='fullScreenSvg' style={{ marginRight: "10px" }} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.83333 7.66663H0.166664V11.8333H4.33333V10.1666H1.83333V7.66663ZM0.166664 4.33329H1.83333V1.83329H4.33333V0.166626H0.166664V4.33329ZM10.1667 10.1666H7.66667V11.8333H11.8333V7.66663H10.1667V10.1666ZM7.66667 0.166626V1.83329H10.1667V4.33329H11.8333V0.166626H7.66667Z" fill="#0080E2" />
        </svg>
    );
}
const crossSvg = () => {
    return (
        <svg className='crossSvg' width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.5 2.6225L19.3775 0.5L11 8.8775L2.6225 0.5L0.5 2.6225L8.8775 11L0.5 19.3775L2.6225 21.5L11 13.1225L19.3775 21.5L21.5 19.3775L13.1225 11L21.5 2.6225Z" fill="#727A7D" />
        </svg>
    );
}

export { desktopSvg, downArrowSvg, suitCaseSvg, fireSvg, downTrendSvg, upTrendSvg, downTrendRedSvg, upTrendGreenSvg, fullScreenSvg, crossSvg }